import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { retry, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { GlobalSettings } from '../settings/global-settings';
import { ScheduleModuleRestApiService } from '../schedule-module-data/schedule-module-rest-api.service';
import { WeightBalanceModuleRestApiService } from '../weight-balance-data/weight-ballance-rest-api-service';
import { Flight as FlightWB } from '../weight-balance-data/weight-balance';
import { ReferanceBoardPass, RandomPassanger } from './referances';
import { FlightListItem, Flight, Route, Flights, Process, ProcessesResources } from '../schedule-module-data/flight';
import * as moment from 'moment-timezone';
import { Comission, CommercialAct, FilterParams,
  Statement, StatementData, Baggage,
  Flight as LSFlight, Referance, Damage, Passenger} from '../lost-found-data/lost-found';
import { LostFoundModuleRestApiService } from '../lost-found-data/lost-found-rest-api.service';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.less']
})

export class TestComponent implements OnInit {

  referanceBoardPass: ReferanceBoardPass = new ReferanceBoardPass();
  flights: Array<FlightListItem> = [];
  flightsWB: Array<FlightWB> = []
  flight: Flight = new Flight(1);
  selectedFlightId = -1;
  selectedFlightIdWB = -1;
  passangerId = 0;
  barCodeText = '';
  channel: any;
  message: any;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  // Lost and found
  references = {
    airlines: [],
    airports: [],
    baggage_categories: [],
    baggage_types: [],
    baggage_materials: [],
    baggage_colors: [],
    baggage_elements: [],
    statement_types: [],
    statement_manual_types: [],
    irregularity_codes: [],
    baggage_damage_types: [],
    baggage_damage_levels: [],
    baggage_damage_locations: [],
    baggage_internals: [],
    baggage_internal_categories: [],
    baggage_statuses: []
  };

  selectLoadAnimation = {
    airlines: true,
    airports: true,
    baggage_categories: true,
    baggage_types: true,
    baggage_materials: true,
    baggage_colors: true,
    baggage_elements: true,
    statement_types: true,
    irregularity_codes: true,
    baggage_damage_types: true,
    baggage_damage_levels: true,
    baggage_damage_locations: true,
    baggage_internals: true,
    storages: false,
    notifications: false,
    worldTracer: false,
    baggage_statuses_animation: false
  };

  // 1 Неприбытие, 5 Невостребованный, 7 Засланный, 8 Задержанный, 3 Поврежденный, 2 Недостача
  statementTypesOrder: [1, 5, 7, 8, 3, 2];
  statementManualOrder: [5, 7, 8];

  // Текущая просматриваемая вкладка
  activeTab = 'tab-lost';

  balance = {
    typeTelegram: '',
    textTelegram: '',
  }

  matrixArray: CustomArray = new CustomArray(9, 2, 0);

  constructor(
    private http: HttpClient,
    public restApi: ScheduleModuleRestApiService,
    public restApiWB: WeightBalanceModuleRestApiService,
    public restApiLF: LostFoundModuleRestApiService,
    private globalSettings: GlobalSettings
  ){
    globalSettings.loadDefaultConfig();
    this.loadFlights();
    this.loadFlightsWB();
    this.matrix();
    // this.loadReferences();
  }

  ngOnInit(): void {
  }

  // async loadReferences() {
  //   const xRequestId = this.globalSettings.randomUuid;
  //   for (const key in this.references) {
  //     if (key == 'statement_manual_types') continue;
  //     if (Object.prototype.hasOwnProperty.call(this.references, key)) {
  //       this.selectLoadAnimation[key] = true;
  //       await this.restApiLF.getReference(key, xRequestId).then(data => {
  //         this.references[key] = [];
  //         if (key === 'statement_types') {
  //           this.statementManualOrder.forEach(el => {
  //             let newType = new Referance();
  //             Object.assign(newType, this.getById(data, el));
  //             this.references['statement_manual_types'].push(newType);
  //           });
  //           this.statementTypesOrder.forEach(el => {
  //             let newType = new Referance();
  //             Object.assign(newType, this.getById(data, el));
  //             const statuses = newType.baggage_statuses;
  //             let referanceStatuses: Array<Referance> = [];
  //             statuses.forEach(item => {
  //               let status = new Referance();
  //               Object.assign(status, item);
  //               referanceStatuses.push(status);
  //             });
  //             newType.baggage_statuses = referanceStatuses;
  //             this.references[key].push(newType);
  //           });
  //         } else {
  //           data.forEach(el => {
  //             const item = new Referance();
  //             Object.assign(item, el);
  //             this.references[key].push(item);
  //           });
  //         }
  //       });
  //       this.selectLoadAnimation[key] = false;
  //     }
  //   }
  // }

  matrix() {
    console.log('Matrix test run');
  }

  matrixOp() {
    console.log(this.matrixArray.getArray());
  }

  changeTab(event: { target: { id: string; }; }) {
    this.activeTab = event.target.id;
    this.loadTab();
  }

  loadTab() {
    switch (this.activeTab) {
      case 'tab-comman': {
        break;
      }
      case 'tab-barcode': {
        this.loadFlights();
        this.selectedFlightId = -1;
        break;
      }
    }
  }

  showTab(item: string): boolean {
    return item === this.activeTab;
  }

  sendMessage(channel: string, message: string) {
    return this.addMessage(channel, message).subscribe((data: {}) => {
      console.log('sended' + data);
      this.message = '';
      this.channel = '';
    });
  }

  addMessage(channel: string, message: string) {
    return this.http.post(this.globalSettings.apiTestURL + '/messages/' + channel, message)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  sendBalanceTlg() {
    return this.sendTlg(this.balance).subscribe((data: {}) => {
      console.log('sended' + data);
      this.balance = {
        typeTelegram: '',
        textTelegram: '',
      }
    });
  }

  sendTlg(balance: any) {
    return this.http.post(this.globalSettings.apiWeightBalanceURL + '/debug/' + balance.typeTelegram, balance.textTelegram)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  // For Barcode generator
  loadFlights() {
    return this.restApi.getFlights(null).subscribe((data: Flights) => {
      console.log(data);
      for (const item of data.departure) {
        let flight = new FlightListItem();
        Object.assign(flight, item);
        this.flights.push(flight);
      }
      if (this.flights.length > 0) {
        this.generateBarcodeForFlight(this.flights[0].id);
      }
    });
  }

  loadFlightsWB() {
    this.restApiWB.getFlights(null, null).then(data => {
        this.flightsWB = [];
        data.forEach(el => {
          let flight = new FlightWB(this.globalSettings.homeAirport);
          Object.assign(flight, el);
          this.flightsWB.push(flight);
        });
    });
  }

  loadFlight(id: number) {
    if (id == 0) {
      return;
    }
    console.log(id);
    return this.restApi.getFlight(id).subscribe((data: Flight) => {
      console.log(data);
      Object.assign(this.flight, data);
      this.flight.route = [];

      for (const item of data.route) {
        let route = new Route();
        Object.assign(route, item);
        this.flight.route.push(route);
      }

      for (const key in data.processes) {
        let processes = new Process();
        Object.assign(processes, data.processes[key]);
        if (processes.resources && processes.resources.length==0) {
          processes.resources.push(new ProcessesResources());
        }
        this.flight.processes[key] = processes;
      }
    });
  }

  async generateBarcodeForFlight(id) {
    console.log('generateBarcodeForFlight');
    this.selectedFlightId = id;
    await this.loadFlight(id);
    // Barcode string
    // M1MAMLEEVA/LARISA     EFVMUYR SVXSVOSU 1419 057Y008E0052 100
    //
    // Format size and example fields:
    // Format Code: size 1 - 'M'
    // Number of Legs Encoded: size 1 - '1'
    // Passenger Name: size 20 - 'MAMLEEVA/LARISA     '
    // Electronic Ticket Indicator: size 1 - 'E'
    // Operating carrier PNR Code: size 7 - 'FVMUYR '
    // FromCityAirportCode: size 3 - 'SVX'
    // To City Airport Code: size 3 - 'SVO'
    // Operating carrier Designator: size 3 - 'SU '
    // Flight Number: size 5 - '1419 '
    // Date of Flight (Julian Date): size 3 - '057'
    // Compartment Code: size 1 - 'Y'
    // Seat Number: size 4 - '008E'
    // Check-in Sequence Number: size 5 - '0052 '
    // Passenger Status: size 1 - '1'
    // Field Size of variable size field (Conditional + Airline item 4): size 2 - '00'
  }

  setFlightIdWB(id) {
    this.selectedFlightIdWB = id;
  }

  getFlightWB() {
    for (let flight of this.flightsWB) {
      if (flight.id == this.selectedFlightIdWB) {
        return flight;
      }
    }
    return null;
  }

  addName(value) {
    this.referanceBoardPass.name = value;
    this.updateBarcodeLine();
  }

  addTicket(value) {
    this.referanceBoardPass.ticketType = value;
    this.updateBarcodeLine();
  }

  addPNR(value) {
    this.referanceBoardPass.pnr = value;
    this.updateBarcodeLine();
  }

  addSeatNumber(value) {
    this.referanceBoardPass.seatNumber = value;
    this.updateBarcodeLine();
  }

  addSequenceNumber(value) {
    this.referanceBoardPass.sequenceNumber = value;
    this.updateBarcodeLine();
  }

  updateBarcodeLine() {
    this.barCodeText = this.referanceBoardPass.formatCode +
                          this.referanceBoardPass.name +
                          this.referanceBoardPass.ticketType +
                          this.referanceBoardPass.pnr +
                          this.referanceBoardPass.from +
                          this.referanceBoardPass.to +
                          this.referanceBoardPass.airline +
                          this.referanceBoardPass.flight +
                          this.referanceBoardPass.date +
                          this.referanceBoardPass.compartmentCode +
                          this.referanceBoardPass.seatNumber +
                          this.referanceBoardPass.sequenceNumber +
                          this.referanceBoardPass.pasangerStatus +
                          this.referanceBoardPass.fieldSize;
  }

  loadPassanger(number) {
    this.passangerId = number;
    switch (number) {
      case 1:
        this.referanceBoardPass = new ReferanceBoardPass();
        this.referanceBoardPass.name = 'MAMLEEVA/LARISA';
        this.referanceBoardPass.pnr = 'ABC123';
        this.referanceBoardPass.from = this.flight.route[0].airportIata;
        this.referanceBoardPass.to = this.flight.route[1].airportIata;
        this.referanceBoardPass.airline = this.flight.airline;
        this.referanceBoardPass.flight = this.flight.flight;
        this.referanceBoardPass.date = this.flight.route[0].dtDeparture[0].toString();
        this.referanceBoardPass.seatNumber = '8E';
        this.referanceBoardPass.sequenceNumber = '52';
        console.log('this.referanceBoardPass');
        console.log(this.referanceBoardPass);
        this.updateBarcodeLine();
      break;
      case 2:
        this.referanceBoardPass = new ReferanceBoardPass();
        this.referanceBoardPass.name = 'GRIGORYEV/DANIL';
        this.referanceBoardPass.pnr = 'DFH884';
        this.referanceBoardPass.from = this.flight.route[0].airportIata;
        this.referanceBoardPass.to = this.flight.route[1].airportIata;
        this.referanceBoardPass.airline = this.flight.airline;
        this.referanceBoardPass.flight = this.flight.flight;
        this.referanceBoardPass.date = this.flight.route[0].dtDeparture[0].toString();
        this.referanceBoardPass.seatNumber = '3B';
        this.referanceBoardPass.sequenceNumber = '78';
        console.log('this.referanceBoardPass');
        console.log(this.referanceBoardPass);
        this.updateBarcodeLine();
      break;
      case 3:
        this.referanceBoardPass = new ReferanceBoardPass();
        this.referanceBoardPass.name = 'SINITSYN/IGOR';
        this.referanceBoardPass.pnr = 'JJJ909';
        this.referanceBoardPass.from = this.flight.route[0].airportIata;
        this.referanceBoardPass.to = this.flight.route[1].airportIata;
        this.referanceBoardPass.airline = this.flight.airline;
        this.referanceBoardPass.flight = this.flight.flight;
        this.referanceBoardPass.date = this.flight.route[0].dtDeparture[0].toString();
        this.referanceBoardPass.seatNumber = '10A';
        this.referanceBoardPass.sequenceNumber = '107';
        console.log('this.referanceBoardPass');
        console.log(this.referanceBoardPass);
        this.updateBarcodeLine();
      break;
      case 4:
        this.referanceBoardPass = new ReferanceBoardPass();
        this.referanceBoardPass.name = 'BOLGARIN/EVGENII';
        this.referanceBoardPass.pnr = 'JH745';
        this.referanceBoardPass.from = this.flight.route[0].airportIata;
        this.referanceBoardPass.to = this.flight.route[1].airportIata;
        this.referanceBoardPass.airline = this.flight.airline;
        this.referanceBoardPass.flight = this.flight.flight;
        this.referanceBoardPass.date = this.flight.route[0].dtDeparture[0].toString();
        this.referanceBoardPass.seatNumber = '12F';
        this.referanceBoardPass.sequenceNumber = '45';
        console.log('this.referanceBoardPass');
        console.log(this.referanceBoardPass);
        this.updateBarcodeLine();
      break;
      case 5:
        // Random passanger
        let randomPassanger = new RandomPassanger();
        this.referanceBoardPass = new ReferanceBoardPass();
        this.referanceBoardPass.name = randomPassanger.name;
        this.referanceBoardPass.pnr = randomPassanger.pnr;
        this.referanceBoardPass.from = this.flight.route[0].airportIata;
        this.referanceBoardPass.to = this.flight.route[1].airportIata;
        this.referanceBoardPass.airline = this.flight.airline;
        this.referanceBoardPass.flight = this.flight.flight;
        this.referanceBoardPass.date = this.flight.route[0].dtDeparture[0].toString();
        this.referanceBoardPass.seatNumber = randomPassanger.seatNumber;
        this.referanceBoardPass.sequenceNumber = randomPassanger.sequenceNumber;
        console.log('this.referanceBoardPass');
        console.log(this.referanceBoardPass);
        this.updateBarcodeLine();
      break;
      case 6:
        this.referanceBoardPass = new ReferanceBoardPass();
        this.referanceBoardPass.name = 'PNEV/KONSTANTIN';
        this.referanceBoardPass.pnr = 'FGW394';
        this.referanceBoardPass.from = this.flight.route[0].airportIata;
        this.referanceBoardPass.to = this.flight.route[1].airportIata;
        this.referanceBoardPass.airline = this.flight.airline;
        this.referanceBoardPass.flight = this.flight.flight;
        this.referanceBoardPass.date = this.flight.route[0].dtDeparture[0].toString();
        this.referanceBoardPass.seatNumber = 'INF';
        this.referanceBoardPass.sequenceNumber = '345';
        console.log('this.referanceBoardPass');
        console.log(this.referanceBoardPass);
        this.updateBarcodeLine();
      break;
      case 7:
        this.referanceBoardPass = new ReferanceBoardPass();
        this.referanceBoardPass.name = 'MAZHARTSEV/DENIS';
        this.referanceBoardPass.pnr = 'RPT489';
        this.referanceBoardPass.from = this.flight.route[0].airportIata;
        this.referanceBoardPass.to = this.flight.route[1].airportIata;
        this.referanceBoardPass.airline = this.flight.airline;
        this.referanceBoardPass.flight = this.flight.flight;
        this.referanceBoardPass.date = this.flight.route[0].dtDeparture[0].toString();
        this.referanceBoardPass.seatNumber = '';
        this.referanceBoardPass.sequenceNumber = '345';
        console.log('this.referanceBoardPass');
        console.log(this.referanceBoardPass);
        this.updateBarcodeLine();
      break;
      default:
        this.referanceBoardPass = new ReferanceBoardPass();
        this.referanceBoardPass.name = 'KRUGLOV/SERGEI';
        this.referanceBoardPass.pnr = 'HIO583';
        this.referanceBoardPass.from = this.flight.route[0].airportIata;
        this.referanceBoardPass.to = this.flight.route[1].airportIata;
        this.referanceBoardPass.airline = this.flight.airline;
        this.referanceBoardPass.flight = this.flight.flight;
        this.referanceBoardPass.date = this.flight.route[0].dtDeparture[0].toString();
        this.referanceBoardPass.seatNumber = '34D';
        this.referanceBoardPass.sequenceNumber = '103';
        console.log('this.referanceBoardPass');
        console.log(this.referanceBoardPass);
        this.updateBarcodeLine();
        break;
    }
  }

  get currentFlightPlanDateTime(): string {
    if (this.flight && this.flight.route.length > 0) {
      return this.flight.route[0].dtDeparture[0].toString();
    } else {
      return '';
    }
  }

  setDefaultTelegramText() {
    if (this.balance.typeTelegram === "wgtmsg") {
      this.balance.textTelegram = `ZCZC
QU ALLDESK
.LCLHOST
WGTMSG 05062022095837 73144 ` + this.getFlightWB().airlineIata +  this.getFlightWB().flightNumber +` ` + moment(this.getFlightWB().homeRoute.dtDepartureShow).format("DDMMMYYYY").toUpperCase() + ` ` +  this.getFlightWB().homeRoute.airportIata + ` ` +  this.getFlightWB().nextRoute.airportIata + ` KGS MTOW=317514 MLDW=251290 FUEL=12000 BURN=6400 TXOF=200 TXIF=NIL STD=1235.`
    } else if (this.balance.typeTelegram === "uws") {
      this.balance.textTelegram = `UWS
` + this.getFlightWB().airlineIata +  this.getFlightWB().flightNumber + `/` + moment(this.getFlightWB().homeRoute.dtDepartureShow).format("DD").toUpperCase() + `.` + this.getFlightWB().homeRoute.airportIata + `
-PMC51233SU/` + this.getFlightWB().nextRoute.airportIata + `/2105P/C.PER
-PLA00137SU/` + this.getFlightWB().nextRoute.airportIata + `/1116P/M
-PMC69060SU/` + this.getFlightWB().nextRoute.airportIata + `/1315P/C.PER
-PNA69227SU/` + this.getFlightWB().nextRoute.airportIata + `/1230P/C.PER
-PNA69052SU/` + this.getFlightWB().nextRoute.airportIata + `/1648P/C.PER
BULK
-` + this.getFlightWB().nextRoute.airportIata + `/168P/E
-` + this.getFlightWB().nextRoute.airportIata + `/90P/E`
    } else if (this.balance.typeTelegram === "pnl_adl") {
      this.balance.textTelegram = `=HEADER
RCV,2019/10/05 12:00
=DESTINATION TYPE B
STX,SVXOP7X
STX,SVXKP7X
=ORIGIN
LEDPNFV
=MSGID
051200
=SMI
PNL
=TEXT
` + this.getFlightWB().airlineIata +  this.getFlightWB().flightNumber + `/` +  moment(this.getFlightWB().homeRoute.dtDepartureShow).format("DDMMM").toUpperCase() + ` ` +  this.getFlightWB().homeRoute.airportIata + ` PART1 \
CFG/C14
RBD C/C Y/Y
AVAIL
  ` +  this.getFlightWB().homeRoute.airportIata + `  ` +  this.getFlightWB().nextRoute.airportIata + `
C008
Y066
-` +  this.getFlightWB().homeRoute.airportIata + `000C
-` +  this.getFlightWB().homeRoute.airportIata + `054Y
1AZMOUN/SARDAR KHALIL MR-A30
.C/CHARTGRP
.L/221K0C/FV
.L/5CPTZ7/1H
.R/TKNE HK1 1952434825236/1
.R/DOCS HK1/P/IR/U96415139/IR/01JAN95/M/16JUL24/AZMOUN/SARDAR
.RN/ KHALIL
1BIRIUKOV/MIKHAIL IUREVICH MR-B24
.C/CHARTGRP
.L/221K0G/FV
.L/5CPTW7/1H
.R/TKNE HK1 1952434825264/1
.R/DOCS HK1/P/RU/4005601477/RU/07MAY58/M/01JAN30/BIRIUKOV
.RN//MIKHAIL IUREVICH
1BULYCHEV/VALERII VITALEVICH MR-A30
.R/TKNE HK1 1952434825246/1
.R/DOCS HK1/P/RU/4010088151/RU/05JUN65/M/01JAN30/BULYCHEV
.RN//VALERII VITALEVICH
1CARMINATI/IVAN MR-B24
.R/TKNE HK1 1952434825266/1
.R/DOCS HK1/P/IT/YA9225072/IT/13SEP54/M/07MAR26/CARMINATI/IVAN
1DE/OLIVEIRA WILLIAM ARTUR MR-B24
.R/TKNE HK1 1952434825271/1
.R/DOCS HK1/P/BR/YB687025/BR/20OCT82/M/30AUG20/DE/OLIVEIRA
.RN/ WILLIAM ARTUR
ENDPNL`
    }
  }

  getById(array, id) {
    if (array && id) {
      return array.find(el => el.id === id) || null;
    }
  }

  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }
}

class CustomArray {
  private CustomArray: Array<Array<number>> =  new Array<Array<number>>();
  private rows: number;
  private columns: number;

  /**
   * Инициализировать массив
   */
  public constructor(rows:number,columns:number,value:number){
      this.rows = rows;
      this.columns = columns;
      this.initRows(rows);
      this.initColumns(columns,value);
  }
  /**
   * Возьмите значение в массиве
   */
  public getValue(rows:number,columns:number):number{
      if(rows < 0 || columns < 0 || rows >= this.rows || columns >= this.columns){
          return null;
      }
      return this.CustomArray[rows][columns];
  }
  /**
   * Назначить значения для массивов
   */
  public setValue(rows:number,columns:number,value:number):void{
      if(rows < 0 || columns < 0 || rows >= this.rows || columns >= this.columns){
          return ;
      }
      this.CustomArray[rows][columns] = value;
  }
  /**
   * Количество строк инициализации
   */
  private initRows(rows:number):void{
      if(rows < 1) {
          return;
      }
      for(let i = 0 ; i < rows ; i ++){
          this.CustomArray.push(new Array<number>());
      }
  }
  /**
   * Инициализировать количество столбцов
   */
  private initColumns(columns:number,value:number):void{
      if(columns < 1){
          return;
      }
      for(let i = 0 ; i < this.CustomArray.length ; i ++){
          for(let j = 0 ; j < columns ; j ++){
              this.CustomArray[i].push(value);
          }
      }
  }
  /**
   * Получить массив
   */
  public getArray():Array<Array<number>>{
      return this.CustomArray;
  }
}

class Bay {
  constructor(mapIndex: []) {
    this.position = mapIndex;
  }

  position: [];
}
