import { Component, OnInit, ViewChild } from '@angular/core';
import { Comission, CommercialAct, FilterParams,
        Statement, StatementData, Baggage,
        Flight, Referance, Damage, Passenger} from '../lost-found-data/lost-found';
import { LostFoundModuleRestApiService } from '../lost-found-data/lost-found-rest-api.service';
import { GlobalSettings, TypeEnterprise } from '../settings/global-settings';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Subject, timer } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { NgSelectComponent } from '@ng-select/ng-select';
import * as moment from 'moment-timezone';
import { FileSaverService } from 'ngx-filesaver';
import { GlobalI18n } from '../settings/global-i18n';
import { ModuleName } from '../settings/global-settings';
import { isNgTemplate } from '@angular/compiler';

@Component({
  selector: 'app-lost-found-module',
  templateUrl: './lost-found-module.component.html',
  styleUrls: ['./lost-found-module.component.less']
})

export class LostFoundModuleComponent implements OnInit {
  @ViewChild('closeButton') closeButton: any;
  @ViewChild('newClaimAirport') newClaimAirport: NgSelectComponent;

  typeEnterprise = TypeEnterprise;

  filter = {
    params: new FilterParams(),
    apply: false,
    show: false,
  };

  filterManual = {
    params: new FilterParams(),
    apply: false,
    show: false,
  };

  classes = {
    baggage: Baggage,
    flight: Flight,
  };

  error = {
    errorMessage: '',
    errorType: '',
  };

  pagination = {
    countsPerPage: [20, 40, 80],
    perPage: 20,
    pageActive: 1,
    statementsCount: 0,
    counters: null,
  };

  marks: Array<string> = [];

  viewParametrs = {
    activeWindow: 'statementsList',
    loading: true,
    baggageBlock: 0,
    // 1 Неприбытие, 5 Невостребованный, 7 Засланный, 8 Задержанный, 3 Поврежденный, 2 Недостача
    statementTypesOrder: [1, 5, 7, 8, 3, 2],
    statementManualOrder: [5, 7, 8]
  };

  // массив добавленных в сравнение
  baggageCompareArray = [];

  statementOfList: number;

  references = {
    airlines: [],
    airports: [],
    baggage_categories: [],
    baggage_types: [],
    baggage_materials: [],
    baggage_colors: [],
    baggage_elements: [],
    statement_types: [],
    statement_manual_types: [],
    irregularity_codes: [],
    baggage_damage_types: [],
    baggage_damage_levels: [],
    baggage_damage_locations: [],
    baggage_internals: [],
    baggage_internal_categories: [],
    baggage_statuses: []
  };


  baggageInternalForCategory: Array<Referance> = [];
  // Текущая выбранная категория для содержимого багажа
  baggageInternalCategoryId: number = null;
  // Текущая выбранный элемент в категории для содержимого багажа
  baggageInternalId: number = null;

  adminData = {
    storages: [
      {id: 1, name: 'МВЛ'},
      {id: 2, name: 'ВВЛ'},
      {id: 3, name: 'A_ВВЛ'},
      {id: 4, name: 'A_МВЛ'},
      {id: 5, name: 'В_ВВЛ'},
      {id: 6, name: 'В_МВЛ'},
      {id: 7, name: 'C'},
      {id: 8, name: 'D'},
      {id: 9, name: 'E'},
      {id: 9, name: 'F'}
    ],
    notifications: [{id: 1, name: 'Email'}, {id: 2, name: 'SMS'}],
    worldTracer: [{id: 1, name: 'AHL'}, {id: 2, name: 'AH'}],
  };

  reasons = [
    {id: 1, name: 'Неприбытие багажа'},
    {id: 2, name: 'Недостача багажа'},
    {id: 3, name: 'Повреждение багажа'},
    {id: 4, name: 'Утрата багажа'},
    {id: 5, name: 'Невостребованный багаж'},
    {id: 6, name: 'Засланный багаж'},
  ];

  selectLoadAnimation = {
    airlines: true,
    airports: true,
    baggage_categories: true,
    baggage_types: true,
    baggage_materials: true,
    baggage_colors: true,
    baggage_elements: true,
    statement_types: true,
    irregularity_codes: true,
    baggage_damage_types: true,
    baggage_damage_levels: true,
    baggage_damage_locations: true,
    baggage_internals: true,
    storages: false,
    notifications: false,
    worldTracer: false,
    baggage_statuses_animation: false
  };

  buffer = {
    airlines: [],
    airports: [],
  };

  // Размер отображаемых данных в выпадающем списке
  bufferSize = {
    airlines: 50,
    airports: 50,
  };

  filterParams = {
    statementsAll: true,
    statements: [],
    timesAll: false,
    times: [],
    storageAll: false,
    storage: [],
  };

  filterParamsManual = {
    statementsAll: false,
    statements: [5, 7, 8],
    timesAll: false,
    times: [],
    storageAll: false,
    storage: []
  };

  localReferences = {
    times: [
      {value: 5, nameEnglish: '5 days', nameLocal: '5 дней'},
      {value: 21, nameEnglish: '21 days', nameLocal: '21 день'},
    ],
    storage: [
      {value: 180, nameEnglish: '6 month', nameLocal: '6 месяцев'}
    ],
    gender: [
      {nameEnglish: 'Male', nameLocal: 'Мужской'},
      {nameEnglish: 'Female', nameLocal: 'Женский'}
    ],
    passenger_status: [
      {nameEnglish: 'Bonus', nameLocal: 'Bonus'},
      {nameEnglish: 'Silver', nameLocal: 'Silver'},
      {nameEnglish: 'Gold', nameLocal: 'Gold'},
      {nameEnglish: 'Platinum', nameLocal: 'Platinum'}
    ],
    languages: [
      {nameEnglish: 'Russian', nameLocal: 'Русский'},
      {nameEnglish: 'English', nameLocal: 'Английский'},
      {nameEnglish: 'Chinese', nameLocal: 'Китайский'},
      {nameEnglish: 'German', nameLocal: 'Немецкий'},
      {nameEnglish: 'French', nameLocal: 'Французский'},
      {nameEnglish: 'Spanish', nameLocal: 'Испанский'}
    ],
    classes: [
      {nameEnglish: 'Economy', nameLocal: 'Эконом'},
      {nameEnglish: 'Business', nameLocal: 'Бизнес'},
      {nameEnglish: 'Comfort', nameLocal: 'Комфорт'},
      {nameEnglish: '1 class', nameLocal: '1 класс'},
    ],
    address_types: [
      {nameEnglish: 'Registration address', nameLocal: 'Адрес регистрации'},
      {nameEnglish: 'Temporary address', nameLocal: 'Временный адрес'},
    ],
  };

  numberOfItemsFromEndBeforeFetchingMore = 10;

  statements: Array<Statement>;
  statement: StatementData = new StatementData();
  manualSearchStatement: Statement = new Statement();

  commercialAct: CommercialAct = new CommercialAct();

  closeResult: string;

  autoSaveData = new Subject();

  // Таймер обновления данных
  interval: any;

  filterLoadAnimation = {
    name: false
  };

  public addTagClaim: (name) => void;
  public addTagBaggageTag: (name) => void;

  constructor(
    public restApi: LostFoundModuleRestApiService,
    public globalSettings: GlobalSettings,
    private modalService: NgbModal,
    private fileSaverService: FileSaverService,
    public globalI18n: GlobalI18n,
  ) {
    globalSettings.loadDefaultConfig();
    this.addTagClaim = (name) => this.addTagFilterFromArray(name, 'name');
    this.addTagBaggageTag = (name) => this.addTagFilterFromArray(name, 'tag');
  }

  ngOnInit(): void {
    Promise.all([
      this.loadReferences(),
      this.loadMarks(),
    ]).then(() => {
    });
    this.loadStatements();

    this.loadAdminData();

    // debounceTime() - время в миллисекундах перед сохранением данных, если в течении
    // этого времени приходят изменения то будет сохранено последнее состояние
    // distinctUntilChanged() - сравнение полученого объекта на изменения, если
    // данные прежние, сохранение не проихсодит
    this.autoSaveData
    .pipe(
      debounceTime(350),
      distinctUntilChanged(),
      switchMap(async () => {
        await this.saveStatement();
      })
    ).subscribe(result => {
      // console.log("result: ", result);
    }, error => {
      // console.log("error");
    });
  }

  // Обработка значения, введенного в поле фильтра
  addTagFilterFromArray(names, key) {
    if (!names) {
      return;
    }

    names = names.toUpperCase().split(' ');

    // Скрытие оригинальной строки ng-select, так как
    // не корректно отрабатывает добавление элементов.
    // После скрытия и повторной отрисовки - все отлично.
    // Нюанс работы с компонентом.
    this.filterLoadAnimation[key] = true;
    if (names.length > 1) {
      // Добавление всех элементов в адреса, если выше проверки пройденны
      names.forEach(element => {
        if (element.length !== 0 && !this.filter.params[key].includes(element)) {
          this.filter.params[key] = [...this.filter.params[key], element.trim()];
        }
      });
    } else if (!this.filter.params[key].includes(names[0])) {
      // Если в массиве один элемент, использование стандартного метода добавления
      // единичной записи для ng-select
      this.filter.params[key] = [...this.filter.params[key], names[0].trim()];
    }
    this.interval = setTimeout(() => {
      this.filterLoadAnimation[key] = false;
    }, 250);
  }

  filterArchive() {
    this.filter.params.archived = !this.filter.params.archived;
    this.loadStatements();
  }

  quickSearchCheck() {
    let newFilter = new FilterParams();
    newFilter.archived = this.filter.params.archived;
    return JSON.stringify(this.filter.params) === JSON.stringify(newFilter);
  }

  async loadStatements(activePage = 1) {
    this.viewParametrs.activeWindow = 'statementsList';
    const xRequestId = this.globalSettings.randomUuid;
    this.filter.params.type = this.filterParams.statementsAll ? this.viewParametrs.statementTypesOrder : this.filterParams.statements;

    const paramsStatements: FilterParams = new FilterParams();
    if (this.filter.apply) {
      // Дублируем. чтобы не менять исходный
      Object.assign(paramsStatements, this.filter.params);
    }

    paramsStatements.mark = this.filter.params.mark;
    paramsStatements.type = this.filter.params.type;
    paramsStatements.archived = this.filter.params.archived;

    this.restApi.getCountStatements(paramsStatements, this.filterParams.times, xRequestId).then((counters: any) => {
      this.pagination.statementsCount = counters.filtered.all;
      this.pagination.counters = counters;
    });

    this.pagination.pageActive = activePage;

    const data = await this.restApi.getStatements(this.pagination, paramsStatements, this.filterParams.times, xRequestId);
    this.statements = [];
    data.forEach(el => {
      const statement = new Statement(el);
      this.statements.push(statement);
    });
    this.viewParametrs.loading = false;
    this.statement = new StatementData();
    this.statementOfList = null;
    this.baggageCompareArray = [];
  }

  async loadManualStatements(activePage = 1) {
    const xRequestId = this.globalSettings.randomUuid;
    this.filterManual.params.type = this.filterParamsManual.statements.length === 0
                                    ? this.viewParametrs.statementManualOrder
                                    : this.filterParamsManual.statements;

    this.restApi.getCountStatements(this.filterManual.params, this.filterParamsManual.times, xRequestId).then((counters: any) => {
      this.pagination.statementsCount = counters.filtered.all;
      this.pagination.counters = counters;
    });

    this.pagination.pageActive = activePage;

    const data = await this.restApi.getStatements(this.pagination, this.filterManual.params, this.filterParamsManual.times, xRequestId);
    this.statements = [];
    data.forEach(el => {
      const statement = new Statement(el);
      this.statements.push(statement);
    });
    this.viewParametrs.loading = false;
    this.statement = new StatementData();
    this.statementOfList = null;
    this.baggageCompareArray = [];
  }

  createStatement() {
    this.statement = new StatementData();
    // Для аэропорта автоматом заполняется поле Аэропорт в заявлении,
    // для авиакомпании поле Авиакомпания в заявлении
    if (this.globalSettings.typeEnterprise == this.typeEnterprise.Airport) {
      if (this.globalSettings.defaultAirportId) {
        this.statement.airportId = this.globalSettings.defaultAirportId;
      }
    } else {
      if (this.globalSettings.defaultAirlineId) {
        this.statement.airlineId = this.globalSettings.defaultAirlineId;
      }
    }
    timer(700).subscribe(() => {
      this.newClaimAirport.focus();
    });
  }

  async loadMarks() {
    const xRequestId = this.globalSettings.randomUuid;
    const data = await this.restApi.getMarks(xRequestId);
    this.marks = data;
  }

  async loadStatement(id: string, index?) {
    const xRequestId = this.globalSettings.randomUuid;
    // Копирование данных текущей записи для отображения в ручом поиске
    // плохой код, форма ручного поиска нуждается в переосмыслении
    for (let statement of this.statements) {
      if (statement.statementId == id) {
        this.manualSearchStatement = statement;
      }
    }

    const data = await this.restApi.getStatement(id, xRequestId);
    this.statement = new StatementData(data);
    this.statement.baggage = [];
    data.baggage.forEach(el => {
      let baggage = new Baggage(el);
      this.statement.baggage.push(baggage);
    });
    this.viewParametrs.baggageBlock = 0;
    this.statementOfList = index;
  }

  openStatement() {
    this.viewParametrs.activeWindow = 'statementItem';
  }

  manualSearch() {
    this.viewParametrs.activeWindow = 'manualSearch';
  }

  clearManualStatement() {
    this.manualSearchStatement = new Statement();
  }

  async addStatement() {
    const xRequestId = this.globalSettings.randomUuid;
    const data = await this.restApi.addStatement(this.statement, xRequestId);
    // Получение данных из заголовков ответа
    const location = data.headers.get('Location');
    this.statement.id = location.replace('/statements/', '');
    this.closeButton.nativeElement.click();
    this.loadStatement(this.statement.id);
    this.openStatement();
    return;
  }

  async saveStatement() {
    const xRequestId = this.globalSettings.randomUuid;
    await this.restApi.updateStatement(this.statement, xRequestId);
    this.closeButton.nativeElement.click();
  }

  async sendToArchive() {
    this.statement.archived = true;
    await this.saveStatement();
    this.loadStatements();
  }

  async restoreFromArchive() {
    this.statement.archived = false;
    await this.saveStatement();
    this.loadStatements();
  }

  loadBagData() {
    const xRequestId = this.globalSettings.randomUuid;
    this.statement.baggage.forEach(async el => {
      let data = await this.restApi.getFromBagData(el.id, xRequestId);
      el.bagData = data.sort((a, b) => b.score - a.score);
    });
  }

  loadComparison() {
    return;
  }

  addToCompare(val) {
    this.baggageCompareArray.push(val);
  }

  removeToCompare(id) {
    this.baggageCompareArray = this.baggageCompareArray.filter(el => el.id !== id);
  }

  async makeBaggageSelected(id, bagdataId) {
    const xRequestId = this.globalSettings.randomUuid;
    await this.restApi.makeBaggageSelected(id, bagdataId, xRequestId);
    this.loadBagData();
  }

  async makeBaggageUnselected(id, bagdataId) {
    const xRequestId = this.globalSettings.randomUuid;
    await this.restApi.makeBaggageUnselected(id, bagdataId, xRequestId);
    this.loadBagData();
  }

  getFlightsString(flights) {
    // const array = flights.map(el => {
    //   const date = new Date(el.dt);
    //   return el.flight + ' ' + el.arrival + ' - ' + el.departure + ' '
    //           + date.toLocaleString('ru-RU', { day: 'numeric', month: 'numeric', year: 'numeric' });
    // });
    return flights.map(el => el.flight).join(' / ');
  }

  getCodeExternalElements(array) {
    return array.map(el => this.getById(this.references.baggage_elements, el)?.code).join('');
  }

  downloadDocument(id: string, type: string) {
    const xRequestId = this.globalSettings.randomUuid;
    return this.restApi.detDocument(id, type, xRequestId).subscribe(data => {
      const blob = new Blob([data], { type: 'application' });
      this.fileSaverService.save(blob, type + '_' + this.statement.name + '.xlsx');
    });
  }

  async openCommercialAct(id: number, content) {
    const xRequestId = this.globalSettings.randomUuid;
    const data = await this.restApi.getCommercialAct(id, xRequestId);
    this.commercialAct = new CommercialAct();
    Object.assign(this.commercialAct, data);
    if (content) {
      this.open(content);
    }
  }

  createCommercialAct(baggageId, content) {
    this.commercialAct = new CommercialAct(baggageId);
    if (content) {
      this.open(content);
    }
  }

  addCommercialAct() {
    return;
  }

  async saveCommercialAct() {
    const xRequestId = this.globalSettings.randomUuid;
    const data = await this.restApi.updateCommercialAct(this.commercialAct, xRequestId);
  }

  // Открывает модалку
  open(content) {
    this.modalService.open(content, { size: 'xxl', backdrop: 'static' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  async loadReferences() {
    const xRequestId = this.globalSettings.randomUuid;
    for (const key in this.references) {
      if (key == 'statement_manual_types') continue;
      if (Object.prototype.hasOwnProperty.call(this.references, key)) {
        this.selectLoadAnimation[key] = true;
        await this.restApi.getReference(key, xRequestId).then(data => {
          this.references[key] = [];
          if (key === 'statement_types') {
            this.viewParametrs.statementManualOrder.forEach(el => {
              let newType = new Referance();
              Object.assign(newType, this.getById(data, el));
              this.references['statement_manual_types'].push(newType);
            });
            this.viewParametrs.statementTypesOrder.forEach(el => {
              let newType = new Referance();
              Object.assign(newType, this.getById(data, el));
              const statuses = newType.baggage_statuses;
              let referanceStatuses: Array<Referance> = [];
              statuses.forEach(item => {
                let status = new Referance();
                Object.assign(status, item);
                referanceStatuses.push(status);
              });
              newType.baggage_statuses = referanceStatuses;
              this.references[key].push(newType);
            });
          } else {
            data.forEach(el => {
              const item = new Referance();
              Object.assign(item, el);
              this.references[key].push(item);
            });
          }
        });
        this.selectLoadAnimation[key] = false;
      }
    }
    this.baggageInternalForCategory = this.references.baggage_internals;
  }

  get baggage_statuses() {
    for (const item of this.references.statement_types) {
      if (item.id === this.statement.typeId) {
        return item.baggage_statuses;
      }
    }
    return [];
  }

  async loadAdminData() {
    const xRequestId = this.globalSettings.randomUuid;
    for (const key in this.adminData) {
      if (Object.prototype.hasOwnProperty.call(this.adminData, key)) {
        this.selectLoadAnimation[key] = true;
        await this.restApi.getAdminData(key, xRequestId).then(data => {
          this.adminData[key] = data;
        // }, err => {
        //   this.displayError(err);
        });
        this.selectLoadAnimation[key] = false;
      }
    }
  }

  filterSwitch() {
    this.filter.apply = !this.filter.apply;
    this.loadStatements();
  }

  clearFilterParametr(field: string, event) {
    event.stopPropagation();

    switch (field) {
      case 'name':
        this.filter.params.name = [];
        break;
      case 'tag':
        this.filter.params.tag = [];
        break;
      case 'passenger':
        this.filter.params.passenger = null;
        break;
      case 'pnr':
        this.filter.params.pnr = null;
        break;
      case 'airport':
        this.filter.params.airport = [];
        break;
      case 'baggageType':
        this.filter.params.baggageType = [];
        break;
      case 'baggageColor':
        this.filter.params.baggageColor = [];
        break;
      case 'baggageMaterial':
        this.filter.params.baggageMaterial = [];
        break;
      case 'baggageExternalElements':
        this.filter.params.baggageExternalElements = [];
        break;
      case 'airline':
          this.filter.params.airline = [];
          break;
      case 'status':
          this.filter.params.status = '';
          break;
      case 'start':
        this.filter.params.start = null;
        break;
      case 'finish':
        this.filter.params.finish = null;
        break;
      case 'date':
        this.filter.params.start = null;
        this.filter.params.finish = null;
        break;
      default:
        break;
    }

    if (this.quickSearchCheck()) {
      this.filter.apply = false;
    };
    this.loadStatements();
  }

  copyToClipboard(str) {
    const textArea = document.createElement("textarea");
    textArea.value = str;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand('copy');
    } catch (err) {
      console.error('Unable to copy to clipboard', err);
    }
    document.body.removeChild(textArea);
    // window.navigator.clipboard.writeText(str);
  }

  clearFilter() {
    this.filter.params = new FilterParams();
    this.filter.apply = false;
  }

  filterApp() {
    this.filter.apply = true;
    this.filter.show = !this.filter.show;
    this.loadStatements();
  }

  async changePerPage(perPage) {
    this.pagination.perPage = perPage;
    this.loadStatements();
  }

  onScrollToEndNgSelect(name) {
    this.fetchMore(name);
  }

  onScrollNgSelect({ end }, name) {
    if (this.selectLoadAnimation[name] || this.references[name].length <= this.buffer[name].length) {
      return;
    }
    if (end + this.numberOfItemsFromEndBeforeFetchingMore >= this.buffer[name].length) {
      this.fetchMore(name);
    }
  }

  private fetchMore(name) {
    const len = this.buffer[name].length;
    const more = this.references[name].slice(len, this.bufferSize[name] + len);
    this.selectLoadAnimation[name] = true;
    this.buffer[name] = this.buffer[name].concat(more);
    this.selectLoadAnimation[name] = false;
  }

  /**
   * Функция поиска в выпадающим списке по нескольким параметрам
   * @param {string} term Строка для поиска введеня пользователем
   * @param {ReferanceAirline} item Элемент для поиска
   */
   customSelectSearch(term: string, item) {
    term = term.toLowerCase();
    return term.length < 4 ?
            (item.iata && item.iata.toLowerCase().indexOf(term) > -1) ||
            (item.code && item.code[1] && item.code[1].toLowerCase().indexOf(term) > -1)
          : term.length < 5 ?
            (item.icao && item.icao.toLowerCase().indexOf(term) > -1)
          :
            (item.name && item.name[0] && item.name[0].toLowerCase().indexOf(term) > -1) ||
            (item.name && item.name[1] && item.name[1].toLowerCase().indexOf(term) > -1)
  }

  addPersonComission() {
    const person = new Comission();
    this.commercialAct.comission.push(person);
  }

  // selectRouteFullItinerary() {
  //   const routeLength = this.statement.route.full.routeId.length;
  //   const flightsLength = this.statement.route.full.flights.length;
  //   if (routeLength > flightsLength) {
  //     this.statement.route.full.flights.push(new Flight());
  //   } else if (routeLength < flightsLength) {
  //     this.statement.route.full.flights.pop();
  //   }
  // }

  parseDate(date: string, time?): Date {
    if (time && date) {
      return new Date(date + 'T' + time);
    } else if (!time && date) {
      return new Date(date);
    }
    return null;
  }

  addItemBaggage() {
    let baggage = new Baggage();
    baggage.route = [...this.statement.route.full.flights];
    this.viewParametrs.baggageBlock = this.statement.baggage.push(baggage) - 1;
    this.saveStatement().then(() => {
      let baggageBlock = this.viewParametrs.baggageBlock;
      this.loadStatement(this.statement.id).then(() => {
        this.viewParametrs.baggageBlock = baggageBlock;
      });
    });
  }

  addItemRoute() {
    const newRoute = new this.classes.flight();
    newRoute.dt = moment().startOf('day').toDate();
    this.statement.route.full.flights.push(newRoute);
    this.statement.baggage.forEach(baggage => {
      if (baggage.routeExtendsFlight) {
        baggage.route.push(newRoute);
      }
    });
  }

  removeItemRoute(i) {
    this.removeItem(this.statement.route.full.flights, i);
    this.statement.baggage.forEach(baggage => {
      if (baggage.routeExtendsFlight) {
        this.removeItem(baggage.route, i);
      }
    });
  }

  addItem(array, type) {
    if (array !== null) {
      const item = new type();
      array.push(item);
    }
  }

  removeItem(array, item) {
    array.splice(item, 1);
  }

  displayError(err) {
    if (err.type) {
      this.error.errorType = err.type;
      if (typeof err.message === 'object') {
        if (this.globalSettings.language === 'en') {
          this.error.errorMessage = err.message[0];
        } else {
          this.error.errorMessage = err.message[1];
        }
      } else {
        this.error.errorMessage = err.message;
      }
    } else {
      this.error.errorMessage = err;
    }
  }

  toggleItem(array, item) {
    if (array.includes(item)) {
      array.splice(array.indexOf(item), 1);
    } else {
      array.push(item);
    }

    if (this.viewParametrs.activeWindow === 'manualSearch') {
      this.loadManualStatements();
    } else {
      this.loadStatements();
    }
  }

  toggleDisabledItem(array, item) {
    if (this.filterParams.statementsAll) {
      this.filterParams.statements = [];
      this.filterParams.statementsAll = false;
    }
  }

  loadWindow(id) {
    switch (id) {
      case 'statementsList':
        if (this.viewParametrs.activeWindow === 'statementItem' && this.validationStatement()) {
          this.viewParametrs.activeWindow = id;
          this.loadStatements(this.pagination.pageActive);
        } else if (this.viewParametrs.activeWindow === 'manualSearch') {
          this.viewParametrs.activeWindow = id;
          this.loadStatements(this.pagination.pageActive);
        }
        break;
      case 'statementItem':
        break;
    }
  }

  validationStatement() {
    let valid = true;

    const errorTag = [];
    const errorStatus = [];
    this.statement.baggage.forEach(baggage => {
      if (baggage.tag) {
        const testTag = /^([A-Za-z]{1})([A-Za-z]{1})|^([A-Za-z0-9]{1})([A-Za-z]{1})|^([A-Za-z]{1})([A-Za-z0-9]{1})/i.test(baggage.tag);
        if (!testTag) {
          errorTag.push(baggage.tag);
        }
      }

      // Проверка признака ухода заявления в Архив, данный признак так же
      // является требованием для проставления Кода розыска по заявлению
      if (this.getById(this.baggage_statuses, baggage.statusId)?.last && !baggage.irregularityCode1Id) {
        errorStatus.push(baggage.tag);
      }
    });

    if (errorTag.length > 0) {
      this.error.errorMessage = this.globalI18n.getMessage(ModuleName.LostFound, 'baggageTagError') + ': ' + errorTag.join(', ');
      this.error.errorType = 'error';
      return false;
    }

    if (errorStatus.length > 0) {
      this.error.errorMessage = this.globalI18n.getMessage(ModuleName.LostFound, 'baggageStatusCodeError') + ': ' + errorStatus.join(', ');
      this.error.errorType = 'error';
      return false;
    }
    return valid;
  }

  clearErrorMess() {
    this.error = {
      errorMessage: '',
      errorType: '',
    }
  }

  getById(array, id) {
    if (array && id) {
      return array.find(el => el.id === id) || null;
    }
  }

  getCharacteristicCode(baggage) {
    const color = this.getById(this.references.baggage_colors, baggage.characteristics.colorId)?.code;
    const type = this.getById(this.references.baggage_types, baggage.characteristics.typeId)?.code;
    let material = '';
    if (type !== '22D' && type !== '22R') {
      material = this.getById(this.references.baggage_materials, baggage.characteristics.materialId)?.code;
    }
    const elem = baggage.characteristics.externalElements.reduce((acc, el) => {
      return acc += this.getById(this.references.baggage_elements, el)?.code;
    }, '');
    return (color ?? 'XX') + (type ?? 'XX') + (material ?? 'X') + elem;
  }

  getCharacteristicFromCode(baggage, code) {
    code = code.toUpperCase();

    const color = this.references.baggage_colors.find(el => el.code === (code[0] + code[1]));
    baggage.characteristics.colorId = color?.id || null;

    // для корректной отработки исключения, когда тип - 3 символа
    let codeType = code[2] + code[3] + code[4];

    if (codeType === '22D' || codeType === '22R') {
    } else {
      codeType = code[2] + code[3];
    }

    const type = this.references.baggage_types.find(el => el.code === (codeType));
    baggage.characteristics.typeId = type?.id || null;

    const material = this.references.baggage_materials.find(el => el.code === code[4]);
    baggage.characteristics.materialId = material?.id || null;

    baggage.characteristics.externalElements = [];
    for (let i = 5; i < code.length; i++) {
      const elem = this.references.baggage_elements.find(el => el.code === code[i]);
      if (elem) {
        baggage.characteristics.externalElements.push(elem?.id);
      }
    }
  }

  checkRouteCoincides(val, index) {
    if (val) {
      this.statement.baggage[index].route = [];
      this.statement.route.full.flights.forEach(el => {
        this.statement.baggage[index].route.push(Object.assign({}, el));
      });
    }
  }

  setMark(id: string, color: string) {
    const xRequestId = this.globalSettings.randomUuid;
    return this.restApi.setMark(id, color, xRequestId).subscribe(data =>  {
      this.statement.mark = color;
      this.loadStatements();
      this.loadStatement(id);
    }, err => this.displayError(err));
  }

  unsetMark(id: string, color?: string) {
    const xRequestId = this.globalSettings.randomUuid;
    return this.restApi.unsetMark(id, xRequestId).subscribe(data => {
      this.statement.mark = '';
      this.loadStatements();
      this.loadStatement(id);
    }, err => this.displayError(err));
  }

  getBaggageByTag(tag) {
    let a = this.statement.baggage.find(el => el.tag === tag);
    return a;
  }

  setAttribute(array, item, val) {
    array[item] = val;
  }

  autoSave() {
    // Объект необходимо преобразовывать в JSON, так как далее происходит
    // проверка на наличии изменений по принципу оператора ===
    // в таком случае сравнивается сам объект а не его содержимое
    this.autoSaveData.next(JSON.stringify(this.statement));
  }

  setStorageBeginDate(value, bagId) {
    if (value) {
      for (let bag of this.statement.baggage) {
        if (bag.id === bagId && !bag.storage.dtStart) {
          bag.storage.dtStart = moment().startOf('day').toDate();
          this.autoSave();
          break;
        }
      }
    }
  }

  /**
   * Функция определения списка элементов для группы или
   * группы для выбранного элемента, в зависимости от действий пользователя
   * @param value Действие пользователя
   * @param element Выбранный элемент пользователем
   * @returns 
   */
  findBaggageContentAndGroup(value, element) {
    // Запрос списка элементов по Категории
    if (value === "items") {
      // Если категория задана, загрузка элементов из данной категории в 
      // массив для отображения
      if (element !== undefined) {
        this.baggageInternalForCategory = this.references.baggage_internals.filter(el => el.categoryId === element.id);
      } else {
        // Если категории нет, загрузка полного списка Элементов
        this.baggageInternalForCategory = this.references.baggage_internals;
      }
      this.baggageInternalId = null;
      return;
    } else {
      // Запрос группы к которой принадлежит элемент
      if (element != undefined) {
        for (let item of this.references.baggage_internal_categories) {
          if (item.id === element.categoryId) {
            this.baggageInternalCategoryId = element.categoryId;
          }
        }
      }
    }
  }

  /**
   * Функция добавления нового описания повреждения багажа
   * @param id Идентификатор места багажа
   */
   addBaggageDamage(id) {
    // Место багажа
    let baggage =  this.statement.baggage.find(el => el.id === id);
    if (!baggage.damage.damages) {
      baggage.damage.damages = [];
    }

    baggage.damage.damages.push(new Damage());
  }

  /**
   * Функция добавления нового элемента в массив вложений
   * каждый Элемент принадлежит к Категории, все Элементы нужно помещать внутрь
   * нужной Категории для дальнейшего праильно вывода пользователю
   * @param id Идентификатор места багажа
   */
  addBaggageInternalElement(id) {
    if (this.baggageInternalId) {
      // Добавляемый Элемента из массива
      const internalElement = this.references.baggage_internals.find(el => el.id === this.baggageInternalId);
      // Место багажа
      let baggage =  this.statement.baggage.find(el => el.id === id);
      // Категория добавляемого Элемента
      let category =  this.references.baggage_internal_categories.find(el => el.id === this.baggageInternalCategoryId);
      // Флаг проверки существования Категории в текущем наборе данных
      let categoryIsExist = false;
      // Поиск существующей Категории элементов для добавления нового Элемента
      if (baggage.characteristics.internalElements) {
        for (let element of baggage.characteristics.internalElements) {
          // Если есть группы с нужной Категорией, добавление элемент в данную группу
          if (element.categoryId === internalElement.categoryId) {
            // Проверка на существование данного Элемента, что бы избежать дублирование
            if (element.internals.find(el => el.id === internalElement.id) === undefined) {
              // Добавление элемента в виде объекта
              element.internals.push({"id": internalElement.id, "name": internalElement.name});
            }
            // Установка флага что Категория найдена
            categoryIsExist = true;
            break;
          }
        }
      } else {
        // Если раздела не существует, происходит его создание
        baggage.characteristics['internalElements'] = [];
      }

      // Если Категории не было, ее необходимо создать и записать туда Элемент
      if (!categoryIsExist) {
        baggage.characteristics.internalElements.push({"categoryId": this.baggageInternalCategoryId, "categoryName": category.name, "internals": [{"id": internalElement.id, "name": internalElement.name}]});
      }
      // Обнулении идентификатора выбранного Элемента, что бы он не отображался
      // в комбобоксе после добавления в список, визуал для пользователя
      this.baggageInternalId = null;
    }
  }

  /**
   * Функция подсчета вложений для отображения в аккордионе
   * @param internalElements Массив вложений
   * @returns Число зафиксированных вложений
   */
  internalElementsCount(internalElements): number {
    if (!internalElements || internalElements === undefined) {
      return 0;
    } else {
      let count = 0;
      // Перебор всех групп и подсчет всех перечислений вложений  каждой группе
      for (const item of internalElements) {
        if (item.internals) {
          count += item.internals.length;
        }
      }
      return count;
    }
  }

  /**
   * Функция удаления пустыхх Категорий
   * @param internalGroup Массив с Категориями
   * @param index Индекс элемента где было последнее удаление пользователем
   */
  checkInternalElementsCategoryEmpty(internalGroup, index) {
    // Если внутри Категории нет Элементов, происходит ее удаление что бы
    // не отображать пустую группу для пользователя
    if (internalGroup[index].internals && internalGroup[index].internals.length === 0) {
      this.removeItem(internalGroup, index);
    }
  }

  extractFlight(value) {
    let flightList = [];
    let retunrValue = '';
    if (value) {
      flightList = value.split(';');
    }

    flightList.pop();
    if (flightList) {
      for (let item of flightList) {
        let flightItems = item.split('/');
        if (flightItems) {
          retunrValue +=  '\r\n' + flightItems[0] + ' / ' + flightItems[1];
        }
      }
    }

    return retunrValue.trim();
  }

  extractRoute(value) {
    let flightList = [];
    let retunrValue = '';
    if (value) {
      flightList = value.split(';');
    }

    flightList.pop();
    if (flightList) {
      for (let item of flightList) {
        let flightItems = item.split('/');
        if (flightItems) {
          retunrValue += '\r\n' + flightItems[2];
        }
      }
    }

    return retunrValue.trim();
  }

  passengerFullName(passenger) {
    let fullName = '';
    fullName = passenger.surname;
    if (passenger.name) {
      fullName += "/" + passenger.name;
    }

    if (passenger.middleName) {
      fullName += " " + passenger.middleName;
    }
    return fullName;
  }
}
