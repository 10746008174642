<div *ngIf="viewParametrs.activeWindow == 'calculation'"
  class="mb-2 p-3 border border-top-0 shadow-sm rounded-bottom d-flex align-items-center justify-content-between">
  <div>
    <button class="btn btn-sm btn-ico btn-light-gray"
            (click)="loadWindow('flight-list')">
      <i class="material-icons">arrow_back_ios</i>
      <span i18n="wb|Control panel@@wb.controlPanel.button.flights">Flights</span>
    </button>
    <button class="btn btn-blue ml-3 btn-xs"
            [disabled]="!flight.id"
            data-toggle="modal"
            (click)="editFlight('loading')"
            data-target=".flight-card">
      <i class="material-icons">flight</i>
      <span i18n="wb|Control panel@@wb.controlPanel.button.editFlight">Edit flight</span>
    </button>
  </div>
  <div class="d-flex align-items-center">
    <div class="input-group flex-nowrap mr-3">
      <div class="input-group-prepend custom-sm">
        <span class="input-group-text font-small py-0"
              i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.groupAircraft.table.registration">
          Registration
        </span>
      </div>
      <input type="text"
            class="w-100 form-control custom-sm"
            [value]="getById(references.tails, +flight.tailId) ? getById(references.tails, +flight.tailId).tail : 'Loading...'"
            disabled />
    </div>
    <div class="input-group flex-nowrap mr-3">
      <div class="input-group-prepend custom-sm">
        <span class="input-group-text font-small py-0"
          i18n="wb|Control panel@@wb.controlPanel.field.flight">Flight
        </span>
      </div>
      <input type="text"
            class="form-control custom-sm"
            value="{{flight.airlineIata}} {{flight.flightNumber}}"
            disabled />
    </div>
    <div class="input-group flex-nowrap mr-3">
      <div class="input-group-prepend custom-sm">
        <span class="input-group-text font-small py-0"
              i18n="wb|Control panel@@wb.controlPanel.field.route">Route
        </span>
      </div>
      <input type="text"
            class="form-control custom-sm"
            value="{{ flight.homeRoute ? flight.homeRoute.displayName : '' }} - {{ flight.nextRoute ? flight.nextRoute.displayName : '' }}"
            disabled />
    </div>
    <div class="input-group flex-nowrap">
      <div class="input-group-prepend custom-sm">
        <span class="input-group-text font-small py-0"
              i18n="wb|Control panel@@wb.controlPanel.field.departure">Departure
        </span>
      </div>
      <input type="text"
            class="form-control custom-sm"
            value="{{ flight.homeRoute ? (flight.homeRoute.dtDepartureShow | date: 'dd.MM.yyyy HH:mm') : '' }}"
            disabled />
    </div>
    <!--<button class="btn btn-sm btn-blue ml-3"
            (click)="calculate()"
            i18n="wb|Control panel - button calculate@@wb.controlPanel.button.calculate">Calculate</button>-->
    <button type="button"
            class="btn btn-xs ml-3 btn-ico btn-light-gray"
            ngbTooltip="Reload AHM data"
            (click)="reloadAhmData()"
            i18n-ngbTooltip="wb|Control panel - button calculate@@wb.controlPanel.button.reloadAhmData">
      <i class="material-icons">refresh</i>
    </button>
    <button class="btn btn-sm btn-blue ml-3"
            (click)="addCalculate()"
            *ngIf="usesAction == 'addCalculation'"
            i18n="wb|Control panel - button calculate@@wb.controlPanel.button.calculate">Calculate</button>
    <button class="btn btn-sm btn-blue ml-3"
            (click)="saveCalculate(calculation.id)"
            *ngIf="usesAction == 'editCalculationAhm'"
            i18n="wb|Control panel - button calculate@@wb.controlPanel.button.calculate">Calculate</button>

  </div>
</div>
<div *ngIf="viewParametrs.activeWindow == 'calculation-manual'"
  class="mb-2 p-3 border border-top-0 shadow-sm rounded-bottom d-flex align-items-center justify-content-between">
  <div>
    <button class="btn btn-sm btn-ico btn-light-gray"
            (click)="loadWindow('flight-list')">
      <i class="material-icons">arrow_back_ios</i>
      <span i18n="wb|Control panel@@wb.controlPanel.button.flights">Flights</span>
    </button>
    <button class="btn btn-blue ml-3 btn-xs"
            [disabled]="!flight.id"
            data-toggle="modal"
            (click)="editFlight()"
            data-target=".flight-card">
      <i class="material-icons">flight</i>
      <span i18n="wb|Control panel@@wb.controlPanel.button.editFlight">Edit flight</span>
    </button>
  </div>
  <div class="d-flex align-items-center">
    <div class="input-group flex-nowrap mr-3">
      <div class="input-group-prepend custom-sm">
        <span class="input-group-text font-small py-0"
          i18n="wb|Control panel@@wb.controlPanel.field.flight">Flight
        </span>
      </div>
      <input type="text"
            class="form-control custom-sm"
            value="{{flight.airlineIata}} {{flight.flightNumber}}"
            disabled />
    </div>
    <div class="input-group flex-nowrap mr-3">
      <div class="input-group-prepend custom-sm">
        <span class="input-group-text font-small py-0"
              i18n="wb|Control panel@@wb.controlPanel.field.route">Route
        </span>
      </div>
      <input type="text"
            class="form-control custom-sm"
            value="{{ flight.homeRoute ? flight.homeRoute.displayName : '' }} - {{ flight.nextRoute ? flight.nextRoute.displayName : '' }}"
            disabled />
    </div>
    <div class="input-group flex-nowrap">
      <div class="input-group-prepend custom-sm">
        <span class="input-group-text font-small py-0"
              i18n="wb|Control panel@@wb.controlPanel.field.departure">Departure
        </span>
      </div>
      <input type="text"
            class="form-control custom-sm"
            value="{{ flight.homeRoute ? (flight.homeRoute.dtDepartureShow | date: 'dd.MM.yyyy HH:mm') : '' }}"
            disabled />
    </div>
    <button class="btn btn-sm btn-blue ml-3"
            (click)="addCalculateManual()"
            *ngIf="usesAction == 'addCalculationManual'"
            i18n="wb|Control panel - button calculate@@wb.controlPanel.button.calculate">Calculate</button>
    <button class="btn btn-sm btn-blue ml-3"
            (click)="saveCalculateManual(calculationManual.id)"
            *ngIf="usesAction == 'editCalculationManual'"
            i18n="wb|Control panel - button calculate@@wb.controlPanel.button.calculate">Calculate</button>

  </div>
</div>
<div *ngIf="viewParametrs.activeWindow == 'flight-list'"
     class="mb-2 p-3 border border-top-0 shadow-sm rounded-bottom d-flex align-items-center justify-content-between">
  <div>
    <button type="button"
            class="btn btn-blue btn-xs" data-toggle="modal"
            data-target=".flight-card" (click)="createFlight()">
      <i class="material-icons">add</i>
      <span i18n="wb|Control panel@@wb.controlPanel.button.addFlight">Add flight</span>
    </button>
    <button class="btn btn-blue ml-3 btn-xs"
            [disabled]="!flight.id"
            data-toggle="modal"
            (click)="editFlight()"
            data-target=".flight-card">
      <i class="material-icons">flight</i>
      <span i18n="wb|Control panel@@wb.controlPanel.button.editFlight">Edit flight</span>
    </button>
    <button class="btn btn-blue ml-3 btn-xs"
            (click)="openCalculation(flight.id)"
            [disabled]="!flight.id">
      <i class="material-icons">wrap_text</i>
      <span i18n="wb|Control panel@@wb.controlPanel.button.editCalculation">Edit calculation</span>
    </button>
    <button type="button"
            class="btn btn-xs btn-ico btn-light-gray ml-3"
            data-toggle="modal"
            data-target=".move-desktop"
            ngbTooltip="Reassign flight"
            [disabled]="!flight.id"
            i18n-ngbTooltip="wb|Main buttons - reassign flight@@wb.main.button.reassignFlight">
      <i class="material-icons">how_to_vote</i>
    </button>
    <button type="button"
            class="btn btn-xs btn-ico btn-light-gray ml-3"
            data-toggle="modal"
            ngbTooltip="Performer choice"
            (click)="loadUsers()"
            [disabled]="!flight.id"
            data-target=".choice-performer"
            i18n-ngbTooltip="wb|Main buttons - performer choice@@wb.main.button.performerChoice">
      <i class="material-icons">person</i>
    </button>
  </div>

  <div class="d-flex flex-grow-1 pl-3">
    <div class="">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text font-small"
                i18n="wb|Control panel@@wb.controlPanel.text.displayedPeriod">
            Displayed period
          </span>
        </div>
        <ng-select class="form-control p-0 border-0 form-control-sm"
                  [items]="[8,12,24]"
                  [clearable]="false"
                  [ngModel]="viewParametrs.displayedPeriod"
                  (change)="changeDisplayedPeriod($event)">
        </ng-select>
          <!-- <ng-container *ngIf="filterApply && filterParams.start && filterParams.finish; else current">
            <input type="text"
                   value="{{filterParams.start | date: 'dd.MM.yyyy'}} - {{filterParams.finish | date: 'dd.MM.yyyy'}}"
                   class="form-control form-control-sm"
                   disabled>
          </ng-container>
          <ng-template #current>
            <input type="text"
                   value="{{messages['current']}}"
                   class="form-control form-control-sm"
                   disabled>
          </ng-template> -->
      </div>
    </div>
    <div class="d-flex flex-fill row pl-3">
      <div class="col-4">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text font-small" i18n="wb|Filter@@wb.filter.label.search">
              Search
            </span>
          </div>
          <input type="text" class="form-control form-control-sm" [(ngModel)]="searchFlight">
        </div>
      </div>
      <div class="col-8 pl-0">
        <div class="d-flex">
          <!-- TODO для логов не отслеживаемое действия пользователя, так как идет прямое присвоение -->
          <div class="filter-block-wrap mr-2 w-100" (click)="showFilter = !showFilter">
            <div class="input-group-prepend">
              <span class="input-group-text border-0 rounded-0 py-1"
                    i18n="cmn|Main search - label find@@cmn.main.search.label.find">Find</span>
              <div class="filter-elements d-flex pl-2 text-nowrap flex-wrap">
                <div class="filter-element mr-2 my-1" *ngIf="filterParams.start && filterParams.finish">
                  <i class="material-icons mx-1" (click)="clearFilterParametr('date', $event)">close</i>
                  <span i18n="wb|Filter - date@@wb.filter.field.date">Date</span>&nbsp;{{filterParams.start | date: 'dd.MM.yyyy'
                  }}&nbsp;-&nbsp;{{filterParams.finish | date: 'dd.MM.yyyy'}}
                </div>
                <div class="filter-element mr-2 my-1" *ngIf="filterParams.flight!=null">
                  <i class="material-icons ml-2" (click)="clearFilterParametr('flight', $event)">close</i>
                  <span i18n="wb|Filter - date@@wb.filter.field.flight">Flight</span>&nbsp;{{filterParams.flight}}
                </div>
                <div class="filter-element mr-2 my-1" *ngIf="filterParams.airport!=null">
                  <i class="material-icons ml-2" (click)="clearFilterParametr('airport', $event)">close</i>
                  <span i18n="wb|Filter - airport@@wb.filter.field.airport">Airport</span>&nbsp;{{getById(references.airports, filterParams.airport ).iata}}
                </div>
                <div class="filter-element mr-2 my-1" *ngIf="filterParams.airline!=null">
                  <i class="material-icons ml-2" (click)="clearFilterParametr('airline', $event)">close</i>
                  <span i18n="wb|Filter - airline@@wb.filter.field.airline">Airline</span>&nbsp;{{getById(references.airlines, filterParams.airline ).iata}}
                </div>
                <div class="filter-element mr-2 my-1" *ngIf="filterParams.aircraft!=null">
                  <i class="material-icons ml-2" (click)="clearFilterParametr('aircraft', $event)">close</i>
                  <span i18n="wb|Filter - aircraft@@wb.filter.field.aircraft">Aircraft</span>&nbsp;{{getById(references.aircraft_types, filterParams.aircraft ).names}}
                </div>
                <div class="filter-element mr-2 my-1" *ngIf="filterParams.tail!=null">
                  <i class="material-icons ml-2" (click)="clearFilterParametr('tail', $event)">close</i>
                  <span i18n="wb|Filter - tail@@wb.filter.field.tail">Tail</span>&nbsp;{{filterParams.tail}}
                </div>
              </div>
            </div>
            <div class="d-flex align-items-center text-dark-gray">
              <i class="material-icons mx-1">arrow_drop_down</i>
            </div>
          </div>
          <!-- Filter -->
            <div class="filter-block border p-2 bg-white font-small" *ngIf="showFilter">
              <div class="container">
                <div class="row pb-2">
                  <div class="col-4 px-1">
                    <label for="filter-date-from"
                           class="mr-2 mb-0"
                           i18n="wb|Filter - date from@@wb.filter.field.dateFrom">
                      Date from
                    </label>
                  </div>
                  <div class="col-8 px-1">
                    <input type="date"
                           class="form-control"
                           [ngModel]="filterParams.start | date: 'yyyy-MM-dd'"
                           (input)="filterParams.start = parseDate($event.target.value, '00:00')"
                           name="filterParamsStartDate"
                           #start_date>
                  </div>
                </div>
                <div class="row pb-2">
                  <div class="col-4 px-1">
                    <label for="filter-date-from"
                           class="mr-2 mb-0"
                           i18n="wb|Filter - date to@@wb.filter.field.dateTo">
                      Date to
                    </label>
                  </div>
                  <div class="col-8 px-1">
                    <input type="date"
                           class="form-control"
                           [ngModel]="filterParams.finish | date: 'yyyy-MM-dd'"
                           (input)="filterParams.finish = parseDate($event.target.value, '23:59')"
                           name="filterParamsStartDate"
                           #finish_date>
                  </div>
                </div>
                <div class="row pb-2">
                  <div class="col-4 px-1">
                    <label for="filter-date-from"
                           class="mr-2 mb-0"
                           i18n="wb|Filter - date@@wb.filter.field.flight">
                      Flight
                    </label>
                  </div>
                  <div class="col-8 px-1">
                    <input type="text"
                           class="form-control form-control-sm"
                           [(ngModel)]="filterParams.flight"
                           name="filterParamsFlight">
                  </div>
                </div>
                <div class="row pb-2">
                  <div class="col-4 px-1">
                    <label for="filter-date-from" class="mr-2 mb-0"
                           i18n="wb|Filter - airport@@wb.filter.field.airport">
                      Airport
                    </label>
                  </div>
                  <div class="col-8 px-1">
                    <ng-select class="form-control custom-sm p-0"
                              [items]="references.airports"
                              bindLabel="iata"
                              bindValue="id"
                              [(ngModel)]="filterParams.airport"
                              #filterParamsAirport="ngModel"
                              name="filterParamsAirport"
                              [virtualScroll]="true"
                              [loading]="selectLoadAnimation.airports"
                              (scroll)="onScrollNgSelect($event, 'airports')"
                              (scrollToEnd)="onScrollToEndNgSelect('airports')"
                              [searchFn]="customSelectSearch"
                              appendTo="body">
                      <ng-template ng-option-tmp let-item="item">
                        <div class="font-small">
                          <span class="font-weight-bold">{{item.iata}}</span>&nbsp;{{item.names}}
                        </div>
                      </ng-template>
                    </ng-select>
                  </div>
                </div>
                <div class="row pb-2">
                  <div class="col-4 px-1">
                    <label for="filter-date-from" class="mr-2 mb-0"
                           i18n="wb|Filter - airline@@wb.filter.field.airline">
                      Airline
                    </label>
                  </div>
                  <div class="col-8 px-1">
                    <ng-select class="form-control custom-sm p-0"
                               [items]="references.airlines"
                               bindLabel="iata"
                               bindValue="id"
                               [(ngModel)]="filterParams.airline"
                               #filterParamsAirline="ngModel"
                               name="filterParamsAirline"
                               [virtualScroll]="true"
                               [loading]="selectLoadAnimation.airlines"
                               (scroll)="onScrollNgSelect($event, 'airlines')"
                               (scrollToEnd)="onScrollToEndNgSelect('airlines')"
                               [searchFn]="customSelectSearch">
                      <ng-template ng-option-tmp
                                   let-item="item">
                        {{item.code}} / {{item.name}}
                      </ng-template>
                    </ng-select>
                  </div>
                </div>
                <div class="row pb-2">
                  <div class="col-4 px-1">
                    <label for="filter-date-from" class="mr-2 mb-0"
                           i18n="wb|Filter - aircraft@@wb.filter.field.aircraft">
                      Aircraft
                    </label>
                  </div>
                  <div class="col-8 px-1">
                    <ng-select class="form-control custom-sm p-0"
                               [items]="references.aircraft_types"
                               bindLabel="iata"
                               bindValue="id"
                               name="filterParamsAircraft"
                               id="filterParamsAircraft"
                               [(ngModel)]="filterParams.aircraft"
                               [virtualScroll]="true"
                               [loading]="selectLoadAnimation.aircraft_types"
                               (scroll)="onScrollNgSelect($event, 'aircraft_types')"
                               (scrollToEnd)="onScrollToEndNgSelect('aircraft_types')"
                               [searchFn]="customSelectSearch">
                    </ng-select>
                  </div>
                </div>
                <div class="row pb-2">
                  <div class="col-4 px-1">
                    <label for="filter-date-from" class="mr-2 mb-0"
                           i18n="wb|Filter - tail@@wb.filter.field.tail">
                      Tail
                    </label>
                  </div>
                  <div class="col-8 px-1">
                    <ng-select class="form-control custom-sm p-0"
                              [items]="references.tails"
                              bindLabel="tail"
                              bindValue="tail"
                              [multiple]="true"
                              [(ngModel)]="filterParams.tail"
                              [loading]="selectLoadAnimation.tails"
                              [searchFn]="customSelectSearchTail"
                              [virtualScroll]="true">
                    </ng-select>
                    <!--<input type="text"
                           class="form-control form-control-sm"
                           [(ngModel)]="filterParams.tail"
                           name="filterParamsTail">-->
                  </div>
                </div>
              </div>
              <hr />
              <button class="btn btn-gray-blue-dark btn-sm float-right ml-2"
                      (click)="showFilter = !showFilter"
                      i18n="wb|Filter - close@@wb.filter.button.close">
                Close
              </button>
              <button class="btn btn-blue btn-sm float-right ml-2"
                      (click)="filterApp()"
                      i18n="wb|Filter - find@@wb.filter.button.find">
                Find
              </button>
              <button class="btn btn-blue btn-sm float-right"
                      (click)="clearFilter()"
                      i18n="wb|Filter - clear all@@wb.filter.button.clearAll">
                Clear all
              </button>
            </div>
          <button type="button"
                  class="btn btn-xs btn-ico btn-light-gray"
                  ngbTooltip="Filter on/off"
                  i18n-ngbTooltip="cmn|Main buttons - filter on/off@@cmn.main.button.filterOnOff"
                  [ngClass]="{'btn-active' : filterApply}"
                  (click)="filterSwitch()">
            <i class="material-icons">filter_alt</i>
          </button>
        </div>
      </div>
    </div>
  </div>

</div>
<div *ngIf="viewParametrs.activeWindow == 'flight-list'" class="weight-balance-content row">
  <div class="col-md-10 pr-0">
    <div class="card h-100">
      <div class="card-body">
        <div class="d-flex justify-content-between">
          <p class="font-weight-bold text-blue h5 mb-3" i18n="wb|Flight list@@wb.flightList.groupName">Flight list</p>
          <!-- TODO вернуть и сделать фильтрацию, закоменчено для показа Единой центровки -->
          <!-- <div class="input-group-prepend">
            <div class="custom-control custom-switch d-flex" style="min-width: fit-content;">
              <input type="checkbox" class="custom-control-input" id="showMyFlights"
                  [checked]="viewParametrs.showedMyFlights" (click)="showMyFlights()">
              <label class="custom-control-label" for="showMyFlights" i18n="wb|Flight list@@wb.flightList.myFlights">
                My flights
              </label>
            </div>
          </div> -->
        </div>
        <table class="table-striped table-border w-100 table-sticky-header">
          <thead class="bg-blue color-white text-center font-small">
            <tr class="text-uppercase">
              <th i18n="wb|Flight list@@wb.flightList.table.column.flight" colspan="6">Flight</th>
              <th i18n="wb|Flight list@@wb.flightList.table.column.aircraft" colspan="2">Aircraft</th>
              <th i18n="wb|Flight list@@wb.flightList.table.column.fuel" colspan="3">Fuel</th>
              <th i18n="wb|Flight list@@wb.flightList.table.column.payload" colspan="4">Payload</th>
              <th i18n="wb|Flight list@@wb.flightList.table.column.documents" colspan="3">Documents</th>
              <th i18n="wb|Flight list@@wb.flightList.table.column.location" colspan="2">Location</th>
            </tr>
            <tr>
              <th></th>
              <th i18n="wb|Flight list@@wb.flightList.table.column.date">Date</th>
              <th i18n="wb|Flight list@@wb.flightList.table.column.time">Time</th>
              <th i18n="wb|Flight list@@wb.flightList.table.column.number">Number</th>
              <th i18n="wb|Flight list@@wb.flightList.table.column.from">From</th>
              <th i18n="wb|Flight list@@wb.flightList.table.column.to">To</th>

              <th i18n="wb|Flight list@@wb.flightList.table.column.registration">Registration</th>
              <th i18n="wb|Flight list@@wb.flightList.table.column.type">Type</th>

              <th i18n="wb|Flight list@@wb.flightList.table.column.onBoard">On board</th>
              <th i18n="wb|Flight list@@wb.flightList.table.column.taxi">Taxi</th>
              <th i18n="wb|Flight list@@wb.flightList.table.column.trip">Trip</th>

              <th i18n="wb|Flight list@@wb.flightList.table.column.passengers">PAX</th>
              <th i18n="wb|Flight list@@wb.flightList.table.column.baggage">B</th>
              <th i18n="wb|Flight list@@wb.flightList.table.column.mail">M</th>
              <th i18n="wb|Flight list@@wb.flightList.table.column.cargo">C</th>

              <th i18n="wb|Flight list@@wb.flightList.table.column.released">Released</th>
              <th i18n="wb|Flight list@@wb.flightList.table.column.date">Date</th>
              <th i18n="wb|Flight list@@wb.flightList.table.column.user">User</th>

              <th i18n="wb|Flight list@@wb.flightList.table.column.desktop">Desktop</th>
              <th i18n="wb|Flight list@@wb.flightList.table.column.assignee">Assignee</th>
            </tr>
          </thead>
          <tbody cdkDropList cdkDropListSortingDisabled>
          <tr (click)="selectFlight(oneFlight.id)"
              (dblclick)="openCalculation(oneFlight.id)"
              *ngFor="let oneFlight of flightList"
              class="text-center"
              [ngClass]="{'bg-gray-blue-dark text-white': flight.id === oneFlight.id}"
              cdkDrag
              (cdkDragStarted)="draggableFlight = oneFlight.id">
              <span *cdkDragPreview>{{ getProp(references.airlines, oneFlight.airlineId, "iata") }}&nbsp;{{ oneFlight.flightNumber }}</span>
            <td width="35px">
              <i class="material-icons pointer d-hover" (click)="editFlight()" data-toggle="modal" data-target=".flight-card">flight</i>
            </td>
            <td>{{ oneFlight.homeRoute ? (oneFlight.homeRoute.dtDepartureShow | date: 'dd.MM.yyyy') : '' }}</td>
            <td>{{ oneFlight.homeRoute ? (oneFlight.homeRoute.dtDepartureShow | date: 'HH:mm') : '' }}</td>
            <td class="text-left">{{ getProp(references.airlines, oneFlight.airlineId, "iata") }}&nbsp;{{ oneFlight.flightNumber }}</td>
            <td>{{ oneFlight.homeRoute ? oneFlight.homeRoute.displayName : '' }}</td>
            <td>{{ oneFlight.nextRoute ? oneFlight.nextRoute.displayName : '' }}</td>

            <td class="text-left">{{ references.tails.length > 0 ? getProp(references.tails, oneFlight.tailId, 'tail') : '' }}</td>
            <td>{{ oneFlight.aircraftTypeIata }}</td>

            <td>{{ oneFlight.fuelData.onBoard }}</td>
            <td>{{ oneFlight.fuelData.taxi }}</td>
            <td>{{ oneFlight.fuelData.trip }}</td>

            <td>{{ oneFlight.payload.passengers }}</td>
            <td>{{ oneFlight.payload.baggage }}</td>
            <td>{{ oneFlight.payload.mail }}</td>
            <td>{{ oneFlight.payload.cargo }}</td>

            <td>
              <i class="material-icons" *ngIf="oneFlight.edno">check</i>
            </td>
            <td>{{ oneFlight.preparedDate | date: 'dd.MM.yyyy' }}</td>
            <td>{{ oneFlight.preparedBy }}</td>

            <td>{{ oneFlight.workspace }}</td>
            <td>{{ oneFlight.assignee }}</td>
          </tr>
        </tbody>
      </table>
      </div>
    </div>
  </div>
  <div class="col-md-2">
    <div class="card h-100">
      <div class="card-body">
        <p class="font-weight-bold text-blue h5 mb-3" i18n="wb|Flight list@@wb.flightList.desktops">Desktops</p>
        <div class="list-group mb-3">
          <button class="list-group-item d-flex justify-content-between align-items-center p-2"
            i18n="wb|Flight list @@wb.desktops.main"
            [ngClass]="{'bg-gray-blue-dark color-white': viewParametrs.activeDesktop == 0}"
            (click)="changeDesktop(0)"
            [disabled]="viewParametrs.mergeDesktops"
            (mouseup)="moveFlightToDesktop(0)">
            <span>Main</span>
            <!-- <span class="badge badge-pill"
                  [ngClass]="{'badge-light': !viewParametrs.activeDesktop,
                              'badge-secondary': viewParametrs.activeDesktop }">
              3
            </span> -->
          </button>
        </div>
        <div class="list-group">
          <button *ngFor="let workspace of workspaces"
              class="list-group-item d-flex justify-content-between align-items-center p-2"
              [ngClass]="{'bg-gray-blue-dark color-white': viewParametrs.activeDesktop == workspace.id}"
              (click)="changeDesktop(workspace.id)"
              [disabled]="viewParametrs.mergeDesktops"
              (mouseup)="moveFlightToDesktop(workspace.id)">
            <span>{{ workspace.name }}</span>
            <!-- <span class="badge badge-pill"
                  [ngClass]="{'badge-light': viewParametrs.activeDesktop == workspace.id,
                              'badge-secondary': viewParametrs.activeDesktop != workspace.id }">
              3
            </span> -->
          </button>
        </div>
        <div class="input-group-prepend mt-3">
          <div class="custom-control custom-switch d-flex" style="min-width: fit-content;">
            <input type="checkbox" class="custom-control-input" id="mergeDesktops"
                [checked]="viewParametrs.mergeDesktops" (click)="mergeDesktops()">
            <label class="custom-control-label" for="mergeDesktops" i18n="wb|Flight list@@wb.desktops.mergeDesktops">
              Merge desktops
            </label>
          </div>
        </div>
      </div>
      <!-- Закоменчено для показа единйо центровки -->
      <!-- <div class="card-footer bg-white  font-small">
        <p class="text-uppercase text-center h5 text-dark-gray" i18n="wb|Flight list@@wb.desktops.taskStatuses">Task statuses</p>
        <div class="row">
          <div class="col-6 pr-1 pl-0">
            <div class="card border-warning text-center">
              <div class="card-body text-warning p-1">
                <div class="card-title h2">3</div>
                <div class="card-text text-uppercase">Attention</div>
              </div>
            </div>
          </div>
          <div class="col-6 pl-1 pr-0">
            <div class="card border-danger text-center">
              <div class="card-body text-danger p-1">
                <div class="card-title h2">2</div>
                <div class="card-text text-uppercase">Failed</div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</div>

<div class="weight-balance-content" *ngIf="viewParametrs.activeWindow == 'calculation'">
  <div class="row">
    <div class="col-2 pr-0">
      <ng-select [items]="workspaces"
                  class="w-100 custom-sm"
                  bindLabel="name"
                  bindValue="id"
                  [searchable]="false"
                  [(ngModel)]="viewParametrs.activeDesktop"
                  (change)="changeDesktop(viewParametrs.activeDesktop )">
      </ng-select>
      <!-- TODO вернуть и сделать фильтрацию, закоменчено для показа Единой центровки -->
      <!-- <div class="custom-control custom-switch">
        <input type="checkbox" class="custom-control-input" id="myFlightShortList">
        <label class="custom-control-label mt-2" for="myFlightShortList">My flights</label>
      </div> -->
      <hr>
      <div *ngFor="let oneFlight of flightList"
           class="mb-2"
           [ngClass]="{'bg-gray-blue-dark text-white': flight.id === oneFlight.id}"
           (click)="selectFlight(oneFlight.id); openCalculation(oneFlight.id)">
        <div class="d-flex border border-secondary rounded py-1" style="font-size: 1.1em;">
          <div class="d-flex flex-column pl-2">
            <!-- TODO -->
            <!-- <i class="material-icons text-white-50">lock</i> -->
            <!-- <i class="material-icons text-white-50">mail</i> -->
          </div>
          <div class="d-flex flex-column pl-2">
            <div class="font-weight-bold">{{oneFlight.airlineIata}}&nbsp;{{oneFlight.flightNumber}}</div>
            <div>{{ oneFlight.homeRoute ? oneFlight.homeRoute.displayName : '' }}-{{ oneFlight.nextRoute ? oneFlight.nextRoute.displayName : '' }}</div>
          </div>
          <div class="d-flex flex-column pl-3">
            <div>{{getById(references.tails, +flight.tailId) ? getById(references.tails, +oneFlight.tailId).tail : ''}}</div>
            <div>{{oneFlight.aircraftTypeIata}}</div>
          </div>
          <div class="d-flex flex-column pr-2 ml-auto">
            <div>{{ oneFlight.homeRoute ? (oneFlight.homeRoute.dtDepartureShow | date: 'HH:mm') : '' }}</div>
            <div>{{ oneFlight.homeRoute ? (oneFlight.homeRoute.dtDepartureShow | date: 'MM.yy') : '' }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-10 pr-0">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a id="flight-tab-info"
            [ngClass]="{'active': showTab('flight-tab-info')}"
            class="nav-link"
            (click)="changeTab($event)"
            i18n="wb|Main weight balance@@wb.main.tab.flight.name">Aircraft and flight information
          </a>
        </li>
        <li class="nav-item">
          <a id="flight-tab-passenger"
            [ngClass]="{'active': showTab('flight-tab-passenger')}"
            class="nav-link"
            (click)="changeTab($event)"
            i18n="wb|Main weight balance@@wb.main.tab.passengers.name">Passengers
          </a>
        </li>
        <li class="nav-item">
          <a id="flight-tab-detributional"
            [ngClass]="{'active': showTab('flight-tab-detributional')}"
            class="nav-link"
            (click)="changeTab($event)"
            i18n="wb|Main weight balance@@wb.main.tab.distribution.name">Load distribution
          </a>
        </li>
        <li class="nav-item">
          <a id="flight-tab-documents"
            [ngClass]="{'active': showTab('flight-tab-documents')}"
            class="nav-link"
            (click)="changeTab($event)"
            i18n="wb|Main weight balance@@wb.main.tab.documents.name">Documents
          </a>
        </li>
        <li class="nav-item">
          <a id="flight-tab-specialLoad"
            [ngClass]="{'active': showTab('flight-tab-specialLoad')}"
            class="nav-link"
            (click)="changeTab($event)"
            i18n="wb|Main weight balance@@wb.main.tab.notoc.name">Notoc
          </a>
        </li>
      </ul>
      <div *ngIf="showTab('flight-tab-info')" class="bg-white p-3">
        <div class="row">
          <div class="col-4">
            <div class="card mb-3">
              <div class="card-body">
                <p class="font-weight-bold text-blue h5 mb-3"
                  i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.flightData">Flight data</p>
                <div class="row">
                  <div class="col-6">
                    <div class="input-group flex-nowrap mb-3">
                      <div class="input-group-prepend custom-sm">
                        <span class="input-group-text font-small py-0"
                              i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.groupAircraft.table.type">
                          Type
                        </span>
                      </div>
                      <input type="text"
                            class="w-100 form-control custom-sm"
                            [value]="flight.aircraftTypeIata"
                            disabled />
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="input-group flex-nowrap mb-3">
                      <div class="input-group-prepend custom-sm">
                        <span class="input-group-text font-small py-0"
                              i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.groupAircraft.table.version">
                              Version
                        </span>
                      </div>
                      <ng-select [items]="ahmData.configurations"
                                 class="w-100 custom-sm"
                                 bindLabel="name"
                                 bindValue="name"
                                 name="versionAhm"
                                 id="versionAhm"
                                 (change)="changeVersionAhm($event); autoSave()"
                                 [(ngModel)]="calculation.configuration">
                      </ng-select>
                    </div>
                  </div>
                </div>
                <!--<p class="pt-3 mt-3 border-top" i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.groupAircraft.flight">Flight</p>-->
                <div class="input-group flex-nowrap mb-3">
                  <div class="input-group-prepend custom-sm">
                    <span class="input-group-text font-small py-0"
                        i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.groupFlight.captain">
                          Captain
                    </span>
                  </div>
                  <input type="text"
                         class="w-100 form-control custom-sm"
                         maxlength="13"
                         [(ngModel)]="calculation.captain"
                         (keyup)="autoSave()"/>
                </div>
                <div class="accordion" id="accordionWeight">
                  <div class="card">
                    <div class="card-header p-0" id="headingOne">
                      <h2 class="mb-0">
                        <button class="btn btn-block text-left collapsed d-flex justify-content-between align-items-center"
                                type="button"
                                data-toggle="collapse"
                                data-target="#collapseOne"
                                aria-expanded="true"
                                aria-controls="collapseOne">
                          <span class="text-light-blue" i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.accordion.weight">Weight</span>
                        </button>
                      </h2>
                    </div>
                    <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionWeight">
                      <div class="card-body">
                        <div class="input-group flex-nowrap mb-3">
                          <div class="input-group-prepend custom-sm">
                            <span class="input-group-text font-small py-0"
                                  i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.mzfw">MZFW (Max zero fuel weight)
                            </span>
                          </div>
                          <input type="number"
                                class="w-100 form-control custom-sm"
                                name="mzfw"
                                id="mtow"
                                [(ngModel)]="calculation.mzfw"
                                (keyup)="autoSave()"/>
                        </div>
                        <div class="input-group flex-nowrap mb-3">
                          <div class="input-group-prepend custom-sm">
                            <span class="input-group-text font-small py-0"
                                  i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.mtow">MTOW (max take-off weight)
                            </span>
                          </div>
                          <input type="number"
                                class="w-100 form-control custom-sm"
                                name="mtow"
                                id="mtow"
                                [(ngModel)]="calculation.mtow"
                                (keyup)="autoSave()"/>
                        </div>
                        <div class="input-group flex-nowrap mb-3">
                          <div class="input-group-prepend custom-sm">
                            <span class="input-group-text font-small py-0"
                                  i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.mlw">MLW (max landing weight)
                            </span>
                          </div>
                          <input type="number"
                                class="w-100 form-control custom-sm"
                                name="mlw"
                                id="mlw"
                                [(ngModel)]="calculation.mlw"
                                (keyup)="autoSave()"/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header p-0" id="headingTwo">
                      <h2 class="mb-0">
                        <button class="btn btn-block text-left collapsed d-flex justify-content-between align-items-center"
                                type="button"
                                data-toggle="collapse"
                                data-target="#collapseTwo"
                                aria-expanded="false"
                                aria-controls="collapseTwo">
                          <span>
                            <span class="text-light-blue" i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.accordion.pantry">Pantry</span>&nbsp;
                            <span class="text-dark-gray">{{ calculation.dowChanges.pantry.code ? calculation.dowChanges.pantry.code : '' }}</span>
                          </span>
                          <span class="text-gray-blue-dark"
                            *ngIf="calculation.dowChanges.pantry.code">
                            <!-- DOW {{deltaDowDoi.pantry.dow}} / DOI {{deltaDowDoi.pantry.doi}} -->
                          </span>
                        </button>
                      </h2>
                    </div>
                    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionWeight">
                      <div class="card-body">
                        <div class="input-group flex-nowrap mb-3">
                          <div class="input-group-prepend custom-sm">
                            <span class="input-group-text font-small py-0"
                                  i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.pantryCode">Pantry code
                            </span>
                          </div>
                          <ng-select [items]="ahmData.dowChanges.pantry"
                                     class="w-100 custom-sm"
                                     bindLabel="code"
                                     bindValue="code"
                                     name="pantryCode"
                                     id="pantryCode"
                                     (change)="changeDowChanges($event, 'pantry'); autoSave()"
                                     [(ngModel)]="calculation.dowChanges.pantry.code">
                            <ng-template ng-option-tmp let-item="item">
                              <span class="font-weight-bold">{{item.code}}</span>&nbsp;({{item.weight}}&nbsp;/&nbsp;{{item.index}})&nbsp;<span i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.pantryCodeStandard" *ngIf="item.standard">Standard</span>
                            </ng-template>
                            <ng-template ng-label-tmp let-item="item">
                              <span class="font-weight-bold">{{item.code}}</span>&nbsp;({{item.weight}}&nbsp;/&nbsp;{{item.index}})&nbsp;<span i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.pantryCodeStandard" *ngIf="item.standard">Standard</span>
                            </ng-template>
                          </ng-select>
                        </div>
                        <div class="input-group flex-nowrap mb-3">
                          <div class="input-group-prepend custom-sm">
                            <span class="input-group-text font-small py-0"
                                  i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.dowDoiPantry">Δ DOW/DOI
                            </span>
                          </div>
                          <input type="number"
                                class="w-100 form-control custom-sm"
                                name="pantryDeltaDow"
                                id="pantryDeltaDow"
                                [value]="deltaDowDoi.pantryDelta.dow"
                                disabled />
                          <input type="number"
                                class="w-100 form-control custom-sm"
                                name="pantryDeltaDoi"
                                id="pantryDeltaDoi"
                                [value]="deltaDowDoi.pantryDelta.doi"
                                disabled />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header p-0" id="headingThree">
                      <h2 class="mb-0">
                        <button class="btn btn-block text-left collapsed d-flex justify-content-between align-items-center"
                                type="button"
                                data-toggle="collapse"
                                data-target="#collapseThree"
                                aria-expanded="false"
                                aria-controls="collapseThree">
                          <span>
                            <span class="text-light-blue" i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.accordion.water">Water</span>&nbsp;
                            <span class="text-dark-gray">{{ calculation.dowChanges.potableWater.code ? calculation.dowChanges.potableWater.code : '' }}</span>
                          </span>
                          <span class="text-gray-blue-dark"
                              *ngIf="calculation.dowChanges.potableWater.code">
                            <!-- DOW {{calculation.dowChanges.potableWater.dow}} / DOI {{calculation.dowChanges.potableWater.doi}} -->
                          </span>
                        </button>
                      </h2>
                    </div>
                    <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionWeight">
                      <div class="card-body">
                        <div class="input-group flex-nowrap mb-3">
                          <div class="input-group-prepend custom-sm">
                            <span class="input-group-text font-small py-0"
                                  i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.waterCode">Water code
                            </span>
                          </div>
                          <ng-select [items]="ahmData.dowChanges.potableWater"
                                     class="w-100 custom-sm"
                                     bindLabel="code"
                                     bindValue="code"
                                     name="potableWaterCode"
                                     id="potableWaterCode"
                                     (change)="changeDowChanges($event, 'potableWater'); autoSave();"
                                     [(ngModel)]="calculation.dowChanges.potableWater.code">
                            <ng-template ng-option-tmp let-item="item">
                              <span class="font-weight-bold">{{item.code}}</span>&nbsp;({{item.weight}}&nbsp;/&nbsp;{{item.index}})&nbsp;<span i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.waterCodeStandard" *ngIf="item.standard">Standard</span>
                            </ng-template>
                            <ng-template ng-label-tmp let-item="item">
                              <span class="font-weight-bold">{{item.code}}</span>&nbsp;({{item.weight}}&nbsp;/&nbsp;{{item.index}})&nbsp;<span i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.waterCodeStandard" *ngIf="item.standard">Standard</span>
                            </ng-template>
                          </ng-select>
                        </div>
                        <div class="input-group flex-nowrap mb-3">
                          <div class="input-group-prepend custom-sm">
                            <span class="input-group-text font-small py-0"
                                  i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.dowDoiWater">Δ DOW/DOI
                            </span>
                          </div>
                          <input type="number"
                                class="w-100 form-control custom-sm"
                                name="potableWaterDeltaDow"
                                id="potableWaterDeltaDow"
                                [value]="deltaDowDoi.potableWaterDelta.dow"
                                disabled />
                          <input type="number"
                                class="w-100 form-control custom-sm"
                                name="potableWaterDeltaDoi"
                                id="potableWaterDeltaDoi"
                                [value]="deltaDowDoi.potableWaterDelta.doi"
                                disabled />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card mb-4">
              <div class="card-body">
                <p class="font-weight-bold text-blue h5 mb-3"
                  i18n="wb|Main weight balance tab fuel@@wb.main.tab.fuel.groupFuel">Fuel</p>
                <div class="row">
                  <div class="col-6 pr-0">
                    <div class="input-group flex-nowrap mt-3">
                      <div class="input-group-prepend custom-sm">
                        <div class="input-group-text font-small py-0">
                          <span i18n="wb|Main weight balance tab fuel@@wb.main.tab.fuel.groupFuel.onBoard">On board</span><span
                                class="required-asterisk">*</span>
                        </div>
                      </div>
                      <input type="number"
                            class="w-100 form-control custom-sm"
                            name="fuelOnBoard"
                            id="fuelOnBoard"
                            [(ngModel)]="calculation.fuel.onBoard"
                            (keyup)="autoSave()" />
                      <!-- <ng-input-formatted class="w-100"
                                          [(ngModel)]="calculation.fuel.onBoard"
                                          type="number">
                      </ng-input-formatted> -->
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="input-group flex-nowrap mt-3">
                      <div class="input-group-prepend custom-sm">
                        <div class="input-group-text font-small py-0">
                          <span i18n="wb|Main weight balance tab fuel@@wb.main.tab.fuel.groupFuel.tripFuel">Trip fuel</span><span
                                class="required-asterisk">*</span>
                        </div>
                      </div>
                      <input type="number"
                             class="w-100 form-control custom-sm"
                             name="fuelTrip"
                             id="fuelTrip"
                             [(ngModel)]="calculation.fuel.trip"
                             (keyup)="autoSave()" />
                    </div>
                  </div>
                  <div class="col-6 pr-0">
                    <div class="input-group flex-nowrap mt-3">
                      <div class="input-group-prepend custom-sm">
                        <div class="input-group-text font-small py-0">
                          <span i18n="wb|Main weight balance tab fuel@@wb.main.tab.fuel.groupFuel.taxi">Taxi</span><span
                                class="required-asterisk">*</span>
                        </div>
                      </div>
                      <input type="number"
                             class="w-100 form-control custom-sm"
                             name="fuelTaxi"
                             id="fuelTaxi"
                             [(ngModel)]="calculation.fuel.taxi"
                             (keyup)="autoSave()" />
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="input-group flex-nowrap mt-3">
                      <div class="input-group-prepend custom-sm">
                        <span class="input-group-text font-small py-0" i18n="wb|Main weight balance tab fuel@@wb.main.tab.fuel.groupFuel.takeOffFuel">
                          Take off
                        </span>
                      </div>
                      <input type="number"
                            class="w-100 form-control custom-sm"
                            name="fuelOnBoard"
                            id="fuelOnBoard"
                            [value]="calculation.fuel.onBoard - calculation.fuel.taxi"
                            disabled />
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="input-group flex-nowrap mt-3">
                      <div class="input-group-prepend custom-sm">
                        <div class="input-group-text font-small py-0">
                          <span i18n="wb|Main weight balance tab fuel@@wb.main.tab.fuel.groupFuel.density">
                            Density</span><span class="required-asterisk">*</span>
                        </div>
                      </div>
                      <ng-select [items]="ahmData.effectOfFuel"
                                 class="w-100 custom-sm"
                                 bindLabel="density"
                                 bindValue="density"
                                 [(ngModel)]="calculation.fuelDensity"
                                 (change)="autoSave()">
                      </ng-select>
                    </div>
                  </div>
                </div>
                <div class="accordion mt-3" id="accordionIncludingBallast">
                  <div class="card">
                    <div class="card-header p-0" id="headingBallast">
                      <h2 class="mb-0">
                        <button class="btn btn-block text-left collapsed d-flex justify-content-between align-items-center"
                                type="button"
                                data-toggle="collapse"
                                data-target="#ballastOne"
                                aria-expanded="true"
                                aria-controls="ballastOne">
                          <span>
                            <span class="text-light-blue"
                                  i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.accordion.includingBallast">
                              Including ballast
                            </span>&nbsp;
                            <span class="text-dark-gray">{{ calculation.fuel.ballast ? calculation.fuel.ballast : '' }}</span>
                          </span>
                          <span class="text-gray-blue-dark" *ngIf="calculation.fuel.ballast">
                            Δ DOW {{ deltaDowDoi.ballast.dow }} / DOI {{ deltaDowDoi.ballast.doi }}
                          </span>
                        </button>
                      </h2>
                    </div>
                    <div id="ballastOne" class="collapse" aria-labelledby="headingBallast" data-parent="#accordionIncludingBallast">
                      <div class="card-body">
                        <div class="input-group flex-nowrap mt-3">
                          <div class="input-group-prepend custom-sm">
                            <div class="input-group-text font-small py-0">
                              <span i18n="wb|Main weight balance tab fuel@@wb.main.tab.fuel.groupFuel.includingBallast">Ballast</span>
                            </div>
                          </div>
                          <input type="number"
                                 class="w-100 form-control custom-sm"
                                 name="includingBallast"
                                 id="includingBallast"
                                 [(ngModel)]="calculation.fuel.ballast"
                                 (keyup)="autoSave()" />
                        </div>
                        <div class="input-group flex-nowrap mt-3">
                          <div class="input-group-prepend custom-sm">
                            <div class="input-group-text font-small py-0">
                              <span i18n="wb|Main weight balance tab fuel@@wb.main.tab.fuel.groupFuel.DowDoiBallast">Δ DOW/DOI ballast</span>
                            </div>
                          </div>
                          <input type="number"
                                class="w-100 form-control custom-sm rounded-0"
                                name="DowBallast"
                                id="DowBallast"
                                [(ngModel)]="deltaDowDoi.ballast.dow"
                                disabled />
                          <input type="number"
                                class="w-100 form-control custom-sm"
                                name="DoiBallast"
                                id="DoiBallast"
                                [(ngModel)]="deltaDowDoi.ballast.doi"
                                disabled />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="input-group flex-nowrap mb-3">
              <div class="input-group-prepend custom-sm">
                <span class="input-group-text font-small py-0"
                      i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.preparedBy">Prepared by
                </span>
              </div>
              <input type="text"
                    class="w-100 form-control custom-sm"
                    name="preparedBy"
                    id="preparedBy"
                    [(ngModel)]="calculation.preparedBy"
                    disabled />
            </div>
          </div>
          <div class="col-4">
            <div class="card mb-3">
              <div class="card-body">
                <p class="font-weight-bold text-blue h5 mb-3"
                  i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.groupCrewComposition">
                  Crew composition
                </p>
                <div>
                  <div class="form-group">
                    <label>
                      <span i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.groupCrewComposition.composition">Composition</span>
                      <span class="required-asterisk">*</span>
                    </label>
                    <ng-select [items]="crewComposition"
                              class="custom-sm bg-white"
                              bindLabel="nameDowDoi"
                              bindValue="name"
                              name="crewComposition"
                              id="crewComposition"
                              [ngModel]="calculationCrewComposition.name"
                              (change)="updateDowDoiAfterCrewChange($event); autoSave()">
                      <ng-template ng-option-tmp let-item="item">
                        <span class="font-weight-bold">{{item.name}}</span>&nbsp;({{item.dow}}&nbsp;/&nbsp;{{item.doi}})
                      </ng-template>
                      <ng-template ng-label-tmp let-item="item">
                        <span class="font-weight-bold">{{item.name}}</span>&nbsp;(DOW {{item.dow}}&nbsp;/&nbsp;DOI {{item.doi}})
                      </ng-template>
                    </ng-select>
                  </div>
                  <div class="form-group">
                    <label i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.groupCrewComposition.crew">Сrew</label>
                    <div class="input-group input-group-sm">
                      <div class="input-group-prepend">
                        <span class="input-group-text"
                              i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.groupCrewComposition.standard.crewAndStewards">Cockpit and Cabin</span>
                      </div>
                      <input type="number"
                             class="form-control"
                             [(ngModel)]="calculation.crew.cockpit.amount"
                             (keyup)="autoSave()">
                      <input type="number"
                             class="form-control"
                             [(ngModel)]="calculation.crew.cabin.amount"
                             (keyup)="autoSave()">
                    </div>
                  </div>

                  <div class="form-group">
                    <label i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.groupCrewComposition.additional">Additional crew</label>
                    <div class="input-group input-group-sm">
                      <div class="input-group-prepend">
                        <span class="input-group-text"
                              i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.groupCrewComposition.additional.crewAndStewards">
                          Cockpit and Cabin
                        </span>
                      </div>
                      <input type="number"
                             class="form-control"
                             [(ngModel)]="calculation.crew.additional.cockpit"
                             (keyup)="autoSave()">
                      <input type="number"
                             class="form-control"
                             [(ngModel)]="calculation.crew.additional.cabin"
                             (keyup)="autoSave()">
                    </div>
                  </div>

                  <div class="input-group input-group-sm mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text"
                            i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.groupCrewComposition.additionalCrewInCabin">
                        Additional crew in the cabin
                      </span>
                    </div>
                    <input type="number"
                           class="form-control"
                           aria-label="Sizing example input"
                           aria-describedby="inputGroup-sizing-default"
                           [(ngModel)]="calculation.crew.additional.inCabin"
                           (keyup)="autoSave()">
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <p class="font-weight-bold text-blue h5 mb-3"
                  i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.dowDoi">
                  DOW / DOI
                </p>
                <div class="row mb-3">
                  <div class="col-4"></div>
                  <div class="col-4">
                    <span i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.groupDryPlane.dow">DOW</span>
                  </div>
                  <div class="col-4">
                    <span i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.groupDryPlane.doi">DOI</span>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-4">
                    <span i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.groupDryPlane.standard">Standard</span>
                  </div>
                  <div class="col-4">
                    <input type="number"
                          class="w-100 form-control custom-sm"
                          name="standardDow"
                          id="standardDow"
                          [value]="deltaDowDoi.standard.dow"
                          disabled />
                  </div>
                  <div class="col-4">
                    <input type="number"
                          class="w-100 form-control custom-sm"
                          name="standardDoi"
                          id="standardDoi"
                          [value]="deltaDowDoi.standard.doi"
                          disabled />
                  </div>
                </div>
                <div class="row mb-3" *ngIf="deltaDowDoi.pantry.dow !==0 && deltaDowDoi.pantry.doi !== 0">
                  <div class="col-4">
                    <span i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.groupDryPlane.deltaPantry">Δ Pantry</span>
                  </div>
                  <div class="col-4">
                    <input type="number"
                          class="w-100 form-control custom-sm rounded-0"
                          name="deltaPantryDow"
                          id="deltaPantryDow"
                          [value]="deltaDowDoi.pantryDelta.dow"
                          disabled />
                  </div>
                  <div class="col-4">
                    <input type="number"
                          class="w-100 form-control custom-sm"
                          name="deltaPantryDoi"
                          id="deltaPantryDoi"
                          [value]="deltaDowDoi.pantryDelta.doi"
                          disabled />
                  </div>
                </div>
                <div class="row mb-3" *ngIf="deltaDowDoi.potableWater.dow !==0 && deltaDowDoi.potableWater.doi !== 0">
                  <div class="col-4">
                    <span i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.groupDryPlane.deltaWater">Δ Water</span>
                  </div>
                  <div class="col-4">
                    <input type="number"
                          class="w-100 form-control custom-sm rounded-0"
                          name="deltaWaterDow"
                          id="deltaWaterDow"
                          [value]="deltaDowDoi.potableWaterDelta.dow"
                          disabled />
                  </div>
                  <div class="col-4">
                    <input type="number"
                          class="w-100 form-control custom-sm"
                          name="deltaWaterDoi"
                          id="deltaWaterDoi"
                          [value]="deltaDowDoi.potableWaterDelta.doi"
                          disabled />
                  </div>
                </div>
                <div class="row mb-3" *ngIf="deltaDowDoi.ballast.dow !==0 && deltaDowDoi.ballast.doi !== 0">
                  <div class="col-4">
                    <span i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.groupDryPlane.deltaBallast">Δ Ballast</span>
                  </div>
                  <div class="col-4">
                    <input type="number"
                          class="w-100 form-control custom-sm rounded-0"
                          name="deltaBallastDow"
                          id="deltaBallastDow"
                          [value]="deltaDowDoi.ballast.dow"
                          disabled />
                  </div>
                  <div class="col-4">
                    <input type="number"
                          class="w-100 form-control custom-sm"
                          name="deltaBallastDoi"
                          id="deltaBallastDoi"
                          [value]="deltaDowDoi.ballast.doi"
                          disabled />
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-4">
                    <span i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.groupDryPlane.calculated">Calculated</span>
                  </div>
                  <div class="col-4">
                    <input type="number"
                          class="w-100 form-control custom-sm"
                          name="calculatedDow"
                          id="calculatedDow"
                          [value]="deltaDowDoi.calculated.dow"
                          disabled />
                  </div>
                  <div class="col-4">
                    <input type="number"
                          class="w-100 form-control custom-sm"
                          name="calculatedDoi"
                          id="calculatedDoi"
                          [value]="deltaDowDoi.calculated.doi.toFixed(3)"
                          disabled />
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <span i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.groupDryPlane.fact">Fact</span>
                  </div>
                  <div class="col-4">
                    <input type="number"
                           class="w-100 form-control custom-sm"
                           name="factDow"
                           id="factDow"
                           [(ngModel)]="calculation.dow"
                           [disabled]="enterFactDowDoiAutomatically"
                           (keyup)="autoSave()" />
                  </div>
                  <div class="col-4">
                    <input type="number"
                           class="w-100 form-control custom-sm"
                           name="factDoi"
                           id="factDoi"
                           [(ngModel)]="calculation.doi"
                           [disabled]="enterFactDowDoiAutomatically"
                           (keyup)="autoSave()" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="input-group-prepend mt-3">
                      <div class="custom-control custom-switch d-flex" style="min-width: fit-content;">
                        <input type="checkbox"
                              class="custom-control-input"
                              id="enterFactDowDoiAutomatically"
                              name="handLuggageIncluded"
                              [checked]="enterFactDowDoiAutomatically"
                              (click)="changeEnterFactDowDoiAutomatically()">
                        <label class="custom-control-label"
                               for="enterFactDowDoiAutomatically"
                               i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.groupDryPlane.enterFactDowDoiAutomatically">Put Fact
                          automatically</label>
                      </div>
                    </div>
                  </div>
                </div>

                <!--<div class="row">
                  <div class="col-6 pr-0">
                    <div class="input-group flex-nowrap mb-3">
                      <div class="input-group-prepend custom-sm">
                        <div class="input-group-text font-small py-0">
                          <span i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.groupDryPlane.dow">DOW</span>
                          <span class="required-asterisk">*</span>
                        </div>
                      </div>
                      <input type="number"
                            class="w-100 form-control custom-sm"
                            name="calculationDow"
                            id="calculationDow"
                            [(ngModel)]="calculation.dow" />
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="input-group flex-nowrap">
                      <div class="input-group-prepend custom-sm">
                        <div class="input-group-text font-small py-0">
                          <span i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.groupDryPlane.doi">DOI</span>
                          <span class="required-asterisk">*</span>
                        </div>
                      </div>
                      <input type="number"
                            class="w-100 form-control custom-sm"
                            name="calculationDoi"
                            id="calculationDoi"
                            [(ngModel)]="calculation.doi" />
                    </div>
                  </div>
                </div>-->
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="card mb-3">
              <div class="card-body">
                <div class="d-flex flex-column">
                  <p class="font-weight-bold text-blue h5 mb-3 flex-grow-1"
                    i18n="wb|Main weight balance calculation@@wb.main.calculation.weightAndBalanceValues">
                    Weight and balance values
                  </p>
                  <div class="alert alert-warning" role="alert" *ngIf="ahmData.lastupdate > calculation.ahmLastupdate"
                        i18n="wb|Main weight balance calculation@@wb.main.calculation.oldData">
                    Changes found in AHM, current calculation uses old data
                  </div>
                  <div class="alert alert-danger" role="alert" *ngIf="printDisabled"
                        i18n="wb|Main weight balance calculation@@wb.main.calculation.error">
                    Aircraft alignment is not correct
                  </div>
                  <div class="alert alert-success fade show" role="alert" *ngIf="calculationCorrect">
                    <div class="d-flex justify-content-between">
                      <div i18n="wb|Main weight balance calculation@@wb.main.calculation.correct">Aircraft alignment is correct</div>
                      <div contenteditable="true"><small><span i18n="wb|Main weight balance calculation@@wb.main.calculation.name">Calculation</span>&nbsp;{{calculation.lastupdate | date: 'dd.MM.yyyy HH:mm'}}</small></div>
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="btn-group btn-group-toggle h-auto flex-grow-1" data-toggle="buttons">
                      <label class="btn font-small pt-1 btn-xs"
                            [ngClass]="{'active bg-blue text-white': !viewParametrs.chartIsShow,
                                              'bg-light-gray border': viewParametrs.chartIsShow}">
                        <input type="radio"
                              name="options"
                              id="table"
                              (click)="changeWeightAndBalanceView('table')"><span i18n="wb|Main weight balance calculation@@wb.main.calculation.weightAndBalanceValues.button.table">Table</span>
                      </label>
                      <label class="btn font-small pt-1 btn-xs"
                            [ngClass]="{'active bg-blue text-white': viewParametrs.chartIsShow,
                                  'bg-light-gray border': !viewParametrs.chartIsShow}">
                        <input type="radio"
                              name="options"
                              id="chart"
                              (click)="changeWeightAndBalanceView('chart')"><span i18n="wb|Main weight balance calculation@@wb.main.calculation.weightAndBalanceValues.button.chart">Chart</span>
                      </label>
                    </div>
                      <button class="btn btn-ico btn-light-gray px-3 ml-2 h-auto"
                              style="min-width: auto;"
                              data-toggle="modal"
                              data-target=".bd-example-modal-xl">
                        <i class="material-icons">open_in_new</i>
                      </button>
                  </div>
                </div>
                <div *ngIf="!viewParametrs.chartIsShow">
                  <p class="font-weight-bold text-muted mt-3 mb-0" i18n="wb|Main weight balance calculation@@wb.main.calculation.dryPlane">Dry plane</p>
                  <table class="w-100 table-striped table-border">
                    <tbody>
                      <tr>
                        <td class="font-weight-bold d-flex align-items-center">DOW</td>
                        <td i18n="wb|Main weight balance calculation@@wb.main.calculation.dryOperationWeight">Dry operation weight</td>
                        <td class="font-weight-bold">{{ calculation.dow | formatNumber }}</td>
                      </tr>
                      <tr>
                        <td class="font-weight-bold d-flex align-items-center">DOI</td>
                        <td i18n="wb|Main weight balance calculation@@wb.main.calculation.dryOperationIndex">Dry operating index</td>
                        <td class="font-weight-bold">{{ calculation.doi | formatNumber }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <p class="font-weight-bold text-muted mt-3 mb-0">MACZFW <span class="text-blue">{{ calculation.maczfw ? calculation.maczfw.toFixed(2) : '' }}</span></p>
                  <table class="w-100 table-striped table-border">
                    <thead>
                      <tr class="font-weight-bold">
                        <td scope="col"></td>
                        <td scope="col"></td>
                        <td scope="col" i18n="wb|Main weight balance calculation@@wb.main.calculation.curent">Current</td>
                        <td scope="col" i18n="wb|Main weight balance calculation@@wb.main.calculation.max">Max</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="font-weight-bold d-flex align-items-center">LIZFW</td>
                        <td i18n="wb|Main weight balance calculation@@wb.main.calculation.loadedIndexAtZeroFuelWeight">Loaded index at zero fuel weight</td>
                        <td class="font-weight-bold">{{ (calculation.lizfw ? calculation.lizfw.toFixed(2) : '') | formatNumber}}</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td class="font-weight-bold d-flex align-items-center">TTL</td>
                        <td i18n="wb|Main weight balance calculation@@wb.main.calculation.totalTrafficLoad">Total traffic load</td>
                        <td class="font-weight-bold">{{ calculation.ttl | formatNumber }}</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td class="font-weight-bold d-flex align-items-center"
                            [ngClass]="{'text-red': calculation.zfw > calculation.mzfw}">ZFW</td>
                        <td i18n="wb|Main weight balance calculation@@wb.main.calculation.zeroFuelWeight">Zero fuel weight</td>
                        <td class="font-weight-bold"
                            [ngClass]="{'text-red': calculation.zfw > calculation.mzfw}">{{ calculation.zfw | formatNumber }}</td>
                        <td>{{ calculation.mzfw | formatNumber }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <p class="font-weight-bold text-muted mt-3 mb-0">MACTOW <span class="text-blue">{{ calculation.mactow ? calculation.mactow.toFixed(2) : '' }}</span></p>
                  <table class="w-100 table-striped table-border">
                    <thead>
                      <tr class="font-weight-bold">
                        <td scope="col"></td>
                        <td scope="col"></td>
                        <td scope="col" i18n="wb|Main weight balance calculation@@wb.main.calculation.curent">Current</td>
                        <td scope="col" i18n="wb|Main weight balance calculation@@wb.main.calculation.max">Max</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="font-weight-bold">FOB</td>
                        <td i18n="wb|Main weight balance calculation@@wb.main.calculation.fuelOnBoard">Fuel on board</td>
                        <td class="font-weight-bold">{{ calculation.fuel.onBoard | formatNumber }}</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td class="font-weight-bold">Taxi</td>
                        <td i18n="wb|Main weight balance calculation@@wb.main.calculation.taxiFuel">Taxi fuel</td>
                        <td class="font-weight-bold">{{ calculation.fuel.taxi | formatNumber }}</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td class="font-weight-bold">TOF</td>
                        <td i18n="wb|Main weight balance calculation@@wb.main.calculation.takeOffFuel">Take off fuel</td>
                        <td class="font-weight-bold">{{ (calculation.fuel.onBoard - calculation.fuel.taxi) | formatNumber }}</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td class="font-weight-bold"
                            [ngClass]="{'text-red': calculation.tow > calculation.mtow}">TOW
                        </td>
                        <td i18n="wb|Main weight balance calculation@@wb.main.calculation.takeOffWeightActual">
                          Take off weight actual
                        </td>
                        <td [ngClass]="{'text-red': calculation.tow > calculation.mtow}"
                            class="font-weight-bold">{{ calculation.tow | formatNumber }}</td>
                        <td>{{ calculation.mtow | formatNumber }}</td>
                      </tr>
                      <tr>
                        <td class="font-weight-bold">LITOW</td>
                        <td i18n="wb|Main weight balance calculation@@wb.main.calculation.loadedIndexAtTakeOffWeight">Loaded index at take off weight</td>
                        <td class="font-weight-bold">{{ calculation.litow ? calculation.litow.toFixed(2) : '' | formatNumber }}</td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                  <p class="font-weight-bold text-muted mt-3 mb-0">
                    MACLAW <span class="text-blue">{{ calculation.maclaw ? calculation.maclaw.toFixed(2) : '' }}</span>
                  </p>
                  <table class="w-100 table-striped table-border">
                    <thead>
                      <tr class="font-weight-bold">
                        <td scope="col"></td>
                        <td scope="col"></td>
                        <td scope="col" i18n="wb|Main weight balance calculation@@wb.main.calculation.curent">Current</td>
                        <td scope="col" i18n="wb|Main weight balance calculation@@wb.main.calculation.max">Max</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="font-weight-bold" [ngClass]="{'text-red': calculation.lw > calculation.mlw}">LAW</td>
                        <td i18n="wb|Main weight balance calculation@@wb.main.calculation.landingWeightActual">Landing weight actual </td>
                        <td [ngClass]="{'text-red': calculation.lw > calculation.mlw}"
                            class="font-weight-bold">{{ calculation.lw | formatNumber }}</td>
                        <td>{{ calculation.mlw | formatNumber }}</td>
                      </tr>
                      <tr>
                        <td class="font-weight-bold">LILAW</td>
                        <td i18n="wb|Main weight balance calculation@@wb.main.calculation.loadedIndexAtLandingWeight">Loaded index at landing weight</td>
                        <td class="font-weight-bold">{{ calculation.lilaw ? calculation.lilaw.toFixed(2) : '' | formatNumber }}</td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                  <p class="font-weight-bold text-muted mt-3 mb-0" i18n="wb|Main weight balance calculation@@wb.main.calculation.trafficLoad">Traffic load</p>
                  <table class="w-100 table-striped table-border">
                    <tbody>
                      <tr>
                        <td i18n="wb|Main weight balance calculation@@wb.main.calculation.trafficLoad.allowed">Allowed traffic load</td>
                        <td class="font-weight-bold text-right">{{ calculation.ll | formatNumber }}</td>
                      </tr>
                      <tr>
                        <td i18n="wb|Main weight balance calculation@@wb.main.calculation.trafficLoad.total">Total traffic load</td>
                        <td class="font-weight-bold text-right">{{ calculation.ttl | formatNumber }}</td>
                      </tr>
                      <tr>
                        <td i18n="wb|Main weight balance calculation@@wb.main.calculation.trafficLoad.underload">Underload</td>
                        <td class="font-weight-bold text-right">{{ (calculation.ll - calculation.ttl) | formatNumber }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div [ngStyle]="{'height': !viewParametrs.chartIsShow ? '0' : 'auto'}" style="overflow: hidden;">
                  <div id="gravityCentreBlock" class="mt-3" #gravityCentreBlock></div>
                  <table class="w-100 table-striped mt-3 table-border">
                    <thead>
                      <tr>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col" i18n="wb|Main weight balance calculation@@wb.main.calculation.curent">Current</th>
                        <th scope="col" i18n="wb|Main weight balance calculation@@wb.main.calculation.max">Max</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="font-weight-bold d-flex align-items-center"
                            [ngClass]="{'text-red': calculation.zfw > calculation.mzfw}">ZFW</td>
                        <td i18n="wb|Main weight balance calculation@@wb.main.calculation.zeroFuelWeight">Zero fuel weight</td>
                        <td class="font-weight-bold"
                            [ngClass]="{'text-red': calculation.zfw > calculation.mzfw}">{{ calculation.zfw | formatNumber }}</td>
                        <td>{{ calculation.mzfw | formatNumber }}</td>
                      </tr>
                      <tr>
                        <td class="font-weight-bold"
                            [ngClass]="{'text-red': calculation.tow > calculation.mtow}">TOW
                        </td>
                        <td i18n="wb|Main weight balance calculation@@wb.main.calculation.takeOffWeightActual">
                          Take off weight actual
                        </td>
                        <td [ngClass]="{'text-red': calculation.tow > calculation.mtow}"
                            class="font-weight-bold">{{ calculation.tow | formatNumber }}</td>
                        <td>{{ calculation.mtow | formatNumber }}</td>
                      </tr>
                      <tr>
                        <td class="font-weight-bold" [ngClass]="{'text-red': calculation.lw > calculation.mlw}">LAW</td>
                        <td i18n="wb|Main weight balance calculation@@wb.main.calculation.landingWeightActual">Landing weight actual </td>
                        <td [ngClass]="{'text-red': calculation.lw > calculation.mlw}"
                            class="font-weight-bold">{{ calculation.lw | formatNumber }}</td>
                        <td>{{ calculation.mlw | formatNumber }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <p class="font-weight-bold text-muted mt-3 mb-0" i18n="wb|Main weight balance calculation@@wb.main.calculation.trafficLoad">Traffic load</p>
                  <table class="w-100 table-striped table-border">
                    <tbody>
                      <tr>
                        <td i18n="wb|Main weight balance calculation@@wb.main.calculation.trafficLoad.allowed">Allowed traffic load</td>
                        <td class="font-weight-bold text-right">{{ calculation.ll | formatNumber }}</td>
                      </tr>
                      <tr>
                        <td i18n="wb|Main weight balance calculation@@wb.main.calculation.trafficLoad.total">Total traffic load</td>
                        <td class="font-weight-bold text-right">{{ calculation.ttl | formatNumber }}</td>
                      </tr>
                      <tr>
                        <td i18n="wb|Main weight balance calculation@@wb.main.calculation.trafficLoad.underload">Underload</td>
                        <td class="font-weight-bold text-right">{{ (calculation.ll - calculation.ttl) }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- <div class="card">
              <div class="card-body">
                <div class="d-flex align-items-center justify-content-between mb-3">
                  <p class="font-weight-bold text-blue h5 m-0"
                    i18n="wb|Main weight balance calculation@@wb.main.calculation.centureOfGravity">
                    Centre of gravity
                  </p>
                  <button class="btn btn-xs btn-ico btn-light-gray"
                  data-toggle="modal"
                  data-target=".bd-example-modal-xl"><i class="material-icons">open_in_new</i></button>
                </div>
                <div id="gravityCentreBlock" #gravityCentreBlock></div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
      <div *ngIf="showTab('flight-tab-passenger')" class="bg-white p-3">
        <div class="row align-items-center">
          <div class="col-xl-2 col-lg-6 pr-0">
            <div class="input-group input-group-sm">
              <div class="input-group-prepend">
                <span class="input-group-text"
                      i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPassengersAndBaggage.placesDeclared">
                  Seats declared
                </span>
              </div>
              <input type="number"
                    class="form-control"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-default"
                    [value]="placesDeclared()"
                    disabled>
            </div>
          </div>
          <div class="col-xl-3 col-lg-6 pr-0">
            <div class="input-group input-group-sm">
              <div class="input-group-prepend">
                <span class="input-group-text"
                      i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPassengersAndBaggage.additionalCrewInCabin">
                  Additional crew in the cabin
                </span>
              </div>
              <input type="number"
                    class="form-control"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-default"
                    [value]="calculation.crew.additional.inCabin"
                    disabled>
            </div>
          </div>
          <div class="col-xl-2 col-lg-6 pr-0">
            <div class="input-group input-group-sm">
              <div class="input-group-prepend">
                <span class="input-group-text"
                      i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPassengersAndBaggage.total">
                  Total
                </span>
              </div>
              <input type="number"
                    class="form-control"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-default"
                    [value]="placesDeclared() + calculation.crew.additional.inCabin"
                    disabled>
            </div>
          </div>
          <div class="col-xl-2 col-lg-6 pr-o">
            <div class="input-group input-group-sm">
              <div class="input-group-prepend">
                <span class="input-group-text"
                      i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPassengersAndBaggage.unallocatedSeats"
                      [ngClass]="{'border-danger': unallocatedSeats() > 0 && calculation.configuration}">
                  Unallocated seats
                </span>
              </div>
              <input type="number"
                    class="form-control"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-default"
                    [value]="unallocatedSeats()"
                    [ngClass]="{'border-danger': unallocatedSeats() > 0 && calculation.configuration}"
                    disabled>
            </div>
          </div>
          <div class="col-xl-3 col-lg-6">
            <div>
              <span i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.lineGraph">Line balance graph</span>&nbsp;FWD ->
              <div class="w-100 rounded border border-dark mt-2" style="height: 6px;">
                <div style="width: 2px;height: 18px;position: relative;background-color: #667077;top: -7px;float: left;left: 50%;"></div>
                <div *ngIf="percentageBalancePosition > -1"
                     class="rounded"
                     style="width: 10px; height: 22px; position: relative; background-color: #6b6883; top: -9px;"
                     [ngStyle]="{'left': percentageBalancePosition + '%'}">
                  &nbsp;
                </div>
              </div>
            </div>
          </div>
        </div>
        <p class="font-weight-bold text-blue h5 mb-3"
          i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPassengersAndBaggage.table.zoneSeating">
          Zone seating
        </p>
        <table class="w-100 mt-3 table-border" *ngIf="calculation.configuration; else: selectConfiguration">
          <thead class="text-center">
            <tr class="bg-blue color-white font-weight-bold">
              <th i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupCrew.table.crewInPassengersSeats.fwd"
                [attr.colspan]="getZoneSeating(calculation.passengers.cabinArea, 'FWD').length">
                FWD
              </th>
              <th i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupCrew.table.crewInPassengersSeats.aft"
                [attr.colspan]="getZoneSeating(calculation.passengers.cabinArea, 'AFT').length">
                AFT
              </th>
            </tr>
            <tr class="bg-blue color-white font-weight-bold">
              <th *ngFor="let zone of getZoneSeating(calculation.passengers.cabinArea, 'FWD')">
                {{ zone.name }}
              </th>
              <th *ngFor="let zone of getZoneSeating(calculation.passengers.cabinArea, 'AFT')">
                {{ zone.name }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td *ngFor="let zone of getZoneSeating(calculation.passengers.cabinArea, 'FWD')">
                <div class="card bg-light-gray p-1 text-center">
                  <div class="border-bottom">
                    <span i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.zoneSeating.rows">Rows</span>&nbsp;{{ zone.rowsString }}
                  </div>
                  <div class="row">
                    <div class="col pr-0">
                      <span i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.zoneSeating.max">Max</span>
                      <input type="number"
                             class="text-center"
                             [value]="zone.maxPassengers"
                             [ngClass]="{'text-danger font-weight-bold border-danger': zone.passengers.adult > zone.maxPassengers}"
                             disabled>
                    </div>
                    <div class="col">
                      <span i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.zoneSeating.fact">Fact</span>
                      <input type="number" class="text-center" [(ngModel)]="zone.passengers.adult" (keyup)="autoSave()">
                    </div>
                  </div>
                </div>
              </td>
              <td *ngFor="let zone of getZoneSeating(calculation.passengers.cabinArea, 'AFT')">
                <div class="card bg-light-gray p-1 text-center">
                  <div class="border-bottom">
                    <span i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.zoneSeating.rows">Rows</span>&nbsp;{{ zone.rowsString }}
                  </div>
                  <div class="row">
                    <div class="col pr-0">
                      <span i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.zoneSeating.max">Max</span>
                      <input type="number"
                             class="text-center"
                             [value]="zone.maxPassengers"
                             [ngClass]="{'text-danger font-weight-bold border-danger': zone.passengers.adult > zone.maxPassengers}"
                             disabled>
                    </div>
                    <div class="col">
                      <span i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.zoneSeating.fact">Fact</span>
                      <input type="number" class="text-center" [(ngModel)]="zone.passengers.adult" (keyup)="autoSave()">
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <ng-template #selectConfiguration>
          <div class="alert alert-warning mt-3"
            i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.selectCabinVersion">
            Select cabin version
          </div>
        </ng-template>
        <div class="row mt-3">
          <div class="col-6">
            <p class="font-weight-bold text-blue h5 mb-3"
              i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPassengersAndBaggage.table.passengersWeight">Passenger weights
            </p>
            <table class="w-100 mb-3 table-border">
              <tbody>
                <tr class="bg-blue color-white font-weight-bold text-center">
                  <th i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPassengersAndBaggage.table.passengersWeight.adultMale">Adult / Male</th>
                  <th i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPassengersAndBaggage.table.passengersWeight.female">Female</th>
                  <th i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPassengersAndBaggage.table.passengersWeight.children">Children</th>
                  <th i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPassengersAndBaggage.table.passengersWeight.infant">Infant</th>
                </tr>
                <tr class="bg-light-gray">
                  <td>
                    <input type="number" class="text-center" [(ngModel)]="calculation.passengers.weights.adult" (keyup)="autoSave()">
                  </td>
                  <td>
                    <input type="number" class="text-center" [(ngModel)]="calculation.passengers.weights.female" (keyup)="autoSave()">
                  </td>
                  <td>
                    <input type="number" class="text-center" [(ngModel)]="calculation.passengers.weights.child" (keyup)="autoSave()">
                  </td>
                  <td>
                    <input type="number" class="text-center" [(ngModel)]="calculation.passengers.weights.infant" (keyup)="autoSave()">
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="d-flex justify-content-between">
              <div class="input-group-prepend">
                <div class="custom-control custom-switch d-flex ml-2" style="min-width: fit-content;">
                  <input type="checkbox"
                        class="custom-control-input"
                        id="handLuggageIncluded"
                        name="handLuggageIncluded"
                        [checked]="calculation.passengers.weights.handLuggageIncluded"
                        (click)="changeHandBaggage('ahm')">
                  <label class="custom-control-label" for="handLuggageIncluded"
                  i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPassengersAndBaggage.handBaggageWeightIncluded">
                  Hand baggage weight is included in the passenger weights
                  </label>
                </div>
              </div>
              <div class="input-group flex-nowrap w-auto my-auto" *ngIf="!calculation.passengers.weights.handLuggageIncluded">
                <div class="input-group-prepend custom-sm">
                  <span class="input-group-text font-small py-0"
                    i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPassengersAndBaggage.table.passengersWeight.handBaggage">
                    Hand baggage
                  </span>
                </div>
                <input type="number" class="form-control custom-sm" [(ngModel)]="calculation.passengers.weights.handLuggage" (keyup)="autoSave()"/>
              </div>
            </div>
          </div>
          <div class="col-6">
            <p class="font-weight-bold text-blue h5 mb-3"
              i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPassengersAndBaggage.table.passengers">Passengers
            </p>
            <table class="w-100 mb-3 table-border">
              <thead>
                <tr class="bg-blue color-white font-weight-bold text-center">
                  <th i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPassengersAndBaggage.table.payload.cityAirport">City / Airport</th>
                  <th i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPassengersAndBaggage.table.payload.mfc">Adult+Child</th>
                  <th i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPassengersAndBaggage.table.payload.business">Business</th>
                  <th i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPassengersAndBaggage.table.payload.economy">Economy</th>
                </tr>
              </thead>
              <tbody *ngFor="let route of flight.route">
                <tr *ngIf="flight.isDeparture(route.airportId)">
                  <td class="font-weight-bold">{{route.displayName}}</td>
                  <td>{{route.loading.pax}}</td>
                  <td>{{route.loading.business}}</td>
                  <td>{{route.loading.economy}}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr class="bg-light-yellow">
                  <td class="font-weight-bold"
                    i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPassengersAndBaggage.table.payload.total">
                    Total
                  </td>
                  <td>{{flight.loading.adult + flight.loading.female + flight.loading.child}}</td>
                  <td>{{flight.loading.business}}</td>
                  <td>{{flight.loading.economy}}</td>
                </tr>
              </tfoot>
            </table>

          </div>
        </div>
        <!--<div class="row mb-4">
          <div class="col-5">
            <div class="card h-100">
              <div class="card-body">
                <p class="font-weight-bold text-blue h5 mb-3"
                  i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPassengersAndBaggage.table.zoneSeating">
                  Zone seating
                </p>
                <div class="input-group input-group-sm mb-2">
                  <div class="input-group-prepend">
                    <span class="input-group-text"
                          i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPassengersAndBaggage.placesDeclared"
                          [ngClass]="{'border-danger': getTotalPassenger() != placesDeclared() && calculation.configuration}">
                      Seats declared
                    </span>
                  </div>
                  <input type="number"
                        class="form-control"
                        aria-label="Sizing example input"
                        aria-describedby="inputGroup-sizing-default"
                        [value]="placesDeclared()"
                        [ngClass]="{'border-danger': getTotalPassenger() != placesDeclared() && calculation.configuration}"
                        disabled>
                </div>
                <div class="input-group input-group-sm mb-1">
                  <div class="input-group-prepend">
                    <span class="input-group-text"
                          i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPassengersAndBaggage.unallocatedSeats"
                          [ngClass]="{'border-danger': unallocatedSeats() > 0 && calculation.configuration}">
                      Unallocated seats
                    </span>
                  </div>
                  <input type="number"
                        class="form-control"
                        aria-label="Sizing example input"
                        aria-describedby="inputGroup-sizing-default"
                        [value]="unallocatedSeats() <= 0 ? '' : unallocatedSeats()"
                        [ngClass]="{'border-danger': unallocatedSeats() > 0 && calculation.configuration}"
                        disabled>
                </div>
                <div *ngIf="getTotalPassenger() != placesDeclared() && calculation.configuration"
                      class="text-red d-flex align-items-center">
                    <i class="material-icons mr-2">warning</i>
                    <span i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.totalPassengersNotEqualCurrent">
                      Total passengers is not equal to the current load
                    </span>
                  </div>
                <table class="w-100 table-striped mt-3 table-border" *ngIf="calculation.configuration; else: selectConfiguration">
                  <thead>
                    <tr class="bg-blue color-white font-weight-bold">
                      <th i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupCrew.table.crewInPassengersSeats.cabinSection">Cabin section</th>
                      <th i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupCrew.table.crewInPassengersSeats.index">Index</th>
                      <th i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupCrew.table.crewInPassengersSeats.rows">Rows</th>
                      <th i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupCrew.table.crewInPassengersSeats.max">MAX</th>
                      <th i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupCrew.table.crewInPassengersSeats.fact">Fact</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let element of calculation.passengers.cabinArea; let i = index">
                      <td>{{ element.name }}</td>
                      <td>{{ element.index }}</td>
                      <td class="text-nowrap">{{ element.rowsString }}</td>
                      <td class="text-center" [ngClass]="{'text-red font-weight-bold': element.maxPassengers < element.passengers.adult}">{{ element.maxPassengers }}</td>
                      <td>
                        <input type="number" [(ngModel)]="element.passengers.adult">
                      </td>
                    </tr>
                    <tr class="bg-light-yellow">
                      <td class="font-weight-bold" colspan="4"
                        i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.total">
                        Total
                      </td>
                      <td [ngClass]="{'font-weight-bold text-danger': unallocatedSeats() < 0}">{{ getTotalPassenger() }}</td>
                    </tr>
                  </tbody>
                </table>
                <ng-template #selectConfiguration>
                  <div class="alert alert-warning mt-3"
                    i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.selectCabinVersion">
                    Select cabin version
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
          <div class="col-7">
            <div class="card h-100">
              <div class="card-body">
                <p class="font-weight-bold text-blue h5 mb-3"
                  i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPassengersAndBaggage.table.passengersWeight">Passenger weights
                </p>
                  <table class="w-100 table-striped mb-3 table-border">
                    <tbody>
                      <tr class="bg-blue color-white font-weight-bold text-center">
                        <th i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPassengersAndBaggage.table.passengersWeight.adultMale">Adult / Male</th>
                        <th i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPassengersAndBaggage.table.passengersWeight.female">Female</th>
                        <th i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPassengersAndBaggage.table.passengersWeight.children">Children</th>
                        <th i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPassengersAndBaggage.table.passengersWeight.infant">Infant</th>
                      </tr>
                      <tr>
                        <td>
                          <input type="number" [(ngModel)]="calculation.passengers.weights.adult">
                        </td>
                        <td>
                          <input type="number" [(ngModel)]="calculation.passengers.weights.female">
                        </td>
                        <td>
                          <input type="number" [(ngModel)]="calculation.passengers.weights.child">
                        </td>
                        <td>
                          <input type="number" [(ngModel)]="calculation.passengers.weights.infant">
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="d-flex justify-content-between">
                    <div class="input-group-prepend">
                      <div class="custom-control custom-switch d-flex ml-2" style="min-width: fit-content;">
                        <input type="checkbox"
                              class="custom-control-input"
                              id="handLuggageIncluded"
                              name="handLuggageIncluded"
                              [checked]="calculation.passengers.weights.handLuggageIncluded"
                              (click)="changeHandBaggage()">
                        <label class="custom-control-label" for="handLuggageIncluded"
                        i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPassengersAndBaggage.handBaggageWeightIncluded">
                        Hand baggage weight is included in the passenger weights
                        </label>
                      </div>
                    </div>
                    <div class="input-group flex-nowrap w-auto my-auto" *ngIf="!calculation.passengers.weights.handLuggageIncluded">
                      <div class="input-group-prepend custom-sm">
                        <span class="input-group-text font-small py-0"
                          i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPassengersAndBaggage.table.passengersWeight.handBaggage">
                          Hand baggage
                        </span>
                      </div>
                      <input type="number" class="form-control custom-sm" [(ngModel)]="calculation.passengers.weights.handLuggage"/>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="card">
              <div class="card-body">
                <p class="font-weight-bold text-blue h5 mb-3"
                  i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupCrew">
                  Crew
                </p>
                <table class="w-100 table-striped table-border">
                  <thead>
                    <tr class="bg-blue color-white font-weight-bold">
                      <th i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupCrew.table.type">Type</th>
                      <th i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPasengers.table.amount">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPasengers.table.standardCrew">Standard cockpit crew</td>
                      <td>{{calculation.crew.schema.cockpit}}</td>
                    </tr>
                    <tr>
                      <td i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPasengers.table.standardStewards">Standard cabin crew</td>
                      <td>{{calculation.crew.schema.cabin}}</td>
                    </tr>
                    <tr>
                      <td i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPasengers.table.additionalCrew">Additional cockpit crew</td>
                      <td>{{calculation.crew.additional.cabin}}</td>
                    </tr>
                    <tr>
                      <td i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPasengers.table.additionalStewards">Additional cabin crew</td>
                      <td>{{calculation.crew.additional.cockpit}}</td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold" i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPasengers.table.additionalCrewInCabin">Additional crew in the cabin</td>
                      <td>{{calculation.crew.additional.inCabin}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="card">
              <div class="card-body">
                <p class="font-weight-bold text-blue h5 mb-3"
                  i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPasengers">Passengers</p>
                <div class="row">
                  <div class="col-6">
                    <p class="font-weight-bold mb-0"
                      i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.totalPassengers">Total passengers</p>
                    <table class="w-100 table-striped table-border">
                      <thead>
                        <tr class="bg-blue color-white font-weight-bold">
                          <th i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPasengers.table.passengersSeats.type">Type</th>
                          <th i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPasengers.table.passengersSeats.amount">Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.adult">Adult</td>
                          <td>{{flight.loading.adult}}</td>
                        </tr>
                        <tr>
                          <td i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.child">Child</td>
                          <td>{{flight.loading.child}}</td>
                        </tr>
                        <tr>
                          <td i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.infant">Infant</td>
                          <td>{{flight.loading.infant}}</td>
                        </tr>
                        <tr class="bg-light-yellow">
                          <td class="font-weight-bold"
                            i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.total">
                            Total
                          </td>
                          <td>{{ flight.loading.adult + flight.loading.child + flight.loading.infant }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="col-6">
                    <p class="font-weight-bold mb-0"
                      i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.occupiedSeats">Occupied seats</p>
                    <table class="w-100 table-striped table-border">
                      <thead>
                        <tr class="bg-blue color-white font-weight-bold">
                          <th i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPasengers.table.passengersSeats.type">Type</th>
                          <th i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPasengers.table.passengersSeats.amount">Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.business">Business</td>
                          <td>{{flight.loading.business}}</td>
                        </tr>
                        <tr>
                          <td i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.economy">Economy</td>
                          <td>{{flight.loading.economy}}</td>
                        </tr>
                        <tr class="bg-light-yellow">
                          <td class="font-weight-bold"
                            i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.total">
                            Total
                          </td>
                          <td>{{flight.loading.business + flight.loading.economy}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>-->
      </div>
      <div *ngIf="showTab('flight-tab-detributional')" class="bg-white p-3">
        <div class="row align-items-center mb-3">
          <div class="col-3 pr-0">
            <div class="input-group input-group-sm">
              <div class="input-group-prepend">
                <span class="input-group-text" i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.payload">
                  Payload
                </span>
              </div>
              <input type="number"
                    class="form-control"
                    [value]="flight.loading.luggage + flight.loading.cargo + flight.loading.mail"
                    disabled>
            </div>
          </div>
          <div class="col-3 pr-0">
            <div class="input-group input-group-sm">
              <div class="input-group-prepend">
                <span class="input-group-text" i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.loaded">
                  Loaded
                </span>
              </div>
              <input type="number"
                    class="form-control"
                    [value]="allLoadWeight()"
                    disabled>
            </div>
          </div>
          <div class="col-3 pr-0">
            <div class="input-group input-group-sm">
              <div class="input-group-prepend">
                <span class="input-group-text" i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.unallocatedLoad">
                  Unallocated load
                </span>
              </div>
              <input type="number"
                    class="form-control"
                    [value]="(flight.loading.luggage + flight.loading.cargo + flight.loading.mail) - allLoadWeight()"
                    disabled>
            </div>
          </div>
          <div class="col-3">
            <div>
              <span i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.lineGraph">Line balance graph</span>&nbsp;FWD ->
              <div class="w-100 rounded border border-dark mt-2" style="height: 6px;">
                <div style="width: 2px;height: 18px;position: relative;background-color: #667077;top: -7px;float: left;left: 50%;"></div>
                <div *ngIf="percentageBalancePosition > -1"
                     class="rounded"
                     style="width: 10px; height: 22px; position: relative; background-color: #6b6883; top: -9px;"
                     [ngStyle]="{'left': percentageBalancePosition + '%'}">
                  &nbsp;
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row my-2">
          <div class="input-group-prepend">
            <div class="custom-control custom-switch d-flex ml-2" style="min-width: fit-content;">
              <input type="checkbox"
                    class="custom-control-input"
                    id="fktIncludedInDowDoi"
                    name="fktIncludedInDowDoi"
                    [checked]="calculation.fktIncludedInDowDoi"
                    (click)="changeFktIncluded()">
              <label class="custom-control-label" for="fktIncludedInDowDoi"
                  i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.equipmentIncludedInDowDoi">
                  Equipment included in DOW/DOI
              </label>
            </div>
          </div>
        </div>
        <div class="example-boundary d-flex position-relative flex-row-reverse w-100 mb-3"
            [ngStyle]="{'width': (trunkMap.width * trunkMap.proportions) + 'px'}"
            id="bayMap" #bayMap>
          <div class="bay-map" *ngFor="let compartment of trunkMap.trunkMap; let i = index"
                cdkDropList cdkDropListSortingDisabled
                [ngStyle]="{'width': (compartment.width * trunkMap.proportions) + 'px',
                            'height': (trunkMap.height * trunkMap.proportions) + 'px'}">
            <div class="ac-doors"
                *ngFor="let door of compartment.doors; let i_door = index"
                [ngStyle]="{'width': door.width * trunkMap.proportions + 'px',
                          'right': (door.right * trunkMap.proportions) + 'px'}"></div>
            <a *ngFor="let mapItem of compartment.bays"
                class="map-item map-item-{{mapItem.category}} {{trunkMapClassList(mapItem)}}"
                (focus)="focusBay(mapItem.name);"
                (blur)="blurBay()"
                href="javascript:void(0);"
                cdkDrag (cdkDragStarted)="dragCargoOnMap(mapItem.name, 'map')" (cdkDragEnded)="dragEnd()"
                (mouseover)="trunkMap.targetBay= mapItem;"
                (mouseout)="trunkMap.targetBay = null;"
                [ngStyle]="{'width': mapItem.styles.width * trunkMap.proportions + 'px',
                            'height': mapItem.styles.height * trunkMap.proportions + 'px',
                            'top': mapItem.styles.top * trunkMap.proportions + 'px',
                            'z-index': mapItem.styles.index,
                            'bottom': mapItem.styles.bottom * trunkMap.proportions + 'px',
                            'right': mapItem.styles.right * trunkMap.proportions + 'px'}">
              <div class="map-item-name text-right px-2">
                {{mapItem.name}}
              </div>
              <div *cdkDragPreview
                   style="width: 140px; height: 180px"
                   class="rounded border border-dark bg-light">
                <div class="d-flex flex-column w-100 h-100 justify-content-center">
                  <div class="text-center font-weight-bold" [innerHTML]="printMapItemInfo(mapItem.name)"></div>
                </div>
              </div>
              <div class="map-item-info p-1" [innerHTML]="printMapItemInfo(mapItem.name)"></div>
              <div class="load-volume">
                <div class="loaded-volume"
                    [ngStyle]="{'height': (getBayVolume(mapItem.name) * 100 / mapItem.maxVolume) + '%'}"
                    [ngClass]="{'bg-red': (getBayVolume(mapItem.name) * 100 / mapItem.maxVolume) > 100}"></div>
              </div>
            </a>
          </div>
        </div>

        <!-- TODO временно возвращенная таблиа для показа Единой центровки -->
        <!-- <p class="font-weight-bold text-blue h5 mb-2"
          i18n="wb|Main weight balance tab load@@wb.main.tab.load.title.holdState">
          Hold state
        </p>
        <table class="table-border w-100 mb-4">
          <thead>
            <tr class="bg-blue color-white font-weight-bold">
              <th i18n="wb|Main weight balance tab load@@wb.main.tab.load.holdSate.fwd" width="48%" class="text-center border-right">FWD</th>
              <th class="border-right"></th>
              <th i18n="wb|Main weight balance tab load@@wb.main.tab.load.holdSate.aft" width="48%" class="text-center">AFT</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div class="row pr-2">
                  <div *ngFor="let compartment of ahmData.holdsAndCompartments.fwd" class="col-sm pl-2 pr-0">
                    <div class="card bg-light-gray p-2 font-small"
                          [ngClass]="{'border-danger': findOverloadSection(compartment).names}">
                      <div class="d-flex justify-content-between border-bottom">
                        <div class="font-weight-bold">
                          <span i18n="wb|Main weight balance tab passengers@@wb.main.tab.load.groupLoad.hold">HOLD</span>
                          &nbsp;{{compartment.name}}</div>
                        <div class="text-right">
                          <div *ngIf="findOverloadSection(compartment).names"
                               class="text-red font-weight-bold d-flex align-items-center justify-content-center">
                            <span class="material-icons font-small">north_east</span> {{ findOverloadSection(compartment).weight }}
                          </div>
                        </div>
                      </div>
                      <div class="d-flex justify-content-between border-bottom text-dark-gray py-2">
                        <div class="text-center">
                          <div class="d-flex align-items-center justify-content-center">
                            <span>B&nbsp;</span>
                            <span class="material-icons font-small">work_outline</span>
                          </div>
                          <div>{{ getLoadCompartment(compartment.name, 'B') }}</div>
                        </div>
                        <div class="text-center">
                          <div class="d-flex align-items-center justify-content-center">
                            <span>C&nbsp;</span>
                            <span class="material-icons font-small">picture_in_picture_alt</span>
                          </div>
                          <div>{{ getLoadCompartment(compartment.name, 'C') }}</div>
                        </div>
                        <div class="text-center">
                          <div class="d-flex align-items-center justify-content-center">
                            <span>M&nbsp;</span>
                            <span class="material-icons font-small">mail_outline</span>
                          </div>
                          <div>{{ getLoadCompartment(compartment.name, 'M') }}</div>
                        </div>
                      </div>
                      <div *ngIf="findOverloadSection(compartment).names; else: notOverload">
                        BAY: {{ findOverloadSection(compartment).names }}
                      </div>
                      <ng-template #notOverload>
                        <div class="d-flex justify-content-between">
                          <div>T&nbsp;<span class="font-weight-bold">{{ getLoadCompartment(compartment.name) }}</span></div>
                          <div>U&nbsp;<span class="font-weight-bold">{{ compartment.maxWeight - getLoadCompartment(compartment.name) }}</span></div>
                        </div>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </td>
              <td></td>
              <td>
                <div class="row pr-2">
                  <div *ngFor="let compartment of ahmData.holdsAndCompartments.aft" class="col-sm pl-2 pr-0">
                    <div class="card bg-light-gray p-2 font-small"
                          [ngClass]="{'border-danger': findOverloadSection(compartment).names}">
                      <div class="d-flex justify-content-between border-bottom">
                        <div class="font-weight-bold">
                          <span i18n="wb|Main weight balance tab passengers@@wb.main.tab.load.groupLoad.hold">HOLD</span>&nbsp;
                          {{compartment.name}}
                        </div>
                        <div class="text-right">
                          <div *ngIf="findOverloadSection(compartment).names"
                               class="text-red font-weight-bold d-flex align-items-center justify-content-center">
                            <span class="material-icons font-small">north_east</span> {{ findOverloadSection(compartment).weight }}
                          </div>
                        </div>
                      </div>
                      <div class="d-flex justify-content-between border-bottom text-dark-gray py-2">
                        <div class="text-center">
                          <div class="d-flex align-items-center justify-content-center">
                            <span>B&nbsp;</span>
                            <span class="material-icons font-small">work_outline</span>
                          </div>
                          <div>{{ getLoadCompartment(compartment.name, 'B') }}</div>
                        </div>
                        <div class="text-center">
                          <div class="d-flex align-items-center justify-content-center">
                            <span>C&nbsp;</span>
                            <span class="material-icons font-small">picture_in_picture_alt</span>
                          </div>
                          <div>{{ getLoadCompartment(compartment.name, 'C') }}</div>
                        </div>
                        <div class="text-center">
                          <div class="d-flex align-items-center justify-content-center">
                            <span>M&nbsp;</span>
                            <span class="material-icons font-small">mail_outline</span>
                          </div>
                          <div>{{ getLoadCompartment(compartment.name, 'M') }}</div>
                        </div>
                      </div>
                      <div *ngIf="findOverloadSection(compartment).names; else: notOverload">
                        BAY: {{ findOverloadSection(compartment).names }}
                      </div>
                      <ng-template #notOverload>
                        <div class="d-flex justify-content-between">
                          <div>T&nbsp;<span class="font-weight-bold">{{ getLoadCompartment(compartment.name) }}</span></div>
                          <div>U&nbsp;<span class="font-weight-bold">{{ compartment.maxWeight - getLoadCompartment(compartment.name) }}</span></div>
                        </div>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table> -->

        <div class="d-flex justify-content-between mb-2" #destribution>
          <p class="font-weight-bold text-blue h5 mb-0"
            i18n="wb|Main weight balance tab load@@wb.main.tab.load.title.destribution">
            Destribution
          </p>
          <div>
            <button type="button"
                    class="btn btn-xs mr-3 btn-ico btn-light-gray"
                    ngbTooltip="Select all"
                    (click)="selectAllCargo()"
                    i18n-ngbTooltip="wb|wb|Main weight balance tab loa@@wb.main.tab.load.groupLoad.button.selectAll">
              <i class="material-icons">checklist</i>
            </button>
            <button type="button"
                    class="btn btn-xs mr-3 btn-ico btn-light-gray"
                    ngbTooltip="Delete selected"
                    (click)="deleteSelectCargo()"
                    [disabled]="!selectedBays"
                    i18n-ngbTooltip="wb|wb|Main weight balance tab loa@@wb.main.tab.load.groupLoad.button.deleteSelected">
              <i class="material-icons">delete</i>
            </button>
            <button type="button"
                    class="btn btn-xs mr-3 btn-ico btn-light-gray"
                    ngbTooltip="Upload selected"
                    (click)="uploadSelectCargo()"
                    [disabled]="!selectedBays"
                    i18n-ngbTooltip="wb|wb|Main weight balance tab loa@wb.main.tab.load.groupLoad.button.uploadSelected">
              <i class="material-icons">upload</i>
            </button>
            <button class="btn btn-xs btn-blue"
                    (click)="addLoadHoldsAndCompartments()"
                    i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.button.addLoad">Add load
            </button>
          </div>
        </div>
        <table class="w-100 mb-3 table-border contenteditable">
          <thead>
            <tr class="bg-blue color-white font-weight-bold text-center">
              <td width="35px"></td>
              <td i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.destination">Destination</td>
              <td i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.type">Type</td>
              <td i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.ref">Ref</td>
              <td i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.uldType">ULD type</td>
              <td i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.uldOwner">ULD owner</td>
              <td i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.uldWeight">ULD weight</td>
              <td i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.uldNumber">ULD number</td>
              <td i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.location">Location</td>
              <td i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.weight">Weight</td>
              <td i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.volume">Volume</td>
              <td i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.priority">Priority</td>
              <td i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.slc">SLC</td>
              <td i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.t">T</td>
              <td i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.p">P</td>
              <td i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.r">R</td>
              <td i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.remarks">Remarks</td>
              <td i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.total">Total</td>
              <td i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.net">Net</td>
              <td i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.tare">Tare</td>
              <td i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.max">Max</td>
              <td width="35px"></td>
            </tr>
          </thead>
          <tbody cdkDropList cdkDropListSortingDisabled>
            <tr>
              <td colspan="22" class="font-weight-bold bg-light-yellow" *ngIf="unlocatedCargo.length">Unlocated</td>
            </tr>
            <ng-container *ngFor="let cargo of calculation.holdsAndCompartments; let i = index">
              <tr *ngIf="!cargo.name">
                <td cdkDrag (cdkDragStarted)="dragPlaceCargo([cargo])" (cdkDragEnded)="dragEnd()">
                  <span class="material-icons pointer">drag_handle</span>
                  <div *cdkDragPreview
                       style="width: 140px; height: 180px"
                       class="rounded border border-dark bg-light">
                    <div class="d-flex flex-column w-100 h-100 justify-content-center">
                      <div class="text-center font-weight-bold">{{ cargo.destination}}</div>
                      <div class="text-center font-weight-bold">{{ cargo.uldType}}</div>
                      <div class="text-center font-weight-bold">{{ cargo.weight }}</div>
                    </div>
                  </div>
                </td>
                <td>
                  <ng-select [items]="destination"
                            class="custom-sm bg-white text-uppercase"
                            bindLabel="displayName"
                            bindValue="displayName"
                            [(ngModel)]="cargo.destination"
                            [selectOnTab]="true"
                            (change)="autoSave()"
                            (close)="unlocatedTypeOfCargo.focus()">
                  </ng-select>
                </td>
                <td>
                  <ng-select #unlocatedTypeOfCargo [items]="typeOfCargo"
                            class="custom-sm bg-white text-uppercase airports-long"
                            [selectOnTab]="true"
                            [(ngModel)]="cargo.type"
                            (change)="autoSave()"
                            (close)="unlocatedUldTypes.focus()">
                  </ng-select>
                </td>
                <td></td>
                <td>
                  <ng-select #unlocatedUldTypes
                            [items]="uldTypes"
                            class="custom-sm bg-white airports-long"
                            bindLabel="name"
                            bindValue="name"
                            [selectOnTab]="true"
                            [(ngModel)]="cargo.uldType"
                            appendTo="body"
                            (change)="setUldWeight($event, cargo); autoSave()"
                            (close)="unlocatedUldWeight.focus()">
                    <ng-template ng-option-tmp let-item="item">
                      <span i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.uld.name">Name:</span>&nbsp;{{item.name}}&nbsp;
                      <span i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.uld.weight">Weight:</span>&nbsp;{{item.weight}}
                    </ng-template>
                  </ng-select>
                </td>
                <td class="text-center">{{ cargo.owner }}</td>
                <td><input #unlocatedUldWeight type="number" [(ngModel)]="cargo.uldWeight" (keyup)="autoSave()"></td>
                <td><input type="text" [(ngModel)]="cargo.uldNum" (keyup)="autoSave()"></td>
                <td>
                  <ng-select [items]="locations[cargo.uldType]"
                            class="custom-sm bg-white airports-long"
                            bindLabel="name"
                            bindValue="name"
                            name="location_unlocated_{{i}}"
                            id="location_unlocated_{{i}}"
                            required
                            [selectOnTab]="true"
                            [ngModel]="cargo.name"
                            (change)="changeBay($event, cargo); autoSave()"
                            [disabled]="!cargo.uldType"
                            (close)="unlocatedWeight.focus()">
                  </ng-select>
                </td>
                <td>
                  <!-- [(ngModel)]="cargo.weight" -->
                  <input #unlocatedWeight type="number"
                        [value]="cargo.weight"
                        (keyup)="checkCargoWeight(cargo, $event.target); autoSave()"></td>
                <td><input #unlocatedWeight type="number" [(ngModel)]="cargo.volume" (keyup)="autoSave()"></td>
                <td><input type="number"></td>
                <td><input type="number"></td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input position-static" type="checkbox" id="blankCheckbox" value="option1" aria-label="...">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input position-static" type="checkbox" id="blankCheckbox" value="option1" aria-label="...">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input position-static" type="checkbox" id="blankCheckbox" value="option1" aria-label="...">
                  </div>
                </td>
                <td><input type="number"></td>
                <td class="text-center">{{ uldTotal(cargo.uldType, cargo.weight, cargo.uldWeight) }}</td>
                <td class="text-center">{{ uldNet(cargo.weight) }}</td>
                <td class="text-center">{{ uldTare(cargo.uldType, cargo.uldWeight) }}</td>
                <td class="text-center">{{ uldMax(cargo.uldType) }}</td>
                <td>
                  <i class="material-icons m-0 text-red font-weight-bold pointer"
                    (click)="deleteElement(calculation.holdsAndCompartments, i)">clear</i>
                </td>
              </tr>
            </ng-container>
            <tr>
              <td colspan="22" class="font-weight-bold bg-light-yellow" *ngIf="locatedCargo.length">Located</td>
            </tr>
            <ng-container *ngFor="let cargo of calculation.holdsAndCompartments; let i = index">
              <tr *ngIf="cargo.name" [ngClass]="{'bg-gray-blue-dark text-white': selectedBays?.includes(cargo)}">
                <td cdkDrag (cdkDragStarted)="dragPlaceCargo([cargo])" (cdkDragEnded)="dragEnd()">
                  <span class="material-icons pointer">drag_handle</span>
                  <div *cdkDragPreview
                       style="width: 140px; height: 180px"
                       class="rounded border border-dark bg-light">
                    <div class="d-flex flex-column w-100 h-100 justify-content-center">
                      <div class="text-center font-weight-bold">{{ cargo.destination}}</div>
                      <div class="text-center font-weight-bold">{{ cargo.uldType}}</div>
                      <div class="text-center font-weight-bold">{{cargo.weight}}</div>
                    </div>
                  </div>
                </td>
                <td>
                  <ng-select [items]="destination"
                            class="custom-sm bg-white text-uppercase"
                            bindLabel="displayName"
                            bindValue="displayName"
                            [selectOnTab]="true"
                            [(ngModel)]="cargo.destination"
                            (close)="locationTypeOfCargo.focus()">
                  </ng-select>
                </td>
                <td>
                  <ng-select #locationTypeOfCargo
                             [items]="typeOfCargo"
                             class="custom-sm bg-white text-uppercase airports-long"
                             [selectOnTab]="true"
                             [(ngModel)]="cargo.type"
                             (close)="locatedUldTypes.focus()">
                  </ng-select>
                </td>
                <td></td>
                <td>
                  <ng-select #locatedUldTypes
                             [items]="uldTypes"
                             class="custom-sm bg-white airports-long"
                             bindLabel="name"
                             bindValue="name"
                             [selectOnTab]="true"
                             [(ngModel)]="cargo.uldType"
                             appendTo="body"
                             (change)="setUldWeight($event, cargo)"
                             (close)="locatedUldWeight.focus()">
                    <ng-template ng-option-tmp let-item="item">
                      <span i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.uld.name">Name:</span>&nbsp;{{item.name}}&nbsp;
                      <span i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.uld.weight">Weight:</span>&nbsp;{{item.weight}}
                    </ng-template>
                  </ng-select>
                </td>
                <td class="text-center">{{ cargo.owner }}</td>
                <td><input #locatedUldWeight type="number" [(ngModel)]="cargo.uldWeight"></td>
                <td><input type="text" [(ngModel)]="cargo.uldNum"></td>
                <td>
                  <ng-select [items]="locations[cargo.uldType]"
                            class="custom-sm bg-white airports-long"
                            bindLabel="name"
                            bindValue="name"
                            name="location_{{i}}"
                            id="location_{{i}}"
                            required
                            [disabled]="!cargo.uldType"
                            [selectOnTab]="true"
                            [ngModel]="cargo.name"
                            (change)="changeBay($event, cargo); autoSave()"
                            (close)="locatedWeight.focus()">
                  </ng-select>
                </td>
                <td>
                  <input #locatedWeight type="number"
                        [value]="cargo.weight"
                        (keyup)="checkCargoWeight(cargo, $event.target); autoSave()">
                </td>
                <td><input #unlocatedWeight type="number" [(ngModel)]="cargo.volume" (keyup)="autoSave()"></td>
                <td><input type="number"></td>
                <td><input type="number"></td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input position-static" type="checkbox" id="blankCheckbox" value="option1" aria-label="...">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input position-static" type="checkbox" id="blankCheckbox" value="option1" aria-label="...">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input position-static" type="checkbox" id="blankCheckbox" value="option1" aria-label="...">
                  </div>
                </td>
                <td><input type="number"></td>
                <td class="text-center"> {{ uldTotal(cargo.uldType, cargo.weight, cargo.uldWeight) }} </td>
                <td class="text-center"> {{ uldNet(cargo.weight) }} </td>
                <td class="text-center"> {{ uldTare(cargo.uldType, cargo.uldWeight) }} </td>
                <td class="text-center"> {{ uldMax(cargo.uldType) }}</td>
                <td>
                  <i class="material-icons m-0 text-red font-weight-bold pointer"
                    (click)="deleteElement(calculation.holdsAndCompartments, i)">clear</i>
                </td>
              </tr>
            </ng-container>
            <tr class="bg-light-yellow">
              <td colspan="17" class="font-weight-bold"
                  i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.total">
                Total
              </td>
              <td colspan="5">{{allLoadWeight()}}</td>
            </tr>
          </tbody>
        </table>

        <p class="font-weight-bold text-blue h5 mb-3"
              i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupCompartments.name">Compartments</p>
        <div class="row">
          <div class="col-6">
            <p class="font-weight-bold mb-3"
                  i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupCompartments.table.fwd">FWD</p>
            <table class="w-100 table-striped table-border">
              <thead>
                <tr class="bg-blue color-white font-weight-bold">
                  <th i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupCompartments.table.fwd.compartments">Compartments</th>
                  <th i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupCompartments.table.fwd.baySection">Bay / section</th>
                  <th i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupCompartments.table.fwd.maxWeight">MAX weight</th>
                  <th i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupCompartments.table.fwd.load">Load</th>
                  <th i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupCompartments.table.aft.overload">Overload</th>
                </tr>
              </thead>
              <tbody *ngFor="let compartment of ahmData.holdsAndCompartments.fwd">
                <tr *ngIf="compartment.maxWeight !== 0 && compartment.index !==0">
                  <td class="font-weight-bold">{{ compartment.name }}</td>
                  <td>{{ compartment.name }}</td>
                  <td>{{ compartment.maxWeight }}</td>
                  <td class="font-weight-bold"
                      [ngClass]="{'text-red': getLoadWeight(compartment.name) > compartment.maxWeight}">
                      {{ getLoadCompartment(compartment.name) }}
                  </td>
                  <td class="font-weight-bold text-red">
                    {{ getLoadCompartment(compartment.name) > compartment.maxWeight ? getLoadCompartment(compartment.name) - compartment.maxWeight : '' }}
                  </td>
                </tr>
                <tr *ngFor="let bay of compartment.bays">
                  <td class="font-weight-bold">{{ compartment.name }}</td>
                  <td>{{ bay.name }}</td>
                  <td>{{ bay.maxWeight }}</td>
                  <td class="font-weight-bold">{{ getLoadWeight(bay.name) }}</td>
                  <td class="font-weight-bold text-red">{{ getLoadWeight(bay.name) > bay.maxWeight ? getLoadWeight(bay.name) - bay.maxWeight : '' }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-6">
            <p class="font-weight-bold mb-3"
              i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupCompartments.table.aft">AFT</p>
            <table class="w-100 table-striped table-border">
              <thead>
                <tr class="bg-blue color-white font-weight-bold">
                  <th i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupCompartments.table.aft.compartments">Compartments</th>
                  <th i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupCompartments.table.fwd.baySection">Bay / section</th>
                  <th i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupCompartments.table.aft.maxWeight">MAX weight</th>
                  <th i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupCompartments.table.aft.load">Load</th>
                  <th i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupCompartments.table.aft.overload">Overload</th>
                </tr>
              </thead>
              <tbody *ngFor="let compartment of ahmData.holdsAndCompartments.aft">
                <tr *ngIf="compartment.maxWeight !== 0 && compartment.index !==0">
                  <td class="font-weight-bold">{{ compartment.name }}</td>
                  <td>{{ compartment.name }}</td>
                  <td>{{ compartment.maxWeight }}</td>
                  <td class="font-weight-bold"
                      [ngClass]="{'text-red': getLoadWeight(compartment.name) > compartment.maxWeight}">
                    {{ getLoadCompartment(compartment.name) }}
                  </td>
                  <td class="font-weight-bold text-red">
                    {{ getLoadCompartment(compartment.name) > compartment.maxWeight ? getLoadCompartment(compartment.name) - compartment.maxWeight : '' }}
                  </td>
                </tr>
                <tr *ngFor="let bay of compartment.bays">
                  <td class="font-weight-bold">{{ compartment.name }}</td>
                  <td>{{ bay.name }}</td>
                  <td>{{ bay.maxWeight }}</td>
                  <td class="font-weight-bold">{{ getLoadWeight(bay.name) }}</td>
                  <td class="font-weight-bold text-red">{{ getLoadWeight(bay.name) > bay.maxWeight ? getLoadWeight(bay.name) - bay.maxWeight : '' }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="alert alert-danger fixed-bottom  m-2 alert-dismissible" role="alert" *ngIf="error.errorBay">
          {{ error.errorBay }}
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <div *ngIf="showTab('flight-tab-specialLoad')" class="bg-white p-3">
        <div class="input-group-prepend mb-3">
          <div class="custom-control custom-switch d-flex ml-2" style="min-width: fit-content;">
            <input type="checkbox"
                  class="custom-control-input"
                  id="releasedByAnother"
                  name="releasedByAnother"
                  [checked]="calculation.notoc.releasedByAnother"
                  (click)="changeNotocNil()">
            <label class="custom-control-label" for="releasedByAnother"
                i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.releasedByAnother">
                No Item Loaded (the telegram will mark NOTOC - NIL)
            </label>
          </div>
        </div>
        <div class="card mb-4" [ngClass]="{'disabled': calculation.notoc.releasedByAnother}">
          <div class="card-body">
            <p class="font-weight-bold text-blue h5 mb-3"
              i18n="wb|Main weight balance tab fuel@@wb.main.tab.specialLoad.dangerousGoods">Dangerous goods</p>
              <table class="w-100 mb-3 table-border">
                <thead>
                  <tr class="bg-blue color-white font-weight-bold text-center">
                    <td width="65px" i18n="wb|Main weight balance tab specialLoad@@wb.main.tab.specialLoad.dangerousGoods.to">TO</td>
                    <td i18n="wb|Main weight balance tab specialLoad@@wb.main.tab.specialLoad.dangerousGoods.awb">AWB</td>
                    <td i18n="wb|Main weight balance tab specialLoad@@wb.main.tab.specialLoad.dangerousGoods.clDv">CL/DV</td>
                    <td i18n="wb|Main weight balance tab specialLoad@@wb.main.tab.specialLoad.dangerousGoods.comp">COMP</td>
                    <td i18n="wb|Main weight balance tab specialLoad@@wb.main.tab.specialLoad.dangerousGoods.unId">UN/ID</td>
                    <td i18n="wb|Main weight balance tab specialLoad@@wb.main.tab.specialLoad.dangerousGoods.unIdNumber">UN/ID NUMBER</td>
                    <td i18n="wb|Main weight balance tab specialLoad@@wb.main.tab.specialLoad.dangerousGoods.subRisk">SUB RISK</td>
                    <td i18n="wb|Main weight balance tab specialLoad@@wb.main.tab.specialLoad.dangerousGoods.pcs">PCS</td>
                    <td i18n="wb|Main weight balance tab specialLoad@@wb.main.tab.specialLoad.dangerousGoods.qtyTi">QTY/TI</td>
                    <!--<td i18n="wb|Main weight balance tab specialLoad@@wb.main.tab.specialLoad.dangerousGoods.units">Units</td>-->
                    <td i18n="wb|Main weight balance tab specialLoad@@wb.main.tab.specialLoad.dangerousGoods.prrCat">RRR CAT</td>
                    <td i18n="wb|Main weight balance tab specialLoad@@wb.main.tab.specialLoad.dangerousGoods.pck">PCK</td>
                    <td i18n="wb|Main weight balance tab specialLoad@@wb.main.tab.specialLoad.dangerousGoods.impCode">IMP CODE</td>
                    <!--<td i18n="wb|Main weight balance tab specialLoad@@wb.main.tab.specialLoad.dangerousGoods.cao">CAO</td>-->
                    <td i18n="wb|Main weight balance tab specialLoad@@wb.main.tab.specialLoad.dangerousGoods.pos">POS</td>
                    <td i18n="wb|Main weight balance tab specialLoad@@wb.main.tab.specialLoad.dangerousGoods.uldCode">ULD CODE</td>
                  </tr>
                </thead>
                <tbody *ngFor="let dangerous of calculation.notoc.dangerous; let i = index">
                  <tr>
                    <td>
                      <ng-select [items]="destination"
                                class="custom-sm bg-white airports-long text-uppercase"
                                bindLabel="displayName"
                                bindValue="displayName"
                                [(ngModel)]="dangerous.to">
                      </ng-select>
                    </td>
                    <td><input type="text" name="awbNr_{{i}}" [(ngModel)]="dangerous.awbNr" maxlength="8"></td>
                    <td>
                      <ng-select [items]="getClDvByIata(dangerous.impDrill)"
                                class="custom-sm bg-white airports-long text-uppercase"
                                bindLabel="iata"
                                bindValue="iata"
                                [(ngModel)]="dangerous.clDv">
                      </ng-select>
                    </td>
                    <td><input type="text" [(ngModel)]="dangerous.comp" maxlength="1"></td>
                    <td><input type="text" [(ngModel)]="dangerous.unId" maxlength="2"></td>
                    <td><input type="text" [(ngModel)]="dangerous.unIdNumber" maxlength="4"></td>
                    <td><input type="text" [(ngModel)]="dangerous.subRsk" maxlength="7"></td>
                    <td><input type="number" [(ngModel)]="dangerous.pieces" oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" maxlength="4"></td>
                    <td><input type="text" [(ngModel)]="dangerous.qtyTi" maxlength="6"></td>
                    <td><input type="text" [(ngModel)]="dangerous.rrrCat" maxlength="3"></td>
                    <td><input type="text" [(ngModel)]="dangerous.pckGrp" maxlength="3"></td>
                    <td>
                      <ng-select [items]="impCodes.dangerous"
                                class="custom-sm bg-white airports-long text-uppercase"
                                bindLabel="iata"
                                bindValue="iata"
                                (change)="changeImpCode(i)"
                                [(ngModel)]="dangerous.impDrill">
                        <ng-template ng-option-tmp let-item="item">
                          <strong>{{item.iata}} </strong><small>({{globalSettings.language=='en' || item.name[1]=='' ? item.name[0] : item.name[1]}})</small>
                        </ng-template>
                      </ng-select>
                    <td><input type="text" [(ngModel)]="dangerous.pos" maxlength="3"></td>
                    <td><input type="text" [(ngModel)]="dangerous.uldCode" maxlength="11"></td>
                  </tr>
                  <tr>
                    <td>
                      <label>
                        <input type="checkbox" id="cao_{{i}}" name="cao_{{i}}" [(ngModel)]="dangerous.cao">&nbsp;CAO
                      </label>
                    </td>
                    <td colspan="11">
                      <div class="input-group flex-nowrap">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0"
                            i18n="wb|Main weight balance tab specialLoad@@wb.main.tab.specialLoad.dangerousGoods.properShippingName">
                            Proper shipping name
                          </span>
                        </div>
                        <input type="text" class="form-control custom-sm" maxlength="60" [(ngModel)]="dangerous.properShippingName"/>
                      </div>
                    </td>
                    <td class="text-right">
                      <button type="button" class="btn btn-sm btn-red"
                              (click)="deleteElement(calculation.notoc.dangerous, i)">
                        <span i18n="wb|Main weight balance@@wb.main.button.delete">
                          Delete
                        </span>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <button class="btn btn-xs float-right btn-blue"
                      (click)="addItemByClass(calculation.notoc.dangerous,
                                              NotocDangerous,
                                              destination && destination.length === 1 ? {to: this.destination[0].airportIata} : null)"
                      i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.button.addLoad">Add load
              </button>
          </div>
        </div>
        <div class="card" [ngClass]="{'disabled': calculation.notoc.releasedByAnother}">
          <div class="card-body">
            <p class="font-weight-bold text-blue h5 mb-3"
              i18n="wb|Main weight balance tab fuel@@wb.main.tab.specialLoad.otherSpecialLoad">Other special load</p>
              <table class="w-100 mb-3 table-border">
                <thead>
                  <tr class="bg-blue color-white font-weight-bold">
                    <td width="10%" i18n="wb|Main weight balance tab specialLoad@@wb.main.tab.specialLoad.dangerousGoods.to">TO</td>
                    <td i18n="wb|Main weight balance tab specialLoad@@wb.main.tab.specialLoad.dangerousGoods.awb">AWB</td>
                    <td i18n="wb|Main weight balance tab specialLoad@@wb.main.tab.specialLoad.dangerousGoods.psc">PCS</td>
                    <td i18n="wb|Main weight balance tab specialLoad@@wb.main.tab.specialLoad.dangerousGoods.qty">QTY</td>
                    <td width="10%" i18n="wb|Main weight balance tab specialLoad@@wb.main.tab.specialLoad.dangerousGoods.impCode">IMP CODE</td>
                    <td i18n="wb|Main weight balance tab specialLoad@@wb.main.tab.specialLoad.dangerousGoods.pos">POS</td>
                    <td i18n="wb|Main weight balance tab specialLoad@@wb.main.tab.specialLoad.dangerousGoods.uldCode">ULD CODE</td>
                  </tr>
                </thead>
                <tbody *ngFor="let other of calculation.notoc.other; let i = index">
                  <tr>
                    <td>
                      <ng-select [items]="destination"
                                class="custom-sm bg-white airports-long text-uppercase"
                                bindLabel="displayName"
                                bindValue="displayName"
                                [(ngModel)]="other.to">
                      </ng-select>
                    </td>
                    <td><input type="text" [(ngModel)]="other.awbNr" maxlength="8"></td>
                    <td><input type="number" [(ngModel)]="other.pieces" oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" maxlength="4"></td>
                    <td><input type="text" [(ngModel)]="other.qty" maxlength="6"></td>
                    <td>
                      <ng-select [items]="impCodes.other"
                                class="custom-sm bg-white airports-long text-uppercase"
                                bindLabel="iata"
                                bindValue="iata"
                                [(ngModel)]="other.impCode">
                        <ng-template ng-option-tmp let-item="item">
                          <strong>{{item.iata}} </strong><small>({{globalSettings.language=='en' || item.name[1]=='' ? item.name[0] : item.name[1]}})</small>
                        </ng-template>
                      </ng-select>
                    </td>
                    <td><input type="text" [(ngModel)]="other.pos" maxlength="3"></td>
                    <td><input type="text" [(ngModel)]="other.uldCode" maxlength="11"></td>
                  </tr>
                  <tr>
                    <td colspan="6">
                      <div class="input-group flex-nowrap">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0"
                            i18n="wb|Main weight balance tab specialLoad@@wb.main.tab.specialLoad.dangerousGoods.contentsAdnDescription">
                            Contents adn description
                          </span>
                        </div>
                        <input type="text" class="form-control custom-sm" maxlength="21" [(ngModel)]="other.contents"/>
                      </div>
                    </td>
                    <td class="text-right">
                      <button type="button" class="btn btn-sm btn-red"
                              (click)="deleteElement(calculation.notoc.other, i)">
                        <span i18n="wb|Main weight balance@@wb.main.button.delete">
                          Delete
                        </span>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <button class="btn btn-xs float-right btn-blue"
                      (click)="addItemByClass(calculation.notoc.other,
                                              NotocOther,
                                              destination && destination.length === 1 ? {to: this.destination[0].airportIata} : null)"
                      i18n="wb|Main weight balance tab load@@wb.main.tab.load.groupLoad.button.addLoad">Add load
              </button>
          </div>
        </div>
      </div>
      <div *ngIf="showTab('flight-tab-documents')" class="bg-white p-3">
          <div class="d-flex flex-column">
            <div>
              <div class="row">
                <div class="col-4">
                  <div class="alert alert-danger" role="alert"
                        *ngIf="printDisabled; else printBlock"
                        i18n="wb|Main weight balance tab documents@@wb.main.tab.documents.text.error">
                    Aircraft alignment is not correct: the function of printing and saving documents is not available
                  </div>
                  <ng-template #printBlock>
                    <div class="form-group">
                      <label i18n="wb|Main weight balance tab documents@@wb.main.tab.documents.editionNumber">Edition number</label>
                      <button class="btn btn-xs btn-ico btn-blue mb-2 w-100"
                              i18n="wb|Main weight balance tab documents@@wb.main.tab.documents.button.createNewEdition"
                              (click)="createDocuments()"
                              [disabled]="loadingType == 'createDocuments'">
                        Create a new edition of documents
                      </button>
                      <ng-select [items]="documentEditions"
                                bindLabel="edno"
                                bindValue="edno"
                                class="custom-sm w-100 mb-3"
                                (change)="documentText = null; currentDocumentView = null"
                                [(ngModel)]="currentDocumentEditions"
                                [searchable]="false">
                      </ng-select>
                    </div>
                    <label i18n="wb|Main weight balance tab documents@@wb.main.tab.documents.released">Released</label>
                    <div class="input-group mb-3">
                      <input type="text"
                            class="form-control"
                            value="{{getDocumentValue(currentDocumentEditions, 'preparedBy')}}"
                            disabled>
                      <input type="text"
                            class="form-control"
                            value="{{getDocumentValue(currentDocumentEditions, 'preparedAt') | date: 'dd.MM.yyyy HH:mm' : 'UTC+0' }}"
                            disabled>
                    </div>
                    <hr>
                      <nav>
                        <div class="nav nav-tabs" role="tablist">
                          <a class="nav-item nav-link active"
                              id="nav-document-type-tab"
                              data-toggle="tab" href="#nav-document-type"
                              role="tab" aria-selected="true"
                              *ngIf="currentDocumentEditions > 0"
                              i18n="wb|Main weight balance tab documents@@wb.main.tab.documents.documentType">
                            Document type
                          </a>
                          <a class="nav-item nav-link"
                              id="nav-telegrams-tab"
                              data-toggle="tab" href="#nav-telegrams"
                              role="tab" aria-selected="false"
                              [ngClass]="{'active': !currentDocumentEditions}"
                              i18n="wb|Main weight balance tab documents@@wb.main.tab.documents.telegrams">
                            Telegrams
                          </a>
                        </div>
                      </nav>
                      <div class="tab-content pt-2">
                        <div class="tab-pane show active" id="nav-document-type" role="tabpanel" *ngIf="currentDocumentEditions > 0">
                          <div class="list-group">
                            <a *ngFor="let document of documentTypes; let i = index"
                              class="list-group-item list-group-item-action"
                              (click)="loadDocument(document.url); currentDocumentView = document.id"
                              [ngClass]="{'active': currentDocumentView === document.id}">
                              <div class="d-flex align-items-center">
                                <div class="flex-fill">{{document.type}}</div>
                                <!-- TODO Кнопки [disabled] привязать к выходу за границу центровки -->
                                <button class="btn btn-xs btn-ico btn-light-gray mr-1"
                                        (click)="saveDocument(document.url)"><span class="material-icons">
                                    file_download
                                  </span></button>
                                <!-- TODO Кнопки [disabled] привязать к выходу за границу центровки -->
                                <button class="btn btn-xs btn-ico btn-light-gray"
                                        (click)="printDocument(document.url)"><span class="material-icons">
                                    print
                                  </span></button>
                              </div>
                            </a>
                          </div>
                        </div>
                        <div class="tab-pane fade" id="nav-telegrams" role="tabpanel" [ngClass]="{'active show': !currentDocumentEditions}">
                          <div class="list-group">
                            <a *ngFor="let telegram of telegramTypes"
                              class="list-group-item list-group-item-action"
                              (click)="loadTelegram(telegram); currentTelegramView = telegram"
                              [ngClass]="{'active': currentTelegramView === telegram}">
                              <div class="d-flex align-items-center">
                                <div class="flex-fill text-uppercase">{{telegram}}</div>
                                <!-- TODO Кнопки [disabled] привязать к выходу за границу центровки -->
                                <!-- <button class="btn btn-xs btn-ico btn-light-gray mr-1"
                                        (click)="saveTelegram(telegram)">
                                  <span class="material-icons">file_download</span>
                                </button> -->
                                <!-- TODO Кнопки [disabled] привязать к выходу за границу центровки -->
                                <button class="btn btn-xs btn-ico btn-light-gray"
                                        (click)="printTelegram(telegram)">
                                  <span class="material-icons">print</span>
                                </button>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                      <!-- <p i18n="wb|Main weight balance tab documents@@wb.main.tab.documents.documentType">Document type</p> -->
                      <button #print
                              printSectionId="print-section"
                              ngxPrint
                              hidden="true"
                              [useExistingCss]="true"></button>
                  </ng-template>
                  <p class="my-3" i18n="wb|Main weight balance tab documents@@wb.main.tab.documents.siForLdm">Supplementary information (SI) for LDM</p>
                  <div class="validation-textarea">
                    <textarea name="si" id="si"
                        class="form-control h-100 text-uppercase p-2 pl-4 text-monospace"
                        rows="10"
                        style="min-height: 100px;"
                        [(ngModel)]="calculation.si"
                        (input)="calculation.si = splitIntoLines($event.target.value, $event.target)"></textarea>
                    <div class="check-error">
                      <div>
                        <div *ngFor="let line of verifiedMessage" class="check-error-line">
                          <div class="has-error text-center">
                            <i *ngIf="line[0]" ngbTooltip="{{line[2]}}"
                              class="material-icons text-red">error</i>
                          </div>
                          <div class="text-line text-uppercase">{{line[1]}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-8 p-0">
                  <div class="h-100">
                    <div class="d-flex align-items-center justify-content-between mb-2">
                      <p class="m-0" i18n="wb|Main weight balance tab documents@@wb.main.tab.documents.documentView">Document view</p>
                      <!-- <button class="btn btn-sm btn-blue"
                              i18n="wb|Main weight balance tab documents@@wb.main.tab.documents.button.print"
                              *ngIf="documentText !== ''"
                              [useExistingCss]="true"
                              printSectionId="print-section"
                              ngxPrint>Print</button> -->
                    </div>
                    <div *ngIf="(documentText == '' && currentDocumentView > 0); else documentTextBlock" class="h-100">
                      <div class="d-flex flex-column h-100 justify-content-center bg-light-gray rounded">
                        <h3 class="text-center" i18n="wb|Main weight balance tab documents@@wb.main.tab.documents.documentMissing">Document missing</h3>
                      </div>
                    </div>
                    <ng-template #documentTextBlock>
                      <textarea rows="38" class="w-100 p-2 border text-monospace" style="font-size: 12px;" readonly>{{ documentText }}</textarea>
                    </ng-template>
                    <div id="print-section" class="d-none">
                      <span class="message-text-wrapping text-monospace m-0">{{ documentText }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="d-flex">
                <ng-select [items]="documentTypes"
                          class="custom-sm w-100 mb-3"
                          bindLabel="type"
                          bindValue="url"
                          [(ngModel)]="documentUrl">
                </ng-select>
                <button class="btn btn-sm btn-blue ml-3 flex-shrink-1"
                        i18n="wb|Main weight balance tab documents@@wb.main.tab.documents.button.download"
                        (click)="saveDocument(documentUrl)">Download</button>
                <button class="btn btn-sm btn-blue ml-3 flex-shrink-1"
                        i18n="wb|Main weight balance tab documents@@wb.main.tab.documents.button.create"
                        (click)="loadDocument(documentUrl)">Create</button>
              </div> -->
            </div>
          </div>
      </div>
    </div>
  </div>
  <div class="modal fade bd-example-modal-xl" data-backdrop="static" tabindex="-1" role="dialog"
       aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xxl">
      <div class="modal-content bg-light-gray">
        <div class="modal-header">
          <h5 class="modal-title">
            <span i18n="wb|Main weight balance calculation@@wb.main.calculation.centureOfGravity">Centre of gravity</span>
          </h5>
          <button #airportFormFade type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body bg-white">
          <div class="text-center" id="gravityCentreBlockBig" #gravityCentreBlockBig></div>
        </div>
        <div class="modal-footer">
          <button type="button"
                  class="btn btn-secondary btn-sm"
                  data-dismiss="modal"
                  i18n="wb|Main weight balance - modal button close@@wb.main.calculation.button.close">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="weight-balance-content" *ngIf="viewParametrs.activeWindow == 'calculation-manual'">
  <div class="row">
    <div class="col-8">
      <ul class="nav nav-tabs" id="myTab1" role="tablist">
        <li class="nav-item">
          <a class="nav-link active" id="flight-info-tab" data-toggle="tab"
            i18n="wb|Main weight balance@@wb.main.tab.flight.name"
            href="#flight-info" role="tab">Aircraft and flight information</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="flight-documents-tab" data-toggle="tab"
            i18n="wb|Main weight balance@@wb.main.tab.documents.name"
            href="#flight-documents" role="tab">Documents</a>
        </li>
      </ul>
      <div class="tab-content pt-3" id="myTabContent1">
        <div class="tab-pane fade show active" id="flight-info" role="tabpanel" aria-labelledby="flight-info-tab">
          <div class="row">
            <div class="col-6">
              <div class="card mb-3">
                <div class="card-body">
                  <p class="font-weight-bold text-blue h5 mb-3"
                    i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.flightData">Flight data</p>
                  <p i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.groupAircraft.groupName">Aircraft</p>
                  <div class="row mb-2">
                    <div class="col-6">
                      <div class="input-group flex-nowrap mb-3">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0"
                                i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.groupAircraft.table.registration">
                            Registration
                          </span>
                        </div>
                        <input *ngIf="flight.tailId; else: textTail"
                              type="text"
                              class="w-100 form-control custom-sm"
                              [value]="getById(references.tails, +flight.tailId) ? getById(references.tails, +flight.tailId).tail : ''"
                              disabled />
                        <ng-template #textTail>
                          <input type="text"
                                class="w-100 form-control custom-sm"
                                [(ngModel)]="calculationManual.tail"/>
                        </ng-template>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="input-group flex-nowrap mb-3">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0"
                                i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.groupAircraft.table.type">
                            Type
                          </span>
                        </div>
                        <input *ngIf="flight.tailId; else: textType"
                              type="text"
                              class="w-100 form-control custom-sm"
                              [(ngModel)]="flight.aircraftTypeIata"
                              disabled/>
                        <ng-template #textType>
                          <input type="text"
                                class="w-100 form-control custom-sm"
                                [(ngModel)]="calculationManual.aircraftType"/>
                        </ng-template>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="input-group flex-nowrap mb-3">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0"
                                i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.groupAircraft.table.version">
                                Version
                          </span>
                        </div>
                        <input type="text"
                              class="w-100 form-control custom-sm"
                              [(ngModel)]="calculationManual.configuration" />
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="input-group flex-nowrap mb-3">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0"
                                i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.groupAircraft.table.loadLimit">
                            Load limit
                          </span>
                        </div>
                        <input type="number"
                              class="w-100 form-control custom-sm"
                              [(ngModel)]="calculationManual.ll" />
                      </div>
                    </div>
                  </div>
                  <p class="pt-3 border-top" i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.groupAircraft.flight">Flight</p>
                  <div class="row mb-2">
                    <div class="col-6">
                      <div class="input-group flex-nowrap mb-3">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0"
                              i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.groupFlight.captain">
                                Captain
                          </span>
                        </div>
                        <input type="text" class="w-100 form-control custom-sm" [(ngModel)]="calculationManual.captain" />
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="input-group flex-nowrap mb-3">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0"
                              i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.groupFlight.catering">
                                Catering
                          </span>
                        </div>
                        <input type="number" class="w-100 form-control custom-sm" [(ngModel)]="flight.cateringWeight"/>
                      </div>
                    </div>
                  </div>
                  <p class="pt-3 border-top" i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.groupFlight.seats">Seats</p>
                  <div class="row">
                    <div class="col-6">
                      <div class="input-group flex-nowrap mb-3">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0"
                              i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.groupFlight.seatsTotal">
                                Total
                          </span>
                        </div>
                        <input type="number" class="w-100 form-control custom-sm" [(ngModel)]="calculationManual.seats.current" />
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="input-group flex-nowrap mb-3">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0"
                              i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.groupFlight.seatsLimit">
                                Limit
                          </span>
                        </div>
                        <input type="number" class="w-100 form-control custom-sm" [(ngModel)]="calculationManual.seats.max" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend custom-sm">
                  <span class="input-group-text font-small py-0"
                        i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.preparedBy">Prepared by
                  </span>
                </div>
                <input type="text"
                      class="w-100 form-control custom-sm"
                      name="preparedBy"
                      id="preparedBy"
                      [(ngModel)]="calculationManual.preparedBy"
                      disabled />
              </div>
            </div>
            <div class="col-6">
              <div class="card mb-4">
                <div class="card-body">
                  <p class="font-weight-bold text-blue h5 mb-3"
                    i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.groupCrewComposition">
                    Crew composition
                  </p>
                  <div>
                    <div class="form-group">
                      <label i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.groupCrewComposition.standard">Standard crew</label>
                      <div class="input-group input-group-sm">
                        <div class="input-group-prepend">
                          <span class="input-group-text"
                                i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.groupCrewComposition.standard.crewAndStewards">Cockpit and Cabin</span>
                        </div>
                        <input type="number"
                              class="form-control"
                              [(ngModel)]="calculationManual.crew.cockpit.amount">
                        <input type="number"
                              class="form-control"
                              [(ngModel)]="calculationManual.crew.cabin.amount">
                      </div>
                    </div>

                    <div class="form-group">
                      <label i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.groupCrewComposition.additional">Additional crew</label>
                      <div class="input-group input-group-sm">
                        <div class="input-group-prepend">
                          <span class="input-group-text"
                                i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.groupCrewComposition.additional.crewAndStewards">Cockpit and Cabin</span>
                        </div>
                        <input type="number"
                              class="form-control"
                              [(ngModel)]="calculationManual.crew.additional.cabin">
                        <input type="number"
                              class="form-control"
                              [(ngModel)]="calculationManual.crew.additional.cockpit">
                      </div>
                    </div>

                    <div class="input-group input-group-sm mb-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text"
                              i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.groupCrewComposition.additionalCrewInCabin">Additional
                          crew in the cabin</span>
                      </div>
                      <input type="number"
                            class="form-control"
                            aria-label="Sizing example input"
                            aria-describedby="inputGroup-sizing-default"
                            [(ngModel)]="calculationManual.crew.additional.inCabin">
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-body">
                  <p class="font-weight-bold text-blue h5 mb-3"
                    i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.groupPassengers">
                    Passengers
                  </p>
                  <table class="w-100 mb-3 table-border text-center">
                    <thead>
                      <tr class="bg-blue color-white font-weight-bold">
                        <th i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPassengersAndBaggage.table.payload.cityAirport">City / Airport</th>
                        <th i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPassengersAndBaggage.table.payload.mfc">Adult+Child</th>
                        <th i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPassengersAndBaggage.table.payload.business">Business</th>
                        <th i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPassengersAndBaggage.table.payload.economy">Economy</th>
                      </tr>
                    </thead>
                    <tbody *ngFor="let route of flight.route">
                      <tr *ngIf="flight.isDeparture(route.airportId)">
                        <td class="font-weight-bold">{{route.displayName}}</td>
                        <td>{{route.loading.pax}}</td>
                        <td>{{route.loading.business}}</td>
                        <td>{{route.loading.economy}}</td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr class="bg-light-yellow">
                        <td class="font-weight-bold"
                          i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPassengersAndBaggage.table.payload.total">
                          Total
                        </td>
                        <td>{{flight.loading.adult + flight.loading.female + flight.loading.child}}</td>
                        <td>{{flight.loading.business}}</td>
                        <td>{{flight.loading.economy}}</td>
                      </tr>
                    </tfoot>
                  </table>
                  <label i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.groupPassengers.weights">Weights</label>
                  <table class="w-100 mb-3 table-border text-center">
                    <thead>
                      <tr class="bg-blue color-white font-weight-bold">
                        <th i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPassengersAndBaggage.table.payload.adultMale">Adult/Male</th>
                        <th i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPassengersAndBaggage.table.payload.female">Female</th>
                        <th i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPassengersAndBaggage.table.payload.child">Child</th>
                        <th i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPassengersAndBaggage.table.payload.infant">Infant</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <input type="number"
                                class="form-control"
                                [(ngModel)]="calculationManual.passengers.weights.adult">
                        </td>
                        <td>
                          <input type="number"
                                class="form-control"
                                [(ngModel)]="calculationManual.passengers.weights.female">
                        </td>
                        <td>
                          <input type="number"
                                class="form-control"
                                [(ngModel)]="calculationManual.passengers.weights.child">
                        </td>
                        <td>
                          <input type="number"
                                class="form-control"
                                [(ngModel)]="calculationManual.passengers.weights.infant">
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="input-group-prepend">
                    <div class="custom-control custom-switch d-flex ml-2" style="min-width: fit-content;">
                      <input type="checkbox"
                            class="custom-control-input"
                            id="handLuggageIncluded"
                            name="handLuggageIncluded"
                            [checked]="calculationManual.passengers.weights.handLuggageIncluded"
                            (click)="changeHandBaggage('manual')">
                      <label class="custom-control-label" for="handLuggageIncluded"
                      i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPassengersAndBaggage.handBaggageWeightIncluded">
                      Hand baggage weight is included in the passenger weights
                      </label>
                    </div>
                  </div>
                  <div class="input-group flex-nowrap w-auto my-auto" *ngIf="!calculationManual.passengers.weights.handLuggageIncluded">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0"
                        i18n="wb|Main weight balance tab passengers@@wb.main.tab.passengers.groupPassengersAndBaggage.table.passengersWeight.handBaggage">
                        Hand baggage
                      </span>
                    </div>
                    <input type="number" class="form-control custom-sm" [(ngModel)]="calculationManual.passengers.weights.handLuggage"/>
                  </div>
                  <!-- <div class="row">
                    <div class="col-6">
                      <div class="form-group">
                        <div class="input-group input-group-sm">
                          <div class="input-group-prepend">
                            <span class="input-group-text"
                                  i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.groupPassengers.weights.male">Male</span>
                          </div>
                          <input type="number"
                                class="form-control"
                                [(ngModel)]="calculationManual.passengers.weights.adult">
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <div class="input-group input-group-sm">
                          <div class="input-group-prepend">
                            <span class="input-group-text"
                                  i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.groupPassengers.weights.female">Female</span>
                          </div>
                          <input type="number"
                                class="form-control"
                                [(ngModel)]="calculationManual.passengers.weights.female">
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <div class="input-group input-group-sm">
                          <div class="input-group-prepend">
                            <span class="input-group-text"
                                  i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.groupPassengers.weights.children">Children</span>
                          </div>
                          <input type="number"
                                class="form-control"
                                [(ngModel)]="calculationManual.passengers.weights.child">
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <div class="input-group input-group-sm">
                          <div class="input-group-prepend">
                            <span class="input-group-text"
                                  i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.groupPassengers.weights.infant">Infant</span>
                          </div>
                          <input type="number"
                                class="form-control"
                                [(ngModel)]="calculationManual.passengers.weights.infant">
                        </div>
                      </div>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="flight-documents" role="tabpanel" aria-labelledby="flight-documents-tab">
          <div class="d-flex flex-column">
            <div>
              <div class="row">
                <div class="col-4">
                  <div class="alert alert-danger" role="alert"
                        *ngIf="printDisabled; else printBlock"
                        i18n="wb|Main weight balance tab documents@@wb.main.tab.documents.text.error">
                    Aircraft alignment is not correct: the function of printing and saving documents is not available
                  </div>
                  <ng-template #printBlock>
                    <div class="form-group">
                      <label i18n="wb|Main weight balance tab documents@@wb.main.tab.documents.editionNumber">Edition number</label>
                      <button class="btn btn-xs btn-ico btn-blue mb-2 w-100"
                              i18n="wb|Main weight balance tab documents@@wb.main.tab.documents.button.createNewEdition"
                              (click)="createDocuments()"
                              [disabled]="loadingType == 'createDocuments'">
                        Create a new edition of documents
                      </button>
                      <ng-select [items]="documentEditions"
                                bindLabel="edno"
                                bindValue="edno"
                                class="custom-sm w-100 mb-3"
                                (change)="documentText = null; currentDocumentView = null"
                                [(ngModel)]="currentDocumentEditions"
                                [searchable]="false">
                      </ng-select>
                    </div>
                    <label i18n="wb|Main weight balance tab documents@@wb.main.tab.documents.released">Released</label>
                    <div class="input-group mb-3">
                      <input type="text"
                            class="form-control"
                            value="{{getDocumentValue(currentDocumentEditions, 'preparedBy')}}"
                            disabled>
                      <input type="text"
                            class="form-control"
                            value="{{getDocumentValue(currentDocumentEditions, 'preparedAt') | date: 'dd.MM.yyyy HH:mm' : 'UTC+0' }}"
                            disabled>
                    </div>
                    <hr>
                      <nav>
                        <div class="nav nav-tabs" role="tablist">
                          <a class="nav-item nav-link active"
                              id="nav-document-type-tab"
                              data-toggle="tab" href="#nav-document-type"
                              role="tab" aria-selected="true"
                              *ngIf="currentDocumentEditions > 0"
                              i18n="wb|Main weight balance tab documents@@wb.main.tab.documents.documentType">
                            Document type
                          </a>
                          <a class="nav-item nav-link"
                              id="nav-telegrams-tab"
                              data-toggle="tab" href="#nav-telegrams"
                              role="tab" aria-selected="false"
                              [ngClass]="{'active': !currentDocumentEditions}"
                              i18n="wb|Main weight balance tab documents@@wb.main.tab.documents.telegrams">
                            Telegrams
                          </a>
                        </div>
                      </nav>
                      <div class="tab-content pt-2">
                        <div class="tab-pane show active"
                              *ngIf="currentDocumentEditions > 0"
                              id="nav-document-type"
                              role="tabpanel">
                          <div class="list-group">
                            <a *ngFor="let document of documentTypesManual; let i = index"
                              class="list-group-item list-group-item-action"
                              (click)="loadDocument(document.url); currentDocumentView = document.id"
                              [ngClass]="{'active': currentDocumentView === document.id}">
                              <div class="d-flex align-items-center">
                                <div class="flex-fill">{{document.type}}</div>
                                <!-- TODO Кнопки [disabled] привязать к выходу за границу центровки -->
                                <button class="btn btn-xs btn-ico btn-light-gray mr-1"
                                        (click)="saveDocument(document.url)"><span class="material-icons">
                                    file_download
                                  </span></button>
                                <!-- TODO Кнопки [disabled] привязать к выходу за границу центровки -->
                                <button class="btn btn-xs btn-ico btn-light-gray"
                                        (click)="printDocument(document.url)"><span class="material-icons">
                                    print
                                  </span></button>
                              </div>
                            </a>
                          </div>
                        </div>
                        <div class="tab-pane fade"
                            id="nav-telegrams" role="tabpanel"
                            [ngClass]="{'active show': !currentDocumentEditions}">
                          <div class="list-group">
                            <a *ngFor="let telegram of telegramTypes"
                              class="list-group-item list-group-item-action"
                              (click)="loadTelegram(telegram); currentTelegramView = telegram"
                              [ngClass]="{'active': currentTelegramView === telegram}">
                              <div class="d-flex align-items-center">
                                <div class="flex-fill text-uppercase">{{telegram}}</div>
                                <!-- TODO Кнопки [disabled] привязать к выходу за границу центровки -->
                                <!-- <button class="btn btn-xs btn-ico btn-light-gray mr-1"
                                        (click)="saveTelegram(telegram)">
                                  <span class="material-icons">file_download</span>
                                </button> -->
                                <!-- TODO Кнопки [disabled] привязать к выходу за границу центровки -->
                                <button class="btn btn-xs btn-ico btn-light-gray"
                                        (click)="printTelegram(telegram)">
                                  <span class="material-icons">print</span>
                                </button>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                      <button #print
                              printSectionId="print-section"
                              ngxPrint
                              hidden="true"
                              [useExistingCss]="true"></button>
                  </ng-template>
                </div>
                <div class="col-8 p-0">
                  <div>
                    <div class="d-flex align-items-center justify-content-between mb-2">
                      <p class="m-0" i18n="wb|Main weight balance tab documents@@wb.main.tab.documents.documentView">Document view</p>
                      <!-- <button class="btn btn-sm btn-blue"
                              i18n="wb|Main weight balance tab documents@@wb.main.tab.documents.button.print"
                              *ngIf="documentText !== ''"
                              [useExistingCss]="true"
                              printSectionId="print-section"
                              ngxPrint>Print</button> -->
                    </div>
                    <textarea rows="38" class="w-100 p-2 border text-monospace" style="font-size: 12px;" readonly>{{ documentText }}</textarea>
                    <div id="print-section" class="d-none">
                      <span class="message-text-wrapping text-monospace m-0">{{ documentText }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="d-flex">
                <ng-select [items]="documentTypes"
                          class="custom-sm w-100 mb-3"
                          bindLabel="type"
                          bindValue="url"
                          [(ngModel)]="documentUrl">
                </ng-select>
                <button class="btn btn-sm btn-blue ml-3 flex-shrink-1"
                        i18n="wb|Main weight balance tab documents@@wb.main.tab.documents.button.download"
                        (click)="saveDocument(documentUrl)">Download</button>
                <button class="btn btn-sm btn-blue ml-3 flex-shrink-1"
                        i18n="wb|Main weight balance tab documents@@wb.main.tab.documents.button.create"
                        (click)="loadDocument(documentUrl)">Create</button>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-4 h-auto">
      <div class="card mb-3 h-100">
        <div class="card-body">
          <p class="font-weight-bold text-blue h5 mb-3 pb-3 border-bottom"
              i18n="wb|Main weight balance calculation@@wb.main.calculation.weightAndBalanceValues">
              Weight and balance values
          </p>
          <div class="d-flex flex-column justify-content-center h-100">
            <div class="text-center text-dark-gray p-5">
              <i class="material-icons text-hight-gray h1 mb-4" style="font-size: 5em;">signal_cellular_no_sim</i>
              <p class="text-center">
                <span i18n="wb|Main weight balance calculation@@wb.main.calculation.noAhmTextPart1">No AHM560/565 has been added for
                  this aircraft.</span><br><span
                      i18n="wb|Main weight balance calculation@@wb.main.calculation.noAhmTextPart2">Calculation of centering is
                  possible</span><br><span i18n="wb|Main weight balance calculation@@wb.main.calculation.noAhmTextPart3">only in manual
                  mode.</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal -->
<div class="modal fade bd-example-modal-md move-desktop mw-100"
      data-backdrop="static" role="dialog">
  <div class="modal-dialog modal-md">
    <div class="modal-content bg-light-gray">
      <div class="loading" *ngIf="loading">
        <div class="cssload-spin-box"></div>
      </div>
      <div class="modal-header">
        <h5 class="modal-title"
            id="exampleModalScrollableTitle">
          <span i18n="wb|Move to desktop - header name@@wb.moveDesktop.header.name">Move to desktop</span>
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <label class="d-block" *ngFor="let workspace of workspaces">
          <input type="radio" class="mr-2"
                (click)="targetDesktop = workspace.id"
                [value]="workspace.id"
                [checked]="targetDesktop === workspace.id">
            {{workspace.name}}
        </label>
      </div>
      <div class="modal-footer">
        <button type="button"
                class="btn btn-sm btn-blue"
                (click)="moveDesktop(targetDesktop, flight.id)"
                tabindex="2">
          <span i18n="wb|Move to desktop - button move@@wb.moveDesktop.button.move">Move</span>
        </button>
        <button type="button"
                class="btn btn-sm btn-gray-blue-dark ml-2"
                data-dismiss="modal"
                tabindex="2">
          <span i18n="wb|Move to desktop - button close@@wb.moveDesktop.button.close">Close</span>
        </button>
      </div>
    </div>
  </div>
</div>
<!-- Modal -->
<div class="modal fade bd-example-modal-md choice-performer mw-100"
      data-backdrop="static" role="dialog">
  <div class="modal-dialog modal-md">
    <div class="modal-content bg-light-gray">
      <div class="loading" *ngIf="loading">
        <div class="cssload-spin-box"></div>
      </div>
      <div class="modal-header">
        <h5 class="modal-title"
            id="exampleModalScrollableTitle">
          <span i18n="wb|Move to desktop - header name@@wb.assignUser.header.name">Assign to user</span>
        </h5>
        <button #closebutton type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body bg-white columns-2">
        <label class="d-block" *ngFor="let user of users">
          <input type="radio" class="mr-2"
                (click)="targetUser = user.username"
                [value]="user.username"
                [checked]="targetUser === user.username">
            {{user.fullName}}
        </label>
      </div>
      <!-- Кнопки карточки рейса -->
      <div class="modal-footer">
        <button type="button"
                class="btn btn-sm btn-blue"
                (click)="choicePerformer(flight.id, targetUser)"
                tabindex="2">
          <span i18n="wb|Move to desktop - button move@@wb.moveDesktop.button.move">Move</span>
        </button>
        <button type="button"
                class="btn btn-sm btn-gray-blue-dark ml-2"
                data-dismiss="modal"
                tabindex="2">
          <span i18n="wb|Move to desktop - button close@@wb.moveDesktop.button.close">Close</span>
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade bd-example-modal-xl flight-card mw-100"
      data-backdrop="static" tabindex="-1"
      role="dialog"
      aria-labelledby="myExtraLargeModalLabel"
      aria-hidden="true">
  <div class="modal-dialog modal-xxl load--max-width">
    <div class="modal-content bg-light-gray">
      <form #flightForm="ngForm">
        <div class="loading" *ngIf="loading">
          <div class="cssload-spin-box"></div>
        </div>
        <div class="modal-header">
          <h5 class="modal-title"
              id="exampleModalScrollableTitle">
            <span i18n="sch|Flight card - header name@@sch.flightCard.header.name">Flight card</span>
          </h5>
          <button #closeFlightCard type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-nav pl-3 pr-3 pb-1">
          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
              <a class="nav-link" id="route-tab"
                [ngClass]="{'active': viewParametrs.activeTabFlight === 'route'}"
                i18n="sch|Flight card tabs - route@@sch.flightCard.tabs.route"
                data-toggle="tab" href="#route" role="tab">Route</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="loading-tab"
                [ngClass]="{'active': viewParametrs.activeTabFlight === 'loading'}"
                i18n="sch|Flight card tabs - payload departure@@sch.flightCard.tabs.payloadDeparture"
                data-toggle="tab" href="#loading" role="tab">Payload Departure</a>
            </li>
          </ul>
        </div>
        <div class="modal-body">
          <div class="tab-content pt-3" id="myTabContent">
            <div class="tab-pane fade" id="route" [ngClass]="{'show active': viewParametrs.activeTabFlight === 'route'}" role="tabpanel" aria-labelledby="route-tab">
              <div class="row mb-2">
                <div class="col-4">
                  <div class="card bg-none">
                    <div class="card-body p-3 bg-white rounded">
                      <p class="m-0 h6 card__header--important">
                        <i class="material-icons">apartment</i>
                        <span i18n="sch|Flight card tab route - group airline name@@sch.flightCard.tab.route.groupAirline.name">Airline</span>
                      </p>
                      <div class="row">
                        <div class="col-12">
                          <label class="col-form-label col-form-label-sm">
                            <span i18n="sch|Flight card tab route - group airline iata@@sch.flightCard.tab.route.groupAirline.iataIcaoLocalCode">
                              IATA / ICAO / Local code
                            </span><span class="required-asterisk">*</span>
                          </label>
                          <ng-select class="form-control custom-sm p-0"
                                     [items]="airlinesSortIata"
                                     bindLabel="iata"
                                     bindValue="id"
                                     [(ngModel)]="flight.airlineId"
                                     #airlineIata="ngModel"
                                     name="airlineIata"
                                     [virtualScroll]="true"
                                     [loading]="selectLoadAnimation.airlines"
                                     [searchFn]="customSelectSearchAirline"
                                     required>
                            <ng-template ng-option-tmp
                                         let-item="item">
                              IATA:&nbsp;<span class="font-weight-bold">{{item.iata?.trim() || '-'}}</span>,&nbsp;
                              ICAO:&nbsp;<span class="font-weight-bold">{{item.icao?.trim() || '-'}}</span>,&nbsp;
                              CODE:&nbsp;<span class="font-weight-bold">{{item.code[1] || '-'}}</span><br />
                              {{item.name}}
                            </ng-template>
                            <ng-template ng-label-tmp
                                         let-item="item">
                              IATA:&nbsp;<span class="font-weight-bold">{{item.iata?.trim() || '-'}}</span>,&nbsp;
                              ICAO:&nbsp;<span class="font-weight-bold">{{item.icao?.trim() || '-'}}</span>,&nbsp;
                              CODE:&nbsp;<span class="font-weight-bold">{{item.code && item.code[1] ? item.code[1] : '-'}}</span>,&nbsp;
                              {{item.name}}
                            </ng-template>
                          </ng-select>
                          <div *ngIf="!airlineIata?.valid && (airlineIata?.dirty || airlineIata?.touched)"
                            class="invalid-feedback d-block">
                            <div *ngIf="airlineIata.errors.required"
                                i18n="sch|Flight card tab route - group airline field req@@sch.flightCard.tab.route.groupAirline.field.req">
                              Field is required
                            </div>
                          </div>
                        </div>
                        <!-- <div class="col-3 pr-0">
                          <label class="col-form-label col-form-label-sm">
                            <span i18n="sch|Flight card tab route - group airline iata@@sch.flightCard.tab.route.groupAirline.iata">IATA</span><span class="required-asterisk">*</span>
                          </label>
                          <ng-select class="form-control custom-sm p-0"
                            [items]="airlinesSortIata"
                            bindLabel="iata"
                            bindValue="id"
                            [(ngModel)]="flight.airlineId"
                            #airlineIata="ngModel"
                            name="airlineIata"
                            [virtualScroll]="true"
                            [loading]="selectLoadAnimation.airlines"
                            required>
                          </ng-select>
                          <div *ngIf="!airlineIata?.valid && (airlineIata?.dirty || airlineIata?.touched)"
                            class="invalid-feedback d-block">
                            <div *ngIf="airlineIata.errors.required"
                                i18n="sch|Flight card tab route - group airline iata req@@sch.flightCard.tab.route.groupAirline.iata.req">
                              IATA is required
                            </div>
                          </div>
                        </div>
                        <div class="col-3 pr-0">
                          <label class="col-form-label col-form-label-sm">
                            <span i18n="sch|Flight card tab route - group airline icao@@sch.flightCard.tab.route.groupAirline.icao">ICAO</span><span class="required-asterisk">*</span>
                          </label>
                          <ng-select class="form-control custom-sm p-0"
                            [items]="airlinesSortIcao"
                            bindLabel="icao"
                            bindValue="id"
                            [(ngModel)]="flight.airlineId"
                            name="airlineIcao"
                            #airlineIcao="ngModel"
                            [virtualScroll]="true"
                            [loading]="selectLoadAnimation.airlines"
                            required>
                          </ng-select>
                          <div *ngIf="!airlineIcao?.valid && (airlineIcao?.dirty || airlineIcao?.touched)"
                            class="invalid-feedback d-block">
                            <div *ngIf="airlineIcao.errors.required"
                                i18n="sch|Flight card tab route - group airline icao req@@sch.flightCard.tab.route.groupAirline.icao.req">
                              ICAO is required
                            </div>
                          </div>
                        </div>
                        <div class="col-6">
                          <label class="col-form-label col-form-label-sm">
                            <span i18n="sch|Flight card tab route - group airline name@@sch.flightCard.tab.route.groupAirline.nameAirline">Name</span><span class="required-asterisk">*</span>
                          </label>
                          <ng-select class="form-control custom-sm p-0"
                            [items]="references.airlines"
                            bindLabel="name"
                            bindValue="id"
                            [(ngModel)]="flight.airlineId"
                            name="airlineName"
                            #airlineName="ngModel"
                            [virtualScroll]="true"
                            [loading]="selectLoadAnimation.airlines"
                            required>
                          </ng-select>
                          <div *ngIf="!airlineName?.valid && (airlineName?.dirty || airlineName?.touched)"
                            class="invalid-feedback d-block">
                            <div *ngIf="airlineName.errors.required"
                                i18n="sch|Flight card tab route - group airline name req@@sch.flightCard.tab.route.groupAirline.name.req">
                              Name is required
                            </div>
                          </div>
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>
                <div class="pl-0 col-4">
                  <div class="card bg-none">
                    <div class="card-body p-3 bg-white rounded">
                      <p class="m-0 h6 card__header--important">
                        <i class="material-icons">flight_takeoff</i>
                        <span i18n="sch|Flight card tab route - group flight name@@sch.flightCard.tab.route.groupFlight.name">Flight</span>
                      </p>
                      <div class="row">
                        <div class="col-6 pr-0">
                          <label class="col-form-label col-form-label-sm">
                            <span i18n="sch|Flight card tab route - group flight number@@sch.flightCard.tab.route.groupFlight.number">Number</span>
                            <span class="required-asterisk">*</span>
                          </label>
                          <input type="text"
                                class="form-control form-control-sm"
                                name="flightinput"
                                [(ngModel)]="flight.flightNumber"
                                #flightinput="ngModel"
                                required>
                          <div *ngIf="!flightinput?.valid && (flightinput?.dirty || flightinput?.touched)"
                            class="invalid-feedback d-block">
                            <div *ngIf="flightinput.errors.required"
                                i18n="sch|Flight card tab route - group airline flight req@@sch.flightCard.tab.route.groupAirline.flight.req">
                              Flight code is required
                            </div>
                            <div *ngIf="flightinput.errors.pattern"
                                i18n="sch|Flight card tab route - group airline flight en only@@sch.flightCard.tab.route.groupAirline.flight.enOnlyNumber">
                              Flight code is English only and number
                            </div>
                          </div>
                        </div>
                        <div class="col-6">
                          <label class="col-form-label col-form-label-sm">
                            <span i18n="sch|Flight card tab route - group flight type@@sch.flightCard.tab.route.groupFlight.type">Type</span>
                          </label>
                          <ng-select class="form-control custom-sm airports-long p-0"
                                    [items]="references.movement_types"
                                    bindLabel="code"
                                    bindValue="id"
                                    [(ngModel)]="flight.movementTypeId"
                                    #movementTypeId="ngModel"
                                    [loading]="selectLoadAnimation.movement_types"
                                    name="movementTypeId">
                            <ng-template ng-option-tmp let-item="item">
                              {{item.code}} ({{globalSettings.language=='en' || item.name[1]=='' ? item.name[0] : item.name[1]}})
                            </ng-template>
                          </ng-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-4 pl-0">
                  <div class="card bg-none">
                    <div class="card-body p-3 bg-white rounded">
                      <p class="m-0 h6 card__header--important">
                        <i class="material-icons">flight</i>
                        <span i18n="sch|Flight card tab route - group flight name@@sch.flightCard.tab.route.groupAircraft.name">Aircraft</span>
                      </p>
                      <div class="row">
                        <div class="col-6 pr-1">
                          <label for="departureType"
                                class="col-form-label col-form-label-sm"
                                i18n="sch|Flight card tab route - group departure tail@@sch.flightCard.tab.route.groupAircraft.tail">
                            A/C registration
                          </label>
                          <ng-select class="form-control custom-sm p-0"
                                    [items]="references.tails"
                                    bindLabel="tail"
                                    bindValue="id"
                                    name="tail"
                                    id="tail"
                                    [(ngModel)]="flight.tailId"
                                    [loading]="selectLoadAnimation.tails"
                                    (change)="findAircraftTypeByTail()"
                                    (clear)="onClearAircraft()"
                                    [searchFn]="customSelectSearchTail"
                                    [virtualScroll]="true">
                          </ng-select>
                        </div>
                        <div class="col-6 pl-0 pr-1">
                          <label for="departureTail"
                                class="col-form-label col-form-label-sm"
                                i18n="sch|Flight card tab route - group departure type@@sch.flightCard.tab.route.groupAircraft.type">
                            A/C type
                          </label>
                          <ng-select class="form-control custom-sm p-0"
                                    [items]="references.aircraft_types"
                                    bindLabel="iata"
                                    bindValue="id"
                                    name="aircraftTypeIdD"
                                    id="aircraftTypeIdD"
                                    [(ngModel)]="flight.aircraftTypeId"
                                    [loading]="selectLoadAnimation.aircraft_types"
                                    [virtualScroll]="true">
                          </ng-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card p-3">
                <div class="d-flex justify-content-between mb-3">
                  <p class="m-0 h6 card__header--important">
                    <i class="material-icons">map</i>
                    <span i18n="sch|Flight card tab route - group departure name@@sch.flightCard.tab.route.route">Route</span>
                  </p>
                  <div>
                    <button type="button"
                            class="btn btn-sm btn-blue"
                            (click)="addRoutePoint()">
                      <i class="material-icons">add_circle_outline</i>
                      <span i18n="sch|Flight card tab route - button add route point@@sch.flightCard.tab.route.button.addRoutePoint">
                        Add route point
                      </span>
                    </button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4 pr-0"></div>
                  <div class="col pr-0">
                    <div class="bg-light-gray rounded p-2 text-uppercase text-center"
                        i18n="sch|Flight card tab route - button add route point@@sch.flightCard.tab.route.header.shedule">
                      Shedule
                    </div>
                  </div>
                  <div class="col pr-0">
                    <div class="bg-light-gray rounded p-2 text-uppercase text-center"
                        i18n="sch|Flight card tab route - button add route point@@sch.flightCard.tab.route.header.estimated">
                      Estimated
                    </div>
                  </div>
                  <div class="col">
                    <div class="bg-light-gray rounded p-2 text-uppercase text-center"
                        i18n="sch|Flight card tab route - button add route point@@sch.flightCard.tab.route.header.actual">
                      Actual
                    </div>
                  </div>
                </div>
                <div *ngFor="let route of flight.route; let i = index" class="row py-1 border-bottom">
                  <div class="col-4 pr-0">
                    <div class="d-flex align-items-center">
                      <i class="material-icons m-0 text-red font-weight-bold pointer mr-2"
                          (click)="deleteRoute(i)">clear</i>
                      <ng-select class="form-control airports-long custom-sm p-0 ng-select"
                                [items]="airportsSortIata"
                                bindLabel="iata"
                                bindValue="id"
                                (change)="changeAirport(route, $event)"
                                [(ngModel)]="route.airportId"
                                #airlineId{{i}}="ngModel"
                                name="airlineId{{i}}"
                                [virtualScroll]="true"
                                [loading]="selectLoadAnimation.airports"
                                (scroll)="onScrollNgSelect($event, 'airports')"
                                (scrollToEnd)="onScrollToEndNgSelect('airports')"
                                [searchFn]="customSelectSearch"
                                appendTo="body"
                                required>
                        <ng-template ng-option-tmp let-item="item">
                            IATA:&nbsp;<span class="font-weight-bold">{{item.iata?.trim() || '-'}}</span>,&nbsp;
                            ICAO:&nbsp;<span class="font-weight-bold">{{item.icao?.trim() || '-'}}</span>,&nbsp;
                            CODE:&nbsp;<span class="font-weight-bold">{{item.code ? item.code[1] : '-'}}</span><br />
                            {{item.name}}
                        </ng-template>
                        <ng-template ng-label-tmp let-item="item">
                          <span *ngIf="item.iata?.trim(); else: airportIcao">
                            IATA:&nbsp;<span class="font-weight-bold">{{item.iata?.trim()}}</span>,&nbsp;
                          </span>
                          <ng-template #airportIcao>
                            <span *ngIf="item.icao?.trim(); else: airportCode">
                            ICAO:&nbsp;<span class="font-weight-bold">{{item.icao?.trim()}}</span>,&nbsp;
                            </span>
                            <ng-template #airportCode>
                              CODE:&nbsp;<span class="font-weight-bold">{{item.code ? item.code[1] : '-'}}</span>,&nbsp;
                            </ng-template>
                          </ng-template>
                          {{item.name}}
                        </ng-template>
                        <!-- <ng-template ng-label-tmp let-item="item">
                          <span class="font-weight-bold">{{item.iata}}</span>&nbsp;{{item.name}}
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item">
                          <div class="font-small">
                            <span class="font-weight-bold">{{item.iata}}</span>&nbsp;{{item.name}}
                          </div>
                        </ng-template> -->
                      </ng-select>
                      <div class="type-route">
                        <div *ngIf="i!==0"
                            class="text-uppercase py-2 ml-2"
                            [ngClass]="{'font-weight-bold text-blue': globalSettings.homeAirport === route.airportId}"
                            i18n="sch|Flight card tab route - button add route point@@sch.flightCard.tab.route.title.arrival">
                          Arrival
                        </div>
                        <div *ngIf="i!==flight.route.length-1"
                            class="text-uppercase py-2 ml-2"
                            [ngClass]="{'font-weight-bold text-blue': globalSettings.homeAirport === route.airportId}"
                            i18n="sch|Flight card tab route - button add route point@@sch.flightCard.tab.route.title.departure">
                          Departure
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col pr-0">
                    <div *ngIf="i!==0" class="row">
                      <div class="col-auto pr-0">
                        <input type="time" class="form-control my-1" [ngModel]="route.dtArrivalScheduled | date: 'HH:mm'"
                          (input)="route.dtArrivalScheduled = parseTime(Arrival_date_0, $event.target.value)"
                          name="dtArrivalScheduledTime{{i}}" #Arrival_time_0
                          #dtArrivalScheduledTime{{i}}="ngModel" required>
                      </div>
                      <div class="col">
                        <input type="date" class="form-control my-1"
                          [ngModel]="route.dtArrivalScheduled | date: 'yyyy-MM-dd'"
                          (input)="route.dtArrivalScheduled = parseDate($event.target.value, Arrival_time_0.value)"
                          name="dtArrivalScheduledDate{{i}}" #Arrival_date_0
                          #dtArrivalScheduledDate{{i}}="ngModel" required>
                      </div>
                    </div>
                    <div *ngIf="i!==flight.route.length-1" class="row">
                      <div class="col-auto pr-0">
                        <input type="time" class="form-control my-1"
                          [ngModel]="route.dtDepartureScheduled | date: 'HH:mm'"
                          (input)="route.dtDepartureScheduled = parseTime(Departure_date_0, $event.target.value)"
                          name="dtDepartureScheduledTime{{i}}" #Departure_time_0
                          #dtDepartureScheduledTime{{i}}="ngModel" required>
                      </div>
                      <div class="col">
                        <input type="date" class="form-control my-1"
                          [ngModel]="route.dtDepartureScheduled | date: 'yyyy-MM-dd'"
                          (input)="route.dtDepartureScheduled = parseDate($event.target.value, Departure_time_0.value)"
                          name="dtDepartureScheduledDate{{i}}" #Departure_date_0
                          #dtDepartureScheduledDate{{i}}="ngModel" required>
                      </div>
                    </div>
                  </div>
                  <div class="col pr-0">
                    <div *ngIf="i!==0" class="row">
                      <div class="col-auto pr-0">
                        <input type="time" class="form-control my-1" [ngModel]="route.dtArrivalEstimated | date: 'HH:mm'"
                          (input)="route.dtArrivalEstimated = parseTime(Arrival_date_1, $event.target.value)"
                          name="dtArrivalEstimatedTime{{i}}" #Arrival_time_1>
                      </div>
                      <div class="col">
                        <input type="date" class="form-control my-1"
                          [ngModel]="route.dtArrivalEstimated | date: 'yyyy-MM-dd'"
                          (input)="route.dtArrivalEstimated = parseDate($event.target.value, Arrival_time_1.value)"
                          name="dtArrivalEstimatedDate{{i}}" #Arrival_date_1>
                      </div>
                    </div>
                    <div *ngIf="i!==flight.route.length-1" class="row">
                      <div class="col-auto pr-0">
                        <input type="time" class="form-control my-1"
                          [ngModel]="route.dtDepartureEstimated | date: 'HH:mm'"
                          (input)="route.dtDepartureEstimated = parseTime(Departure_date_1, $event.target.value)"
                          name="dtDepartureEstimatedTime{{i}}" #Departure_time_1>
                      </div>
                      <div class="col">
                        <input type="date" class="form-control my-1"
                          [ngModel]="route.dtDepartureEstimated | date: 'yyyy-MM-dd'"
                          (input)="route.dtDepartureEstimated = parseDate($event.target.value, Departure_time_1.value)"
                          name="dtDepartureEstimatedDate{{i}}" #Departure_date_1>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div *ngIf="i!==0" class="row">
                      <div class="col-auto pr-0">
                        <input type="time" class="form-control my-1" [ngModel]="route.dtArrivalFact | date: 'HH:mm'"
                          (input)="route.dtArrivalFact = parseTime(Arrival_date_2, $event.target.value)"
                          name="dtArrivalFactTime{{i}}" #Arrival_time_2>
                      </div>
                      <div class="col">
                        <input type="date" class="form-control my-1" [ngModel]="route.dtArrivalFact | date: 'yyyy-MM-dd'"
                          (input)="route.dtArrivalFact = parseDate($event.target.value, Arrival_time_2.value)"
                          name="dtArrivalFactDate{{i}}" #Arrival_date_2>
                      </div>
                    </div>
                    <div *ngIf="i!==flight.route.length-1" class="row">
                      <div class="col-auto pr-0">
                        <input type="time" class="form-control my-1" [ngModel]="route.dtDepartureFact | date: 'HH:mm'"
                          (input)="route.dtDepartureFact = parseTime(Departure_date_2, $event.target.value)"
                          name="dtDepartureFactTime{{i}}" #Departure_time_2>
                      </div>
                      <div class="col">
                        <input type="date" class="form-control my-1"
                          [ngModel]="route.dtDepartureFact | date: 'yyyy-MM-dd'"
                          (input)="route.dtDepartureFact = parseDate($event.target.value, Departure_time_2.value)"
                          name="dtDepartureFactDate{{i}}" #Departure_date_2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="loading" [ngClass]="{'show active': viewParametrs.activeTabFlight === 'loading'}" role="tabpanel" aria-labelledby="loading-tab">
              <table class="table table-borderless table-hover table-border">
                <thead>
                  <tr class="text-light type-head table__main-header">
                    <th scope="col"
                        colspan=""
                        class="head-border-right-bold font-large text-center"
                        style="border-bottom-left-radius: 0;"
                        i18n="sch|Flight card tab loading - table loading dep column destination@@sch.flightCard.tab.loading.table.loadingDeparture.column.destination">
                      Destination
                    </th>
                        <th scope="col"
                            colspan="8"
                            class="head-border-right-bold font-large text-center"
                            i18n="sch|Flight card tab loading - table loading dep column passengers@@sch.flightCard.tab.loading.table.loading.loadingDeparture.passengers">
                          Passengers
                        </th>
                        <th scope="col"
                            colspan="3"
                            class="head-border-right-bold font-large text-center"
                            i18n="sch|Flight card tab loading - table loading dep column leggage@@sch.flightCard.tab.loading.table.loading.loadingDeparture.baggage">
                          Baggage
                        </th>
                        <th scope="col"
                            colspan="0"
                            class="head-border-right-bold font-large text-center"
                            i18n="sch|Flight card tab loading - table loading dep column cargo@@sch.flightCard.tab.loading.table.loading.loadingDeparture.cargo">
                          Cargo
                        </th>
                        <th scope="col"
                            class="font-large text-center"
                            style="border-bottom-right-radius: 0;"
                            i18n="sch|Flight card tab loading - table loading dep column mail@@sch.flightCard.tab.loading.table.loading.loadingDeparture.mail">
                          Mail
                        </th>
                      </tr>
                      <tr class="text-muted detail-head table__mail-header--subhead head-border-top">
                        <th class="text-center text-uppercase font-small head-border-right-bold"
                          style="border-top-left-radius: 0;"
                          i18n="sch|Flight card tab loading - table loading dep column city\airport@@sch.flightCard.tab.loading.table.loading.loadingDeparture.cityAirport">
                          CITY\AIRPORT
                        </th>
                        <th></th>
                        <th class="text-center text-uppercase font-small"
                            i18n="sch|Flight card tab loading - table loading dep column adult@@sch.flightCard.tab.loading.table.loading.loadingDeparture.adultMale">
                          Adl / Male
                        </th>
                        <th class="text-center text-uppercase font-small"
                            i18n="sch|Flight card tab loading - table loading dep column adult@@sch.flightCard.tab.loading.table.loading.loadingDeparture.female">
                          Fem
                        </th>
                        <th class="text-center text-uppercase font-small"
                            i18n="sch|Flight card tab loading - table loading dep column child@@sch.flightCard.tab.loading.table.loading.loadingDeparture.child">
                          Chd
                        </th>
                        <th class="text-center text-uppercase font-small"
                            i18n="sch|Flight card tab loading - table loading dep column infant@@sch.flightCard.tab.loading.table.loading.loadingDeparture.infant">
                          Inf
                        </th>
                        <th class="text-center text-uppercase font-small"
                            i18n="sch|Flight card tab loading - table loading dep column business@@sch.flightCard.tab.loading.table.loading.loadingDeparture.business">
                          Business
                        </th>
                        <th class="text-center text-uppercase font-small head-border-right-bold"
                            i18n="sch|Flight card tab loading - table loading dep column economy@@sch.flightCard.tab.loading.table.loading.loadingDeparture.economy">
                          Economy
                        </th>
                        <th class="text-center text-uppercase font-small head-border-right-bold"
                            i18n="sch|Flight card tab loading - table loading dep column handBaggage@@sch.flightCard.tab.loading.table.loading.loadingDeparture.handBaggage">
                          Hand baggage
                        </th>
                        <th class="text-center text-uppercase font-small head-border-right-bold"
                            i18n="sch|Flight card tab loading - table loading dep column units@@sch.flightCard.tab.loading.table.loading.loadingDeparture.units">
                          Amount
                        </th>
                        <th class="text-center text-uppercase font-small"
                            i18n="sch|Flight card tab loading - table loading dep column all@@sch.flightCard.tab.loading.table.loading.loadingDeparture.all">
                          All, kg
                        </th>
                        <th class="text-center text-uppercase font-small"
                            i18n="sch|Flight card tab loading - table loading dep column paid@@sch.flightCard.tab.loading.table.loading.loadingDeparture.paid">
                          Paid, kg
                        </th>
                        <th class="text-center text-uppercase font-small head-border-right-bold"
                            i18n="sch|Flight card tab loading - table loading dep column weight kg@@sch.flightCard.tab.loading.table.loading.loadingDeparture.weightKg">
                          Weight/kg
                        </th>
                        <th class="text-center text-uppercase font-small"
                            style="border-top-right-radius: 0;"
                            i18n="sch|Flight card tab loading - table loading dep column weight kg 2@@sch.flightCard.tab.loading.table.loading.loadingDeparture.weightKg2">
                          Weight/kg
                        </th>
                      </tr>
                    </thead>
                    <tbody *ngFor="let route of flight.route; let i = index">
                      <tr *ngIf="flight.isDeparture(route.airportId)">
                        <td class="text-center tbody-border-right-bold">
                          <div class="input-group-sm load__airport" *ngIf="route.airportId">
                            {{route.displayName}}
                          </div>
                        </td>
                        <td class="text-center body-border__right--gray-normal">
                          <div class="input-group-sm load__poeple-count-l d-flex flex-column justify-content-around h-100 py-1">
                            <div class="input-group-prepend">
                              <span class="input-group-text border-0"
                                    i18n="sch|Flight card tab loading - table loading dep column transit@@sch.flightCard.tab.loading.table.loading.loadingDeparture.transit">TR</span>
                            </div>
                            <div class="input-group-prepend">
                              <span class="input-group-text border-0"
                                    i18n="sch|Flight card tab loading - table loading dep column supply@@sch.flightCard.tab.loading.table.loading.loadingDeparture.supply">SP</span>
                            </div>
                            <!-- <div class="input-group-prepend">
                              <span class="input-group-text border-0"
                                    i18n="sch|Flight card tab loading - table loading dep column transfer@@sch.flightCard.tab.loading.table.loading.loadingDeparture.transfer">TF</span>
                            </div>
                            <div class="input-group-prepend">
                              <span class="input-group-text border-0"
                                    i18n="sch|Flight card tab loading - table loading dep column selft@@sch.flightCard.tab.loading.table.loading.loadingDeparture.self">SLF</span>
                            </div> -->
                          </div>
                        </td>
                        <td class="text-center body-border__right--gray-normal">
                          <div class="input-group-sm load__poeple-count-l">
                            <div class="input-group-sm d-flex align-items-center my-1">
                              <input type="number"
                                    name="DepartureAdult_1_{{i}}"
                                    class="form-control"
                                    placeholder=""
                                    [(ngModel)]="route.passengersAdultTransit"
                                    (input)="calcEconomPassengers(route, 1)"
                                    tabindex="1">
                            </div>
                            <div class="input-group-sm d-flex align-items-center my-1">
                              <input type="number"
                                    name="DepartureAdult_0_{{i}}"
                                    class="form-control border-primary"
                                    placeholder=""
                                    [(ngModel)]="route.passengersAdultStandard"
                                    (input)="calcEconomPassengers(route, 0)"
                                    tabindex="2">
                            </div>
                          </div>
                        </td>
                        <td class="text-center body-border__right--gray-normal">
                          <div class="input-group-sm load__poeple-count-l">
                            <div class="input-group-sm d-flex align-items-center my-1">
                              <input type="number"
                                    name="DepartureFemale_1_{{i}}"
                                    class="form-control"
                                    placeholder=""
                                    [(ngModel)]="route.passengersFemaleTransit"
                                    (input)="calcEconomPassengers(route, 1)"
                                    tabindex="1">
                            </div>
                            <div class="input-group-sm d-flex align-items-center my-1">
                              <input type="number"
                                    name="DepartureFemale_0_{{i}}"
                                    class="form-control border-primary"
                                    placeholder=""
                                    [(ngModel)]="route.passengersFemaleStandard"
                                    (input)="calcEconomPassengers(route, 0)"
                                    tabindex="2">
                            </div>
                          </div>
                        </td>
                        <td class="text-center body-border__right--gray-normal">
                          <div class="input-group-sm load__poeple-count-l">
                            <div class="input-group-sm d-flex align-items-center my-1">
                              <input type="number"
                                    name="DepartureChild_1_{{i}}"
                                    class="form-control"
                                    placeholder=""
                                    [(ngModel)]="route.passengersChildTransit"
                                    (input)="calcEconomPassengers(route, 1)"
                                    tabindex="1">
                            </div>
                            <div class="input-group-sm d-flex align-items-center my-1">
                              <input type="number"
                                    name="DepartureChild_0_{{i}}"
                                    class="form-control border-primary"
                                    placeholder=""
                                    [(ngModel)]="route.passengersChildStandard"
                                    (input)="calcEconomPassengers(route, 0)"
                                    tabindex="2">
                            </div>
                          </div>
                        </td>
                        <td class="text-center body-border__right--gray-normal">
                          <div class="input-group-sm load__poeple-count-l">
                            <div class="input-group-sm d-flex align-items-center my-1">
                              <input type="number"
                                    name="DepartureInfant_1_{{i}}"
                                    class="form-control"
                                    placeholder=""
                                    [(ngModel)]="route.passengersInfantTransit"
                                    tabindex="1">
                            </div>
                            <div class="input-group-sm d-flex align-items-center my-1">
                              <input type="number"
                                    name="DepartureInfant_0_{{i}}"
                                    class="form-control border-primary"
                                    placeholder=""
                                    [(ngModel)]="route.passengersInfantStandard"
                                    tabindex="2">
                            </div>
                          </div>
                        </td>
                        <td class="text-center body-border__right--gray-normal">
                          <div class="input-group-sm load__poeple-count-l">
                            <div class="input-group-sm d-flex align-items-center my-1">
                              <input type="number"
                                    name="DepartureBusiness_1_{{i}}"
                                    class="form-control"
                                    [(ngModel)]="route.passengersBusinessTransit"
                                    (input)="calcEconomPassengers(route, 1)"
                                    tabindex="1">
                            </div>
                            <div class="input-group-sm d-flex align-items-center my-1">
                              <input type="number"
                                    name="DepartureBusiness_0_{{i}}"
                                    class="form-control border-primary"
                                    [(ngModel)]="route.passengersBusinessStandard"
                                    (input)="calcEconomPassengers(route, 0)"
                                    tabindex="2">
                            </div>
                          </div>
                        </td>
                        <td class="text-center body-border__right--gray-normal">
                          <div class="input-group-sm load__poeple-count-l">
                            <div class="input-group-sm d-flex align-items-center my-1">
                              <input type="number"
                                    name="DepartureEconomy_1_{{i}}"
                                    class="form-control"
                                    disabled
                                    [(ngModel)]="route.passengersEconomyTransit"
                                    tabindex="1">
                            </div>
                            <div class="input-group-sm d-flex align-items-center my-1">
                              <input type="number"
                                    name="DepartureEconomy_0_{{i}}"
                                    class="form-control border-primary"
                                    disabled
                                    [(ngModel)]="route.passengersEconomyStandard"
                                    tabindex="2">
                            </div>
                          </div>
                        </td>
                        <td class="text-center body-border__right--gray-normal">
                          <div class="input-group-sm load__poeple-count-l">
                            <div class="input-group-sm d-flex align-items-center my-1">
                              <input type="number"
                                name="DepartureHandLuggage_1_{{i}}"
                                class="form-control"
                                [(ngModel)]="route.passengersHandLuggageTransit"
                                tabindex="1">
                            </div>
                            <div class="input-group-sm d-flex align-items-center my-1">
                              <input type="number"
                                name="DepartureHandLuggage_0_{{i}}"
                                class="form-control border-primary"
                                [(ngModel)]="route.passengersHandLuggageStandard"
                                tabindex="2">
                            </div>
                          </div>
                        </td>
                        <td class="text-center body-border__right--gray-normal">
                          <div class="input-group-sm load__poeple-count-l">
                            <div class="input-group-sm d-flex align-items-center my-1">
                              <input type="number"
                                    name="DepartureBaggageUnit_1_{{i}}"
                                    class="form-control"
                                    placeholder=""
                                    [(ngModel)]="route.baggageUnitTransit"
                                    tabindex="1">
                            </div>
                            <div class="input-group-sm d-flex align-items-center my-1">
                              <input type="number"
                                    name="DepartureBaggageUnit_0_{{i}}"
                                    class="form-control border-primary"
                                    placeholder=""
                                    [(ngModel)]="route.baggageUnitStandard"
                                    tabindex="2">
                            </div>
                          </div>
                        </td>
                        <td class="text-center body-border__right--gray-normal">
                          <div class="input-group-sm load__poeple-count-l">
                            <div class="input-group-sm d-flex align-items-center my-1">
                              <input type="number"
                                name="DepartureBaggageWeight_1_{{i}}"
                                class="form-control"
                                [(ngModel)]="route.baggageWeightTransit"
                                tabindex="1">
                            </div>
                            <div class="input-group-sm d-flex align-items-center my-1">
                              <input type="number"
                                name="DepartureBaggageWeight_0_{{i}}"
                                class="form-control border-primary"
                                [(ngModel)]="route.baggageWeightStandard"
                                tabindex="2">
                            </div>
                          </div>
                        </td>
                        <td class="text-center body-border__right--gray-normal">
                          <div class="input-group-sm load__poeple-count-l">
                            <div class="input-group-sm d-flex align-items-center my-1">
                              <input type="number"
                                    name="DepartureBaggagePayed_1_{{i}}"
                                    class="form-control"
                                    placeholder=""
                                    [(ngModel)]="route.baggagePayedTransit"
                                    tabindex="1">
                            </div>
                            <div class="input-group-sm d-flex align-items-center my-1">
                              <input type="number"
                                    name="DepartureBaggagePayed_0_{{i}}"
                                    class="form-control border-primary"
                                    placeholder=""
                                    [(ngModel)]="route.baggagePayedStandard"
                                    tabindex="2">
                            </div>
                          </div>
                        </td>
                        <td class="text-center body-border__right--gray-normal">
                          <div class="input-group-sm load__poeple-count-l">
                            <div class="input-group-sm d-flex align-items-center my-1">
                              <input type="number"
                                    name="DepartureCargo_1_{{i}}"
                                    class="form-control"
                                    placeholder=""
                                    [(ngModel)]="route.cargoTransit"
                                    tabindex="1">
                            </div>
                            <div class="input-group-sm d-flex align-items-center my-1">
                              <input type="number"
                                    name="DepartureCargo_0_{{i}}"
                                    class="form-control border-primary"
                                    placeholder=""
                                    [(ngModel)]="route.cargoStandard"
                                    tabindex="2">
                            </div>
                          </div>
                        </td>
                        <td class="text-center body-border__right--gray-normal">
                          <div class="input-group-sm load__poeple-count-l">
                            <div class="input-group-sm d-flex align-items-center my-1">
                              <input type="number"
                                    name="DepartureMail_1_{{i}}"
                                    class="form-control"
                                    placeholder=""
                                    [(ngModel)]="route.mailTransit"
                                    tabindex="1">
                            </div>
                            <div class="input-group-sm d-flex align-items-center my-1">
                              <input type="number"
                                    name="DepartureMail_0_{{i}}"
                                    class="form-control border-primary"
                                    placeholder=""
                                    [(ngModel)]="route.mailStandard"
                                    tabindex="2">
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
            </div>
          </div>
        </div>

        <!-- Кнопки карточки рейса -->
        <div class="modal-footer justify-content-between align-items-center">
          <div>
            <ng-template #ahmDetailContent>
              <div i18n="sch|Flight card - buttons select calculation@@sch.flightCard.button.selectCalculation.ahmTooltip">
                AHM 560/565 not found for this aircraft
              </div>
            </ng-template>
            <span i18n="sch|Flight card - buttons select calculation@@sch.flightCard.button.selectCalculation">Calculation:</span>
            <input id="calculate-by-manual"
                   type="radio"
                   class="mx-2"
                   name="calculationType"
                   [checked]="!flight.isAhmCalc"
                   (click)="changeAhmCalc(false)"
                   tabindex="2">
            <label class="m-0"
                   for="calculate-by-manual"
                   i18n="sch|Flight card - buttons select calculation@@sch.flightCard.button.selectCalculation.manual">Manual</label>
            <input id="calculate-by-ahm"
                   type="radio"
                   class="mx-2"
                   name="calculationType"
                   [disabled]="!calculationByAhm"
                   [checked]="flight.isAhmCalc"
                   (click)="changeAhmCalc(true)"
                   tabindex="2">
            <label class="m-0"
                   for="calculate-by-ahm"
                   [ngClass]="{'text-hight-gray': !calculationByAhm}"
                   [ngbTooltip]="!calculationByAhm ? ahmDetailContent : ''"
                   i18n="sch|Flight card - buttons select calculation@@sch.flightCard.button.selectCalculation.ahm">
              By AHM 560/565</label>
          </div>
          <div>
            <button type="button"
                    class="btn btn-sm btn-blue"
                    *ngIf="!flight.id"
                    (click)="addFlight()"
                    tabindex="2">
              <span i18n="sch|Flight card - button append@@sch.flightCard.button.append">Append</span>
            </button>
            <button type="button"
                    class="btn btn-sm btn-blue"
                    *ngIf="flight.id"
                    (click)="saveFlight()"
                    tabindex="2">
              <span i18n="sch|Flight card - button save@@sch.flightCard.button.save">Save</span>
            </button>
            <button type="button"
                    class="btn btn-sm btn-gray-blue-dark ml-2"
                    data-dismiss="modal"
                    tabindex="2">
              <span i18n="sch|Flight card - button close@@sch.flightCard.button.close">Close</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<div *ngIf="error.errorMessage" class="ms-modal-message">
  <div class="modal-content w-50">
    <div class="modal-header" [ngClass]="{'bg-info': error.errorType == 'info',
                                    'bg-warning': error.errorType == 'warning',
                                    'bg-danger': error.errorType == 'error'}">
      <h5 *ngIf="error.errorType == 'info'" class="modal-title"
        i18n="cmn|Modal message error - header information@@cmd.main.modalMessage.errMes.header.inf">
        Information
      </h5>
      <h5 *ngIf="error.errorType == 'warning'" class="modal-title"
        i18n="cmn|Modal message error - header watning@@cmd.main.modalMessage.errMes.header.warn">
        Warning
      </h5>
      <h5 *ngIf="error.errorType == 'error'" class="modal-title"
        i18n="cmn|Modal message error - header error@@cmd.main.modalMessage.errMes.header.err">
        Error
      </h5>
      <button type="button" class="close" (click)="clearErrorMess()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="white-space: pre-line">
      {{error.errorMessage}}
    </div>
    <div class="modal-footer">
      <button type="button" *ngIf="modalType==='setAnswer'" class="btn btn-sm btn-blue" (click)="userAnswer = true"
        i18n="cmn|Modal message error - button yes@@cmd.main.modalMessage.errMes.button.yes">
        Yes
      </button>
      <button type="button" *ngIf="modalType==='setAnswer'" class="btn btn-sm btn-gray-blue-dark"
        (click)="userAnswer = false" i18n="cmn|Modal message error - button no@@cmd.main.modalMessage.errMes.button.no">
        No
      </button>
      <button type="button" *ngIf="modalType!=='setAnswer'" class="btn btn-sm btn-gray-blue-dark"
        (click)="error.errorMessage = null"
        i18n="cmn|Modal create message error - button close@@cmn.modalCreateMessage.buttons.close">
        Close
      </button>
    </div>
  </div>
</div>
