<ng-container *ngIf="viewParametrs.activeWindow == 'statementsList'">
  <div class="row border-bottom py-2 justify-content-between font-small">
    <div class="col-auto">
      <button class="btn btn-sm btn-blue mr-2"
              data-toggle="modal"
              data-target=".statement-card"
              (click)="createStatement();"
              i18n="laf|Main - control panel@@laf.main.controlPanel.button.baggageStatement">
        Baggage statement
      </button>
      <button class="btn btn-sm btn-blue mr-2"
              i18n="laf|Main - control panel@@laf.main.controlPanel.button.statementOnFind"
              disabled>Statement on the find</button>
      <!-- <button class="btn btn-sm btn-blue"
              data-toggle="modal"
              data-target=".statement-card"
              [disabled]="!statement.id"
              i18n="laf|Main - control panel@@laf.main.controlPanel.button.edit">
        Edit
      </button> -->

      <div class="btn-group">
        <button class="btn btn-light-gray btn-sm"
                type="button"
                (click)="manualSearch(); loadManualStatements();"
                i18n="laf|Main - control panel@@laf.main.controlPanel.button.manualSearche">
          Manual search
        </button>
        <!-- <button type="button"
                class="btn btn-xxs btn-light-gray dropdown-toggle dropdown-toggle-split"
                data-toggle="dropdown"
                aria-expanded="false"
                data-reference="parent">
          <span class="sr-only">Toggle Dropdown</span>
        </button>
        <div class="dropdown-menu">
          <button class="dropdown-item" type="button">Action</button>
          <button class="dropdown-item" type="button">Another action</button>
          <button class="dropdown-item" type="button">Something else here</button>
        </div> -->
      </div>
    </div>
    <div class="col d-flex flex-row-reverse">
      <!-- <button class="btn btn-ico btn-light-gray btn-xs mr-2"><span class="material-icons">filter_none</span></button> -->
      <div class="dropdown">
        <button class="btn btn-xs btn-ico btn-light-gray dropdown-toggle"
                ngbTooltip="Filter by flag"
                i18n-ngbTooltip="laf|Main - control panel@@laf.main.controlPanel.button.filterByFlag"
                type="button"
                id="dropdownFlags"
                data-toggle="dropdown">
          <i class="material-icons" [ngStyle]="{'color': filter.params.mark }">assistant_photo</i>
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownFlags">
          <button *ngFor="let mark of marks"
                  class="dropdown-item"
                  (click)="filter.params.mark = mark; loadStatements()">
            <i class="material-icons" [ngStyle]="{'color': mark }">assistant_photo</i>
            {{mark}}
          </button>
          <div class="dropdown-divider"></div>
          <button class="dropdown-item font-small"
                  (click)="filter.params.mark = null; loadStatements()">
            <i class="material-icons" style="color: #5c5c5c;">assistant_photo</i>
            <span i18n="laf|Main - control panel@@laf.main.controlPanel.button.clearFilter">Clear filter</span>
          </button>
        </div>
      </div>
      <!-- Показываются все заявления, существуте только Архив -->
      <!-- <div class="input-group mr-2">
        <div class="input-group-prepend">
          <span class="input-group-text font-small"
                i18n="laf|Main - control panel@@laf.main.controlPanel.displayPeriod">Displayed period</span>
        </div>
        <input type="text"
              disabled=""
              class="form-control form-control-sm"
              value="Current (-24h)"
              i18n-value="laf|Main - control panel@@laf.main.controlPanel.current24">
      </div> -->
    </div>
    <div class="col-5 pl-0">
      <div class="d-flex w-100">
        <div class="filter-block-wrap mr-2 w-100" (click)="filter.show = !filter.show">
          <div class="input-group-prepend">
            <span class="input-group-text border-0 rounded-0 py-1"
                  i18n="laf|Main - control panel@@laf.main.controlPanel.find">Find</span>
            <div class="filter-elements d-flex pl-2 text-nowrap flex-wrap">
              <div class="filter-element mr-2 my-1" *ngIf="filter.params.start">
                <i class="material-icons mx-1" (click)="clearFilterParametr('start', $event)">close</i>
                <span i18n="laf|Filter@@laf.main.filter.from">From:</span>&nbsp;
                {{ filter.params.start | date: 'dd.MM.yyyy HH:mm' }}
              </div>
              <div class="filter-element mr-2 my-1" *ngIf="filter.params.finish">
                <i class="material-icons mx-1" (click)="clearFilterParametr('finish', $event)">close</i>
                <span i18n="laf|Filter@@laf.main.filter.to">To:</span>&nbsp;
                {{ filter.params.finish | date: 'dd.MM.yyyy HH:mm' }}
              </div>
              <div class="filter-element mr-2 my-1" *ngIf="filter.params.status!=null">
                <i class="material-icons ml-2" (click)="clearFilterParametr('status', $event)">close</i>
                <span i18n="laf|Filter@@laf.main.filter.status">Satus:</span>&nbsp;
                {{ getById(baggage_statuses, filter.params.status)?.nameLocal }}
              </div>
              <div class="filter-element mr-2 my-1" *ngIf="filter.params.airport?.length > 0">
                <i class="material-icons ml-2" (click)="clearFilterParametr('airport', $event)">close</i>
                <span i18n="laf|Filter@@laf.main.filter.airport">Airports:</span>&nbsp;
                <span *ngFor="let airport of filter.params.airport">
                  {{ getById(references.airports, airport)?.iata }}
                </span>
              </div>
              <div class="filter-element mr-2 my-1" *ngIf="filter.params.name?.length > 0">
                <i class="material-icons ml-2" (click)="clearFilterParametr('name', $event)">close</i>
                <span i18n="laf|Filter@@laf.main.filter.claimNumber">Claim number:</span>&nbsp;
                <span *ngFor="let name of filter.params.name">{{ name }}</span>
              </div>
              <div class="filter-element mr-2 my-1" *ngIf="filter.params.tag?.length > 0">
                <i class="material-icons ml-2" (click)="clearFilterParametr('tag', $event)">close</i>
                <span i18n="laf|Filter@@laf.main.filter.baggageTag">Baggage tag:</span>&nbsp;
                <span *ngFor="let tag of filter.params.tag">{{ tag }}</span>
              </div>
              <div class="filter-element mr-2 my-1" *ngIf="filter.params.passenger!=null">
                <i class="material-icons ml-2" (click)="clearFilterParametr('passenger', $event)">close</i>
                <span i18n="laf|Filter@@laf.main.filter.passenger">Passenger:</span>&nbsp;
                {{ filter.params.passenger }}
              </div>
              <div class="filter-element mr-2 my-1" *ngIf="filter.params.pnr!=null">
                <i class="material-icons ml-2" (click)="clearFilterParametr('pnr', $event)">close</i>
                <span i18n="laf|Filter@@laf.main.filter.pnr">Pnr:</span>&nbsp;
                {{ filter.params.pnr }}
              </div>
              <div class="filter-element mr-2 my-1" *ngIf="filter.params.baggageType?.length > 0">
                <i class="material-icons ml-2" (click)="clearFilterParametr('baggageType', $event)">close</i>
                <span i18n="laf|Filter@@laf.main.filter.type">Type:</span>&nbsp;
                <span [ngClass]="{'del': filter.params.baggageTypeFlag == '!' }" *ngFor="let type of filter.params.baggageType">
                  {{ getById(references.baggage_types, type)?.code }}
                </span>
              </div>
              <div class="filter-element mr-2 my-1" *ngIf="filter.params.baggageColor?.length > 0">
                <i class="material-icons ml-2" (click)="clearFilterParametr('baggageColor', $event)">close</i>
                <span i18n="laf|Filter@@laf.main.filter.color">Color:</span>&nbsp;
                <span [ngClass]="{'del': filter.params.baggageColorFlag == '!' }" *ngFor="let color of filter.params.baggageColor">
                  {{ getById(references.baggage_colors, color)?.code }}
                </span>
              </div>
              <div class="filter-element mr-2 my-1" *ngIf="filter.params.baggageMaterial?.length > 0">
                <i class="material-icons ml-2" (click)="clearFilterParametr('baggageMaterial', $event)">close</i>
                <span i18n="laf|Filter@@laf.main.filter.material">Material:</span>&nbsp;
                <span [ngClass]="{'del': filter.params.baggageMaterialFlag == '!' }" *ngFor="let material of filter.params.baggageMaterial">
                  {{ getById(references.baggage_materials, material)?.code }}
                </span>
              </div>
              <div class="filter-element mr-2 my-1" *ngIf="filter.params.baggageExternalElements?.length > 0">
                <i class="material-icons ml-2" (click)="clearFilterParametr('baggageExternalElements', $event)">close</i>
                <span i18n="laf|Filter@@laf.main.filter.externalElements">External Elements:</span>&nbsp;
                <span [ngClass]="{'del': filter.params.baggageExternalElementsFlag == '!' }" *ngFor="let elem of filter.params.baggageExternalElements">
                  {{ getById(references.baggage_elements, elem)?.code }}
                </span>
              </div>
              <div class="filter-element mr-2 my-1" *ngIf="filter.params.airline?.length > 0">
                <i class="material-icons ml-2" (click)="clearFilterParametr('airline', $event)">close</i>
                <span i18n="laf|Filter@@laf.main.filter.airline">Airline:</span>&nbsp;
                <span *ngFor="let airline of filter.params.airline">
                  {{ getById(references.airlines, airline)?.iata }}
                </span>
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center text-dark-gray">
            <i class="material-icons mx-1">arrow_drop_down</i>
          </div>
        </div>
        <!-- Filter -->
        <div class="filter-block border p-2 bg-white font-small" *ngIf="filter.show">
          <div class="container">
            <!-- <div class="row">
              <div class="col-3 px-1">
                <label for="filter-flight"
                      class="mr-2 mb-0"
                      i18n="laf|Filter@@laf.main.filter.filters">
                  Filters
                </label>
              </div>
              <div class="col-8 px-1">
                <ng-select class="custom-sm" name="filter-name" [items]="[]"></ng-select>
              </div>
              <div class="col-1 px-1">
                <button class="btn btn-h-25 btn-ico btn-light-gray">
                  <i class="material-icons">delete</i>
                </button>
              </div>
            </div>
            <hr /> -->
            <div class="row pb-2">
              <div class="col-3 px-1">
                <label for="filter-aftn-number"
                      class="mr-2 mb-0"
                      i18n="laf|Filter@@laf.main.filter.field.claimNumber">
                  Claim number
                </label>
              </div>
              <div class="col-9 px-1" *ngIf="!filterLoadAnimation['name']; else filterStatementLoad">
                <!-- <input type="text" id="filter-number"
                      [(ngModel)]="filter.params.name"
                      class="form-control form-control-sm"
                      name="filter-number"/> -->
                <!-- placeholder="WorldTracer number or other"
                i18n-placeholder="laf|Filter@@laf.main.filter.field.statementNumber.placeholder" -->
                <ng-select class="custom-sm p-0"
                          [(ngModel)]="filter.params.name"
                          [multiple]="true"
                          [addTag]="addTagClaim"
                          [selectOnTab]="true">
                    <ng-template ng-tag-tmp let-search="searchTerm">
                      <b i18n="laf|Filter@@laf.main.filter.field.baggageTagAdd">Add:</b>&nbsp;{{search}}
                    </ng-template>
                </ng-select>
              </div>
              <ng-template #filterStatementLoad>
                <div class="col-9 px-1">
                  <div class="d-flex align-items-center pl-2 bg-light-gray rounded">
                    <div class="spinner-border spinner-border-sm float-left"
                        role="status"
                        aria-hidden="true"></div>
                    <div class="float-left pl-2"
                        i18n="laf|Filter@@laf.main.filter.field.statementNumberMessage">
                      Parsing an claim string...
                    </div>
                  </div>
                </div>
              </ng-template>
            </div>
            <div class="row pb-2">
              <div class="col-3 px-1">
                <label for="filter-flight"
                      class="mr-2 mb-0"
                      i18n="laf|Filter@@laf.main.filter.field.baggageTag">
                  Baggage tag
                </label>
              </div>
              <div class="col-9 px-1" *ngIf="!filterLoadAnimation['tag']; else filterBaggageTagLoad">
                <ng-select class="custom-sm p-0"
                          [(ngModel)]="filter.params.tag"
                          [multiple]="true"
                          [addTag]="addTagBaggageTag"
                          [selectOnTab]="true">
                    <ng-template ng-tag-tmp let-search="searchTerm">
                      <b i18n="laf|Filter@@laf.main.filter.field.baggageTagAdd">Add:</b>&nbsp;{{search}}
                    </ng-template>
                </ng-select>
                <!-- <input type="text" id="filter-tag"
                      [(ngModel)]="filter.params.tag"
                      class="form-control form-control-sm"
                      name="filter-tag"/> -->
                  <!-- <ng-select #filterBaggageTagInputCatch
                            class="custom-sm p-0"
                            [multiple]="true"
                            [isOpen]= "false"
                            placeholder="Baggage tag numbers separated by a space"
                            i18n-placeholder="laf|Filter@@laf.main.filter.field.baggageTag.placeholder">
                    <ng-template ng-tag-tmp let-search="searchTerm">
                      <b i18n="laf|Filter@@laf.main.filter.field.baggageTagAdd">Add:</b>&nbsp;{{search}}
                    </ng-template>
                  </ng-select> -->
              </div>
              <ng-template #filterBaggageTagLoad>
                <div class="col-9 px-1">
                  <div class="d-flex align-items-center pl-2 bg-light-gray rounded">
                    <div class="spinner-border spinner-border-sm float-left"
                        role="status"
                        aria-hidden="true"></div>
                    <div class="float-left pl-2"
                        i18n="laf|Filter@@laf.main.filter.field.baggageTagMessage">
                      Parsing an baggage tag numbers string...
                    </div>
                  </div>
                </div>
              </ng-template>
            </div>
            <div class="row pb-2">
              <div class="col-3 px-1">
                <label for="filter-passenger-name"
                      class="mr-2 mb-0"
                      i18n="laf|Filter@@laf.main.filter.field.passengerName">
                  Passenger name
                </label>
              </div>
              <div class="col-9 px-1">
                <input type="text" id="filter-passenger-name"
                      [(ngModel)]="filter.params.passenger"
                      class="form-control form-control-sm"
                      name="filter-passenger-name"
                      appUpperCase />
              </div>
            </div>
            <div class="row pb-2">
              <div class="col-3 px-1">
                <label for="filter-pnr"
                      class="mr-2 mb-0"
                      i18n="laf|Filter@@laf.main.filter.field.pnr">
                  PNR
                </label>
              </div>
              <div class="col-9 px-1">
                <input id="filter-pnr"
                      class="form-control form-control-sm"
                      [(ngModel)]="filter.params.pnr"
                      name="filter-pnr"
                      appUpperCase/>
              </div>
            </div>
            <div class="row pb-2">
              <div class="col-3 px-1">
                <label for="filter-pnr"
                      class="mr-2 mb-0"
                      i18n="laf|Filter@@laf.main.filter.field.airports">
                  Airports
                </label>
              </div>
              <div class="col-9 px-1">
                <ng-select class="w-100 custom-sm p-0"
                          [items]="references.airports"
                          [multiple]="true"
                          bindLabel="iata"
                          bindValue="id"
                          [virtualScroll]="true"
                          [loading]="selectLoadAnimation.airports"
                          [(ngModel)]="filter.params.airport"
                          (scroll)="onScrollNgSelect($event, 'airports')"
                          (scrollToEnd)="onScrollToEndNgSelect('airports')"
                          [searchFn]="customSelectSearch"
                          [selectOnTab]="true">
                  <ng-template ng-option-tmp let-item="item">
                    <div class="font-small">
                      <span class="font-weight-bold">{{item.iata}}</span>&nbsp;{{item.name}}
                    </div>
                  </ng-template>
                </ng-select>
              </div>
            </div>
            <!-- <div class="row pb-2">
              <div class="col-3 px-1">
                <label for="filter-pnr"
                      class="mr-2 mb-0"
                      i18n="laf|Filter@@laf.main.filter.field.station">
                  Station
                </label>
              </div>
              <div class="col-9 px-1">
                <ng-select class="w-100 custom-sm p-0"
                          [items]="references.airports"
                          [multiple]="true"
                          bindLabel="iata"
                          bindValue="id"
                          [virtualScroll]="true"
                          [loading]="selectLoadAnimation.airports"
                          [(ngModel)]="filter.params.airports"
                          (scroll)="onScrollNgSelect($event, 'airports')"
                          (scrollToEnd)="onScrollToEndNgSelect('airports')"
                          [searchFn]="customSelectSearch">
                  <ng-template ng-option-tmp let-item="item">
                    <div class="font-small">
                      <span class="font-weight-bold">{{item.iata}}</span>&nbsp;{{item.name}}
                    </div>
                  </ng-template>
                </ng-select>
              </div>
            </div> -->
            <!-- <div class="row pb-2">
              <div class="col-3 px-1">
                <label class="mr-2 mb-0"
                      i18n="laf|Filter@@laf.main.filter.field.terminal">Terminal
                </label>
              </div>
              <div class="col-3 px-1">
                <select class="form-control form-control-sm py-0">
                  <option value=""
                          i18n="laf|Filter@@laf.main.filter.field.terminalContain">
                    Contain
                  </option>
                  <option value="!"
                          i18n="laf|Filter@@laf.main.filter.field.terminalNotContain">
                    Not contain
                  </option>
                </select>
              </div>
              <div class="col-6 px-1">
                <input class="form-control form-control-sm"/>
              </div>
            </div> -->
            <div class="row pb-2">
              <div class="col-3 px-1">
                <label class="mr-2 mb-0"
                      i18n="laf|Filter@@laf.main.filter.field.baggageType">Type
                </label>
              </div>
              <div class="col-3 px-1">
                <select class="form-control form-control-sm py-0" [(ngModel)]="filter.params.baggageTypeFlag">
                  <option value="" i18n="laf|Filter@@laf.main.filter.field.baggageTypeContain">
                    Contain
                  </option>
                  <option value="!" i18n="laf|Filter@@laf.main.filter.field.baggageTypeNotContain">
                    Not contain
                  </option>
                </select>
              </div>
              <div class="col-6 px-1">
                <ng-select class="w-100 custom-sm p-0"
                          bindLabel="code"
                          bindValue="id"
                          [multiple]="true"
                          [(ngModel)]="filter.params.baggageType"
                          [loading]="selectLoadAnimation.baggage_types"
                          [items]="references.baggage_types"
                          [searchFn]="customSelectSearch"
                          [selectOnTab]="true">
                  <ng-template ng-option-tmp let-item="item">
                    <div class="font-small">
                      <span class="font-weight-bold">{{item.code}}</span>&nbsp;{{item.nameEnglish}}
                    </div>
                  </ng-template>
                </ng-select>
              </div>
            </div>
            <div class="row pb-2">
              <div class="col-3 px-1">
                <label class="mr-2 mb-0" i18n="laf|Filter@@laf.main.filter.field.baggageColor">Color
                </label>
              </div>
              <div class="col-3 px-1">
                <select class="form-control form-control-sm py-0" [(ngModel)]="filter.params.baggageColorFlag">
                  <option value="" i18n="laf|Filter@@laf.main.filter.field.baggageColorContain">
                    Contain
                  </option>
                  <option value="!" i18n="laf|Filter@@laf.main.filter.field.baggageColorNotContain">
                    Not contain
                  </option>
                </select>
              </div>
              <div class="col-6 px-1">
                <ng-select class="w-100 custom-sm p-0"
                          [multiple]="true"
                          bindLabel="code"
                          bindValue="id"
                          [(ngModel)]="filter.params.baggageColor"
                          [loading]="selectLoadAnimation.baggage_colors"
                          [items]="references.baggage_colors"
                          [searchFn]="customSelectSearch"
                          [selectOnTab]="true">
                  <ng-template ng-option-tmp let-item="item">
                    <div class="font-small">
                      <span class="font-weight-bold">{{item.code}}</span>&nbsp;{{item.nameEnglish}}
                    </div>
                  </ng-template>
                </ng-select>
              </div>
            </div>
            <div class="row pb-2">
              <div class="col-3 px-1">
                <label class="mr-2 mb-0" i18n="laf|Filter@@laf.main.filter.field.baggageMaterial">Material
                </label>
              </div>
              <div class="col-3 px-1">
                <select class="form-control form-control-sm py-0" [(ngModel)]="filter.params.baggageMaterialFlag">
                  <option value="" i18n="laf|Filter@@laf.main.filter.field.baggageMaterialContain">
                    Contain
                  </option>
                  <option value="!" i18n="laf|Filter@@laf.main.filter.field.baggageMaterialNotContain">
                    Not contain
                  </option>
                </select>
              </div>
              <div class="col-6 px-1">
                <ng-select class="w-100 custom-sm p-0"
                          bindLabel="code"
                          bindValue="id"
                          [multiple]="true"
                          [(ngModel)]="filter.params.baggageMaterial"
                          [loading]="selectLoadAnimation.baggage_materials"
                          [items]="references.baggage_materials"
                          [searchFn]="customSelectSearch"
                          [selectOnTab]="true">
                  <ng-template ng-option-tmp let-item="item">
                    <div class="font-small">
                      <span class="font-weight-bold">{{item.code}}</span>&nbsp;{{item.nameEnglish}}
                    </div>
                  </ng-template>
                </ng-select>
              </div>
            </div>
            <div class="row pb-2">
              <div class="col-3 px-1">
                <label class="mr-2 mb-0"
                      i18n="laf|Filter@@laf.main.filter.field.baggageExternalElements">External elements
                </label>
              </div>
              <div class="col-3 px-1">
                <select class="form-control form-control-sm py-0" [(ngModel)]="filter.params.baggageExternalElementsFlag">
                  <option value="" i18n="laf|Filter@@laf.main.filter.field.baggageExternalElementsContain">
                    Contain
                  </option>
                  <option value="!" i18n="laf|Filter@@laf.main.filter.field.baggageExternalElementsNotContain">
                    Not contain
                  </option>
                </select>
              </div>
              <div class="col-6 px-1">
                <ng-select class="w-100 custom-sm p-0"
                          bindLabel="code"
                          bindValue="id"
                          [multiple]="true"
                          [(ngModel)]="filter.params.baggageExternalElements"
                          [loading]="selectLoadAnimation.baggage_elements"
                          [items]="references.baggage_elements"
                          [searchFn]="customSelectSearch"
                          [selectOnTab]="true">
                  <ng-template ng-option-tmp let-item="item">
                    <div class="font-small">
                      <span class="font-weight-bold">{{item.code}}</span>&nbsp;{{item.nameEnglish}}
                    </div>
                  </ng-template>
                </ng-select>
              </div>
            </div>
            <div class="row pb-2">
              <div class="col-3 px-1">
                <label for="filter-pnr"
                      class="mr-2 mb-0"
                      i18n="laf|Filter@@laf.main.filter.field.airline">
                  Airline
                </label>
              </div>
              <div class="col-9 px-1">
                <ng-select class="w-100 custom-sm p-0"
                          [items]="references.airlines"
                          [multiple]="true"
                          bindLabel="iata"
                          bindValue="id"
                          [virtualScroll]="true"
                          [(ngModel)]="filter.params.airline"
                          [loading]="selectLoadAnimation.airlines"
                          (scroll)="onScrollNgSelect($event, 'airlines')"
                          (scrollToEnd)="onScrollToEndNgSelect('airlines')"
                          [searchFn]="customSelectSearch"
                          [selectOnTab]="true">
                  <ng-template ng-option-tmp let-item="item">
                    <div class="font-small">
                      <span class="font-weight-bold">{{item.iata}}</span>&nbsp;{{item.name}}
                    </div>
                  </ng-template>
                </ng-select>
              </div>
            </div>
            <!-- <div class="row pb-2">
              <div class="col-3 px-1">
                <label for="filter-recipient"
                      class="mr-2 mb-0"
                      i18n="laf|Filter@@laf.main.filter.field.route">
                  Route
                </label>
              </div>
              <div class="col-3 px-1">
                <select id="filter-route-eq"
                        class="form-control form-control-sm mr-2 py-0"
                        name="filter-route-eq">
                  <option value=""
                          i18n="laf|Filter@@laf.main.filter.field.routeContain">
                    Contain
                  </option>
                  <option value="!"
                          i18n="laf|Filter@@laf.main.filter.field.routeNotContain">
                    Not contain
                  </option>
                </select>
              </div>
              <div class="col-6 px-1">
                <ng-select class="w-100 custom-sm p-0"
                          [items]="references.airports"
                          [multiple]="true"
                          bindLabel="iata"
                          bindValue="id"
                          [virtualScroll]="true"
                          [loading]="selectLoadAnimation.airports"
                          (scroll)="onScrollNgSelect($event, 'airports')"
                          (scrollToEnd)="onScrollToEndNgSelect('airports')"
                          [searchFn]="customSelectSearch">
                  <ng-template ng-option-tmp let-item="item">
                    <div class="font-small">
                      <span class="font-weight-bold">{{item.iata}}</span>&nbsp;{{item.name}}
                    </div>
                  </ng-template>
                </ng-select>
              </div>

              <div class="col-6 px-1" *ngIf="1; else filterRouteLoad">
                <ng-select class="custom-sm p-0"
                          [multiple]="true"
                          [isOpen]="false"
                          placeholder="Enter airport separated by a space"
                          i18n-placeholder="laf|Filter@@laf.main.filter.field.route.placeholder">
                  <ng-template ng-tag-tmp let-search="searchTerm">
                    <b i18n="laf|Filter@@laf.main.filter.field.routeAdd">Add:</b>&nbsp;
                  </ng-template>
                </ng-select>
              </div>
              <ng-template #filterRouteLoad>
                <div class="col-6 px-1">
                  <div class="d-flex align-items-center pl-2 bg-light-gray rounded">
                    <div class="spinner-border spinner-border-sm float-left"
                        role="status"
                        aria-hidden="true"></div>
                    <div class="float-left pl-2"
                        i18n="laf|Filter@@laf.main.filter.field.routeMessage">
                      Parsing an route string...
                    </div>
                  </div>
                </div>
              </ng-template>
            </div> -->
            <!-- <div class="row pb-2">
              <div class="col-3 px-1">
                <label for="filter-flight"
                      class="mr-2 mb-0"
                      i18n="laf|Filter@@laf.main.filter.field.flightNumber">
                  Flight number
                </label>
              </div>
              <div class="col-9 px-1" *ngIf="1; else filterFlightNumberLoad">
                  <ng-select #filterFlightNumberInputCatch
                            class="custom-sm p-0"
                            [multiple]="true"
                            [isOpen]= "false"
                            placeholder="Enter flight numbers separated by a space"
                            i18n-placeholder="laf|Filter@@laf.main.filter.field.flightNumber.placeholder">
                    <ng-template ng-tag-tmp let-search="searchTerm">
                      <b i18n="laf|Filter@@laf.main.filter.field.flightNumberAdd">Add:</b>&nbsp;{{search}}
                    </ng-template>
                  </ng-select>
              </div>
              <ng-template #filterFlightNumberLoad>
                <div class="col-9 px-1">
                  <div class="d-flex align-items-center pl-2 bg-light-gray rounded">
                    <div class="spinner-border spinner-border-sm float-left"
                        role="status"
                        aria-hidden="true"></div>
                    <div class="float-left pl-2"
                        i18n="laf|Filter@@laf.main.filter.field.flightNumberMessage">
                      Parsing an flight numbers string...
                    </div>
                  </div>
                </div>
              </ng-template>
            </div> -->
            <div class="row pb-2">
              <div class="col-3 px-1">
                <label for="filter-status-eq"
                      class="mr-2 mb-0"
                      i18n="laf|Filter@@laf.main.filter.field.status">
                  Status
                </label>
              </div>
              <div class="col-3 px-1">
                <select id="filter-status-eq"
                        class="form-control form-control-sm mr-2 py-0"
                        name="filter-status-eq"
                        [(ngModel)]="filter.params.statusFlag">
                  <option value="" i18n="laf|Filter@@laf.main.filter.field.statusEqual">
                    Equal
                  </option>
                  <option value="!" i18n="laf|Filter@@laf.main.filter.field.statusNotEqual">
                    Not equal
                  </option>
                </select>
              </div>
              <div class="col-6 px-1">
                <ng-select class="custom-sm p-0"
                          [virtualScroll]="true"
                          [hideSelected]="true"
                          [(ngModel)]="filter.params.status"
                          bindLabel="nameLocal"
                          bindValue="id"
                          [items]="references.baggage_statuses">
                </ng-select>
              </div>
            </div>
            <!-- <div class="row pb-2">
              <div class="col-3 px-1">
                <label for="filter-reason-eq"
                      class="mr-2 mb-0"
                      i18n="laf|Filter@@laf.main.filter.field.defect">
                  Defect
                </label>
              </div>
              <div class="col-3 px-1">
                <select id="filter-reason-eq"
                        class="form-control form-control-sm mr-2 py-0"
                        name="filter-reason-eq">
                  <option value=""
                          i18n="laf|Filter@@laf.main.filter.field.reasonEqual">
                    Equal
                  </option>
                  <option value="!"
                          i18n="laf|Filter@@laf.main.filter.field.reasonNotEqual">
                    Not equal
                  </option>
                </select>
              </div>
              <div class="col-6 px-1">
                <ng-select class="w-100 custom-sm bg-white"
                            bindValue="id"
                            [multiple]="true"
                            bindLabel="nameLocal"
                            [(ngModel)]="filterParams.statements"
                            [loading]="selectLoadAnimation.statement_types"
                            [items]="references.statement_types">
                </ng-select>
                <ng-select class="custom-sm p-0"
                          [virtualScroll]="true"
                          [multiple]="true"
                          [hideSelected]="true">
                  <ng-template ng-option-tmp let-item="item">
                    -
                  </ng-template>
                </ng-select>
              </div>
            </div> -->
            <hr />
            <div class="row pb-2">
              <div class="col-3 px-1">
                <label for="filter-date-from"
                        class="mr-2 mb-0"
                        i18n="laf|Filter@@laf.main.filter.field.dateFrom">
                  Date from
                </label>
              </div>
              <div class="col-3 px-1">
                <input type="time"
                       class="form-control"
                       [ngModel]="filter.params.start | date: 'HH:mm'"
                       (blur)="filter.params.start = parseDate(filterStartDate.value, $event.target.value)"
                       name="filterStartTime"
                       #filterStartTime>
              </div>
              <div class="col-6 px-1">
                <input type="date" class="form-control custom-sm"
                      [ngModel]="filter.params.start | date: 'yyyy-MM-dd'"
                      (blur)="filter.params.start = parseDate($event.target.value, filterStartTime.value || '00:00')"
                      name="filterStartDate" #filterStartDate>
              </div>
            </div>
            <div class="row pb-2">
              <div class="col-3 px-1">
                <label for="filter-date-from"
                        class="mr-2 mb-0"
                        i18n="laf|Filter@@laf.main.filter.field.dateTo">
                  Date to
                </label>
              </div>
              <div class="col-3 px-1">
                <input type="time"
                       class="form-control"
                       [ngModel]="filter.params.finish | date: 'HH:mm'"
                       (blur)="filter.params.finish = parseDate(filterFinishDate.value, $event.target.value)"
                       name="filterFinishTime" #filterFinishTime>
              </div>
              <div class="col-6 px-1">
                <input type="date" class="form-control custom-sm"
                      [ngModel]="filter.params.finish | date: 'yyyy-MM-dd'"
                      (blur)="filter.params.finish = parseDate($event.target.value, filterFinishTime.value || '00:00')"
                      name="filterFinishDate" #filterFinishDate>
              </div>
            </div>
          </div>
          <hr />
          <button class="btn btn-gray-blue-dark btn-sm float-right ml-2"
                  (click)="filter.show = !filter.show"
                  i18n="laf|Filter@@laf.main.filter.button.close">
            Close
          </button>
          <button class="btn btn-blue btn-sm float-right ml-2"
                  (click)="filterApp()"
                  i18n="laf|Filter@@laf.main.filter.button.fing">
            Find
          </button>
          <button class="btn btn-blue btn-sm float-right"
                  (click)="clearFilter()"
                  i18n="laf|Filter@@laf.main.filter.button.clearAll">
            Clear all
          </button>
        </div>
        <button type="button"
                class="btn btn-xs btn-ico btn-light-gray mr-2"
                ngbTooltip="Filter on/off"
                i18n-ngbTooltip="laf|Filter@@laf.main.filter.button.filterOnOff"
                [ngClass]="{'btn-active' : filter.apply}"
                (click)="filterSwitch()">
          <i class="material-icons">filter_alt</i>
        </button>
        <!-- <button class="btn btn-ico btn-light-gray btn-xs"><span class="material-icons">library_books</span></button> -->
        <button type="button"
                class="btn btn-xs btn-ico btn-light-gray mr-2"
                ngbTooltip="View archive"
                placement="left"
                i18n-ngbTooltip="laf|Filter@@laf.main.filter.button.viewArchive"
                [ngClass]="{'btn-active' : filter.params.archived}"
                (click)="filterArchive()">
          <i class="material-icons">library_books</i>
        </button>
      </div>
    </div>
  </div>

  <div class="d-flex content" style="margin-left: -15px; margin-right: -15px;">
      <div class="bg-light-gray border-right folders-block h-100 pb-5 col-auto px-0">
        <div class="d-flex align-items-center pointer p-2 header-group">
          <i class="material-icons arrow-menu" data-toggle="collapse"
            data-target="#tracingLuggageMenu">keyboard_arrow_down</i>
          <i class="material-icons mr-2 text-gray-blue-dark">work</i>
          <span class="mr-3" i18n="laf|Left panel@@laf.main.leftPanel.baggageSearch">Baggage search</span>
        </div>
        <div class="collapse multi-collapse show" id="tracingLuggageMenu">
          <div class="mp-0 left-menu">
            <div class="pointer pl-4 ty-1 py-2">
                <div class="input-group-prepend d-flex justify-content-between">
                  <label class="w-100 m-0" for="statementsAll" i18n="laf|Left panel@@laf.main.leftPanel.claimAll">
                    Claim | All
                  </label>
                  <div class="custom-control custom-switch"
                      ngbTooltip="Show all"
                      i18n-ngbTooltip="laf|Filter@@laf.main.filter.button.showAll">
                    <input type="checkbox"
                           class="custom-control-input"
                           id="statementsAll"
                           name="statementsAll"
                           [(ngModel)]="filterParams.statementsAll"
                           [checked]="filterParams.statementsAll"
                           (change)="loadStatements()">
                    <label class="custom-control-label" for="statementsAll"></label>
                  </div>
                </div>
            </div>
          </div>
        </div>
        <div class="collapse multi-collapse show" id="tracingLuggageMenu">
          <div *ngFor="let statement of references.statement_types" class="pl-5 ty-1 mt-1 mb-1 pr-2 m-0">
            <label class="m-0 d-flex align-items-center pointer" (click)="toggleDisabledItem(filterParams.statements, statement.id)">
              <input type="checkbox"
                     class="d-hover mr-2"
                     (click)="toggleItem(filterParams.statements, statement.id)"
                     [checked]="filterParams.statements.includes(statement.id) || filterParams.statementsAll"
                     [disabled]="filterParams.statementsAll">
              <span class="mr-3 mb-0">{{ this.globalSettings.language === 'en' ? statement.nameEnglish : statement.nameLocal }}</span>
              <!-- <span class="badge badge-dark badge-pill ml-auto">{{ statement.id }}</span> -->
            </label>
          </div>
        </div>
        <div class="collapse multi-collapse show" id="tracingLuggageMenu">
          <div class="mp-0 left-menu">
            <div class="pointer pl-4 ty-1 py-2">
                <div class="input-group-prepend d-flex justify-content-between">
                  <label class="w-100 m-0" for="timesAll" i18n="laf|Left panel@@laf.main.leftPanel.searchExpiration">
                    Search expiration
                  </label>
                </div>
            </div>
          </div>
        </div>
        <div class="collapse multi-collapse show" id="tracingLuggageMenu">
          <div *ngFor="let time of localReferences.times" class="pl-5 ty-1 mt-1 mb-1 pr-2 m-0">
            <label class="m-0 d-flex align-items-center pointer">
              <input type="checkbox" class="d-hover mr-2" (click)="toggleItem(filterParams.times, time.value); loadStatements()"
                [checked]="filterParams.times.includes(time.value)">
              <span class="mr-3 mb-0">{{ this.globalSettings.language === 'en' ? time.nameEnglish : time.nameLocal }}</span>
              <!-- <span class="badge badge-dark badge-pill ml-auto">2</span> -->
            </label>
          </div>
        </div>
        <div class="collapse multi-collapse show" id="tracingLuggageMenu">
          <div class="mp-0 left-menu">
            <div class="pointer pl-4 ty-1 py-2">
                <div class="input-group-prepend d-flex justify-content-between">
                  <label class="w-100 m-0" for="storageAll" i18n="laf|Left panel@@laf.main.leftPanel.storageExpiration">
                    Storage expiration
                  </label>
                  <!-- <div class="custom-control custom-switch"
                    ngbTooltip="Show all"
                    i18n-ngbTooltip="laf|Filter@@laf.main.filter.button.showAll">
                    <input type="checkbox" class="custom-control-input" id="storageAll"
                        name="storageAll" [(ngModel)]="filterParams.storageAll"
                        [checked]="filterParams.storageAll"
                        (change)="loadStatements()">
                    <label class="custom-control-label" for="storageAll"></label>
                  </div> -->
                </div>
            </div>
          </div>
        </div>
        <div class="collapse multi-collapse show" id="tracingLuggageMenu">
          <div *ngFor="let storage of localReferences.storage" class="pl-5 ty-1 mt-1 mb-1 pr-2 m-0">
            <label class="m-0 d-flex align-items-center pointer">
              <input type="checkbox" class="d-hover mr-2" (click)="toggleItem(filterParams.times, storage.value)"
                [checked]="filterParams.times.includes(storage.value)">
              <span class="mr-3 mb-0">{{ this.globalSettings.language === 'en' ? storage.nameEnglish : storage.nameLocal }}</span>
              <span class="badge badge-dark badge-pill ml-auto"></span>
            </label>
          </div>
        </div>
        <!-- <div class="collapse multi-collapse show" id="tracingLuggageMenu">
          <div *ngFor="let storage of localReferences.storage" class="pl-5 ty-1 mt-1 mb-1 pr-2 m-0">
            <label class="m-0 d-flex align-items-center pointer">
              <input type="checkbox" class="d-hover mr-2" (click)="toggleItem(filterParams.storage, storage.value)"
                [checked]="filterParams.storage.includes(storage.value) || filterParams.storageAll"
                [disabled]="filterParams.storageAll">
              <span class="mr-3 mb-0">{{ this.globalSettings.language === 'en' ? storage.nameEnglish : storage.nameLocal }}</span>
              <span class="badge badge-dark badge-pill ml-auto"></span>
            </label>
          </div>
        </div> -->
        <div class="d-flex align-items-center pointer p-2 header-group">
          <i class="material-icons arrow-menu opacity-0">keyboard_arrow_down</i>
          <i class="material-icons mr-2 text-gray-blue-dark">local_grocery_store</i>
          <span class="mr-3" i18n="laf|Left panel@@laf.main.leftPanel.lostItems">Lost items</span>
          <!-- <span class="badge badge-dark badge-pill ml-auto">7</span> -->
        </div>
      </div>
      <div class="p-0 col h-100 bg-white d-flex flex-column">
        <div class="bg-light-gray border-bottom py-1 pr-3 font-small">
            <div class="d-flex justify-content-end">
              <div class="d-flex flex-nowrap mr-2 align-items-center per-page">
                <div class="text-nowrap line-height-1" i18n="laf|Baggage@@laf.baggage.table.perPage">Per page</div>
                <select id="perPage" class="form-control form-control-sm ml-2 per-page" name="perPage"
                  [ngModel]="pagination.perPage" (change)="changePerPage(+$event.target.value)">
                  <option *ngFor="let count of pagination.countsPerPage" [value]="count">
                    {{count}}
                  </option>
                </select>
              </div>
              <pagination-controls (pageChange)="loadStatements($event)"
                                  maxSize="7"
                                  class="d-flex"
                                  directionLinks="true"
                                  autoHide="true"
                                  previousLabel=""
                                  nextLabel=""
                                  i18n-previousLabel="laf|Baggage@@laf.main.table.pagination.previousLabel"
                                  i18n-nextLabel="laf|Baggage@@laf.main.table.pagination.nextLabel">
              </pagination-controls>
            </div>
        </div>
        <div class="statements-table" [ngClass]="{'bottom-panel-active': statement.id}">
          <div class="loading" *ngIf="viewParametrs.loading">
            <div class="cssload-spin-box"></div>
          </div>
          <table class="table-striped table-border w-100 table-sticky-header font-small">
            <thead class="bg-gray text-center">
              <tr>
                <th width="35px"></th>
                <th width="35px"></th>
                <th i18n="laf|Baggage@@laf.baggage.table.column.airport">Airport</th>
                <th i18n="laf|Baggage@@laf.baggage.table.column.claimNumber">Claim number</th>
                <th i18n="laf|Baggage@@laf.baggage.table.column.dateOfClaim">Date of claim</th>
                <th i18n="laf|Baggage@@laf.baggage.table.column.flight">Flight</th>
                <th i18n="laf|Baggage@@laf.baggage.table.column.route">Route</th>
                <th i18n="laf|Baggage@@laf.baggage.table.column.namePassenger">Name</th>
                <th i18n="laf|Baggage@@laf.baggage.table.column.passengerStatus">Passenger status</th>
                <th i18n="laf|Baggage@@laf.baggage.table.column.baggageTag">Baggage tag</th>
                <th i18n="laf|Baggage@@laf.baggage.table.column.baggageCode">Baggage code</th>
                <th i18n="laf|Baggage@@laf.baggage.table.column.defectType">Defect type</th>
                <th i18n="laf|Baggage@@laf.baggage.table.column.searchState">Search state</th>
                <!-- <th i18n="laf|Baggage@@laf.baggage.table.column.numberAtWt">Nimber at WT</th> -->
                <th i18n="laf|Baggage@@laf.baggage.table.column.agent">Agent</th>
              </tr>
            </thead>
            <tbody>
              <tr class="text-center" *ngFor="let statementItem of statements
                      | paginate: {
                        itemsPerPage: pagination.perPage,
                        currentPage: pagination.pageActive,
                        totalItems: pagination.statementsCount
                      }; let stat_i = index"
                (click)="loadStatement(statementItem.statementId, stat_i);"
                (dblclick)="openStatement()"
                [ngClass]="{'bg-gray': stat_i === statementOfList }">
                <td></td>
                <td>
                  <span *ngIf="statementItem.mark" [ngStyle]="{'color': statementItem.mark }" class="material-icons">flag</span>
                </td>
                <td>{{statementItem.airport}}</td>
                <td>{{statementItem.statementName}}</td>
                <td>{{statementItem.dtInsert | date: 'dd.MM.yyyy'}}</td>
                <td><pre class="m-0 p-0">{{extractFlight(statementItem.arrival.flight)}}</pre></td>
                <td><pre class="m-0 p-0">{{extractRoute(statementItem.arrival.flight)}}</pre></td>
                <td>{{statementItem.passenger}}</td>
                <td>{{statementItem.passengerStatus}}</td>
                <td>{{statementItem.baggage.tag}}</td>
                <td>
                  {{statementItem.color.code || 'XX'}}{{statementItem.baggageType.code || 'XX'}}{{statementItem.material.code || 'X'}}<ng-container *ngFor="let el of statementItem.externalElements">{{getById(references.baggage_elements, el)?.code}}</ng-container>
                </td>
                <td>{{statementItem.baggage.reason}}</td>
                <td>{{statementItem.baggage.status}}</td>
                <!-- <td>{{statementItem.baggage.wt}}</td> -->
                <td>{{statementItem.agent}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="bottom-panel bg-light-gray border-top py-2 px-3">
          <div class="row" *ngIf="statement.id; else: statementNotSelected">
            <div class="col pr-0">
              <div class="d-flex justify-content-between align-items-center bottom-panel-header mb-1">
                <span class="font-weight-bold" i18n="laf|Baggage@@laf.baggage.statementInfo.flight">Flight</span>
                <div class="btn-group">
                  <button type="button"
                          class="btn btn-xs btn-white mr-2"
                          (click)="sendToArchive()"
                          *ngIf="!statement.archived"
                          ngbTooltip="Send to archive"
                          i18n-ngbTooltip="laf|Baggage@@laf.baggage.statementInfo.statement.button.sendToArchive">
                    <i class="material-icons">archive</i>
                    <!-- <span i18n="laf|Baggage@@laf.baggage.statementInfo.statement.button.sendToArchive">
                      Send to archive
                    </span> -->
                  </button>
                  <button type="button"
                          class="btn btn-xs btn-white mr-2"
                          (click)="restoreFromArchive()"
                          *ngIf="statement.archived"
                          ngbTooltip="Restore from archive"
                          i18n-ngbTooltip="laf|Baggage@@laf.baggage.statementInfo.statement.button.restoreFromArchive">
                    <i class="material-icons">unarchive</i>
                    <!-- <span i18n="laf|Baggage@@laf.baggage.statementInfo.statement.button.restoreFromArchive">
                      Restore from archive
                    </span> -->
                  </button>
                  <div class="dropdown">
                    <button class="btn btn-xs dropdown-toggle btn-white"
                            type="button" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false"
                            ngbTooltip="Mark"
                            i18n-ngbTooltip="laf|Baggage@@laf.baggage.statementInfo.statement.button.mark">
                      <i class="material-icons">assistant_photo</i>
                      <!-- <span i18n="laf|Baggage@@laf.baggage.statementInfo.statement.button.mark">Mark</span> -->
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownFlagsDetail">
                      <button *ngFor="let mark of marks" class="dropdown-item" (click)="setMark(statement.id, mark)">
                        <i class="material-icons" [ngStyle]="{'color': mark }">assistant_photo</i>
                        <span>{{mark}}</span>
                      </button>
                      <div class="dropdown-divider"></div>
                      <button class="dropdown-item font-small">
                        <i class="material-icons" style="color: #5c5c5c;">assistant_photo</i>
                        <span i18n="laf|Baggage@@laf.baggage.statementInfo.statement.button.clear">Clear</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <table class="w-100 info-table font-small">
                <tr>
                  <th i18n="laf|Baggage@@laf.baggage.statementInfo.table.flight">Flight</th>
                  <td></td>
                </tr>
                <tr>
                  <th i18n="laf|Baggage@@laf.baggage.statementInfo.table.codeshare">Codeshare</th>
                  <td></td>
                </tr>
                <tr>
                  <th i18n="laf|Baggage@@laf.baggage.statementInfo.table.passengerRoute" class="text-nowrap">Passenger route</th>
                  <td>{{ statement.passengerRoute }}</td>
                </tr>
                <tr>
                  <th i18n="laf|Baggage@@laf.baggage.statementInfo.table.baggageRoute" class="text-nowrap">Baggage route</th>
                  <td>{{ getBaggageByTag(statements[statementOfList]?.baggage.tag)?.fullRoute}}</td>
                </tr>
                <tr>
                  <th i18n="laf|Baggage@@laf.baggage.statementInfo.table.baggageCountWeight" class="text-nowrap">Bags count / weight</th>
                  <td>{{ statement.passenger.baggage.amount}}<span class="font-weight-bold text-gray-blue-dark">&nbsp;/&nbsp;</span>{{statement.passenger.baggage.weight }}</td>
                </tr>
                <tr>
                  <th i18n="laf|Baggage@@laf.baggage.statementInfo.table.passengersCount" class="text-nowrap">Passengers count</th>
                  <td>{{ statement.passenger.additionalInfo.amount}}</td>
                </tr>
              </table>
            </div>
            <div class="col-5 pr-0">
              <div class="d-flex justify-content-between align-items-center bottom-panel-header mb-1">
                <span class="font-weight-bold" i18n="laf|Baggage@@laf.baggage.bottomPanel.passenger.head">Passenger</span>
              </div>
              <table class="w-100 info-table font-small">
                <tr>
                  <th i18n="laf|Baggage@@laf.baggage.bottomPanel.passenger.name">Name</th>
                  <td>{{passengerFullName(statement.passenger.passenger)}}</td>
                </tr>
                <tr>
                  <th i18n="laf|Baggage@@laf.baggage.bottomPanel.passenger.phoneEmail" class="text-nowrap">Phone / email</th>
                  <td>{{ statement.passenger.phone1 }}<span class="font-weight-bold text-gray-blue-dark">&nbsp;/&nbsp;</span>{{ statement.passenger.email }}</td>
                </tr>
                <tr>
                  <th i18n="laf|Baggage@@laf.baggage.bottomPanel.passenger.address">Address</th>
                  <td>{{ statement.passenger.address }}</td>
                </tr>
                <tr>
                  <th i18n="laf|Baggage@@laf.baggage.bottomPanel.passenger.temporaryAddress" class="text-nowrap">Temporary address</th>
                  <td>{{ statement.passenger.additionalInfo.temporary.address }}</td>
                </tr>
                <tr>
                  <th i18n="laf|Baggage@@laf.baggage.bottomPanel.passenger.pnr">PNR</th>
                  <td>{{ statement.passenger.pnr }}</td>
                </tr>
                <tr>
                  <th i18n="laf|Baggage@@laf.baggage.bottomPanel.passenger.genderLanguage" class="text-nowrap">Gender / language</th>
                  <td>{{statement.passenger.additionalInfo.gender}}<span class="font-weight-bold text-gray-blue-dark">&nbsp;/&nbsp;</span>{{statement.passenger.additionalInfo.language}}</td>
                </tr>
              </table>
            </div>
            <div class="col">
              <div class="d-flex justify-content-between align-items-center bottom-panel-header mb-1">
                <span class="font-weight-bold" i18n="laf|Baggage@@laf.baggage.bottomPanel.baggage.head">Baggage</span>
              </div>
              <table class="w-100 info-table font-small">
                <tr>
                  <th i18n="laf|Baggage@@laf.baggage.bottomPanel.baggage.countBrokenWeight">Count / broken weight</th>
                  <td class="font-weoght-bold">
                    1<span class="font-weight-bold text-gray-blue-dark">&nbsp;/&nbsp;</span>{{getBaggageByTag(statements[statementOfList]?.baggage.tag)?.weight.expected - getBaggageByTag(statements[statementOfList]?.baggage.tag)?.weight.actual}}
                  </td>
                </tr>
                <tr>
                  <th i18n="laf|Baggage@@laf.baggage.bottomPanel.baggage.baggageTagStatus">Baggage tag / Status</th>
                  <td height="60">{{statements[statementOfList]?.baggage.tag}}<span class="font-weight-bold text-gray-blue-dark">&nbsp;/&nbsp;</span>{{ getById(baggage_statuses, statements[statementOfList]?.baggage.statusId)?.nameLocal }}</td>
                </tr>
              </table>
            </div>
          </div>
          <ng-template #statementNotSelected>
            <div class="d-flex align-items-center justify-content-center h-100 statement-not-selected">
              <span class="align-middle">
                <p class="h2 text-secondary"
                  i18n="laf|Main - control panel@@laf.main.preview.statementNotSelected">
                  Claim not selected
                </p>
              </span>
            </div>
          </ng-template>
        </div>
      </div>
  </div>
</ng-container>

<ng-container *ngIf="viewParametrs.activeWindow == 'manualSearch'">
  <div class="row border-bottom py-2 justify-content-between font-small mb-2">
    <div class="d-flex">
      <button class="btn btn-sm btn-ico btn-light-gray mx-3" (click)="loadWindow('statementsList'); clearManualStatement();">
        <i class="material-icons mr-2">arrow_back_ios</i>
        <span i18n="laf|Main - control panel@@laf.main.controlPanel.button.claims">Claims</span>
      </button>
    </div>
  </div>

  <div class="row">
    <div class="col-2 border-right">
      <div class="collapse multi-collapse show" id="tracingLuggageMenu">
        <div class="mp-0 left-menu">
          <div class="pointer pl-4 ty-1 py-2">
            <div class="input-group-prepend d-flex justify-content-between">
              <label class="w-100 m-0" for="statementsAll" i18n="laf|Statement tabs@@laf.manualSearch.typeClaim">
                Type claim
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="collapse multi-collapse show" id="tracingLuggageMenu">
        <div *ngFor="let statement of references.statement_manual_types" class="pl-5 ty-1 mt-1 mb-1 pr-2 m-0">
          <label class="m-0 d-flex align-items-center pointer">
            <input type="checkbox"
                   class="d-hover mr-2"
                   (click)="toggleItem(filterParamsManual.statements, statement.id);"
                   [checked]="filterParamsManual.statements.includes(statement.id) || filterParamsManual.statementsAll">
            <span class="mr-3 mb-0">{{ this.globalSettings.language === 'en' ? statement.nameEnglish : statement.nameLocal }}</span>
          </label>
        </div>
      </div>
    </div>
    <div class="col-10">
      <div>
        <div class="loading" *ngIf="viewParametrs.loading">
          <div class="cssload-spin-box"></div>
        </div>
          <h5 class="font-weight-bold text-blue h5 mb-3"
              i18n="laf|Statement tabs@@laf.manualSearch">
            Manual search
          </h5>
          <table class="w-100 mb-3 table-border contenteditable">
            <thead>
              <tr class="bg-blue color-white font-weight-bold">
                <td i18n="laf|Statement tabs@@laf.manualSearch.airport">Airport</td>
                <td i18n="laf|Statement tabs@@laf.manualSearch.claim">Claim</td>
                <td i18n="laf|Statement tabs@@laf.manualSearch.dateofClaim">Date of claim</td>
                <td i18n="laf|Statement tabs@@laf.manualSearch.flight">Flight</td>
                <td i18n="laf|Statement tabs@@laf.manualSearch.name">Name</td>
                <td i18n="laf|Statement tabs@@laf.manualSearch.baggage">Baggage</td>
                <td i18n="laf|Statement tabs@@laf.manualSearch.reason">Reason</td>
                <td i18n="laf|Statement tabs@@laf.manualSearch.tag">Baggage tag</td>
                <td i18n="laf|Statement tabs@@laf.manualSearch.status">Search status</td>
                <td i18n="laf|Statement tabs@@laf.manualSearch.wt">WorldTracer</td>
              </tr>
            </thead>
            <tbody>
              <tr *ngIf="manualSearchStatement.statementId">
                <td colspan="11"
                    class="font-weight-bold bg-light-yellow"
                    i18n="laf|Statement tabs@@laf.manualSearch.searchBagParameters">Parameters of retractable baggage</td>
              </tr>
              <ng-container *ngIf="manualSearchStatement.statementId">
                <tr class="text-center bg-light text-dark">
                  <td>
                    {{ manualSearchStatement.airport }}
                  </td>
                  <td>
                    {{ manualSearchStatement.statementName }}
                  </td>
                  <td>
                    {{ manualSearchStatement.dtInsert | date: 'dd.MM.yyyy' }}
                  </td>
                  <td>
                    {{ manualSearchStatement.arrival.flight }}
                  </td>
                  <td>
                    {{ manualSearchStatement.passenger }}
                  </td>
                  <td>
                    {{manualSearchStatement.color.code || 'XX'}}{{manualSearchStatement.baggageType.code || 'XX'}}{{manualSearchStatement.material.code || 'XX'}}<ng-container *ngFor="let el of manualSearchStatement.externalElements">{{el}}</ng-container>
                  </td>
                  <td>
                    {{ manualSearchStatement.baggage.reason }}
                  </td>
                  <td>
                    {{ manualSearchStatement.baggage.tag }}
                  </td>
                  <td>
                    {{ manualSearchStatement.baggage.status }}
                  </td>
                  <td>
                    {{ manualSearchStatement.baggage.wt }}
                  </td>
                </tr>
              </ng-container>
              <tr>
                <td colspan="11" class="font-weight-bold bg-light-yellow" i18n="laf|Statement tabs@@laf.manualSearch.baggages">Baggages</td>
              </tr>
              <ng-container>
                <tr class="text-center" *ngFor="let statementItem of statements let stat_i = index"
                  [ngClass]="{'bg-gray': stat_i === statementOfList }" >
                  <td>{{statementItem.airport}}</td>
                  <td>{{statementItem.statementName}}</td>
                  <td>{{statementItem.dtInsert | date: 'dd.MM.yyyy'}}</td>
                  <td>{{statementItem.arrival.flight}}</td>
                  <td>{{statementItem.passenger}}</td>
                  <td>
                    {{statementItem.baggageType.code || 'XX'}}{{statementItem.color.code || 'XX'}}{{statementItem.material.code || 'XX'}}<ng-container *ngFor="let el of statementItem.externalElements">{{el}}</ng-container>
                  </td>
                  <td>{{statementItem.baggage.reason}}</td>
                  <td>{{statementItem.baggage.tag}}</td>
                  <td>{{statementItem.baggage.status}}</td>
                  <td>{{statementItem.baggage.wt}}</td>
                </tr>
              </ng-container>
            </tbody>
          </table>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="viewParametrs.activeWindow == 'statementItem'">
  <div class="row border-bottom py-2 justify-content-between font-small mb-2">
    <div class="d-flex">
      <button class="btn btn-sm btn-ico btn-light-gray ml-3 mr-2" (click)="loadWindow('statementsList');">
        <i class="material-icons mr-2">arrow_back_ios</i>
        <span i18n="laf|Main - control panel@@laf.main.controlPanel.button.claims">Claims</span>
      </button>
      <!-- <button class="btn btn-sm btn-ico btn-blue mr-2" (click)="saveStatement();"
              i18n="laf|Main - control panel@@laf.main.controlPanel.button.save">
        Save
      </button> -->
      <div class="dropdown mr-2">
        <button class="btn btn-sm btn-light-gray dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
          <i class="material-icons">print</i>
          <span i18n="laf|Main - control panel@@laf.main.controlPanel.button.printDocuments">Print documents</span>
        </button>
        <div class="dropdown-menu">
          <button class="btn btn-xs dropdown-item"
                  type="button"
                  (click)="downloadDocument(statement.id, 'pir')">
            <i class="material-icons">receipt</i>
            <span i18n="laf|Main - control panel@@laf.main.controlPanel.button.pirIata">PIR IATA</span>
          </button>
          <button *ngIf="statement.typeId != 2 && statement.typeId != 3; else damagePir"
                  class="btn btn-xs dropdown-item"
                  type="button"
                  (click)="downloadDocument(statement.id, 'pir_pax')">
            <i class="material-icons">receipt</i>
            <span i18n="laf|Main - control panel@@laf.main.controlPanel.button.pirPassenger">PIR for passenger</span>
          </button>
          <ng-template #damagePir>
            <button class="btn btn-xs dropdown-item"
                    type="button"
                    (click)="downloadDocument(statement.id, 'dpr')">
              <i class="material-icons">receipt</i>
              <span i18n="laf|Main - control panel@@laf.main.controlPanel.button.dpr">Damage report</span>
            </button>
          </ng-template>
          <button class="btn btn-xs dropdown-item"
                  type="button"
                  (click)="downloadDocument(statement.id, 'commercial_act')">
            <i class="material-icons">description</i>
            <span i18n="laf|Main - control panel@@laf.main.controlPanel.button.commercialAct">Commercial act</span>
          </button>
          <button class="btn btn-xs dropdown-item"
                  type="button"
                  (click)="downloadDocument(statement.id, 'application')">
            <i class="material-icons">find_in_page</i>
            <span i18n="laf|Main - control panel@@laf.main.controlPanel.button.generateStatement">Wanted claim</span>
          </button>
          <button class="btn btn-xs dropdown-item"
                  type="button"
                  (click)="downloadDocument(statement.id, 'delivery_application')">
            <i class="material-icons">local_shipping</i>
            <span i18n="laf|Main - control panel@@laf.main.controlPanel.button.deliveryStatement">Delivery claim</span>
          </button>
        </div>
      </div>
      <button type="button" class="btn btn-xs btn-light-gray mr-2" (click)="sendToArchive()" *ngIf="!statement.archived">
        <i class="material-icons">archive</i>
        <span i18n="laf|Baggage@@laf.baggage.statementInfo.statement.button.sendToArchive">
          Send to archive
        </span>
      </button>
      <button type="button" class="btn btn-xs btn-light-gray mr-2" (click)="restoreFromArchive()" *ngIf="statement.archived">
        <i class="material-icons">unarchive</i>
        <span i18n="laf|Baggage@@laf.baggage.statementInfo.statement.button.restoreFromArchive">
          Restore from archive
        </span>
      </button>
      <!-- <button class="btn btn-sm btn-ico btn-light-gray mr-2">
        <i class="material-icons mr-2">receipt</i>
        <span i18n="laf|Main - control panel@@laf.main.controlPanel.button.pir">PIR</span>
      </button>
      <button class="btn btn-sm btn-ico btn-light-gray mr-2">
        <i class="material-icons mr-2">description</i>
        <span i18n="laf|Main - control panel@@laf.main.controlPanel.button.commercialAct">Commercial act</span>
      </button>
      <button class="btn btn-sm btn-ico btn-light-gray mr-2">
        <i class="material-icons mr-2">find_in_page</i>
        <span i18n="laf|Main - control panel@@laf.main.controlPanel.button.generateStatement">Wanted claim</span>
      </button>
      <button class="btn btn-sm btn-ico btn-light-gray mr-2">
        <i class="material-icons mr-2">local_shipping</i>
        <span i18n="laf|Main - control panel@@laf.main.controlPanel.button.deliveryStatement">Delivery claim</span>
      </button> -->
      <a class="btn btn-sm btn-ico btn-light-gray mr-2"
          [routerLink]="['/reports']" target="_blank" [queryParams]="{'id': statement.id, 'report_id': 30,  'reportGroup_id': 10}">
        <i class="material-icons mr-2">history</i>
        <span i18n="laf|Main - control panel@@laf.main.controlPanel.button.history">History</span>
      </a>
      <!-- Данные разделы перенесены в вкладку Чаты -->
      <!-- <button class="btn btn-sm btn-ico btn-light-gray mr-2">
        <i class="material-icons mr-2">notifications_active</i>
        <span i18n="laf|Main - control panel@@laf.main.controlPanel.button.notifications">Notifications</span>
      </button>
      <button class="btn btn-sm btn-ico btn-light-gray">
        <i class="material-icons mr-2">email</i>
        <span i18n="laf|Main - control panel@@laf.main.controlPanel.button.telegrams">Telegrams</span>
      </button> -->
    </div>
    <div class="col-auto">
      <div class="d-flex">
        <div class="input-group input-group-sm mr-2">
          <div class="input-group-prepend">
            <span class="input-group-text font-small" i18n="laf|Main - control panel@@laf.main.controlPanel.label.claimIdent">
              Claim
            </span>
          </div>
          <input type="text" class="form-control form-control-sm" [(ngModel)]="statement.name" disabled>
        </div>
        <div class="input-group input-group-sm mr-2">
          <div class="input-group-prepend">
            <span class="input-group-text font-small" i18n="laf|Main - control panel@@laf.main.controlPanel.label.station">
              Station
            </span>
          </div>
          <input type="text" class="form-control form-control-sm" [value]="getById(references.airports, statement.airportId)?.iata" disabled>
        </div>
        <div class="input-group input-group-sm">
          <div class="input-group-prepend">
            <span class="input-group-text font-small" i18n="laf|Main - control panel@@laf.main.controlPanel.label.agent">
              Agent
            </span>
          </div>
          <input type="text" class="form-control form-control-sm" [(ngModel)]="statement.username" disabled>
        </div>
      </div>
    </div>
  </div>
  <ul class="nav nav-tabs" role="tablist">
    <li class="nav-item">
      <a class="nav-link active"
         id="summary-tab"
         data-toggle="tab"
         href="#summary"
         role="tab"
         aria-controls="summary"
         aria-selected="true"
         i18n="laf|Statement tabs@@laf.statement.tabs.name.summary">Summary</a>
    </li>
    <li class="nav-item">
      <a class="nav-link"
         id="claim-tab"
         data-toggle="tab"
         href="#claim"
         role="tab"
         aria-controls="claim"
         aria-selected="false"
         i18n="laf|Statement tabs@@laf.statement.tabs.name.claim">Claim</a>
    </li>
    <li class="nav-item">
      <a class="nav-link"
         id="baggage-tab"
         data-toggle="tab"
         href="#baggage"
         role="tab"
         aria-controls="baggage"
         aria-selected="false"
         (click)="loadBagData()"
         i18n="laf|Statement tabs@@laf.statement.tabs.name.baggage">Baggage</a>
    </li>
    <li class="nav-item">
      <a class="nav-link"
         id="chat-tab"
         data-toggle="tab"
         href="#chat"
         role="tab"
         aria-controls="chat"
         aria-selected="false"
         i18n="laf|Statement tabs@@laf.statement.tabs.name.chatAndMessages">Chat and messages</a>
    </li>
  </ul>
  <div class="tab-content">
    <div class="tab-pane fade pt-3 show active" id="summary" role="tabpanel" aria-labelledby="summary-tab">
      <div class="row">
        <div class="col-8">
          <div class="card mb-3">
            <div class="card-body">
              <p class="font-weight-bold text-blue h5 mb-3" i18n="laf|Statement tabs@@laf.statement.tabs.statement.statement">Claim</p>
              <span class="font-small mr-3">
                <span class="font-weight-bold mr-2" i18n="laf|Statement tabs@@laf.statement.tabs.statement.claimNumber">
                  Claim number</span>{{ statement.name}}
              </span>
              <span class="font-small mr-3">
                <span class="font-weight-bold mr-2" i18n="laf|Statement tabs@@laf.statement.tabs.statement.airport">
                  Airport</span>{{ getById(references.airports, statement.airportId)?.iata }}
              </span>
              <span class="font-small mr-3">
                <span class="font-weight-bold mr-2" i18n="laf|Statement tabs@@laf.statement.tabs.statement.airline">
                  Airline</span>{{ getById(references.airlines, statement.airlineId)?.iata }}
              </span>
              <span class="font-small mr-3">
                <span class="font-weight-bold mr-2" i18n="laf|Statement tabs@@laf.statement.tabs.statement.reason">
                  Reason</span>{{ getById(references.statement_types, statement.typeId)?.nameLocal }}
              </span>
              <span class="font-small mr-3">
                <span class="font-weight-bold mr-2" i18n="laf|Statement tabs@@laf.statement.tabs.statement.date">
                  Date</span>{{ statement.dtInsert | date: 'dd.MM.yyyy' }}
              </span>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <p class="font-weight-bold text-blue h5 mb-3" i18n="laf|Statement tabs@@laf.statement.tabs.statement.passenger">Passenger</p>
              <div class="row">
                <div class="col-4 pr-0">
                  <table class="w-100 font-small">
                    <tr>
                      <td class="font-weight-bold" i18n="laf|Statement tabs@@laf.statement.tabs.statement.passenger.name">Name</td>
                      <!-- <td>{{ statement.passenger.passenger.surname }}{{statement.passenger.passenger.name ? "/" }}{{statement.passenger.passenger.name ? statement.passenger.passenger.name }} {{statement.passenger.passenger.middleMame}}</td> -->
                      <td>{{passengerFullName(statement.passenger.passenger)}}</td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold" i18n="laf|Statement tabs@@laf.statement.tabs.statement.passenger.phone">Phone</td>
                      <td>{{statement.passenger.phone1}}</td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold" i18n="laf|Statement tabs@@laf.statement.tabs.statement.passenger.email">Email</td>
                      <td>{{statement.passenger.email}}</td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold" i18n="laf|Statement tabs@@laf.statement.tabs.statement.passenger.permanentAddress">Permanent address</td>
                      <td>{{statement.passenger.address}}</td>
                    </tr>
                  </table>
                </div>
                <div class="col-4 pr-0">
                  <table class="w-100 font-small">
                    <tr>
                      <td class="font-weight-bold" i18n="laf|Statement tabs@@laf.statement.tabs.statement.passenger.frequentFlyer">Frequent flyer</td>
                      <td>{{statement.passenger.additionalInfo.ffp}}</td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold" i18n="laf|Statement tabs@@laf.statement.tabs.statement.passenger.status">Status</td>
                      <td>{{statement.passenger.additionalInfo.status}}</td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold" i18n="laf|Statement tabs@@laf.statement.tabs.statement.passenger.sex">Sex</td>
                      <td>{{statement.passenger.additionalInfo.gender}}</td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold" i18n="laf|Statement tabs@@laf.statement.tabs.statement.passenger.language">Language</td>
                      <td>{{statement.passenger.additionalInfo.language}}</td>
                    </tr>
                  </table>
                </div>
                <div class="col-4 border-left pr-0">
                  <p class="text-gray-blue-dark mb-0" i18n="laf|Statement tabs@@laf.statement.tabs.statement.passenger.temporaryStay">Temporary stay</p>
                  <table class="w-100 font-small">
                    <tr>
                      <td class="font-weight-bold" i18n="laf|Statement tabs@@laf.statement.tabs.statement.passenger.phone">Phone</td>
                      <td>{{statement.passenger.additionalInfo.temporary.phone || '-'}}</td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold" i18n="laf|Statement tabs@@laf.statement.tabs.statement.passenger.dateStay">Date stay</td>
                      <td>{{statement.passenger.additionalInfo.temporary.dueDate | date: 'dd.MM.yyyy' || '-'}}</td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold" i18n="laf|Statement tabs@@laf.statement.tabs.statement.passenger.temporaryAddress">Temporary address</td>
                      <td>{{statement.passenger.additionalInfo.temporary.address || '-'}}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="card">
            <div class="card-body">
              <p class="font-weight-bold text-blue h5" i18n="laf|Statement tabs@@laf.statement.tabs.statement.flight">Flight</p>
              <table class="w-100 font-small">
                <col style="width:35%">
                <tr>
                  <td class="font-weight-bold" i18n="laf|Statement tabs@@laf.statement.tabs.statement.flight.pnr">PNR</td>
                  <td>{{statement.passenger.pnr}}</td>
                </tr>
                <tr>
                  <td class="font-weight-bold" i18n="laf|Statement tabs@@laf.statement.tabs.statement.flight.serviceClass">Service class</td>
                  <td>{{statement.passenger.additionalInfo.class}}</td>
                </tr>
                <tr>
                  <td class="font-weight-bold" i18n="laf|Statement tabs@@laf.statement.tabs.statement.flight.baggageCount">Baggage count</td>
                  <td>{{statement.passenger.baggage.amount}}</td>
                </tr>
                <tr>
                  <td class="font-weight-bold" i18n="laf|Statement tabs@@laf.statement.tabs.statement.flight.baggageWeight">Baggage weight</td>
                  <td>{{statement.passenger.baggage.weight}}</td>
                </tr>
                <tr>
                  <td class="font-weight-bold" i18n="laf|Statement tabs@@laf.statement.tabs.statement.flight.overnightKitManWoman">Overnight kit man/woman</td>
                  <td>{{statement.passenger.additionalInfo.overknightKit.male}} / {{statement.passenger.additionalInfo.overknightKit.female}}</td>
                </tr>
                <tr>
                  <td class="font-weight-bold" i18n="laf|Statement tabs@@laf.statement.tabs.statement.flight.ticketNumber">Ticket number</td>
                  <td>{{statement.passenger.ticket}}</td>
                </tr>
                <tr>
                  <td class="font-weight-bold" i18n="laf|Statement tabs@@laf.statement.tabs.statement.flight.route">Route</td>
                  <td>
                    <p class="m-0" *ngFor="let route of statement.route.full.flights">
                      {{route.flight}}&nbsp;{{getById(references.airports, route.departureId)?.iata}}-{{getById(references.airports, route.arrivalId)?.iata}} {{route.dt | date: 'dd.MM.yyyy HH:mm'}}
                    </p>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="col-12 mt-3">
          <table class="w-100 font-small table-bordered">
            <thead class="bg-light-gray font-weight-bold text-center">
              <tr>
                <th i18n="laf|Statement tabs@@laf.statement.tabs.statement.findTable.tag" class="align-middle" rowspan="2">Tag</th>
                <th i18n="laf|Statement tabs@@laf.statement.tabs.statement.findTable.status" class="align-middle" rowspan="2">Status</th>
                <th i18n="laf|Statement tabs@@laf.statement.tabs.statement.findTable.weight" colspan="3">Weight</th>
                <th i18n="laf|Statement tabs@@laf.statement.tabs.statement.findTable.parameters" class="align-middle" rowspan="2">Parameters</th>
                <th i18n="laf|Statement tabs@@laf.statement.tabs.statement.findTable.forwarding" colspan="2">Forwarding</th>
                <th i18n="laf|Statement tabs@@laf.statement.tabs.statement.findTable.storage" class="align-middle" rowspan="2">Storage</th>
                <th i18n="laf|Statement tabs@@laf.statement.tabs.statement.findTable.delivery" class="align-middle" rowspan="2">Delivery</th>
                <th i18n="laf|Statement tabs@@laf.statement.tabs.statement.findTable.damage" class="align-middle" rowspan="2">Damage</th>
                <th i18n="laf|Statement tabs@@laf.statement.tabs.statement.findTable.pLost" class="align-middle" rowspan="2">P/Lost</th>
                <th i18n="laf|Statement tabs@@laf.statement.tabs.statement.findTable.faultStation" class="align-middle" colspan="3">Fault station</th>
              </tr>
              <tr>
                <th i18n="laf|Statement tabs@@laf.statement.tabs.statement.findTable.checkedIn">Checked in</th>
                <th i18n="laf|Statement tabs@@laf.statement.tabs.statement.findTable.delivery">Delivery</th>
                <th i18n="laf|Statement tabs@@laf.statement.tabs.statement.findTable.lost">Lost</th>

                <!-- <th i18n="laf|Statement tabs@@laf.statement.tabs.statement.findTable.type">Type</th>
                <th i18n="laf|Statement tabs@@laf.statement.tabs.statement.findTable.material">Material</th>
                <th i18n="laf|Statement tabs@@laf.statement.tabs.statement.findTable.color">Color</th> -->

                <th i18n="laf|Statement tabs@@laf.statement.tabs.statement.findTable.flightArrival">Arrival</th>
                <th i18n="laf|Statement tabs@@laf.statement.tabs.statement.findTable.flightDeparture">Departure</th>
                <!-- <th i18n="laf|Statement tabs@@laf.statement.tabs.statement.findTable.date">Date</th> -->
                <!-- <th i18n="laf|Statement tabs@@laf.statement.tabs.statement.findTable.date">Date</th> -->

                <th i18n="laf|Statement tabs@@laf.statement.tabs.statement.findTable.faultStationAirport">Airport</th>
                <th i18n="laf|Statement tabs@@laf.statement.tabs.statement.findTable.faultStationCode1">Code 1</th>
                <th i18n="laf|Statement tabs@@laf.statement.tabs.statement.findTable.faultStationCode2">Code 2</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let baggageItem of statement.baggage">
                <td>{{baggageItem.tag}}</td>
                <td>{{getById(baggage_statuses, baggageItem.statusId)?.nameLocal}}</td>
                <td class="text-center">{{baggageItem.weight.expected}}</td>
                <td class="text-center">{{baggageItem.weight.actual}}</td>
                <td class="text-center">{{baggageItem.weight.expected - baggageItem.weight.actual}}</td>
                <td class="text-center">{{getCharacteristicCode(baggageItem)}}</td>
                <td><span class="font-weight-bold">{{baggageItem.forwarding.arrival.flights[0]?.flight}}</span><span class="font-weight-bold text-gray-blue-dark">&nbsp;/&nbsp;</span>{{baggageItem.forwarding.arrival.flights[0]?.dt  | date: 'dd.MM.yyyy HH:mm' }}</td>
                <td><span class="font-weight-bold">{{baggageItem.forwarding.departure.flights[0]?.flight}}</span><span class="font-weight-bold text-gray-blue-dark">&nbsp;/&nbsp;</span>{{baggageItem.forwarding.departure.flights[0]?.dt  | date: 'dd.MM.yyyy HH:mm' }}</td>
                <td>{{getById(adminData.storages, baggageItem.storage.id)?.name}}</td>
                <td class="text-break">{{baggageItem.delivery.address}}</td>
                <td class="text-center">
                  <span *ngIf="(baggageItem.damage.damages && baggageItem.damage.damages.length > 0) ||
                                baggageItem.damage.note || baggageItem.damage.declaredValue"
                        class="material-icons">done</span>
                </td>
                <td class="text-center">
                  <span class="material-icons" *ngIf="baggageItem.pilferage.description">done</span>
                </td>
                <td class="text-center">{{getById(references.airports, baggageItem.airportId)?.iata}}</td>
                <td class="text-center">{{getById(references.irregularity_codes, baggageItem.irregularityCode1Id)?.code}}</td>
                <td class="text-center">{{getById(references.irregularity_codes, baggageItem.irregularityCode2Id)?.code}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="tab-pane fade pt-3" id="claim" role="tabpanel" aria-labelledby="claim-tab">
      <div class="row">
        <div class="col-6">
          <div class="card mb-3">
            <div class="card-body pb-1">
              <p class="font-weight-bold text-blue h5 mb-3" i18n="laf|Statement tabs@@laf.statement.tabs.claim.claim">Claim</p>
              <div class="d-flex justify-content-between mb-3">
                <div class="flex-fill mr-3">
                  <div class="input-group flex-nowrap">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0">
                        <span i18n="laf|Statement tabs@@laf.modal.newStatement.airport">Airport</span>&nbsp;
                        <span *ngIf="statement.typeId == 1 || statement.typeId == 3 || statement.typeId == 2 || statement.typeId == 5 || statement.typeId == 7 || statement.typeId == 8" class="required-asterisk">*</span>
                      </span>
                    </div>
                    <ng-select class="w-100 custom-sm bg-white select-long"
                               [items]="references.airports"
                               bindLabel="iata"
                               bindValue="id"
                               [(ngModel)]="statement.airportId"
                               #filterParamsAirport="ngModel"
                               name="statementAirport"
                               appendTo="body"
                               [virtualScroll]="true"
                               [loading]="selectLoadAnimation.airports"
                               (scroll)="onScrollNgSelect($event, 'airports')"
                               (scrollToEnd)="onScrollToEndNgSelect('airports')"
                               [searchFn]="customSelectSearch"
                               [selectOnTab]="true"
                               (change)="autoSave()">
                      <ng-template ng-option-tmp let-item="item">
                        <div class="font-small">
                          <span class="font-weight-bold">{{item.iata}}</span>&nbsp;{{item.name}}
                        </div>
                      </ng-template>
                    </ng-select>
                  </div>
                </div>
                <div class="flex-fill">
                  <div class="input-group flex-nowrap">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.modal.newStatement.airline">
                        Airline
                      </span>
                    </div>
                    <ng-select class="w-100 custom-sm bg-white select-long"
                               [items]="references.airlines"
                               bindLabel="iata"
                               bindValue="id"
                               [(ngModel)]="statement.airlineId"
                               #filterParamsAirport="ngModel"
                               name="statementAirline"
                               appendTo="body"
                               [selectOnTab]="true"
                               [virtualScroll]="true"
                               [loading]="selectLoadAnimation.airlines"
                               (scroll)="onScrollNgSelect($event, 'airlines')"
                               (scrollToEnd)="onScrollToEndNgSelect('airlines')"
                               [searchFn]="customSelectSearch"
                               (change)="autoSave()">
                      <ng-template ng-option-tmp let-item="item">
                        <div class="font-small">
                          <span class="font-weight-bold">{{item.iata}}</span>&nbsp;{{item.name}}
                        </div>
                      </ng-template>
                    </ng-select>
                  </div>
                </div>
              </div>
              <div class="d-flex">
                <div class="input-group flex-nowrap mb-3">
                  <div class="input-group-prepend custom-sm">
                    <span class="input-group-text font-small py-0">
                      <span i18n="laf|Statement tabs@@laf.modal.newStatement.reason">Reason</span>&nbsp;
                      <span *ngIf="statement.typeId == 1 || statement.typeId == 3 || statement.typeId == 2 || statement.typeId == 5 || statement.typeId == 7 || statement.typeId == 8" class="required-asterisk">*</span>
                    </span>
                  </div>
                  <ng-select class="w-100 custom-sm bg-white"
                              bindValue="id"
                              bindLabel="nameLocal"
                              name="statementReason"
                              [(ngModel)]="statement.typeId"
                              [loading]="selectLoadAnimation.statement_types"
                              [items]="references.statement_types"
                              (change)="autoSave()">
                  </ng-select>
                </div>
              </div>

              <div class="accordion mb-2" id="accordionClaimComment">
                <div class="card">
                  <div class="card-header p-0" id="accordionClaimCommentText">
                    <h2 class="mb-0">
                      <button class="btn btn-block text-left collapsed d-flex justify-content-between align-items-center"
                              type="button"
                              data-toggle="collapse"
                              data-target="#claimComment"
                              aria-expanded="true"
                              aria-controls="claimComment">
                        <div class="d-flex">
                          <span class="d-flex align-items-center text-light-blue align-midle" i18n="laf|Statement tabs@@laf.statement.tabs.claim.commentary">
                            Comentary
                          </span>
                          <span class="text-dark-gray ml-2"
                                  *ngIf="statement.commentary && statement.commentary.length > 0">
                              <span class="material-icons">done</span>
                          </span>
                        </div>
                      </button>
                    </h2>
                  </div>
                  <div id="claimComment" class="collapse" aria-labelledby="accordionClaimCommentText" data-parent="#accordionClaimComment">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-12">
                          <div class="input-group flex-nowrap">
                            <textarea class="form-control form-control-sm"
                                      [(ngModel)]="statement.commentary"
                                      rows="2"
                                      (keyup)="autoSave()">{{ statement.commentary }}</textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <p class="font-weight-bold text-blue h5 mb-3" i18n="laf|Baggage@@laf.statement.tabs.flight">Flight</p>
              <div class="row mb-3">
                <div class="col-6 pr-0">
                  <div class="input-group flex-nowrap">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0"
                        i18n="laf|Statement tabs@@laf.statement.tabs.claim.serviceClass">
                        Service class
                      </span>
                    </div>
                    <ng-select [items]="localReferences.classes"
                               class="w-100 custom-sm select-long"
                               name="passengerClass"
                               appendTo="body"
                               [selectOnTab]="true"
                               [bindLabel]="globalSettings.language === 'en' ? 'nameEnglish' : 'nameLocal'"
                               [bindValue]="globalSettings.language === 'en' ? 'nameEnglish' : 'nameLocal'"
                               [(ngModel)]="statement.passenger.additionalInfo.class"
                               (change)="autoSave()">
                    </ng-select>
                  </div>
                </div>
                <div class="col-6">
                  <div class="input-group flex-nowrap">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0"
                          i18n="laf|Statement tabs@@laf.statement.tabs.claim.pnr">
                        PNR
                      </span>
                    </div>
                    <input type="text"
                           class="w-100 form-control custom-sm"
                           name="pnr"
                           [(ngModel)]="statement.passenger.pnr"
                           (keyup)="autoSave()"
                           appUpperCase/>
                  </div>
                </div>
              </div>
              <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend custom-sm">
                  <span class="input-group-text font-small py-0">
                    <span i18n="laf|Statement tabs@@laf.statement.tabs.claim.registrationBaggageCountWeight">Registration baggage Count and Weight</span>&nbsp;
                    <span *ngIf="statement.typeId == 1 || statement.typeId == 3 || statement.typeId == 2" class="required-asterisk">*</span>
                  </span>
                </div>
                <input type="number"
                       class="w-100 form-control custom-sm"
                       name="baggageCount"
                       [(ngModel)]="statement.passenger.baggage.amount"
                       (keyup)="autoSave()" />
                <input type="number"
                       class="w-100 form-control custom-sm"
                       name="baggageWeight"
                       [(ngModel)]="statement.passenger.baggage.weight"
                       (keyup)="autoSave()" />
              </div>
              <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend custom-sm">
                  <span class="input-group-text font-small py-0"
                      i18n="laf|Statement tabs@@laf.statement.tabs.claim.overnightKitMenWomen">
                    Overnight kit for Men and Women
                  </span>
                </div>
                <input type="number"
                       class="w-100 form-control custom-sm"
                       name="kitMen"
                       [(ngModel)]="statement.passenger.additionalInfo.overknightKit.male"
                       (keyup)="autoSave()" />
                <input type="number"
                       class="w-100 form-control custom-sm"
                       name="kitWomen"
                       [(ngModel)]="statement.passenger.additionalInfo.overknightKit.female"
                       (keyup)="autoSave()" />
              </div>
              <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend custom-sm">
                  <span class="input-group-text font-small py-0"
                      i18n="laf|Statement tabs@@laf.statement.tabs.claim.ticketNumber">
                    Ticket number
                  </span>
                </div>
                <input type="text"
                       class="w-100 form-control custom-sm"
                       name="ticketNumber"
                       [(ngModel)]="statement.passenger.ticket"
                       (keyup)="autoSave()"
                       appUpperCase/>
              </div>
              <p class="mb-2 h6 border-top py-2 font-weight-bold text-gray-blue-dark" i18n="laf|Statement tabs@@laf.statement.tabs.claim.route">Route</p>
              <table class="w-100 mb-3">
                <tr>
                  <td width="18%"><span i18n="laf|Statement tabs@@laf.statement.tabs.claim.flight">
                    Flight</span>&nbsp;
                    <span *ngIf="statement.typeId == 1 || statement.typeId == 3 || statement.typeId == 2" class="required-asterisk">*</span>
                  </td>
                  <td><span i18n="laf|Statement tabs@@laf.statement.tabs.claim.date">
                    Date</span>&nbsp;
                    <span *ngIf="statement.typeId == 1 || statement.typeId == 3 || statement.typeId == 2" class="required-asterisk">*</span></td>
                  <td></td>
                  <td width="18%"><span i18n="laf|Statement tabs@@laf.statement.tabs.claim.from">
                    From</span>&nbsp;
                    <span *ngIf="statement.typeId == 1 || statement.typeId == 3 || statement.typeId == 2" class="required-asterisk">*</span></td>
                  <td width="18%"><span i18n="laf|Statement tabs@@laf.statement.tabs.claim.to">
                    To</span>&nbsp;
                    <span *ngIf="statement.typeId == 1 || statement.typeId == 3 || statement.typeId == 2" class="required-asterisk">*</span></td>
                  <td></td>
                </tr>
                <tr *ngFor="let route of statement.route.full.flights; let i = index">
                  <td>
                    <input type="text"
                           class="form-control custom-sm"
                           name="flightItemFlight_{{i}}"
                           [(ngModel)]="route.flight"
                           (keyup)="autoSave()"
                           appUpperCase/>
                  </td>
                  <td>
                    <input type="date"
                           class="form-control custom-sm"
                           [ngModel]="route.dt | date: 'yyyy-MM-dd'"
                           (blur)="route.dt = parseDate($event.target.value, flightItemTime.value)"
                           name="flightItemDate_{{i}}"
                           #flightItemDate
                           #flightItemDate{{i}}="ngModel"
                           (keyup)="autoSave()">
                  </td>
                  <td>
                    <input type="time"
                           class="form-control custom-sm"
                           [ngModel]="route.dt | date: 'HH:mm'"
                           (blur)="route.dt = parseDate(flightItemDate.value, $event.target.value)"
                           name="flightItemTime{{i}}"
                           #flightItemTime
                           #flightItemTime{{i}}="ngModel"
                           (keyup)="autoSave()">
                  </td>
                  <td>
                    <ng-select class="w-100 custom-sm p-0 select-long"
                               [items]="references.airports"
                               bindLabel="iata"
                               bindValue="id"
                               [(ngModel)]="route.departureId"
                               name="departureAirline_{{i}}"
                               appendTo="body"
                               [selectOnTab]="true"
                               [virtualScroll]="true"
                               [loading]="selectLoadAnimation.airports"
                               (scroll)="onScrollNgSelect($event, 'airports')"
                               (scrollToEnd)="onScrollToEndNgSelect('airports')"
                               [searchFn]="customSelectSearch"
                               (change)="setAttribute(route, 'departure', $event.iata); autoSave();">
                      <ng-template ng-option-tmp let-item="item">
                        <div class="font-small">
                          <span class="font-weight-bold">{{item.iata}}</span>&nbsp;{{item.name}}
                        </div>
                      </ng-template>
                    </ng-select>
                  </td>
                  <td>
                    <ng-select class="w-100 custom-sm p-0 select-long"
                               [items]="references.airports"
                               bindLabel="iata"
                               bindValue="id"
                               [(ngModel)]="route.arrivalId"
                               name="arrivalAirline_{{i}}"
                               appendTo="body"
                               [selectOnTab]="true"
                               [virtualScroll]="true"
                               [loading]="selectLoadAnimation.airports"
                               (scroll)="onScrollNgSelect($event, 'airports')"
                               (scrollToEnd)="onScrollToEndNgSelect('airports')"
                               [searchFn]="customSelectSearch"
                               (change)="setAttribute(route, 'arrival', $event.iata); autoSave()">
                      <ng-template ng-option-tmp let-item="item">
                        <div class="font-small">
                          <span class="font-weight-bold">{{item.iata}}</span>&nbsp;{{item.name}}
                        </div>
                      </ng-template>
                    </ng-select>
                  </td>
                  <td>
                    <span class="material-icons text-red" (click)="removeItemRoute(i); autoSave();">close</span>
                  </td>
                </tr>
              </table>
              <button class="btn btn-sm btn-blue float-right"
                      (click)="addItemRoute(); autoSave();"
                      i18n="laf|Main - button@@laf.main.button.add">
                Add
              </button>
            </div>
          </div>
        </div>
        <div class="col-6 pr-0">
          <div class="card">
            <div class="card-body">
              <p class="font-weight-bold text-blue h5 mb-3" i18n="laf|Statement tabs@@laf.statement.tabs.claim.passenger">Passenger</p>
              <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend custom-sm">
                  <span class="input-group-text font-small py-0">
                    <span i18n="laf|Statement tabs@@laf.statement.tabs.claim.fullName">Full name</span>&nbsp;
                    <span *ngIf="statement.typeId == 1 || statement.typeId == 3 || statement.typeId == 2" class="required-asterisk">*</span>
                  </span>
                </div>
                <input type="text"
                       class="w-100 form-control custom-sm"
                       name="passengerSurname"
                       [(ngModel)]="statement.passenger.passenger.surname"
                       (keyup)="autoSave()"
                       placeholder="Surname"
                       appUpperCase
                       i18n-placeholder="laf|Statement tabs@@laf.statement.tabs.claim.placeholder.surname"/>
                <input type="text"
                       class="w-100 form-control custom-sm"
                       name="passengerName"
                       [(ngModel)]="statement.passenger.passenger.name"
                       (keyup)="autoSave()"
                       placeholder="Name"
                       appUpperCase
                       i18n-placeholder="laf|Statement tabs@@laf.statement.tabs.claim.placeholder.name"/>
                <input type="text"
                       class="w-100 form-control custom-sm"
                       name="passengerMiddleName"
                       [(ngModel)]="statement.passenger.passenger.middleName"
                       (keyup)="autoSave()"
                       placeholder="Middle name"
                       appUpperCase
                       i18n-placeholder="laf|Statement tabs@@laf.statement.tabs.claim.placeholder.middleName"/>
              </div>
              
              
              <!-- <div class="row mb-3">
                <div class="col-6">
                  <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="notifyPassenger">
                    <label class="custom-control-label"
                          for="notifyPassenger"
                          i18n="laf|Statement tabs@@laf.statement.tabs.claim.notifyPassenger">
                      Notify passenger
                    </label>
                  </div>
                </div>
              </div> -->

              <div class="accordion" id="accordionPassenger">
                <div class="card">
                  <div class="card-header p-0" id="accordionPassengerAdditionalInfo">
                    <h2 class="mb-0">
                      <button class="btn btn-block text-left collapsed d-flex justify-content-between align-items-center"
                              type="button"
                              data-toggle="collapse"
                              data-target="#passengerContacts"
                              aria-expanded="true"
                              aria-controls="passengerContacts">
                        <div class="d-flex">
                          <span class="d-flex align-items-center text-light-blue align-midle" i18n="laf|Statement tabs@@laf.statement.tabs.claim.contact">
                            Contacts and address
                          </span>
                          <span class="text-dark-gray ml-2"
                                  *ngIf="statement.passenger.phone1 || statement.passenger.email ||
                                  statement.passenger.address">
                              <span class="material-icons">done</span>
                          </span>
                        </div>
                      </button>
                    </h2>
                  </div>
                  <div id="passengerContacts" class="collapse show" aria-labelledby="accordionPassengerAdditionalInfo" data-parent="#accordionPassenger">
                    <div class="card-body">
                      <div class="row mb-3">
                        <div class="col-6 pr-0">
                          <div class="input-group flex-nowrap">
                            <div class="input-group-prepend custom-sm">
                              <span class="input-group-text font-small py-0">
                                <span i18n="laf|Statement tabs@@laf.statement.tabs.claim.phone">Phone</span>&nbsp;
                                <span *ngIf="statement.typeId == 1 || statement.typeId == 3 || statement.typeId == 2"
                                      class="required-asterisk">*</span>
                              </span>
                            </div>
                            <input type="text"
                                   class="w-100 form-control custom-sm"
                                   name="baggageTag"
                                   [(ngModel)]="statement.passenger.phone1"
                                   (keyup)="autoSave()"/>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="input-group flex-nowrap">
                            <div class="input-group-prepend custom-sm">
                              <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.statement.tabs.claim.email">
                                Email
                              </span>
                            </div>
                            <input type="text"
                                   class="w-100 form-control custom-sm"
                                   name="baggageTag"
                                   [(ngModel)]="statement.passenger.email"
                                   (keyup)="autoSave()"
                                   appUpperCase/>
                          </div>
                        </div>
                      </div>
                      <div class="input-group mb-3">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0">
                            <span i18n="laf|Statement tabs@@laf.statement.tabs.claim.permanentAddress">Permanent address</span>&nbsp;
                            <span *ngIf="statement.typeId == 1 || statement.typeId == 3 || statement.typeId == 2" class="required-asterisk">*</span>
                          </span>
                        </div>
                        <textarea class="form-control form-control-sm"
                                  [(ngModel)]="statement.passenger.address"
                                  rows="2"
                                  (keyup)="autoSave()"
                                  appUpperCase>{{ statement.passenger.address }}</textarea>
                      </div>
                      <div class="input-group flex-nowrap">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0"
                            i18n="laf|Statement tabs@@laf.statement.tabs.claim.notification">
                            Notification
                          </span>
                        </div>
                        <ng-select [items]="['Email', 'SMS']"
                                   class="w-100 custom-sm"
                                   name="passengerNotification"
                                   [(ngModel)]="statement.passenger.notification"
                                   [multiple]="true"
                                   (change)="autoSave()">
                        </ng-select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header p-0" id="accordionPassengerAdditionalInfo">
                    <h2 class="mb-0">
                      <button class="btn btn-block text-left collapsed d-flex justify-content-between align-items-center"
                              type="button"
                              data-toggle="collapse"
                              data-target="#passengerAdditionalInfo"
                              aria-expanded="true"
                              aria-controls="passengerAdditionalInfo">
                        <div class="d-flex">
                          <span class="d-flex align-items-center text-light-blue align-midle" i18n="laf|Statement tabs@@laf.statement.tabs.claim.additionalInfo">
                            Additional info
                          </span>
                          <span class="text-dark-gray ml-2"
                                  *ngIf="statement.passenger.additionalInfo.ffp || statement.passenger.additionalInfo.gender ||
                                  statement.passenger.additionalInfo.language || statement.passenger.additionalInfo.amount ||
                                  statement.passenger.additionalInfo.passengers[0].surname  || statement.passenger.additionalInfo.passengers[0].name ||
                                  statement.passenger.additionalInfo.passengers[0].middleName || statement.passenger.additionalInfo.passengers[1].surname  ||
                                  statement.passenger.additionalInfo.passengers[1].name || statement.passenger.additionalInfo.passengers[1].middleName">
                              <span class="material-icons">done</span>
                          </span>
                        </div>
                      </button>
                    </h2>
                  </div>
                  <div id="passengerAdditionalInfo" class="collapse" aria-labelledby="accordionPassengerAdditionalInfo" data-parent="#accordionPassenger">
                    <div class="card-body">
                      <div class="row mb-3">
                        <div class="col-6">
                          <div class="input-group flex-nowrap">
                            <div class="input-group-prepend custom-sm">
                              <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.statement.tabs.claim.frequentFlyer">
                                Frequent flyer
                              </span>
                            </div>
                            <input type="text"
                                   class="w-100 form-control custom-sm"
                                   name="baggageFrequentFlyer"
                                   [(ngModel)]="statement.passenger.additionalInfo.ffp"
                                   (keyup)="autoSave()"
                                   appUpperCase/>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="input-group flex-nowrap">
                            <div class="input-group-prepend custom-sm">
                              <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.statement.tabs.claim.category">
                                Category
                              </span>
                            </div>
                            <ng-select [items]="localReferences.passenger_status"
                                       class="w-100 custom-sm"
                                       [bindLabel]="globalSettings.language === 'en' ? 'nameEnglish' : 'nameLocal'"
                                       [bindValue]="globalSettings.language === 'en' ? 'nameEnglish' : 'nameLocal'"
                                       name="passengerStatus"
                                       [(ngModel)]="statement.passenger.additionalInfo.status"
                                       (change)="autoSave()">
                            </ng-select>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-3">
                        <div class="col-6">
                          <div class="input-group flex-nowrap">
                            <div class="input-group-prepend custom-sm">
                              <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.statement.tabs.claim.sex">
                                Sex
                              </span>
                            </div>
                            <ng-select [items]="localReferences.gender"
                                       class="w-100 custom-sm"
                                       [bindLabel]="globalSettings.language === 'en' ? 'nameEnglish' : 'nameLocal'"
                                       [bindValue]="globalSettings.language === 'en' ? 'nameEnglish' : 'nameLocal'"
                                       name="passengerSex"
                                       [(ngModel)]="statement.passenger.additionalInfo.gender"
                                       (change)="autoSave()">
                            </ng-select>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="input-group flex-nowrap">
                            <div class="input-group-prepend custom-sm">
                              <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.statement.tabs.claim.language">
                                Language
                              </span>
                            </div>
                            <ng-select [items]="localReferences.languages"
                                       class="w-100 custom-sm"
                                       [bindLabel]="globalSettings.language === 'en' ? 'nameEnglish' : 'nameLocal'"
                                       [bindValue]="globalSettings.language === 'en' ? 'nameEnglish' : 'nameLocal'"
                                       name="passengerLanguage"
                                       [(ngModel)]="statement.passenger.additionalInfo.language"
                                       (change)="autoSave()">
                            </ng-select>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-3">
                        <div class="col-12">
                          <div class="input-group flex-nowrap">
                            <div class="input-group-prepend custom-sm">
                              <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.statement.tabs.claim.passengersCount">
                                Passengers count
                              </span>
                            </div>
                            <input type="number"
                                   class="w-100 form-control custom-sm"
                                   name="baggageFrequentFlyer"
                                   [(ngModel)]="statement.passenger.additionalInfo.amount"
                                   (keyup)="autoSave()" />
                          </div>
                        </div>
                      </div>
                      <div class="input-group flex-nowrap mb-3">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0">
                            <span i18n="laf|Statement tabs@@laf.statement.tabs.claim.fullName">Full name</span>&nbsp;2&nbsp;
                          </span>
                        </div>
                        <input type="text"
                               class="w-100 form-control custom-sm"
                               name="passengerSurname"
                               [(ngModel)]="statement.passenger.additionalInfo.passengers[0].surname"
                               (keyup)="autoSave()"
                               placeholder="Surname"
                               appUpperCase
                               i18n-placeholder="laf|Statement tabs@@laf.statement.tabs.claim.placeholder.surname"/>
                        <input type="text"
                               class="w-100 form-control custom-sm"
                               name="passengerName"
                               [(ngModel)]="statement.passenger.additionalInfo.passengers[0].name"
                               (keyup)="autoSave()"
                               placeholder="Name"
                               appUpperCase
                               i18n-placeholder="laf|Statement tabs@@laf.statement.tabs.claim.placeholder.name"/>
                        <input type="text"
                               class="w-100 form-control custom-sm"
                               name="passengerMiddleName"
                               [(ngModel)]="statement.passenger.additionalInfo.passengers[0].middleName"
                               (keyup)="autoSave()"
                               placeholder="Middle name"
                               appUpperCase
                               i18n-placeholder="laf|Statement tabs@@laf.statement.tabs.claim.placeholder.middleName"/>
                      </div>
                      <div class="input-group flex-nowrap">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0">
                            <span i18n="laf|Statement tabs@@laf.statement.tabs.claim.fullName">Full name</span>&nbsp;3&nbsp;
                          </span>
                        </div>
                        <input type="text"
                               class="w-100 form-control custom-sm"
                               name="passengerSurname"
                               [(ngModel)]="statement.passenger.additionalInfo.passengers[1].surname"
                               (keyup)="autoSave()"
                               placeholder="Surname"
                               appUpperCase
                               i18n-placeholder="laf|Statement tabs@@laf.statement.tabs.claim.placeholder.surname"/>
                        <input type="text"
                               class="w-100 form-control custom-sm"
                               name="passengerName"
                               [(ngModel)]="statement.passenger.additionalInfo.passengers[1].name"
                               (keyup)="autoSave()"
                               placeholder="Name"
                               appUpperCase
                               i18n-placeholder="laf|Statement tabs@@laf.statement.tabs.claim.placeholder.name"/>
                        <input type="text"
                               class="w-100 form-control custom-sm"
                               name="passengerMiddleName"
                               [(ngModel)]="statement.passenger.additionalInfo.passengers[1].middleName"
                               (keyup)="autoSave()"
                               placeholder="Middle name"
                               appUpperCase
                               i18n-placeholder="laf|Statement tabs@@laf.statement.tabs.claim.placeholder.middleName"/>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header p-0" id="accordionTemporaryAddress">
                    <h2 class="mb-0">
                      <button class="btn btn-block text-left collapsed d-flex justify-content-between align-items-center"
                              type="button"
                              data-toggle="collapse"
                              data-target="#passengerTemporaryAddress"
                              aria-expanded="false"
                              aria-controls="passengerTemporaryAddress">
                          <div class="d-flex">
                            <span class="d-flex align-items-center text-light-blue align-midle" i18n="laf|Statement tabs@@laf.statement.tabs.claim.temporaryStay">
                              Temporary stay
                            </span>
                            <span class="text-dark-gray ml-2"
                                    *ngIf="statement.passenger.additionalInfo.temporary.address || statement.passenger.additionalInfo.temporary.phone ||
                                          statement.passenger.additionalInfo.temporary.dueDate">
                                <span class="material-icons">done</span>
                            </span>
                          </div>
                          <span class="text-gray-blue-dark d-flex align-items-center" *ngIf="statement.passenger.additionalInfo.temporary.dueDate">
                            <span i18n="laf|Statement tabs@@laf.statement.tabs.claim.dateOfStay">Date of stay</span>&nbsp;
                            {{statement.passenger.additionalInfo.temporary.dueDate | date: 'dd.MM.yyyy'}}
                          </span>
                      </button>
                    </h2>
                  </div>
                  <div id="passengerTemporaryAddress" class="collapse" aria-labelledby="accordionTemporaryAddress" data-parent="#accordionPassenger">
                    <div class="card-body">
                      <div class="input-group mb-3">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0"
                              i18n="laf|Statement tabs@@laf.statement.tabs.claim.temporaryAddress">Temporary address</span>
                        </div>
                        <textarea class="form-control form-control-sm"
                                  [(ngModel)]="statement.passenger.additionalInfo.temporary.address"
                                  rows="2"
                                  (keyup)="autoSave()"
                                  appUpperCase>{{ statement.passenger.additionalInfo.temporary.address }}</textarea>
                      </div>
                      <div class="row">
                        <div class="col-6 pr-0">
                          <div class="input-group flex-nowrap">
                            <div class="input-group-prepend custom-sm">
                              <span class="input-group-text font-small py-0"
                                  i18n="laf|Statement tabs@@laf.statement.tabs.claim.phone">
                                Phone
                              </span>
                            </div>
                            <input type="text"
                                   class="w-100 form-control custom-sm"
                                   name="additionalInfoPhone"
                                   [(ngModel)]="statement.passenger.additionalInfo.temporary.phone"
                                   (keyup)="autoSave()" />
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="input-group flex-nowrap">
                            <div class="input-group-prepend custom-sm">
                              <span class="input-group-text font-small py-0"
                                  i18n="laf|Statement tabs@@laf.statement.tabs.claim.dateOfStay">
                                Date of stay
                              </span>
                            </div>
                            <input type="date"
                                   class="form-control form-control custom-sm"
                                   [ngModel]="statement.passenger.additionalInfo.temporary.dueDate | date: 'yyyy-MM-dd'"
                                   (blur)="statement.passenger.additionalInfo.temporary.dueDate = parseDate($event.target.value); autoSave()"
                                   name="additionalInfoDate">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tab-pane fade pt-3" id="baggage" role="tabpanel" aria-labelledby="baggage-tab">
      <ul class="nav nav-pills mb-3 baggage-items" role="tablist">
        <button class="btn btn-ico btn-light-gray btn-xs mr-2"
              (click)="addItemBaggage();">
          <span class="material-icons">add</span>
        </button>
        <li class="nav-item" *ngFor="let baggageItem of statement.baggage; let i = index">
          <a class="nav-link"
             id="baggage-{{baggageItem.tag || 'NO-TAG' + i}}-tab"
             data-toggle="pill"
             data-target="#baggage-{{baggageItem.tag || 'NO-TAG' + i}}"
             role="tab"
             aria-selected="true"
             (click)="viewParametrs.baggageBlock = i"
             [ngClass]="{'show active': viewParametrs.baggageBlock === i}">
            {{baggageItem.tag || 'NO-TAG' | uppercase}}
          </a>
        </li>
      </ul>
      <div class="tab-content">
        <div class="tab-pane fade show active"
             *ngFor="let baggageItem of statement.baggage; let i = index"
             id="baggage-{{baggageItem.tag || 'NO-TAG' + i}}"
             role="tabpanel"
             attr.aria-labelledby="baggage-{{baggageItem.tag || 'NO-TAG' + i}}-tab"
             [ngClass]="{'show active': viewParametrs.baggageBlock === i}">
          <div class="row">
            <div class="col-5 pr-0">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex justify-content-between align-items-center mb-3">
                    <p class="font-weight-bold text-blue h5 m-0" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.placeOfBaggage">Place of baggage</p>
                    <button class="btn btn-ico btn-light-gray btn-xs"
                            (click)="viewParametrs.baggageBlock = i-1; removeItem(statement.baggage, i); autoSave()">
                      <span class="material-icons">delete</span>
                    </button>
                  </div>
                  <div class="row mb-3">
                    <div class="col pr-0">
                      <div class="input-group flex-nowrap">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0">
                            <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.tagNumber">Tag</span>&nbsp;
                            <span *ngIf="statement.typeId == 1 || statement.typeId == 3 || statement.typeId == 2 || statement.typeId == 5 || statement.typeId == 7 || statement.typeId == 8" class="required-asterisk">*</span>
                          </span>
                        </div>
                        <input type="text"
                               class="w-100 form-control custom-sm"
                               name="baggageTag"
                               [(ngModel)]="baggageItem.tag"
                               (keyup)="autoSave()"
                               appUpperCase/>
                      </div>
                    </div>
                    <div class="col">
                      <div class="input-group flex-nowrap">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.state">
                            State
                          </span>
                        </div>
                        <ng-select [items]="baggage_statuses"
                                   class="w-100 custom-sm select-long"
                                   bindLabel="nameLocal"
                                   bindValue="id"
                                   name="baggageState"
                                   appendTo="body"
                                   [selectOnTab]="true"
                                   [loading]="selectLoadAnimation.baggage_statuses_animation"
                                   (scroll)="onScrollNgSelect($event, 'baggage_statuses')"
                                   (scrollToEnd)="onScrollToEndNgSelect('baggage_statuses')"
                                   [(ngModel)]="baggageItem.statusId"
                                   (change)="autoSave()">
                        </ng-select>
                      </div>
                    </div>
                    <div class="col-auto text-center pl-0 pt-1">
                      <div class="custom-control custom-switch">
                        <input type="checkbox"
                               class="custom-control-input"
                               id="statementsAll_{{i}}"
                               name="statementsAll_{{i}}"
                               [(ngModel)]="baggageItem.noTag"
                               [checked]="baggageItem.noTag"
                               (change)="autoSave()">
                        <label class="custom-control-label" for="statementsAll_{{i}}" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.noTag">No tag</label>
                      </div>
                    </div>
                  </div>
                  <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0">
                        <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.weightCheckedDeliveryLost">Weight Checked in / Delivery / Lost</span>&nbsp;
                        <span *ngIf="statement.typeId == 1 || statement.typeId == 3 || statement.typeId == 2 || statement.typeId == 5 || statement.typeId == 7 || statement.typeId == 8" class="required-asterisk">*</span>
                      </span>
                    </div>
                    <input type="number"
                           class="w-100 form-control custom-sm"
                           name="baggageWeightExpected"
                           [(ngModel)]="baggageItem.weight.expected"
                           (keyup)="autoSave()" />
                    <input type="number"
                           class="w-100 form-control custom-sm"
                           name="baggageWeightActual"
                           [(ngModel)]="baggageItem.weight.actual"
                           (keyup)="autoSave()" />
                    <input type="number"
                           class="w-100 form-control custom-sm"
                           value="{{baggageItem.weight.expected - baggageItem.weight.actual}}"
                           disabled />
                  </div>
                  <!-- <div class="row mb-3">
                    <div class="col-6 pr-0">
                      <div class="input-group flex-nowrap">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.paidBaggageReceipt">
                            Paid baggage receipt
                          </span>
                        </div>
                        <input type="text" class="w-100 form-control custom-sm" />
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="input-group flex-nowrap">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.cost">
                            Cost
                          </span>
                        </div>
                        <input type="text" class="w-100 form-control custom-sm" />
                      </div>
                    </div>
                  </div> -->
                  <div class="accordion" id="accordionBaggage">
                    <div class="card">
                      <div class="card-header p-0" id="headingOne">
                        <h2 class="mb-0">
                          <button class="btn btn-block text-left collapsed d-flex justify-content-between align-items-center"
                                  type="button"
                                  data-toggle="collapse"
                                  data-target="#collapseOne"
                                  aria-expanded="true"
                                  aria-controls="collapseOne">
                            <div>
                              <span class="text-light-blue" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.bag">Bag</span>&nbsp;
                              <span *ngIf="(statement.typeId == 1 || statement.typeId == 3 || statement.typeId == 2 || statement.typeId == 5 || statement.typeId == 7 || statement.typeId == 8) && getCharacteristicCode(baggageItem) == 'XXXXX'" class="required-asterisk">*</span>
                            </div>
                            <span class="text-dark-gray">
                              {{ getCharacteristicCode(baggageItem) }}
                            </span>
                          </button>
                        </h2>
                      </div>
                      <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionBaggage">
                        <div class="card-body">
                          <div class="row mb-3">
                            <div class="col-12">
                              <div class="input-group flex-nowrap">
                                <div class="input-group-prepend custom-sm">
                                  <span class="input-group-text font-small py-0">
                                    <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.characteristicsCode">Characteristics code</span>&nbsp;
                                    <span *ngIf="statement.typeId == 1 || statement.typeId == 3 || statement.typeId == 2 || statement.typeId == 5 || statement.typeId == 7 || statement.typeId == 8" class="required-asterisk">*</span>
                                  </span>
                                </div>
                                <input type="text"
                                       class="w-100 form-control custom-sm"
                                       name="baggageTag"
                                       (input)="getCharacteristicFromCode(baggageItem, $event.target.value)"
                                       (blur)="$event.target.value = ''"
                                       [placeholder]="getCharacteristicCode(baggageItem)"
                                       (keyup)="autoSave()"
                                       />
                                <div class="input-group-append">
                                    <button class="btn btn-h-29 btn-ico btn-outline-secondary"
                                            type="button"
                                            (click)="copyToClipboard(getCharacteristicCode(baggageItem))">
                                      <i class="material-icons text-dark-gray"
                                         ngbTooltip="Copy code"
                                         i18n-ngbTooltip="laf|Statement tabs@@laf.statement.tabs.baggage.copyCode">content_copy</i>
                                    </button>
                                </div>
                                <!-- <i class="material-icons ml-3 text-dark-gray"
                                    ngbTooltip="Copy code"
                                    i18n-ngbTooltip="laf|Statement tabs@@laf.statement.tabs.baggage.copyCode"
                                    (click)="copyToClipboard(getCharacteristicCode(baggageItem))">content_copy</i> -->
                              </div>
                            </div>
                          </div>
                          <div class="row mb-3">
                            <div class="col-6 pr-0">
                              <div class="input-group flex-nowrap">
                                <div class="input-group-prepend custom-sm">
                                  <span class="input-group-text font-small py-0">
                                    <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.type">Type</span>&nbsp;
                                    <span *ngIf="statement.typeId == 1 || statement.typeId == 3 || statement.typeId == 2 || statement.typeId == 5 || statement.typeId == 7 || statement.typeId == 8" class="required-asterisk">*</span>
                                  </span>
                                </div>
                                <ng-select class="w-100 custom-sm p-0 select-long"
                                           [(ngModel)]="baggageItem.characteristics.typeId"
                                           name="baggageItemType{{i}}"
                                           bindLabel="nameEnglish"
                                           bindValue="id"
                                           appendTo="body"
                                           [selectOnTab]="true"
                                           [loading]="selectLoadAnimation.baggage_types"
                                           [items]="references.baggage_types"
                                           (change)="autoSave()">
                                  <ng-template ng-option-tmp let-item="item">
                                    <div class="font-small">
                                      <span class="font-weight-bold">{{item.code}}</span>&nbsp;{{item.nameEnglish}}
                                    </div>
                                  </ng-template>
                                </ng-select>
                              </div>
                            </div>
                            <div class="col-6">
                              <div class="input-group flex-nowrap">
                                <div class="input-group-prepend custom-sm">
                                  <span class="input-group-text font-small py-0">
                                    <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.material">Material</span>&nbsp;
                                    <span *ngIf="statement.typeId == 1 || statement.typeId == 3 || statement.typeId == 2 || statement.typeId == 5 || statement.typeId == 7 || statement.typeId == 8" class="required-asterisk">*</span>
                                  </span>
                                </div>
                                <ng-select class="w-100 custom-sm p-0 select-long"
                                          [(ngModel)]="baggageItem.characteristics.materialId"
                                          name="baggageItemMaterial{{i}}"
                                          bindLabel="nameEnglish"
                                          bindValue="id"
                                          appendTo="body"
                                          [selectOnTab]="true"
                                          [disabled]="baggageItem.characteristics.typeId === 13 || baggageItem.characteristics.typeId === 14"
                                          [loading]="selectLoadAnimation.baggage_materials"
                                          [items]="references.baggage_materials"
                                          (change)="autoSave()">
                                  <ng-template ng-option-tmp let-item="item">
                                    <div class="font-small">
                                      <span class="font-weight-bold">{{item.code}}</span>&nbsp;{{item.nameEnglish}}
                                    </div>
                                  </ng-template>
                                </ng-select>
                              </div>
                            </div>
                          </div>
                          <div class="row mb-3">
                            <div class="col-6 pr-0">
                              <div class="input-group flex-nowrap">
                                <div class="input-group-prepend custom-sm">
                                  <span class="input-group-text font-small py-0">
                                    <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.color">Color</span>&nbsp;
                                    <span *ngIf="statement.typeId == 1 || statement.typeId == 3 || statement.typeId == 2 || statement.typeId == 5 || statement.typeId == 7 || statement.typeId == 8" class="required-asterisk">*</span>
                                  </span>
                                </div>
                                <ng-select class="w-100 custom-sm p-0 select-long"
                                           [(ngModel)]="baggageItem.characteristics.colorId"
                                           name="baggageItemColor{{i}}"
                                           bindLabel="nameEnglish"
                                           bindValue="id"
                                           appendTo="body"
                                           [selectOnTab]="true"
                                           [loading]="selectLoadAnimation.baggage_colors"
                                           [items]="references.baggage_colors"
                                           (change)="autoSave()">
                                  <ng-template ng-option-tmp let-item="item">
                                    <div class="font-small">
                                      <span class="font-weight-bold">{{item.code}}</span>&nbsp;{{item.nameEnglish}}
                                    </div>
                                  </ng-template>
                                </ng-select>
                              </div>
                            </div>
                            <div class="col-6">
                              <div class="input-group flex-nowrap">
                                <div class="input-group-prepend custom-sm">
                                  <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.brand">Brand</span>
                                </div>
                                <input type="text"
                                       class="w-100 form-control custom-sm"
                                       name="baggageTag"
                                       [(ngModel)]="baggageItem.characteristics.brand"
                                       (keyup)="autoSave()"
                                       appUpperCase/>
                              </div>
                            </div>
                          </div>
                          <div class="row mb-3">
                            <div class="col-8 pr-0">
                              <div class="input-group flex-nowrap">
                                <div class="input-group-prepend custom-sm">
                                  <span class="input-group-text font-small py-0">
                                    <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.external">External</span>&nbsp;
                                    <span *ngIf="statement.typeId == 1 || statement.typeId == 3 || statement.typeId == 2 || statement.typeId == 5 || statement.typeId == 7 || statement.typeId == 8" class="required-asterisk">*</span>
                                  </span>
                                </div>
                                <ng-select class="w-100 custom-sm p-0 airports-long"
                                           [(ngModel)]="baggageItem.characteristics.externalElements"
                                           name="baggageItemElements{{i}}"
                                           [multiple]="true"
                                           bindLabel="code"
                                           bindValue="id"
                                           [loading]="selectLoadAnimation.baggage_elements"
                                           [items]="references.baggage_elements"
                                           (change)="autoSave()">
                                  <ng-template ng-option-tmp let-item="item">
                                    <div class="font-small">
                                      {{item.name}}
                                    </div>
                                  </ng-template>
                                </ng-select>
                              </div>
                            </div>
                            <div class="col-4">
                              <div class="input-group flex-nowrap">
                                <div class="input-group-prepend custom-sm">
                                  <span class="input-group-text font-small py-0"
                                      i18n="laf|Statement tabs@@laf.statement.tabs.baggage.keyCode">
                                    Key code
                                  </span>
                                </div>
                                <input type="text"
                                       class="w-100 form-control custom-sm"
                                       name="baggageTag"
                                       [(ngModel)]="baggageItem.characteristics.lockCode"
                                       (keyup)="autoSave()"
                                       appUpperCase/>
                              </div>
                            </div>
                          </div>
                          <div class="input-group">
                            <div class="input-group-prepend custom-sm">
                              <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.note">Note</span>
                            </div>
                            <textarea class="form-control form-control-sm"
                                      [(ngModel)]="baggageItem.characteristics.description"
                                      rows="2"
                                      (keyup)="autoSave()"
                                      appUpperCase>{{baggageItem.characteristics.description}}</textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header p-0" id="headingTwo">
                        <h2 class="mb-0">
                          <button class="btn btn-block text-left collapsed d-flex justify-content-between align-items-center"
                                  type="button"
                                  data-toggle="collapse"
                                  data-target="#collapseTwo"
                                  aria-expanded="false"
                                  aria-controls="collapseTwo">
                            <span>
                              <span class="text-light-blue" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.pantry">Baggage route</span>&nbsp;
                              <span class="text-dark-gray">{{ baggageItem.fullRoute }}</span>
                            </span>
                          </button>
                        </h2>
                      </div>
                      <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionBaggage">
                        <div class="card-body">
                          <table class="w-100 mb-3">
                            <tr>
                              <td width="18%" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.flight">Flight</td>
                              <td i18n="laf|Statement tabs@@laf.statement.tabs.baggage.route">Date</td>
                              <td></td>
                              <td width="18%" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.from">From</td>
                              <td width="18%" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.to">To</td>
                              <td></td>
                            </tr>
                            <tr *ngFor="let route of baggageItem.route; let i_route = index">
                              <td>
                                <input type="text"
                                       class="form-control custom-sm"
                                       name="flightItemFlight_{{i_route}}"
                                       [(ngModel)]="route.flight"
                                       [disabled]="baggageItem.routeExtendsFlight"
                                       (keyup)="autoSave()"
                                       appUpperCase/>
                              </td>
                              <td>
                                <input type="date"
                                       class="form-control custom-sm"
                                       [ngModel]="route.dt | date: 'yyyy-MM-dd'"
                                       (blur)="route.dt = parseDate($event.target.value)"
                                       name="flightItemDate_{{i_route}}"
                                       #flightItemDate
                                       #flightItemDate{{i_route}}="ngModel"
                                       [disabled]="baggageItem.routeExtendsFlight"
                                       (keyup)="autoSave()">
                              </td>
                              <td>
                                <input type="time"
                                       class="form-control custom-sm"
                                       [ngModel]="route.dt | date: 'HH:mm'"
                                       (blur)="route.dt = parseDate(flightItemDate.value, $event.target.value);"
                                       name="flightItemTime{{i_route}}"
                                       #flightItemTime
                                       #flightItemTime{{i_route}}="ngModel"
                                       [disabled]="baggageItem.routeExtendsFlight"
                                       (keyup)="autoSave()">
                              </td>
                              <td>
                                <ng-select class="w-100 custom-sm p-0"
                                           [items]="references.airports"
                                           bindLabel="iata"
                                           bindValue="id"
                                           [(ngModel)]="route.departureId"
                                           name="departureAirline_{{i_route}}"
                                           [virtualScroll]="true"
                                           [loading]="selectLoadAnimation.airports"
                                           (scroll)="onScrollNgSelect($event, 'airports')"
                                           (scrollToEnd)="onScrollToEndNgSelect('airports')"
                                           [searchFn]="customSelectSearch"
                                           [disabled]="baggageItem.routeExtendsFlight"
                                           (change)="setAttribute(route, 'departure', $event.iata); autoSave()">
                                  <ng-template ng-option-tmp let-item="item">
                                    <div class="font-small">
                                      <span class="font-weight-bold">{{item.iata}}</span>&nbsp;{{item.name}}
                                    </div>
                                  </ng-template>
                                </ng-select>
                              </td>
                              <td>
                                <ng-select class="w-100 custom-sm p-0"
                                          [items]="references.airports"
                                          bindLabel="iata"
                                          bindValue="id"
                                          [(ngModel)]="route.arrivalId"
                                          name="arrivalAirline_{{i_route}}"
                                          [virtualScroll]="true"
                                          [loading]="selectLoadAnimation.airports"
                                          (scroll)="onScrollNgSelect($event, 'airports')"
                                          (scrollToEnd)="onScrollToEndNgSelect('airports')"
                                          [searchFn]="customSelectSearch"
                                          [disabled]="baggageItem.routeExtendsFlight"
                                          (change)="setAttribute(route, 'arrival', $event.iata); autoSave()">
                                  <ng-template ng-option-tmp let-item="item">
                                    <div class="font-small">
                                      <span class="font-weight-bold">{{item.iata}}</span>&nbsp;{{item.name}}
                                    </div>
                                  </ng-template>
                                </ng-select>
                              </td>
                              <td>
                                <span class="material-icons text-red" *ngIf="!baggageItem.routeExtendsFlight"
                                      (click)="removeItem(baggageItem.route, i_route); autoSave();">close</span>
                              </td>
                            </tr>
                          </table>
                          <div class="d-flex justify-content-between align-items-center">
                            <div class="custom-control custom-switch">
                              <input type="checkbox"
                                     class="custom-control-input"
                                     id="routeFlightCoincides_{{i}}"
                                     name="routeFlightCoincides_{{i}}"
                                     [(ngModel)]="baggageItem.routeExtendsFlight"
                                     (change)="checkRouteCoincides($event.target.checked, i); autoSave()">
                              <label class="custom-control-label"
                                    for="routeFlightCoincides_{{i}}"
                                    i18n="laf|Statement tabs@@laf.statement.tabs.baggage.routeFlightCoincides">
                                The route coincides with the passenger's route
                              </label>
                            </div>
                            <button class="btn btn-sm btn-blue"
                                    *ngIf="!baggageItem.routeExtendsFlight"
                                    (click)="addItem(baggageItem.route, classes.flight)"
                                    i18n="laf|Main - button@@laf.main.button.add">
                              Add
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header p-0" id="headingThree">
                        <h2 class="mb-0">
                          <button class="btn btn-block text-left collapsed d-flex justify-content-between align-items-center"
                                  type="button"
                                  data-toggle="collapse"
                                  data-target="#collapseThree"
                                  aria-expanded="false"
                                  aria-controls="collapseThree">
                            <div class="d-flex">
                              <span class="d-flex align-items-center text-light-blue align-midle" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.content">
                                Content
                              </span>
                              <span class="text-dark-gray ml-2"
                                    *ngIf="baggageItem.characteristics.internalElements && baggageItem.characteristics.internalElements.length > 0">
                                <span class="material-icons">done</span>
                              </span>
                            </div>
                          </button>
                        </h2>
                      </div>
                      <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionBaggage">
                        <div class="card-body">
                          <div class="d-flex align-items-center justify-content-between">
                            <div>
                              <p *ngIf="internalElementsCount(baggageItem.characteristics.internalElements) == 0; else internalElementSum" class="m-0 text-center text-gray-blue-dark" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.content.annotation">No items</p>
                              <ng-template #internalElementSum>
                                <p class="m-0 text-center text-gray-blue-dark">
                                  <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.content.totalItems">Total recorded</span>:&nbsp;{{internalElementsCount(baggageItem.characteristics.internalElements)}}
                                </p>
                              </ng-template>
                            </div>
                            <div>
                              <button class="btn btn-sm btn-blue"
                                      data-toggle="modal"
                                      data-target=".content-card"
                                      i18n="laf|Statement tabs@@laf.statement.tabs.baggage.content.button.edit">
                                Edit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-4 pr-0">
              <div class="accordion" id="accordionFlight">
                <div class="card">
                  <div class="card-header p-0" id="headingDaparture">
                    <h2 class="mb-0">
                      <button *ngIf="statement.typeId == 3 || statement.typeId == 2; else bagDeparture" class="btn btn-block text-left collapsed d-flex justify-content-between align-items-center text-black-50" type="button" disabled>
                        <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.departure">Departure</span>
                      </button>
                      <ng-template #bagDeparture>
                        <button class="btn btn-block text-left collapsed d-flex justify-content-between align-items-center"
                                type="button"
                                data-toggle="collapse"
                                data-target="#collapseDeparture"
                                aria-expanded="true"
                                aria-controls="collapseDeparture">
                          <span>
                            <span class="text-light-blue" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.departure">Departure</span>&nbsp;
                            <span class="text-dark-gray"></span>
                          </span>
                          <span class="text-gray-blue-dark d-flex align-items-center">
                            <ng-container *ngFor="let route of baggageItem.forwarding.departure.flights;">
                              {{route.flight}} / {{route.dt | date: 'yyyy-MM-dd HH:mm'}}
                            </ng-container>
                          </span>
                        </button>
                      </ng-template>
                    </h2>
                  </div>
                  <div id="collapseDeparture" class="collapse" aria-labelledby="headingDaparture" data-parent="#accordionFlight">
                    <div class="card-body">
                      <div class="input-group flex-nowrap mb-3">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.baggageTag">Baggage tag</span>
                        </div>
                        <input type="text"
                               class="w-100 form-control custom-sm"
                               name="baggageTag"
                               [(ngModel)]="baggageItem.forwarding.departure.tag"
                               (keyup)="autoSave()"
                               appUpperCase/>
                      </div>
                      <table class="w-100 mb-3" *ngIf="baggageItem.forwarding.departure.flights.length">
                        <thead>
                          <tr>
                            <th i18n="laf|Statement tabs@@laf.statement.tabs.baggage.flight" width="90px">Flight</th>
                            <th i18n="laf|Statement tabs@@laf.statement.tabs.baggage.date" width="50px">Date</th>
                            <th i18n="laf|Statement tabs@@laf.statement.tabs.baggage.time" width="50px">Time</th>
                            <th width="30px"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <ng-container *ngFor="let route of baggageItem.forwarding.departure.flights; let i = index">
                            <tr>
                              <td>
                                <input type="text"
                                      class="form-control custom-sm"
                                      name="flightItemFlight_{{i}}"
                                      [(ngModel)]="route.flight"
                                      (keyup)="autoSave()"
                                      appUpperCase/>
                              </td>
                              <td>
                                <input type="date"
                                       class="form-control custom-sm"
                                       [ngModel]="route.dt | date: 'yyyy-MM-dd'"
                                       (blur)="route.dt = parseDate($event.target.value, flightItemTime.value); autoSave()"
                                       name="flightItemDate_{{i}}"
                                       #flightItemDate
                                       #flightItemDate{{i}}="ngModel">
                              </td>
                              <td>
                                <input type="time"
                                       class="form-control custom-sm"
                                       [ngModel]="route.dt | date: 'HH:mm'"
                                       (blur)="route.dt = parseDate(flightItemDate.value, $event.target.value); autoSave()"
                                       name="flightItemTime{{i}}"
                                       #flightItemTime
                                       #flightItemTime{{i}}="ngModel">
                              </td>
                              <td rowspan="2" class="align-middle">
                                <span class="material-icons text-red" (click)="removeItem(baggageItem.forwarding.departure.flights, i); autoSave();">close</span>
                              </td>
                            </tr>
                            <tr>
                              <td colspan="3">
                                <div class="d-flex">
                                  <div class="input-group flex-nowrap mr-2">
                                    <div class="input-group-prepend custom-sm">
                                      <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.from">From</span>
                                    </div>
                                    <ng-select class="w-100 custom-sm p-0 select-long"
                                               [items]="references.airports"
                                               bindLabel="iata"
                                               bindValue="id"
                                               name="flightItemDepartureAirline_{{i}}"
                                               appendTo="body"
                                               [selectOnTab]="true"
                                               [(ngModel)]="route.departureId"
                                               [virtualScroll]="true"
                                               [loading]="selectLoadAnimation.airports"
                                               (scroll)="onScrollNgSelect($event, 'airports')"
                                               (scrollToEnd)="onScrollToEndNgSelect('airports')"
                                               [searchFn]="customSelectSearch"
                                               (change)="setAttribute(route, 'departure', $event.iata); autoSave()">
                                      <ng-template ng-option-tmp
                                                   let-item="item">
                                        <div class="font-small">
                                          <span class="font-weight-bold">{{item.iata}}</span>&nbsp;{{item.name}}
                                        </div>
                                      </ng-template>
                                    </ng-select>
                                  </div>
                                  <div class="input-group flex-nowrap">
                                    <div class="input-group-prepend custom-sm">
                                      <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.to">To</span>
                                    </div>
                                    <ng-select class="w-100 custom-sm p-0 select-long"
                                               [items]="references.airports"
                                               bindLabel="iata"
                                               bindValue="id"
                                               name="flightItemArrivalAirline_{{i}}"
                                               appendTo="body"
                                               [selectOnTab]="true"
                                               [(ngModel)]="route.arrivalId"
                                               [virtualScroll]="true"
                                               [loading]="selectLoadAnimation.airports"
                                               (scroll)="onScrollNgSelect($event, 'airports')"
                                               (scrollToEnd)="onScrollToEndNgSelect('airports')"
                                               [searchFn]="customSelectSearch"
                                               (change)="setAttribute(route, 'arrival', $event.iata); autoSave()">
                                      <ng-template ng-option-tmp
                                                   let-item="item">
                                        <div class="font-small">
                                          <span class="font-weight-bold">{{item.iata}}</span>&nbsp;{{item.name}}
                                        </div>
                                      </ng-template>
                                    </ng-select>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </ng-container>
                        </tbody>
                      </table>
                      <button class="btn btn-sm btn-blue float-right mb-3"
                              (click)="addItem(baggageItem.forwarding.departure.flights, classes.flight)"
                              i18n="laf|Main - button@@laf.main.button.addFlight">
                        Add flight
                      </button>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header p-0" id="headingArrival">
                    <h2 class="mb-0">
                      <button *ngIf="statement.typeId == 3 || statement.typeId == 2; else bagArrival" class="btn btn-block text-left collapsed d-flex justify-content-between align-items-center text-black-50" type="button" disabled>
                        <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.arrival">Arrival</span>
                      </button>
                      <ng-template #bagArrival>
                        <button class="btn btn-block text-left collapsed d-flex justify-content-between align-items-center"
                                type="button"
                                data-toggle="collapse"
                                data-target="#collapseArrival"
                                aria-expanded="false"
                                aria-controls="collapseArrival">
                          <span>
                            <span class="text-light-blue" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.arrival">Arrival</span>&nbsp;
                            <span class="text-dark-gray"></span>
                          </span>
                          <span class="text-gray-blue-dark d-flex align-items-center">
                            <ng-container *ngFor="let route of baggageItem.forwarding.arrival.flights;">
                              {{route.flight}} / {{route.dt | date: 'yyyy-MM-dd HH:mm'}}
                            </ng-container>
                          </span>
                        </button>
                      </ng-template>
                    </h2>
                  </div>
                  <div id="collapseArrival" class="collapse" aria-labelledby="headingArrival" data-parent="#accordionFlight">
                    <div class="card-body">
                      <div class="input-group flex-nowrap mb-3">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.baggageTag">Baggage tag</span>
                        </div>
                        <input type="text"
                               class="w-100 form-control custom-sm"
                               name="baggageTag"
                               [(ngModel)]="baggageItem.forwarding.arrival.tag"
                               (keyup)="autoSave()"
                               appUpperCase/>
                      </div>
                      <table class="w-100 mb-3" *ngIf="baggageItem.forwarding.arrival.flights.length">
                        <thead>
                          <tr>
                            <th i18n="laf|Statement tabs@@laf.statement.tabs.baggage.flight" width="90px">Flight</th>
                            <th i18n="laf|Statement tabs@@laf.statement.tabs.baggage.date" width="50px">Date</th>
                            <th i18n="laf|Statement tabs@@laf.statement.tabs.baggage.time" width="50px">Time</th>
                            <th width="30px"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <ng-container *ngFor="let route of baggageItem.forwarding.arrival.flights; let i = index">
                            <tr>
                              <td>
                                <input type="text"
                                      class="form-control custom-sm"
                                      name="flightItemFlight_{{i}}"
                                      [(ngModel)]="route.flight"
                                      (keyup)="autoSave()"
                                      appUpperCase/>
                              </td>
                              <td>
                                <input type="date"
                                       class="form-control custom-sm"
                                       [ngModel]="route.dt | date: 'yyyy-MM-dd'"
                                       (blur)="route.dt = parseDate($event.target.value, flightItemTime.value); autoSave()"
                                       name="flightItemDate_{{i}}"
                                       #flightItemDate
                                       #flightItemDate{{i}}="ngModel">
                              </td>
                              <td>
                                <input type="time"
                                       class="form-control custom-sm"
                                       [ngModel]="route.dt | date: 'HH:mm'"
                                       (blur)="route.dt = parseDate(flightItemDate.value, $event.target.value); autoSave()"
                                       name="flightItemTime{{i}}"
                                       #flightItemTime
                                       #flightItemTime{{i}}="ngModel">
                              </td>
                              <td rowspan="2" class="align-middle">
                                <span class="material-icons text-red" (click)="removeItem(baggageItem.forwarding.arrival.flights, i); autoSave();">close</span>
                              </td>
                            </tr>
                            <tr>
                              <td colspan="3">
                                <div class="d-flex">
                                  <div class="input-group flex-nowrap mr-2">
                                    <div class="input-group-prepend custom-sm">
                                      <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.from">From</span>
                                    </div>
                                      <ng-select class="w-100 custom-sm p-0 select-long"
                                                [items]="references.airports"
                                                bindLabel="iata"
                                                bindValue="id"
                                                name="flightItemDepartureAirline_{{i}}"
                                                appendTo="body"
                                                [selectOnTab]="true"
                                                [(ngModel)]="route.departureId"
                                                [virtualScroll]="true"
                                                [loading]="selectLoadAnimation.airports"
                                                (scroll)="onScrollNgSelect($event, 'airports')"
                                                (scrollToEnd)="onScrollToEndNgSelect('airports')"
                                                [searchFn]="customSelectSearch"
                                                (change)="setAttribute(route, 'departure', $event.iata); autoSave()">
                                        <ng-template ng-option-tmp
                                                    let-item="item">
                                          <div class="font-small">
                                            <span class="font-weight-bold">{{item.iata}}</span>&nbsp;{{item.name}}
                                          </div>
                                        </ng-template>
                                      </ng-select>
                                  </div>
                                  <div class="input-group flex-nowrap">
                                    <div class="input-group-prepend custom-sm">
                                      <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.to">To</span>
                                    </div>
                                    <ng-select class="w-100 custom-sm p-0 select-long"
                                               [items]="references.airports"
                                               bindLabel="iata"
                                               bindValue="id"
                                               name="flightItemArrivalAirline_{{i}}"
                                               appendTo="body"
                                               [selectOnTab]="true"
                                               [(ngModel)]="route.arrivalId"
                                               [virtualScroll]="true"
                                               [loading]="selectLoadAnimation.airports"
                                               (scroll)="onScrollNgSelect($event, 'airports')"
                                               (scrollToEnd)="onScrollToEndNgSelect('airports')"
                                               [searchFn]="customSelectSearch"
                                               (change)="setAttribute(route, 'arrival', $event.iata); autoSave()">
                                      <ng-template ng-option-tmp
                                                   let-item="item">
                                        <div class="font-small">
                                          <span class="font-weight-bold">{{item.iata}}</span>&nbsp;{{item.name}}
                                        </div>
                                      </ng-template>
                                    </ng-select>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </ng-container>
                        </tbody>
                      </table>
                      <button class="btn btn-sm btn-blue float-right mb-3"
                              (click)="addItem(baggageItem.forwarding.arrival.flights, classes.flight)"
                              i18n="laf|Main - button@@laf.main.button.addFlight">
                        Add flight
                      </button>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header p-0" id="headingStorage">
                    <h2 class="mb-0">
                      <button *ngIf="statement.typeId == 3 || statement.typeId == 2; else bagStorage" class="btn btn-block text-left collapsed d-flex justify-content-between align-items-center text-black-50" type="button" disabled>
                        <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.storage">Storage</span>
                      </button>
                      <ng-template #bagStorage>
                        <button class="btn btn-block text-left collapsed d-flex justify-content-between align-items-center"
                                type="button"
                                data-toggle="collapse"
                                data-target="#collapseStorage"
                                aria-expanded="false"
                                aria-controls="collapseStorage">
                          <span>
                            <span class="text-light-blue" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.storage">Storage</span>&nbsp;
                            <span class="text-dark-gray">{{getById(adminData.storages, baggageItem.storage.id)?.name}}</span>
                          </span>
                          <span class="text-gray-blue-dark d-flex align-items-center"
                                *ngIf="baggageItem.storage.dtStart || baggageItem.storage.dtFinish">
                            <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.storage.from" *ngIf="baggageItem.storage.dtStart"> From</span>&nbsp;
                            {{baggageItem.storage.dtStart | date: 'dd.MM.yyyy'}}&nbsp;
                            <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.storage.to" *ngIf="baggageItem.storage.dtFinish">to</span>&nbsp;
                            {{baggageItem.storage.dtFinish | date: 'dd.MM.yyyy'}}
                          </span>
                        </button>
                      </ng-template>
                    </h2>
                  </div>
                  <div id="collapseStorage" class="collapse" aria-labelledby="headingStorage" data-parent="#accordionFlight">
                    <div class="card-body">
                      <div class="input-group flex-nowrap mb-3">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.storageLocation">Storage location</span>
                        </div>
                        <ng-select class="w-100 custom-sm p-0 select-long"
                                  [(ngModel)]="baggageItem.storage.id"
                                  name="baggageStorage"
                                  bindLabel="name"
                                  bindValue="id"
                                  appendTo="body"
                                  [selectOnTab]="true"
                                  [loading]="selectLoadAnimation.storages"
                                  [items]="adminData.storages"
                                  (change)="autoSave(); setStorageBeginDate($event, baggageItem.id)">
                        </ng-select>
                      </div>
                      <div class="row mb-3">
                        <div class="col-6 pr-0">
                          <div class="input-group flex-nowrap">
                            <div class="input-group-prepend custom-sm">
                              <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.dateFrom">Date from</span>
                            </div>
                            <input type="date"
                                   class="form-control custom-sm"
                                   [ngModel]="baggageItem.storage.dtStart | date: 'yyyy-MM-dd'"
                                   (blur)="baggageItem.storage.dtStart = parseDate($event.target.value)"
                                   name="storageDtStart"
                                   (keyup)="autoSave()">
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="input-group flex-nowrap">
                            <div class="input-group-prepend custom-sm">
                              <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.dateTo">Date to</span>
                            </div>
                            <input type="date"
                                   class="form-control custom-sm"
                                   [ngModel]="baggageItem.storage.dtFinish | date: 'yyyy-MM-dd'"
                                   (blur)="baggageItem.storage.dtFinish = parseDate($event.target.value)"
                                   name="storageDtFinish"
                                   (keyup)="autoSave()">
                          </div>
                        </div>
                      </div>
                      <div class="input-group">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.note">Note</span>
                        </div>
                        <textarea class="form-control form-control-sm"
                                  [(ngModel)]="baggageItem.storage.note"
                                  rows="2"
                                  (keyup)="autoSave()"
                                  appUpperCase>{{baggageItem.storage.note}}</textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header p-0" id="headingDelivery">
                    <h2 class="mb-0">
                      <button *ngIf="statement.typeId == 3 || statement.typeId == 2; else bagDelivery" class="btn btn-block text-left collapsed d-flex justify-content-between align-items-center text-black-50" type="button" disabled>
                        <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.delivery">Delivery</span>
                      </button>
                      <ng-template #bagDelivery>
                        <button class="btn btn-block text-left collapsed d-flex justify-content-between align-items-center"
                                type="button"
                                data-toggle="collapse"
                                data-target="#collapseDelivery"
                                aria-expanded="false"
                                aria-controls="collapseDelivery">
                          <span>
                            <span class="text-light-blue" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.delivery">Delivery</span>&nbsp;
                            <span class="text-dark-gray">{{baggageItem.delivery.address}}</span>
                          </span>
                          <span class="text-gray-blue-dark d-flex align-items-center" *ngIf="baggageItem.delivery.actualDt">
                            <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.actualDate">Actual date</span>&nbsp;
                            {{baggageItem.delivery.actualDt | date: 'dd.MM.yyyy'}}</span>
                        </button>
                      </ng-template>
                    </h2>
                  </div>
                  <div id="collapseDelivery" class="collapse" aria-labelledby="headingDelivery" data-parent="#accordionFlight">
                    <div class="card-body">
                      <div class="input-group flex-nowrap mb-3">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.deliveryAddress">Delivery address</span>
                        </div>
                        <ng-select [items]="localReferences.address_types"
                                   class="w-100 custom-sm"
                                   [bindLabel]="globalSettings.language === 'en' ? 'nameEnglish' : 'nameLocal'"
                                   [bindValue]="globalSettings.language === 'en' ? 'nameEnglish' : 'nameLocal'"
                                   name="baggageStorage"
                                   [(ngModel)]="baggageItem.delivery.address"
                                   (change)="autoSave()">
                        </ng-select>
                      </div>
                      <div class="input-group flex-nowrap mb-3">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0"
                            i18n="laf|Statement tabs@@laf.statement.tabs.baggage.scheduledDeliveryDate">Scheduled delivery date</span>
                        </div>
                        <input type="date"
                               class="form-control custom-sm"
                               [ngModel]="baggageItem.delivery.dt | date: 'yyyy-MM-dd'"
                               (blur)="baggageItem.delivery.dt = parseDate($event.target.value)"
                               name="deliveryDt"
                               (keyup)="autoSave()">
                      </div>
                      <div class="input-group flex-nowrap mb-3">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0"
                            i18n="laf|Statement tabs@@laf.statement.tabs.baggage.actualDeliveryDate">Actual delivery date</span>
                        </div>
                        <input type="date"
                               class="form-control custom-sm"
                               [ngModel]="baggageItem.delivery.actualDt | date: 'yyyy-MM-dd'"
                               (blur)="baggageItem.delivery.actualDt = parseDate($event.target.value)"
                               name="deliveryActualDt"
                               (keyup)="autoSave()">
                      </div>
                      <div class="input-group">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.forwarder">Forwarder</span>
                        </div>
                        <textarea class="form-control form-control-sm"
                                  [(ngModel)]="baggageItem.delivery.note"
                                  rows="2"
                                  (keyup)="autoSave()"
                                  appUpperCase>{{ baggageItem.delivery.note }}</textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header p-0" id="headingWorldTracer">
                    <h2 class="mb-0">
                      <button *ngIf="statement.typeId == 3 || statement.typeId == 2; else bagWorldTracer" class="btn btn-block text-left collapsed d-flex justify-content-between align-items-center text-black-50" type="button" disabled>
                        <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.worldTracer">WorldTracer</span>
                      </button>
                      <ng-template #bagWorldTracer>
                        <button class="btn btn-block text-left collapsed d-flex justify-content-between align-items-center"
                                type="button"
                                data-toggle="collapse"
                                data-target="#collapseWorldTracer"
                                aria-expanded="false"
                                aria-controls="collapseWorldTracer">
                          <span>
                            <span class="text-light-blue" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.worldTracer">WorldTracer</span>&nbsp;
                            <span class="text-dark-gray">{{baggageItem.worldTracer.statement}}</span>
                          </span>
                        </button>
                      </ng-template>
                    </h2>
                  </div>
                  <div id="collapseWorldTracer" class="collapse" aria-labelledby="headingWorldTracer" data-parent="#accordionFlight">
                    <div class="card-body">
                      <div class="input-group flex-nowrap mb-3">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.status">Status</span>
                        </div>
                        <ng-select class="w-100 custom-sm p-0"
                                  [(ngModel)]="baggageItem.worldTracer.statusId"
                                  name="baggageStorage"
                                  bindLabel="name"
                                  bindValue="id"
                                  [loading]="selectLoadAnimation.worldTracer"
                                  [items]="adminData.worldTracer"
                                  (change)="autoSave()">
                        </ng-select>
                      </div>
                      <div class="input-group flex-nowrap">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.number">Number</span>
                        </div>
                        <input type="text"
                               class="w-100 form-control custom-sm"
                               name="baggageTag"
                               [(ngModel)]="baggageItem.worldTracer.statement"
                               (keyup)="autoSave()"
                               appUpperCase/>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header p-0" id="headingDamage">
                    <h2 class="mb-0">
                      <button *ngIf="statement.typeId != 2 && statement.typeId != 3; else bagDamage"
                        class="btn btn-block text-left collapsed d-flex justify-content-between align-items-center text-black-50"
                        type="button" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.damage" disabled>
                        Damage
                      </button>
                      <ng-template #bagDamage>
                        <button class="btn btn-block text-left collapsed d-flex justify-content-between align-items-center"
                                type="button"
                                data-toggle="collapse"
                                data-target="#collapseDamage"
                                aria-expanded="false"
                                aria-controls="collapseDamage">
                          <span>
                            <span class="text-light-blue" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.damage">Damage</span>&nbsp;
                            <span *ngIf="statement.typeId == 3 && !baggageItem.damage.declaredValue &&
                            !baggageItem.damage.note && (!baggageItem.damage.damages ||
                            baggageItem.damage.damages.length != 0)" class="required-asterisk">*</span>&nbsp;
                            <span class="text-dark-gray"
                                  *ngIf="baggageItem.damage.damages.length > 0 || baggageItem.damage.declaredValue
                                  || baggageItem.damage.note">
                              <span class="material-icons">done</span>
                            </span>
                          </span>
                          <span class="text-gray-blue-dark d-flex align-items-center" *ngIf="baggageItem.damage.claim">
                            <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.amountClaims">Amount of claims</span>&nbsp;
                            {{baggageItem.damage.claim}}
                          </span>
                        </button>
                      </ng-template>
                    </h2>
                  </div>
                  <div id="collapseDamage" class="collapse" aria-labelledby="headingDamage" data-parent="#accordionFlight">
                    <div class="card-body">
                      <div class="d-flex align-items-center justify-content-between mb-3">
                        <div>
                          <p *ngIf="!baggageItem.damage.damages || baggageItem.damage.damages.length == 0; else damageElementSum" class="m-0 text-center text-gray-blue-dark" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.damage.annotation">No items</p>
                          <ng-template #damageElementSum>
                            <p class="m-0 text-center text-gray-blue-dark">
                              <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.damage.totalItems">Total recorded</span>:&nbsp;{{baggageItem.damage.damages.length}}
                            </p>
                          </ng-template>
                        </div>
                        <div>
                          <button class="btn btn-sm btn-blue"
                                  data-toggle="modal"
                                  data-target=".damage-card"
                                  i18n="laf|Statement tabs@@laf.statement.tabs.baggage.damage.button.edit">
                            Edit
                          </button>
                        </div>
                      </div>
                      <div class="input-group mb-3">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.note">Note</span>
                        </div>
                        <textarea class="form-control form-control-sm"
                                  [(ngModel)]="baggageItem.damage.note"
                                  name="baggageItemDamageNote"
                                  rows="2"
                                  (keyup)="autoSave()"
                                  appUpperCase>{{baggageItem.damage.note}}</textarea>
                      </div>
                      <div class="input-group flex-nowrap">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.amountClaims">Amount of claims </span>
                        </div>
                        <input type="number"
                               class="w-100 form-control custom-sm"
                               [(ngModel)]="baggageItem.damage.declaredValue"
                               (keyup)="autoSave()" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header p-0" id="headingShortage">
                    <h2 class="mb-0">
                      <button *ngIf="statement.typeId != 2 && statement.typeId != 3; else bagShortage"
                          class="btn btn-block text-left collapsed d-flex justify-content-between align-items-center text-black-50"
                          type="button" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.shortage" disabled>
                        Shortage
                      </button>
                      <ng-template #bagShortage>
                        <button class="btn btn-block text-left collapsed d-flex justify-content-between align-items-center"
                                type="button"
                                data-toggle="collapse"
                                data-target="#collapseShortage"
                                aria-expanded="false"
                                aria-controls="collapseShortage">
                          <span>
                            <span class="text-light-blue" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.shortage">Shortage</span>&nbsp;
                            <span *ngIf="statement.typeId == 2 && !baggageItem.pilferage.description && !baggageItem.pilferage.claim"
                                  class="required-asterisk">*</span>&nbsp;
                            <span class="text-dark-gray"
                                  *ngIf="baggageItem.pilferage.description || baggageItem.pilferage.claim">
                              <span class="material-icons">done</span>
                            </span>
                          </span>
                        </button>
                      </ng-template>
                    </h2>
                  </div>
                  <div id="collapseShortage" class="collapse" aria-labelledby="headingShortage" data-parent="#accordionFlight">
                    <div class="card-body">
                      <div class="input-group mb-3">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.note">Note</span>
                        </div>
                        <textarea class="form-control form-control-sm"
                                  [(ngModel)]="baggageItem.pilferage.description"
                                  name="pilferageNote"
                                  rows="2"
                                  (keyup)="autoSave()"
                                  appUpperCase>{{baggageItem.pilferage.description}}</textarea>
                      </div>
                      <div class="input-group flex-nowrap">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.amountClaims">Amount of claims </span>
                        </div>
                        <input type="number"
                               class="w-100 form-control custom-sm"
                               [(ngModel)]="baggageItem.pilferage.claim"
                               (keyup)="autoSave()" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header p-0" id="headingPhoto">
                    <h2 class="mb-0">
                      <button class="btn btn-block text-left collapsed d-flex justify-content-between align-items-center"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapsePhoto"
                              aria-expanded="false"
                              aria-controls="collapsePhoto">
                        <span>
                          <span class="text-light-blue" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.photo">Photo</span>&nbsp;
                          <span class="text-dark-gray"></span>
                        </span>
                      </button>
                    </h2>
                  </div>
                  <div id="collapsePhoto" class="collapse" aria-labelledby="headingPhoto" data-parent="#accordionFlight">
                    <div class="card-body">
                      <p class="m-0 text-center text-gray-blue-dark" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.actionNote">Click on photo to view or edit</p>
                      <div class="row">
                        <div class="col-4" *ngFor="let photo of baggageItem.photo">
                          <div class="photo-item">
                            <span class="material-icons">add</span>
                            <!-- <span class="material-icons" *ngIf="!photo.id; else photoPreview">add</span>
                            <ng-template #photoPreview>
                              <img src="" class="img-fluid" alt="">
                            </ng-template> -->
                          </div>
                        </div>

                        <div class="col-4">
                          <div class="photo-item">
                            <span class="material-icons">add</span>
                          </div>
                        </div>
                        <div class="col-4">
                          <div class="photo-item">
                            <span class="material-icons">add</span>
                          </div>
                        </div>
                        <div class="col-4">
                          <div class="photo-item">
                            <span class="material-icons">add</span>
                          </div>
                        </div>
                        <div class="col-4">
                          <div class="photo-item">
                            <span class="material-icons">add</span>
                          </div>
                        </div>
                        <div class="col-4">
                          <div class="photo-item">
                            <span class="material-icons">add</span>
                          </div>
                        </div>
                        <div class="col-4">
                          <div class="photo-item">
                            <span class="material-icons">add</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header p-0" id="headingAttachments">
                    <h2 class="mb-0">
                      <button class="btn btn-block text-left collapsed d-flex justify-content-between align-items-center"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseAttachments"
                              aria-expanded="false"
                              aria-controls="collapseAttachments">
                        <span>
                          <span class="text-light-blue" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.attachments">Attachments</span>&nbsp;
                          <span class="text-dark-gray"></span>
                        </span>
                      </button>
                    </h2>
                  </div>
                  <div id="collapseAttachments" class="collapse" aria-labelledby="headingAttachments" data-parent="#accordionFlight">
                    <div class="card-body">
                      <p class="m-0 text-center text-gray-blue-dark" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.actionNoteAttachments">Click on attachments to view or edit</p>
                      <ul class="list-group list-group-flush mb-3">
                        <li class="list-group-item list-group-item-action" *ngFor="let statement of baggageItem.statement">
                          {{statement.id}} / {{statement.note}}
                        </li>
                      </ul>

                      <button class="btn btn-sm btn-blue float-right mb-3"
                              i18n="laf|Statement tabs@@laf.statement.tabs.baggage.button.addFile">
                        Add file
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card mt-3">
                <div class="card-body">
                  <p class="font-weight-bold text-blue h5 mb-3" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.results">Results</p>
                  <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.faultStation">
                        Fault station
                      </span>
                    </div>
                    <ng-select class="w-100 custom-sm"
                               [items]="references.airports"
                               bindLabel="iata"
                               bindValue="id"
                               [(ngModel)]="baggageItem.airportId"
                               name="baggageFaultStation"
                               [virtualScroll]="true"
                               [loading]="selectLoadAnimation.airports"
                               (scroll)="onScrollNgSelect($event, 'airports')"
                               (scrollToEnd)="onScrollToEndNgSelect('airports')"
                               [searchFn]="customSelectSearch"
                               (change)="autoSave()">
                      <ng-template ng-option-tmp let-item="item">
                        <div class="font-small">
                          <span class="font-weight-bold">{{item.iata}}</span>&nbsp;{{item.name}}
                        </div>
                      </ng-template>
                    </ng-select>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <div class="input-group flex-nowrap">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0">
                            <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.code">Code</span>
                          </span>
                        </div>
                        <ng-select [items]="references.irregularity_codes"
                                   class="w-100 custom-sm"
                                   bindLabel="code"
                                   bindValue="id"
                                   name="irregularityCode1Id"
                                   [loading]="selectLoadAnimation.irregularity_codes"
                                   (scroll)="onScrollNgSelect($event, 'irregularity_codes')"
                                   (scrollToEnd)="onScrollToEndNgSelect('irregularity_codes')"
                                   [(ngModel)]="baggageItem.irregularityCode1Id"
                                   (change)="autoSave()">
                        </ng-select>
                      </div>
                    </div>
                    <!-- <div class="col-6">
                      <div class="input-group flex-nowrap">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0">
                            <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.code">Code</span>&nbsp;2
                          </span>
                        </div>
                        <ng-select [items]="references.irregularity_codes"
                                   class="w-100 custom-sm"
                                   bindLabel="code"
                                   bindValue="id"
                                   name="irregularityCode2Id"
                                   [loading]="selectLoadAnimation.irregularity_codes"
                                   (scroll)="onScrollNgSelect($event, 'irregularity_codes')"
                                   (scrollToEnd)="onScrollToEndNgSelect('irregularity_codes')"
                                   [(ngModel)]="baggageItem.irregularityCode2Id"
                                   (change)="autoSave()">
                        </ng-select>
                      </div>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="col-3">
              <button class="btn btn-sm btn-blue float-right mb-3 w-100"
                    i18n="laf|Statement tabs@@laf.statement.tabs.baggage.baggageTracer"
                    disabled>
                Baggage tracer
              </button>
              <div class="card mb-3">
                <div class="card-body">
                  <p class="font-weight-bold text-blue h5" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.search">Search</p>
                  <table class="table-striped table-border w-100 mb-3">
                    <thead class="text-dark-gray">
                      <tr>
                        <th class="text-center" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.raiting">Raiting</th>
                        <th i18n="laf|Statement tabs@@laf.statement.tabs.baggage.claim">Claim</th>
                        <th class="text-center" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.request">Request</th>
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container *ngFor="let baggageMatch of baggageItem.bagData; let bm_i = index;">
                        <tr *ngIf="bm_i < 3">
                          <td class="text-center">{{ baggageMatch.score?.toFixed(2) }}</td>
                          <td class="text-center">{{ baggageMatch.statement.name }}</td>
                          <td class="text-center"><input type="checkbox" [checked]="baggageItem.selected" /></td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                  <button class="btn btn-sm btn-blue float-right"
                        i18n="laf|Statement tabs@@laf.statement.tabs.baggage.openComparison"
                        data-toggle="modal"
                        [attr.data-target]="'#compare-card'+i">
                    Open comparison
                  </button>
                </div>
              </div>
              <!-- Ожидания описания событий на которые показываются уведомления -->
              <!-- <div class="card mb-3">
                <div class="card-body">
                  <p class="font-weight-bold text-blue h5" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.recentEvents">Recent events</p>
                  <div class="font-small text-dark-gray">
                    <span class="font-weight-bold">27.04.2022 13:30 </span>Получен ответ по заявлению IKT-S7-01262274
                  </div>
                  <div class="font-small text-dark-gray">
                    <span class="font-weight-bold">27.04.2022 12:25 </span>Найдено 3 совпадения в базе багажа
                  </div>
                  <div class="font-small text-dark-gray">
                    <span class="font-weight-bold">27.04.2022 12:23 </span>Начат розыск
                  </div>
                  <div class="font-small text-dark-gray">
                    <span class="font-weight-bold">27.04.2022 12:23 </span>Заявление сохранено
                  </div>
                </div>
              </div> -->
            </div>
          </div>
          <div class="modal fade bd-example-modal-xxl compare-card"
            id="compare-card{{i}}"
            data-backdrop="static" tabindex="-1" role="dialog"
            aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-xxl">
                <div class="modal-content bg-light-gray">
                  <form #filterForm="ngForm">
                  <div class="loading" *ngIf="viewParametrs.loading">
                    <div class="cssload-spin-box"></div>
                  </div>
                  <div class="modal-header">
                    <h5 class="modal-title" i18n="laf|Statement tabs@@laf.modal.compare.bagsComparison">Bags comparison</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <div class="row">
                      <div class="col-3">
                        <div class="card h-100">
                          <div class="card-body" style="max-height: 600px; overflow-y: auto;">
                            <p class="font-weight-bold text-blue h5" i18n="laf|Statement tabs@@laf.modal.compare.bagsMatches">Bags matches</p>
                            <table class="w-100 font-small table-bordered">
                              <thead>
                                <tr>
                                  <th></th>
                                  <th i18n="laf|Statement tabs@@laf.modal.compare.number">Number</th>
                                  <th i18n="laf|Statement tabs@@laf.modal.compare.rating" class="text-center">Rating</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let baggageMatch of baggageItem.bagData; let bm_i = index;">
                                  <td class="text-center">
                                    <span *ngIf="!getById(baggageCompareArray, baggageMatch.id)"
                                          class="material-icons text-dark-gray"
                                          (click)="addToCompare(baggageMatch)">add_box</span>
                                    <span *ngIf="getById(baggageCompareArray, baggageMatch.id)"
                                          class="material-icons text-red"
                                          (click)="removeToCompare(baggageMatch.id)">remove_circle</span>
                                  </td>
                                  <td>{{ baggageMatch.statement.name }}</td>
                                  <td class="text-center">{{ baggageMatch.score?.toFixed(2) }}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div class="col-9 pl-0">
                        <div class="card h-100">
                          <div class="card-body">
                            <h5 class="font-weight-bold text-blue h5"
                                i18n="laf|Statement tabs@@laf.modal.compare.comparisonBagsCharacteristics">
                              Comparison of bags characteristics
                            </h5>
                            <div class="table-grid font-small">
                              <div class="div-striped">
                                <div></div>
                                <div i18n="laf|Statement tabs@@laf.modal.compare.matchScore">Match score</div>
                                <div i18n="laf|Statement tabs@@laf.modal.compare.dateCreation">Date of creation</div>
                                <div i18n="laf|Statement tabs@@laf.modal.compare.airport">Airport</div>
                                <div i18n="laf|Statement tabs@@laf.modal.compare.baggageTag">Baggage tag</div>
                                <div i18n="laf|Statement tabs@@laf.modal.compare.fullNamePassenger">Full name of the passenger</div>
                                <div i18n="laf|Statement tabs@@laf.modal.compare.bookingNumber">Booking number</div>
                                <div i18n="laf|Statement tabs@@laf.modal.compare.flight">Flight</div>
                                <div i18n="laf|Statement tabs@@laf.modal.compare.weightBaggage">Weight of baggage</div>
                                <div i18n="laf|Statement tabs@@laf.modal.compare.baggageType">Baggage type</div>
                                <div i18n="laf|Statement tabs@@laf.modal.compare.baggageMaterial">Baggage material</div>
                                <div i18n="laf|Statement tabs@@laf.modal.compare.baggageColor">Baggage color</div>
                                <div i18n="laf|Statement tabs@@laf.modal.compare.externalElements">External elements</div>
                                <div i18n="laf|Statement tabs@@laf.modal.compare.description">Description</div>
                                <div i18n="laf|Statement tabs@@laf.modal.compare.photo">Photo</div>
                              <!-- *ngFor="let item of [].constructor(10); let i = index" -->
                              </div>
                              <div class="div-striped text-center border">
                                <div>
                                  <span i18n="laf|Statement tabs@@laf.modal.compare.wantedBaggage" class="font-weight-bold">Wanted baggage</span>
                                </div>
                                <div></div>
                                <div>{{ statement.dtInsert | date: 'dd.MM.yyyy HH:mm' }}</div>
                                <div>{{ getById(references.airports, statement.airportId)?.iata }}</div>
                                <div>{{ baggageItem.tag }}</div>
                                <div>{{ passengerFullName(statement.passenger.passenger) }}</div>
                                <div>{{ statement.passenger.pnr }}</div>
                                <div>{{ getFlightsString(baggageItem.route) }}</div>
                                <div>{{ baggageItem.weight.expected }}</div>
                                <div>{{ getById(references.baggage_types, baggageItem.characteristics.typeId)?.code }}</div>
                                <div>{{ getById(references.baggage_materials, baggageItem.characteristics.materialId)?.code }}</div>
                                <div>{{ getById(references.baggage_colors, baggageItem.characteristics.colorId)?.code }}</div>
                                <div>{{ getCodeExternalElements(baggageItem.characteristics.externalElements) }}</div>
                                <div>{{ baggageItem.characteristics.description }}</div>
                                <div></div>
                              </div>
                              <div class="div-striped text-center" *ngFor="let baggage of baggageCompareArray">
                                <div>
                                  {{ baggage.statement.name }}<br>
                                  <span class="material-icons text-dark-gray" (click)="removeToCompare(baggage.id)">remove_circle</span>
                                  <span class="material-icons text-green"
                                        *ngIf="!baggage.selected"
                                        (click)="makeBaggageSelected(baggageItem.id, baggage.id)">check_box</span>
                                  <span class="material-icons text-red"
                                        *ngIf="baggage.selected"
                                        (click)="makeBaggageUnselected(baggageItem.id, baggage.id)">unpublished</span>
                                  <span class="material-icons text-dark-gray">chat</span>
                                </div>
                                <div>{{ baggage.score?.toFixed(2) }}</div>
                                <div>{{ baggage.dtInsert | date: 'dd.MM.yyyy HH:mm' }}</div>
                                <div></div>
                                <div [ngClass]="{'text-green font-weight-bold': baggageItem.tag === baggage.baggage.tag }">
                                  {{ baggage.baggage.tag }}
                                </div>
                                <div [ngClass]="{'text-green font-weight-bold': passengerFullName(statement.passenger.passenger) === baggage.passenger.name }">
                                  {{ baggage.passenger.name }}
                                </div>
                                <div [ngClass]="{'text-green font-weight-bold': statement.passenger.pnr === baggage.passenger.pnr }">
                                  {{ baggage.passenger.pnr }}
                                </div>
                                <div [ngClass]="{'text-green font-weight-bold': getFlightsString(baggageItem.route) === getFlightsString(baggage.flights) }">
                                  {{ getFlightsString(baggage.flights) }}
                                </div>
                                <div [ngClass]="{'text-green font-weight-bold': baggageItem.weight.expected === baggage.baggage.weight }">
                                  {{ baggage.baggage.weight }}
                                </div>
                                <div [ngClass]="{'text-green font-weight-bold': getById(references.baggage_types, baggageItem.characteristics.typeId)?.code === baggage.baggage.type }">
                                  {{ baggage.baggage.type }}
                                </div>
                                <div [ngClass]="{'text-green font-weight-bold': getById(references.baggage_materials, baggageItem.characteristics.materialId)?.code === baggage.baggage.material }">
                                  {{ baggage.baggage.material }}
                                </div>
                                <div [ngClass]="{'text-green font-weight-bold': getById(references.baggage_colors, baggageItem.characteristics.colorId)?.code === baggage.baggage.color }">
                                  {{ baggage.baggage.color }}
                                </div>
                                <div [ngClass]="{'text-green font-weight-bold': getCodeExternalElements(baggageItem.characteristics.externalElements) === baggage.baggage.elements?.join('') }">
                                  {{ baggage.baggage.elements?.join('') }}
                                </div>
                                <div></div>
                                <div></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button type="button"
                            class="btn btn-sm btn-gray-blue-dark"
                            data-dismiss="modal"
                            i18n="laf|Baggage modal@@laf.modal.newStatement.button.close">
                      Close
                    </button>
                  </div>
                </form>
                </div>
            </div>
          </div>
          <!-- Модальное окно редактирования содержимого багажа -->
          <div class="modal fade content-card" data-backdrop="static" tabindex="-1" role="dialog"
               aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl">
              <div class="modal-content bg-light-gray">
                <form #filterForm="ngForm">
                  <div class="loading" *ngIf="viewParametrs.loading">
                    <div class="cssload-spin-box"></div>
                  </div>
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalContent" *ngIf="statement.id">
                      <span i18n="laf|Content modal@@laf.modal.baggageContent.header">Baggage contents</span>
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <div class="row mb-3">
                      <div class="col-3 pr-0">
                        <div class="input-group flex-nowrap">
                          <div class="input-group-prepend custom-sm">
                            <span class="input-group-text font-small py-0"
                                  i18n="laf|Content modal@@laf.modal.baggageContent.enter.category">
                              Category
                            </span>
                          </div>
                          <ng-select #bagContentItem
                                    class="w-100 custom-sm bg-white"
                                    [items]="references.baggage_internal_categories"
                                    bindLabel="name"
                                    bindValue="id"
                                    name="contentCategory"
                                    [(ngModel)]="baggageInternalCategoryId"
                                    (change)="findBaggageContentAndGroup('items', $event)"
                                    [selectOnTab]="true"
                                    [virtualScroll]="true">
                          </ng-select>
                        </div>
                      </div>
                      <div class="col-8 pr-0">
                        <div class="input-group flex-nowrap">
                          <div class="input-group-prepend custom-sm">
                            <span class="input-group-text font-small py-0"
                                  i18n="laf|Content modal@@laf.modal.baggageContent.enter.content">
                              Content
                            </span>
                          </div>
                          <ng-select #bagContentCategory
                                    class="w-100 custom-sm bg-white"
                                    [items]="baggageInternalForCategory"
                                    bindLabel="name"
                                    bindValue="id"
                                    name="contentItems"
                                    [(ngModel)]="baggageInternalId"
                                    (change)="findBaggageContentAndGroup('group', $event)"
                                    [selectOnTab]="true"
                                    [virtualScroll]="true">
                          </ng-select>
                        </div>
                      </div>
                      <div class="col-1 pl-2">
                        <button type="button"
                            class="btn btn-h-31 btn-blue"
                            (click)="addBaggageInternalElement(baggageItem.id); autoSave();"
                            [disabled]="!baggageInternalId"
                            i18n="laf|Content modal@@laf.modal.baggageContent.button.add">
                          Add
                        </button>
                      </div>
                    </div>
                    <div class="row px-3">
                      <h5 class="font-weight-bold text-blue h5 mb-3"
                          i18n="laf|Content modal@@laf.modal.baggageContent.table.header">
                        List of items in baggage
                      </h5>
                      <table class="w-100 mb-3 table-border contenteditable">
                        <tbody>
                          <ng-container *ngFor="let internalGroup of baggageItem.characteristics.internalElements; let i = index">
                            <tr>
                              <td colspan="2" class="font-weight-bold bg-light-yellow">{{internalGroup.categoryName}}</td>
                            </tr>  
                            <ng-container *ngFor="let element of internalGroup.internals; let j = index">
                              <tr class="bg-light text-dark">
                                <td class="pl-3">
                                  {{element.name}}
                                </td>
                                <td class="text-center align-middle">
                                  <span class="material-icons text-red" (click)="removeItem(internalGroup.internals, j); checkInternalElementsCategoryEmpty(baggageItem.characteristics.internalElements, i); autoSave();">close</span>
                                </td>
                              </tr>
                            </ng-container>
                          </ng-container>
                          </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button type="button"
                            class="btn btn-sm btn-gray-blue-dark"
                            data-dismiss="modal"
                            i18n="laf|Content modal@@laf.modal.baggageContent.button.close">
                      Close
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <!-- Модальное окно редактирования повреждений по багажу -->
        <div class="modal fade damage-card"
             data-backdrop="static"
             tabindex="-1"
             role="dialog"
             aria-labelledby="myExtraLargeModalLabel"
             aria-hidden="true">
          <div class="modal-dialog modal-xl">
            <div class="modal-content bg-light-gray">
              <form #filterForm="ngForm">
                <div class="loading" *ngIf="viewParametrs.loading">
                  <div class="cssload-spin-box"></div>
                </div>
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalContent" *ngIf="statement.id">
                    <span i18n="laf|Content modal@@laf.modal.damage.header">Damage</span>
                  </h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <p class="font-weight-bold text-gray-blue-dark h6"
                      i18n="laf|Content modal@@laf.modal.damage.locationOfDamage">
                    Locations of damage
                  </p>
                  <div class="row w-50 mx-auto">
                    <div class="col pr-0 text-center">
                      <div><img src="../../assets/img/lost-found/Side1.png" alt=""></div>
                      <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.side">Side</span> 1
                    </div>
                    <div class="col pr-0 text-center">
                      <div><img src="../../assets/img/lost-found/Side2.png" alt=""></div>
                      <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.side">Side</span> 2
                    </div>
                    <div class="col pr-0 text-center">
                      <div><img src="../../assets/img/lost-found/End1.png" alt=""></div>
                      <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.end">End</span> 1
                    </div>
                    <div class="col pr-0 text-center">
                      <div><img src="../../assets/img/lost-found/End2.png" alt=""></div>
                      <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.end">End</span> 2
                    </div>
                    <div class="col text-center">
                      <div><img src="../../assets/img/lost-found/Top.png" alt=""></div>
                      <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.top">Top</span>
                      <div><img src="../../assets/img/lost-found/Bottom.png" alt=""></div>
                      <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.bottom">Bottom</span>
                    </div>
                  </div>

                  <hr>

                  <div class="row px-3">
                    <div class="d-flex w-100 justify-content-between mb-2">
                      <p class="font-weight-bold text-gray-blue-dark h6"
                          i18n="laf|Content modal@@laf.modal.damage.damageList">
                        List of damage
                      </p>
                      <div>
                        <button type="button"
                            class="btn btn-h-31 btn-blue"
                            (click)="addBaggageDamage(baggageItem.id); autoSave();"
                            i18n="laf|Content modal@@laf.modal.damage.button.add">
                          Add
                        </button>
                      </div>
                    </div>

                    <table class="w-100 font-small table-bordered">
                      <col width="30%" />
                      <col width="30%" />
                      <col width="30%" />
                      <col width="10%" />
                      <thead class="bg-light-gray font-weight-bold text-center">
                        <tr>
                          <th i18n="laf|Content modal@@laf.modal.damage.table.head.side">Side</th>
                          <th i18n="laf|Content modal@@laf.modal.damage.table..head.level">Level</th>
                          <th colspan="2" i18n="laf|Content modal@@laf.modal.damage.table..head.type">Type</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody class="bg-white">
                        <ng-container *ngFor="let damageItem of baggageItem.damage.damages; let i = index">
                          <tr>
                            <td>
                              <div class="d-flex justify-content-around">
                                <div class="d-flex flex-column">
                                  
                                  <div class="form-check">
                                    <input class="form-check-input"
                                            type="checkbox"
                                            id="damageCheck1_{{i}}"
                                            name="damageItem.side1_{{i}}"
                                            [checked]="damageItem.side1"
                                            [(ngModel)]="damageItem.side1"
                                            (change)="autoSave()">
                                    <label class="form-check-label" for="damageCheck1_{{i}}">
                                      <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.side">Side</span> 1
                                    </label>
                                  </div>

                                  <div class="form-check">
                                    <input class="form-check-input"
                                            type="checkbox"
                                            id="damageCheck3_{{i}}"
                                            name="damageCheck3_{{i}}"
                                            [checked]="damageItem.end1"
                                            [(ngModel)]="damageItem.end1"
                                            (change)="autoSave()">
                                    <label class="form-check-label" for="damageCheck3_{{i}}">
                                      <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.end">End</span> 1
                                    </label>
                                  </div>

                                  <div class="form-check">
                                    <input class="form-check-input"
                                            type="checkbox"
                                            id="damageCheck5_{{i}}"
                                            name="damageCheck5_{{i}}"
                                            [checked]="damageItem.top"
                                            [(ngModel)]="damageItem.top"
                                            (change)="autoSave()">
                                    <label class="form-check-label" for="damageCheck5_{{i}}">
                                      <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.top">Top</span>
                                    </label>
                                  </div>
                                </div>

                                <div class="d-flex flex-column">
                                  <div class="form-check">
                                    <input class="form-check-input"
                                            type="checkbox"
                                            id="damageCheck2_{{i}}"
                                            name="damageCheck2_{{i}}"
                                            [checked]="damageItem.side2"
                                            [(ngModel)]="damageItem.side2"
                                            (change)="autoSave()">
                                    <label class="form-check-label" for="damageCheck2_{{i}}">
                                      <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.side">Side</span> 2
                                    </label>
                                  </div>

                                  <div class="form-check">
                                    <input class="form-check-input"
                                            type="checkbox"
                                            id="damageCheck4_{{i}}"
                                            name="damageCheck4_{{i}}"
                                            [checked]="damageItem.end2"
                                            [(ngModel)]="damageItem.end2"
                                            (change)="autoSave()">
                                    <label class="form-check-label" for="damageCheck4_{{i}}">
                                      <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.end">End</span> 2
                                    </label>
                                  </div>

                                  <div class="form-check">
                                    <input class="form-check-input"
                                            type="checkbox"
                                            id="damageCheck6_{{i}}"
                                            name="damageCheck6_{{i}}"
                                            [checked]="damageItem.bottom"
                                            [(ngModel)]="damageItem.bottom"
                                            (change)="autoSave()">
                                    <label class="form-check-label" for="damageCheck6_{{i}}">
                                      <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.bottom">Bottom</span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td class="align-middle">
                              <ng-select class="w-100 custom-sm bg-white"
                                         [items]="references.baggage_damage_levels"
                                         bindLabel="nameLocal"
                                         bindValue="id"
                                         name="damageLevel_{{i}}"
                                         [(ngModel)]="damageItem.levelId"
                                         (change)="autoSave()"
                                         [selectOnTab]="true"
                                         [virtualScroll]="true">
                              </ng-select>
                            </td>
                            <td class="align-middle" colspan="2">
                              <ng-select class="w-100 custom-sm bg-white"
                                         [items]="references.baggage_damage_types"
                                         bindLabel="nameLocal"
                                         bindValue="id"
                                         name="damageType_{{i}}"
                                         [(ngModel)]="damageItem.typeId"
                                         (change)="autoSave()"
                                         [selectOnTab]="true"
                                         [virtualScroll]="true">
                              </ng-select>
                            </td>
                            <td class="text-center align-middle"  height="10%">
                              <span class="material-icons text-red" (click)="removeItem(baggageItem.damage.damages, i); autoSave();">close</span>
                            </td>
                          </tr>  
                        </ng-container>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button"
                          class="btn btn-sm btn-gray-blue-dark"
                          data-dismiss="modal"
                          i18n="laf|Content modal@@laf.modal.baggageContent.button.close">
                    Close
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        </div>
      <div class="tab-pane fade" id="S7787565687" role="tabpanel" aria-labelledby="S7787565687-tab">...</div>
    </div>
    </div>
    <div class="tab-pane fade pt-3" id="chat" role="tabpanel" aria-labelledby="chat-tab">
      <div class="row">
        <div class="col-8">
          <div class="card w-100">
            <div class="card-body">
              <h5 class="card-title font-weight-bold text-blue"
                  i18n="laf|Chat and message tabs@@laf.statement.tabs.chatAndMessages.chat">Chat</h5>
              <div class="row">
                <div class="col-4">
                  <p class="d-flex justify-content-center align-items-center h4 h-100 align-middle border-top border-bottom"
                     i18n="laf|Chat and message tabs@@laf.statement.tabs.chatAndMessages.noQueries.chat">No queries</p>
                  <!-- <ul class="list-group">
                    <li class="list-group-item" style="font-size: 0.9em;">VVO_SU_787564/SU87656765</li>
                  </ul> -->
                </div>
                <div class="col-8">
                  <div class="form-group">
                    <textarea class="form-control" id="chatText" rows="13" readonly></textarea>
                  </div>
                  <div class="input-group">
                    <input type="text"
                           class="form-control"
                           placeholder="Enter text"
                           disabled
                           i18n-placeholder="laf|Chat and message tabs@@laf.statement.tabs.chatAndMessages.chat.enterText">
                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary h-100"
                              type="button"
                              disabled
                              i18n="laf|Chat and message tabs@@laf.statement.tabs.chatAndMessages.chat.button.send">Send</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="card w-100">
            <div class="card-body">
              <h5 class="card-title font-weight-bold text-blue"
                  i18n="laf|Chat and message tabs@@laf.statement.tabs.chatAndMessages.messages">Messages</h5>
              <div class="d-flex flex-column">
                <div class="">
                  <div class="form-group m-0">
                    <label for="messageIncoming"
                           i18n="laf|Chat and message tabs@@laf.statement.tabs.chatAndMessages.messages.incoming">Incoming</label>
                    <textarea class="form-control"
                              id="messageIncoming"
                              rows="6"
                              [(ngModel)]="statement.messages.incoming"
                              (keyup)="autoSave()">{{statement.messages.incoming}}</textarea>
                  </div>
                </div>
                <div class="">
                  <div class="form-group m-0">
                    <label for="messageOutgoing"
                           i18n="laf|Chat and message tabs@@laf.statement.tabs.chatAndMessages.messages.outgoing">Outgoing</label>
                    <textarea class="form-control"
                              id="messageOutgoing"
                              rows="6"
                              [(ngModel)]="statement.messages.outgoing"
                              (keyup)="autoSave()">{{statement.messages.outgoing}}</textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Исходник -->
  <!-- <div class="tab-content">
    <div class="tab-pane fade pt-3 show active" id="summary" role="tabpanel" aria-labelledby="summary-tab">
      <div class="row">
        <div class="col-8">
          <div class="card mb-3">
            <div class="card-body">
              <p class="font-weight-bold text-blue h5 mb-3" i18n="laf|Statement tabs@@laf.statement.tabs.statement.statement">Claim</p>
              <span class="font-small mr-4">
                <span class="font-weight-bold mr-2" i18n="laf|Statement tabs@@laf.statement.tabs.statement.airport">
                  Airport </span>{{ getById(references.airports, statement.airportId)?.iata }}
              </span>
              <span class="font-small mr-4">
                <span class="font-weight-bold" i18n="laf|Statement tabs@@laf.statement.tabs.statement.airline">
                  Airline </span>{{ getById(references.airlines, statement.airlineId)?.iata }}
              </span>
              <span class="font-small mr-4">
                <span class="font-weight-bold" i18n="laf|Statement tabs@@laf.statement.tabs.statement.reason">
                  Reason </span>{{ getById(references.statement_types, statement.typeId)?.nameLocal }}
              </span>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <p class="font-weight-bold text-blue h5 mb-3" i18n="laf|Statement tabs@@laf.statement.tabs.statement.passenger">Passenger</p>
              <div class="row">
                <div class="col-4">
                  <table class="w-100 font-small">
                    <tr>
                      <td class="font-weight-bold" i18n="laf|Statement tabs@@laf.statement.tabs.statement.passenger.name">Name</td>
                      <td>{{statement.passenger.passenger}}</td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold" i18n="laf|Statement tabs@@laf.statement.tabs.statement.passenger.phone">Phone</td>
                      <td>{{statement.passenger.phone1}}</td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold" i18n="laf|Statement tabs@@laf.statement.tabs.statement.passenger.email">Email</td>
                      <td>{{statement.passenger.email}}</td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold" i18n="laf|Statement tabs@@laf.statement.tabs.statement.passenger.registrationAddress">Registration address</td>
                      <td>{{statement.passenger.address}}</td>
                    </tr>
                  </table>
                </div>
                <div class="col-4">
                  <table class="w-100 font-small">
                    <tr>
                      <td class="font-weight-bold" i18n="laf|Statement tabs@@laf.statement.tabs.statement.passenger.phone">Phone</td>
                      <td>{{statement.passenger.additionalInfo.temporary.phone || '-'}}</td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold" i18n="laf|Statement tabs@@laf.statement.tabs.statement.passenger.dateStay">Date stay</td>
                      <td>{{statement.passenger.additionalInfo.temporary.dueDate | date: 'dd.MM.yyyy' || '-'}}</td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold" i18n="laf|Statement tabs@@laf.statement.tabs.statement.passenger.temporaryAddress">Temporary address</td>
                      <td>{{statement.passenger.additionalInfo.temporary.address || '-'}}</td>
                    </tr>
                  </table>
                </div>
                <div class="col">
                  <table class="w-100 font-small">
                    <tr>
                      <td class="font-weight-bold" i18n="laf|Statement tabs@@laf.statement.tabs.statement.passenger.frequentFlyer">Frequent flyer</td>
                      <td>{{statement.passenger.additionalInfo.ffp}}</td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold" i18n="laf|Statement tabs@@laf.statement.tabs.statement.passenger.category">Ffp</td>
                      <td>{{statement.passenger.additionalInfo.ffp}}</td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold" i18n="laf|Statement tabs@@laf.statement.tabs.statement.passenger.sex">Sex</td>
                      <td>{{statement.passenger.additionalInfo.gender}}</td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold" i18n="laf|Statement tabs@@laf.statement.tabs.statement.passenger.language">Language</td>
                      <td>{{statement.passenger.additionalInfo.language}}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="card h-100">
            <div class="card-body">
              <p class="font-weight-bold text-blue h5" i18n="laf|Statement tabs@@laf.statement.tabs.statement.flight">Flight</p>
              <table class="w-100 font-small">
                <tr>
                  <td class="font-weight-bold" i18n="laf|Statement tabs@@laf.statement.tabs.statement.flight.pnr">PNR</td>
                  <td>{{statement.passenger.pnr}}</td>
                </tr>
                <tr>
                  <td class="font-weight-bold" i18n="laf|Statement tabs@@laf.statement.tabs.statement.flight.serviceClass">Service class</td>
                  <td>{{statement.passenger.additionalInfo.class}}</td>
                </tr>
                <tr>
                  <td class="font-weight-bold" i18n="laf|Statement tabs@@laf.statement.tabs.statement.flight.baggageCount">Baggage count</td>
                  <td>{{statement.passenger.baggage.amount}}</td>
                </tr>
                <tr>
                  <td class="font-weight-bold" i18n="laf|Statement tabs@@laf.statement.tabs.statement.flight.baggageWeight">Baggage weight</td>
                  <td>{{statement.passenger.baggage.weight}}</td>
                </tr>
                <tr>
                  <td class="font-weight-bold" i18n="laf|Statement tabs@@laf.statement.tabs.statement.flight.overnightKitMen">Overnight kit for Men</td>
                  <td>{{statement.passenger.additionalInfo.overknightKit.male}}</td>
                </tr>
                <tr>
                  <td class="font-weight-bold" i18n="laf|Statement tabs@@laf.statement.tabs.statement.flight.overnightKitWomen">Overnight kit for Women</td>
                  <td>{{statement.passenger.additionalInfo.overknightKit.female}}</td>
                </tr>
                <tr>
                  <td class="font-weight-bold" i18n="laf|Statement tabs@@laf.statement.tabs.statement.flight.ticketNumber">Ticket number</td>
                  <td>{{statement.passenger.ticket}}</td>
                </tr>
                <tr>
                  <td class="font-weight-bold" i18n="laf|Statement tabs@@laf.statement.tabs.statement.flight.route">Route</td>
                  <td>
                    <p class="m-0" *ngFor="let route of statement.route.full.flights">
                      {{route.flight}} {{getById(references.airports, route.departureId)?.iata}} - {{getById(references.airports, route.arrivalId)?.iata}} {{route.dt | date: 'dd.MM.yyyy HH:mm'}}
                    </p>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="col-12 mt-3">
          <table class="w-100 font-small table-bordered">
            <thead class="bg-light-gray font-weight-bold text-center">
              <tr>
                <th i18n="laf|Statement tabs@@laf.statement.tabs.statement.findTable.tag" class="align-middle" rowspan="2">Tag</th>
                <th i18n="laf|Statement tabs@@laf.statement.tabs.statement.findTable.status" class="align-middle" rowspan="2">Status</th>
                <th i18n="laf|Statement tabs@@laf.statement.tabs.statement.findTable.weight" colspan="3">Weight</th>
                <th i18n="laf|Statement tabs@@laf.statement.tabs.statement.findTable.parameters" colspan="3">Parameters</th>
                <th i18n="laf|Statement tabs@@laf.statement.tabs.statement.findTable.forwarding" colspan="4">Forwarding</th>
                <th i18n="laf|Statement tabs@@laf.statement.tabs.statement.findTable.storage" class="align-middle" rowspan="2">Storage</th>
                <th i18n="laf|Statement tabs@@laf.statement.tabs.statement.findTable.delivery" class="align-middle" rowspan="2">Delivery</th>
                <th i18n="laf|Statement tabs@@laf.statement.tabs.statement.findTable.damage" class="align-middle" rowspan="2">Damage</th>
                <th i18n="laf|Statement tabs@@laf.statement.tabs.statement.findTable.pLost" class="align-middle" rowspan="2">P/Lost</th>
              </tr>
              <tr>
                <th i18n="laf|Statement tabs@@laf.statement.tabs.statement.findTable.checkedIn">Checked in</th>
                <th i18n="laf|Statement tabs@@laf.statement.tabs.statement.findTable.delivery">Delivery</th>
                <th i18n="laf|Statement tabs@@laf.statement.tabs.statement.findTable.missing">Missing</th>

                <th i18n="laf|Statement tabs@@laf.statement.tabs.statement.findTable.type">Type</th>
                <th i18n="laf|Statement tabs@@laf.statement.tabs.statement.findTable.material">Material</th>
                <th i18n="laf|Statement tabs@@laf.statement.tabs.statement.findTable.color">Color</th>

                <th i18n="laf|Statement tabs@@laf.statement.tabs.statement.findTable.flight">Departure</th>
                <th i18n="laf|Statement tabs@@laf.statement.tabs.statement.findTable.date">Date</th>
                <th i18n="laf|Statement tabs@@laf.statement.tabs.statement.findTable.flight">Arrival</th>
                <th i18n="laf|Statement tabs@@laf.statement.tabs.statement.findTable.date">Date</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let baggageItem of statement.baggage">
                <td>{{baggageItem.tag}}</td>
                <td>{{getById(baggage_statuses, baggageItem.statusId)?.nameLocal}}</td>
                <td>{{baggageItem.weight.expected}}</td>
                <td>{{baggageItem.weight.actual}}</td>
                <td>{{baggageItem.weight.expected - baggageItem.weight.actual}}</td>
                <td>{{getById(references.baggage_types, baggageItem.characteristics.typeId)?.nameLocal}}</td>
                <td>{{getById(references.baggage_materials, baggageItem.characteristics.materialId)?.nameLocal}}</td>
                <td>{{getById(references.baggage_colors, baggageItem.characteristics.colorId)?.nameLocal}}</td>
                <td>S7 7875</td>
                <td>27.04.2022 22:10</td>
                <td>S7 7683</td>
                <td>27.04.2022 22:10</td>
                <td>{{getById(adminData.storages, baggageItem.storage.id)?.name}}</td>
                <td>{{baggageItem.delivery.address.substr(0, 3)}}</td>
                <td class="text-center">
                  <span *ngIf="baggageItem.damage.side1 || baggageItem.damage.side2
                              || baggageItem.damage.end1 || baggageItem.damage.end2
                              || baggageItem.damage.top || baggageItem.damage.bottom"
                        class="material-icons">done</span>
                </td>
                <td class="text-center">
                  <span class="material-icons" *ngIf="baggageItem.pilferage.description">done</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="tab-pane fade pt-3" id="passenger" role="tabpanel" aria-labelledby="passenger-tab">
      <div class="row">
        <div class="col-6 pr-0">
          <div class="card h-100">
            <div class="card-body">
              <p class="font-weight-bold text-blue h5" i18n="laf|Statement tabs@@laf.statement.tabs.claim.passenger">Passenger</p>
              <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend custom-sm">
                  <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.statement.tabs.claim.name">
                    Name
                  </span>
                </div>
                <input type="text" class="w-100 form-control custom-sm" name="baggageTag" [(ngModel)]="statement.passenger.passenger" />
              </div>
              <div class="row mb-3">
                <div class="col-6 pr-0">
                  <div class="input-group flex-nowrap">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.statement.tabs.claim.phone">
                        Phone
                      </span>
                    </div>
                    <input type="text" class="w-100 form-control custom-sm" name="baggageTag" [(ngModel)]="statement.passenger.phone1" />
                  </div>
                </div>
                <div class="col-6">
                  <div class="input-group flex-nowrap">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.statement.tabs.claim.email">
                        Email
                      </span>
                    </div>
                    <input type="text" class="w-100 form-control custom-sm" name="baggageTag" [(ngModel)]="statement.passenger.email" />
                  </div>
                </div>
              </div>
              <div class="input-group mb-3">
                <div class="input-group-prepend custom-sm">
                  <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.statement.tabs.claim.registrationAddress">Registration address</span>
                </div>
                <textarea class="form-control form-control-sm"
                          [(ngModel)]="statement.passenger.address"
                          rows="2">{{ statement.passenger.address }}</textarea>
              </div>
              <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend custom-sm">
                  <span class="input-group-text font-small py-0"
                    i18n="laf|Statement tabs@@laf.statement.tabs.claim.notification">
                    Notification
                  </span>
                </div>
                <ng-select [items]="['Email', 'SMS']"
                           class="w-100 custom-sm"
                           name="passengerNotification"
                           [multiple]="true">
                </ng-select>
              </div>
              <p class="mb-2 h6 border-top py-2 font-weight-bold" i18n="laf|Statement tabs@@laf.statement.tabs.claim.additionalInfo">Additional info</p>
              <div class="row mb-3">
                <div class="col-6">
                  <div class="input-group flex-nowrap">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.statement.tabs.claim.frequentFlyer">
                        Frequent flyer
                      </span>
                    </div>
                    <input type="text"
                          class="w-100 form-control custom-sm"
                          name="baggageFrequentFlyer"
                          [(ngModel)]="statement.passenger.additionalInfo.ffp" />
                  </div>
                </div>
                <div class="col-6">
                  <div class="input-group flex-nowrap">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.statement.tabs.claim.category">
                        Сategory
                      </span>
                    </div>
                    <ng-select [items]="['No category', 'Gold']"
                               class="w-100 custom-sm"
                               name="passengerСategory"
                               [(ngModel)]="statement.passenger.additionalInfo.status" >
                    </ng-select>
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-6">
                  <div class="input-group flex-nowrap">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.statement.tabs.claim.sex">
                        Sex
                      </span>
                    </div>
                    <ng-select [items]="['Male', 'Female']"
                               class="w-100 custom-sm"
                               name="passengerSex"
                               [(ngModel)]="statement.passenger.additionalInfo.gender">
                    </ng-select>
                  </div>
                </div>
                <div class="col-6">
                  <div class="input-group flex-nowrap">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.statement.tabs.claim.language">
                        Language
                      </span>
                    </div>
                    <ng-select [items]="['Russian', 'English', 'German']"
                               class="w-100 custom-sm"
                               name="passengerLanguage"
                               [(ngModel)]="statement.passenger.additionalInfo.language">
                    </ng-select>
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-12">
                  <div class="input-group flex-nowrap">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.statement.tabs.claim.passengersCount">
                        Passengers count
                      </span>
                    </div>
                    <input type="number"
                          class="w-100 form-control custom-sm"
                          name="baggageFrequentFlyer"
                          [(ngModel)]="statement.passenger.additionalInfo.amount" />
                  </div>
                </div>
              </div>
              <p class="mb-2 h6 border-top py-2 font-weight-bold"
                  i18n="laf|Statement tabs@@laf.statement.tabs.claim.temporaryStay">Temporary stay</p>
              <div class="input-group mb-3">
                <div class="input-group-prepend custom-sm">
                  <span class="input-group-text font-small py-0"
                      i18n="laf|Statement tabs@@laf.statement.tabs.claim.temporaryAddress">Temporary address</span>
                </div>
                <textarea class="form-control form-control-sm"
                          [(ngModel)]="statement.passenger.additionalInfo.temporary.address"
                          rows="2">{{ statement.passenger.additionalInfo.temporary.address }}</textarea>
              </div>
              <div class="row">
                <div class="col-6 pr-0">
                  <div class="input-group flex-nowrap">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0"
                          i18n="laf|Statement tabs@@laf.statement.tabs.claim.phone">
                        Phone
                      </span>
                    </div>
                    <input type="text"
                          class="w-100 form-control custom-sm"
                          name="additionalInfoPhone"
                          [(ngModel)]="statement.passenger.additionalInfo.temporary.phone" />
                  </div>
                </div>
                <div class="col-6">
                  <div class="input-group flex-nowrap">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0"
                          i18n="laf|Statement tabs@@laf.statement.tabs.claim.dateOfStay">
                        Date of stay
                      </span>
                    </div>
                    <input type="date" class="form-control form-control custom-sm"
                      [ngModel]="statement.passenger.additionalInfo.temporary.dueDate | date: 'yyyy-MM-dd'"
                      (blur)="statement.passenger.additionalInfo.temporary.dueDate = parseDate($event.target.value)"
                      name="additionalInfoDate">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="card h-100">
            <div class="card-body">
              <p class="font-weight-bold text-blue h5 mb-3">Flight</p>
              <div class="row mb-3">
                <div class="col-6 pr-0">
                  <div class="input-group flex-nowrap">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0"
                          i18n="laf|Statement tabs@@laf.statement.tabs.claim.ticketNumber">
                        Ticket number
                      </span>
                    </div>
                    <input type="text" class="w-100 form-control custom-sm" name="ticketNumber" [(ngModel)]="statement.passenger.ticket" />
                  </div>
                </div>
                <div class="col-6">
                  <div class="input-group flex-nowrap">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0"
                          i18n="laf|Statement tabs@@laf.statement.tabs.claim.pnr">
                        PNR
                      </span>
                    </div>
                    <input type="text" class="w-100 form-control custom-sm" name="pnr" [(ngModel)]="statement.passenger.pnr" />
                  </div>
                </div>
              </div>
              <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend custom-sm">
                  <span class="input-group-text font-small py-0"
                    i18n="laf|Statement tabs@@laf.statement.tabs.claim.serviceClass">
                    Service class
                  </span>
                </div>
                <ng-select [items]="['Economy', 'Business']"
                           class="w-100 custom-sm"
                           name="serviceClass"
                           [(ngModel)]="statement.passenger.additionalInfo.class">
                </ng-select>
              </div>
              <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend custom-sm">
                  <span class="input-group-text font-small py-0"
                      i18n="laf|Statement tabs@@laf.statement.tabs.claim.registrationBaggageCountWeight">
                    Registration baggage Count and Weight
                  </span>
                </div>
                <input type="number" class="w-100 form-control custom-sm" name="baggageCount" [(ngModel)]="statement.passenger.baggage.amount" />
                <input type="number" class="w-100 form-control custom-sm" name="baggageWeight" [(ngModel)]="statement.passenger.baggage.weight" />
              </div>
              <div class="input-group flex-nowrap mb-3">
                <div class="input-group-prepend custom-sm">
                  <span class="input-group-text font-small py-0"
                      i18n="laf|Statement tabs@@laf.statement.tabs.claim.overnightKitMenWomen">
                    Overnight kit for Men and Women
                  </span>
                </div>
                <input type="number"
                      class="w-100 form-control custom-sm"
                      name="kitMen"
                      [(ngModel)]="statement.passenger.additionalInfo.overknightKit.male" />
                <input type="number"
                      class="w-100 form-control custom-sm"
                      name="kitWomen"
                      [(ngModel)]="statement.passenger.additionalInfo.overknightKit.female" />
              </div>
              <p class="mb-2 h6 border-top py-2 font-weight-bold" i18n="laf|Statement tabs@@laf.statement.tabs.claim.route">Route</p>
              <table class="w-100 mb-3">
                <tr>
                  <td width="18%" i18n="laf|Statement tabs@@laf.statement.tabs.claim.flight">Flight</td>
                  <td i18n="laf|Statement tabs@@laf.statement.tabs.claim.date">Date</td>
                  <td></td>
                  <td width="18%" i18n="laf|Statement tabs@@laf.statement.tabs.claim.from">From</td>
                  <td width="18%" i18n="laf|Statement tabs@@laf.statement.tabs.claim.to">To</td>
                  <td></td>
                </tr>
                <tr *ngFor="let route of statement.route.full.flights; let i = index">
                  <td>
                    <input type="text" class="form-control custom-sm" name="flightItemFlight_{{i}}" [(ngModel)]="route.flight" />
                  </td>
                  <td>
                    <input type="date" class="form-control custom-sm"
                      [ngModel]="route.dt | date: 'yyyy-MM-dd'"
                      (blur)="route.dt = parseDate($event.target.value, flightItemTime.value)"
                      name="flightItemDate_{{i}}" #flightItemDate
                      #flightItemDate{{i}}="ngModel">
                  </td>
                  <td>
                    <input type="time" class="form-control custom-sm"
                      [ngModel]="route.dt | date: 'HH:mm'"
                      (blur)="route.dt = parseDate(flightItemDate.value, $event.target.value)"
                      name="flightItemTime{{i}}" #flightItemTime
                      #flightItemTime{{i}}="ngModel">
                  </td>
                  <td>
                    <ng-select class="w-100 custom-sm p-0"
                              [items]="references.airports"
                              bindLabel="iata"
                              bindValue="id"
                              [(ngModel)]="route.departureId"
                              name="departureAirline_{{i}}"
                              [virtualScroll]="true"
                              [loading]="selectLoadAnimation.airports"
                              (scroll)="onScrollNgSelect($event, 'airports')"
                              (scrollToEnd)="onScrollToEndNgSelect('airports')"
                              [searchFn]="customSelectSearch"
                              (change)="setAttribute(route, 'departure', $event.iata)">
                      <ng-template ng-option-tmp let-item="item">
                        <div class="font-small">
                          <span class="font-weight-bold">{{item.iata}}</span>&nbsp;{{item.name}}
                        </div>
                      </ng-template>
                    </ng-select>
                  </td>
                  <td>
                    <ng-select class="w-100 custom-sm p-0"
                              [items]="references.airports"
                              bindLabel="iata"
                              bindValue="id"
                              [(ngModel)]="route.arrivalId"
                              name="arrivalAirline_{{i}}"
                              [virtualScroll]="true"
                              [loading]="selectLoadAnimation.airports"
                              (scroll)="onScrollNgSelect($event, 'airports')"
                              (scrollToEnd)="onScrollToEndNgSelect('airports')"
                              [searchFn]="customSelectSearch"
                              (change)="setAttribute(route, 'arrival', $event.iata)">
                      <ng-template ng-option-tmp let-item="item">
                        <div class="font-small">
                          <span class="font-weight-bold">{{item.iata}}</span>&nbsp;{{item.name}}
                        </div>
                      </ng-template>
                    </ng-select>
                  </td>
                  <td>
                    <span class="material-icons text-red" (click)="removeItem(statement.route.full.flights, i)">close</span>
                  </td>
                </tr>
              </table>
              <button class="btn btn-sm btn-blue float-right"
                      (click)="addItemRoute()"
                      i18n="laf|Main - button@@laf.main.button.add">
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tab-pane fade pt-3" id="baggage" role="tabpanel" aria-labelledby="baggage-tab">
      <ul class="nav nav-pills mb-3 baggage-items" role="tablist">
        <button class="btn btn-ico btn-light-gray btn-xs mr-2"
              (click)="addItem(statement.baggage, classes.baggage);">
          <span class="material-icons">add</span>
        </button>
        <li class="nav-item" *ngFor="let baggageItem of statement.baggage; let i = index">
          <a class="nav-link"
            id="baggage-{{baggageItem.tag || 'no-tag' + i}}-tab"
            data-toggle="pill"
            href="#baggage-{{baggageItem.tag || 'no-tag' + i}}"
            role="tab" aria-selected="true"
            [ngClass]="{'active': viewParametrs.baggageBlock === i}">
            {{baggageItem.tag || 'no-tag'}}
          </a>
        </li>
      </ul>
      <div class="tab-content">
        <div class="tab-pane fade"
            *ngFor="let baggageItem of statement.baggage; let i = index"
            id="baggage-{{baggageItem.tag || 'no-tag' + i}}"
            role="tabpanel"
            attr.aria-labelledby="baggage-{{baggageItem.tag || 'no-tag' + i}}-tab"
            [ngClass]="{'show active': viewParametrs.baggageBlock === i}">
          <div class="row">
            <div class="col-5 pr-0">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex justify-content-between align-items-center mb-3">
                    <p class="font-weight-bold text-blue h5 m-0" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.placeOfBaggage">Place of baggage</p>
                    <button class="btn btn-ico btn-light-gray btn-xs"
                            (click)="removeItem(statement.baggage, viewParametrs.baggageBlock)">
                      <span class="material-icons">delete</span>
                    </button>
                  </div>
                  <div class="row mb-3">
                    <div class="col pr-0">
                      <div class="input-group flex-nowrap">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.tagNumber">
                            Tag number
                          </span>
                        </div>
                        <input type="text" class="w-100 form-control custom-sm"
                              name="baggageTag"
                              [(ngModel)]="baggageItem.tag"
                              [value]="baggageItem.noTag ? '' : baggageItem.tag"
                              [disabled]="baggageItem.noTag" />
                      </div>
                    </div>
                    <div class="col">
                      <div class="input-group flex-nowrap">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.state">
                            State
                          </span>
                        </div>
                        <ng-select [items]="baggage_statuses"
                                   class="w-100 custom-sm"
                                   bindLabel="nameLocal"
                                   bindValue="id"
                                   name="baggageState"
                                   [loading]="baggage_statuses_animation"
                                   (scroll)="onScrollNgSelect($event, 'baggage_statuses')"
                                   (scrollToEnd)="onScrollToEndNgSelect('baggage_statuses')"
                                   [(ngModel)]="baggageItem.statusId">
                        </ng-select>
                      </div>
                    </div>
                  </div>
                  <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.weightCheckedDelivereMessing">
                        Weight Checked in / Delivere / Messing
                      </span>
                    </div>
                    <input type="number"
                          class="w-100 form-control custom-sm"
                          name="baggageWeigthExpected"
                          [(ngModel)]="baggageItem.weight.expected" />
                    <input type="number"
                          class="w-100 form-control custom-sm"
                          name="baggageWeigthActual"
                          [(ngModel)]="baggageItem.weight.actual" />
                    <input type="number"
                          class="w-100 form-control custom-sm"
                          value="{{baggageItem.weight.expected - baggageItem.weight.actual}}"
                          disabled />
                  </div>
                  <div class="row mb-3">
                    <div class="col-6 pr-0">
                      <div class="input-group flex-nowrap">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.paidBaggageReceipt">
                            Paid baggage receipt
                          </span>
                        </div>
                        <input type="text" class="w-100 form-control custom-sm" />
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="input-group flex-nowrap">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.cost">
                            Cost
                          </span>
                        </div>
                        <input type="text" class="w-100 form-control custom-sm" />
                      </div>
                    </div>
                  </div>
                  <div class="accordion" id="accordionBaggage">
                    <div class="card">
                      <div class="card-header p-0" id="headingOne">
                        <h2 class="mb-0">
                          <button class="btn btn-block text-left collapsed d-flex justify-content-between align-items-center"
                                  type="button"
                                  data-toggle="collapse"
                                  data-target="#collapseOne"
                                  aria-expanded="true"
                                  aria-controls="collapseOne">
                            <span class="text-light-blue" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.bag">Bag</span>
                          </button>
                        </h2>
                      </div>
                      <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionBaggage">
                        <div class="card-body">
                          <div class="row mb-3">
                            <div class="col-6 pr-0">
                              <div class="input-group flex-nowrap">
                                <div class="input-group-prepend custom-sm">
                                  <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.type">Type</span>
                                </div>
                                <ng-select class="w-100 custom-sm p-0"
                                          [(ngModel)]="baggageItem.characteristics.typeId"
                                          name="baggageItemType{{i}}"
                                          bindLabel="name"
                                          bindValue="id"
                                          [loading]="selectLoadAnimation.baggage_types"
                                          [items]="references.baggage_types">
                                </ng-select>
                              </div>
                            </div>
                            <div class="col-6">
                              <div class="input-group flex-nowrap">
                                <div class="input-group-prepend custom-sm">
                                  <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.material">Material</span>
                                </div>
                                <ng-select class="w-100 custom-sm p-0"
                                          [(ngModel)]="baggageItem.characteristics.materialId"
                                          name="baggageItemMaterial{{i}}"
                                          bindLabel="name"
                                          bindValue="id"
                                          [loading]="selectLoadAnimation.baggage_materials"
                                          [items]="references.baggage_materials">
                                </ng-select>
                              </div>
                            </div>
                          </div>
                          <div class="row mb-3">
                            <div class="col-6 pr-0">
                              <div class="input-group flex-nowrap">
                                <div class="input-group-prepend custom-sm">
                                  <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.color">Color</span>
                                </div>
                                <ng-select class="w-100 custom-sm p-0"
                                          [(ngModel)]="baggageItem.characteristics.colorId"
                                          name="baggageItemColor{{i}}"
                                          bindLabel="name"
                                          bindValue="id"
                                          [loading]="selectLoadAnimation.baggage_colors"
                                          [items]="references.baggage_colors">
                                </ng-select>
                              </div>
                            </div>
                            <div class="col-6">
                              <div class="input-group flex-nowrap">
                                <div class="input-group-prepend custom-sm">
                                  <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.brand">Brand</span>
                                </div>
                                <input type="text"
                                      class="w-100 form-control custom-sm"
                                      name="baggageTag"
                                      [(ngModel)]="baggageItem.characteristics.brand" />
                              </div>
                            </div>
                          </div>
                          <div class="row mb-3">
                            <div class="col-8 pr-0">
                              <div class="input-group flex-nowrap">
                                <div class="input-group-prepend custom-sm">
                                  <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.external">External</span>
                                </div>
                                <ng-select class="w-100 custom-sm p-0"
                                          [(ngModel)]="baggageItem.characteristics.externalElements"
                                          name="baggageItemElements{{i}}"
                                          [multiple]="true"
                                          bindLabel="name"
                                          bindValue="id"
                                          [loading]="selectLoadAnimation.baggage_elements"
                                          [items]="references.baggage_elements">
                                </ng-select>
                              </div>
                            </div>
                            <div class="col-4">
                              <div class="input-group flex-nowrap">
                                <div class="input-group-prepend custom-sm">
                                  <span class="input-group-text font-small py-0"
                                      i18n="laf|Statement tabs@@laf.statement.tabs.baggage.keyKode">
                                    Key code
                                  </span>
                                </div>
                                <input type="text"
                                      class="w-100 form-control custom-sm"
                                      name="baggageTag"
                                      [(ngModel)]="baggageItem.characteristics.lockCode" />
                              </div>
                            </div>
                          </div>
                          <div class="input-group">
                            <div class="input-group-prepend custom-sm">
                              <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.note">Note</span>
                            </div>
                            <textarea class="form-control form-control-sm"
                                      [(ngModel)]="baggageItem.characteristics.description"
                                      rows="2">{{baggageItem.characteristics.description}}</textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header p-0" id="headingTwo">
                        <h2 class="mb-0">
                          <button class="btn btn-block text-left collapsed d-flex justify-content-between align-items-center"
                                  type="button"
                                  data-toggle="collapse"
                                  data-target="#collapseTwo"
                                  aria-expanded="false"
                                  aria-controls="collapseTwo">
                            <span>
                              <span class="text-light-blue" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.pantry">Baggage route</span>&nbsp;
                              <span class="text-dark-gray">{{ baggageItem.fullRoute }}</span>
                            </span>
                          </button>
                        </h2>
                      </div>
                      <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionBaggage">
                        <div class="card-body">
                          <table class="w-100 mb-3">
                            <tr>
                              <td width="18%" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.flight">Flight</td>
                              <td i18n="laf|Statement tabs@@laf.statement.tabs.baggage.route">Date</td>
                              <td></td>
                              <td width="18%" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.from">From</td>
                              <td width="18%" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.to">To</td>
                              <td></td>
                            </tr>
                            <tr *ngFor="let route of baggageItem.route; let i_route = index">
                              <td>
                                <input type="text" class="form-control custom-sm"
                                      name="flightItemFlight_{{i_route}}"
                                      [(ngModel)]="route.flight"
                                      [disabled]="baggageItem.routeExtendsFlight" />
                              </td>
                              <td>
                                <input type="date" class="form-control custom-sm"
                                  [ngModel]="route.dt | date: 'yyyy-MM-dd'"
                                  (blur)="route.dt = parseDate($event.target.value)"
                                  name="flightItemDate_{{i_route}}" #flightItemDate
                                  #flightItemDate{{i_route}}="ngModel"
                                  [disabled]="baggageItem.routeExtendsFlight">
                              </td>
                              <td>
                                <input type="time" class="form-control custom-sm"
                                  [ngModel]="route.dt | date: 'HH:mm'"
                                  (blur)="route.dt = parseDate(flightItemDate.value, $event.target.value)"
                                  name="flightItemTime{{i_route}}" #flightItemTime
                                  #flightItemTime{{i_route}}="ngModel"
                                  [disabled]="baggageItem.routeExtendsFlight">
                              </td>
                              <td>
                                <ng-select class="w-100 custom-sm p-0"
                                          [items]="references.airports"
                                          bindLabel="iata"
                                          bindValue="id"
                                          [(ngModel)]="route.departureId"
                                          name="departureAirline_{{i_route}}"
                                          [virtualScroll]="true"
                                          [loading]="selectLoadAnimation.airports"
                                          (scroll)="onScrollNgSelect($event, 'airports')"
                                          (scrollToEnd)="onScrollToEndNgSelect('airports')"
                                          [searchFn]="customSelectSearch"
                                          [disabled]="baggageItem.routeExtendsFlight"
                                          (change)="setAttribute(route, 'departure', $event.iata)">
                                  <ng-template ng-option-tmp let-item="item">
                                    <div class="font-small">
                                      <span class="font-weight-bold">{{item.iata}}</span>&nbsp;{{item.name}}
                                    </div>
                                  </ng-template>
                                </ng-select>
                              </td>
                              <td>
                                <ng-select class="w-100 custom-sm p-0"
                                          [items]="references.airports"
                                          bindLabel="iata"
                                          bindValue="id"
                                          [(ngModel)]="route.arrivalId"
                                          name="arrivalAirline_{{i_route}}"
                                          [virtualScroll]="true"
                                          [loading]="selectLoadAnimation.airports"
                                          (scroll)="onScrollNgSelect($event, 'airports')"
                                          (scrollToEnd)="onScrollToEndNgSelect('airports')"
                                          [searchFn]="customSelectSearch"
                                          [disabled]="baggageItem.routeExtendsFlight"
                                          (change)="setAttribute(route, 'arrival', $event.iata)">
                                  <ng-template ng-option-tmp let-item="item">
                                    <div class="font-small">
                                      <span class="font-weight-bold">{{item.iata}}</span>&nbsp;{{item.name}}
                                    </div>
                                  </ng-template>
                                </ng-select>
                              </td>
                              <td>
                                <span class="material-icons text-red" (click)="removeItem(baggageItem.route, i_route)">close</span>
                              </td>
                            </tr>
                          </table>
                          <div class="d-flex justify-content-between align-items-center">
                            <div class="custom-control custom-switch">
                              <input type="checkbox"
                                    class="custom-control-input"
                                    id="routeFlightCoincides"
                                    [(ngModel)]="baggageItem.routeExtendsFlight"
                                    (change)="checkRouteCoincides($event.target.checked, i)">
                              <label class="custom-control-label"
                                    for="routeFlightCoincides"
                                    i18n="laf|Statement tabs@@laf.statement.tabs.baggage.routeFlightCoincides">
                                The route coincides with the flight
                              </label>
                            </div>
                            <button class="btn btn-sm btn-blue"
                                    (click)="addItem(baggageItem.route, classes.flight)"
                                    i18n="laf|Main - button@@laf.main.button.add">
                              Add
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-4 pr-0">
              <div class="accordion" id="accordionFlight">
                <div class="card">
                  <div class="card-header p-0" id="headingDaparture">
                    <h2 class="mb-0">
                      <button class="btn btn-block text-left collapsed d-flex justify-content-between align-items-center"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseDaparture"
                              aria-expanded="true"
                              aria-controls="collapseDaparture">
                        <span>
                          <span class="text-light-blue" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.daparture">Daparture</span>&nbsp;
                          <span class="text-dark-gray"></span>
                        </span>
                        <span class="text-gray-blue-dark d-flex align-items-center" *ngIf="baggageItem.forwarding.departure.tag">
                          <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.tag">Tag:</span> {{baggageItem.forwarding.departure.tag}}
                        </span>
                      </button>
                    </h2>
                  </div>
                  <div id="collapseDaparture" class="collapse" aria-labelledby="headingDaparture" data-parent="#accordionFlight">
                    <div class="card-body">
                      <div class="input-group flex-nowrap mb-3">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.baggageTag">Baggage tag</span>
                        </div>
                        <input type="text"
                              class="w-100 form-control custom-sm"
                              name="baggageTag"
                              [(ngModel)]="baggageItem.forwarding.departure.tag" />
                      </div>
                      <table class="w-100 mb-3">
                        <thead>
                          <tr>
                            <th i18n="laf|Statement tabs@@laf.statement.tabs.baggage.flight">Flight</th>
                            <th i18n="laf|Statement tabs@@laf.statement.tabs.baggage.date">Date</th>
                            <th i18n="laf|Statement tabs@@laf.statement.tabs.baggage.time">Time</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let route of baggageItem.forwarding.departure.flights; let i = index">
                            <td>
                              <input type="text" class="form-control custom-sm" name="flightItemFlight_{{i}}" [(ngModel)]="route.flight" />
                            </td>
                            <td>
                              <input type="date" class="form-control custom-sm"
                                [ngModel]="route.dt | date: 'yyyy-MM-dd'"
                                (blur)="route.dt = parseDate($event.target.value, flightItemTime.value)"
                                name="flightItemDate_{{i}}" #flightItemDate
                                #flightItemDate{{i}}="ngModel">
                            </td>
                            <td>
                              <input type="time" class="form-control custom-sm"
                                [ngModel]="route.dt | date: 'HH:mm'"
                                (blur)="route.dt = parseDate(flightItemDate.value, $event.target.value)"
                                name="flightItemTime{{i}}" #flightItemTime
                                #flightItemTime{{i}}="ngModel">
                            </td>
                            <td>
                              <span class="material-icons text-red" (click)="removeItem(baggageItem.forwarding.departure.flights, i)">close</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <button class="btn btn-sm btn-blue float-right mb-3"
                              (click)="addItem(baggageItem.forwarding.departure.flights, classes.flight)"
                              i18n="laf|Main - button@@laf.main.button.add">
                        Add
                      </button>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header p-0" id="headingArrival">
                    <h2 class="mb-0">
                      <button class="btn btn-block text-left collapsed d-flex justify-content-between align-items-center"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseArrival"
                              aria-expanded="false"
                              aria-controls="collapseArrival">
                        <span>
                          <span class="text-light-blue" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.arrival">Arrival</span>&nbsp;
                          <span class="text-dark-gray"></span>
                        </span>
                        <span class="text-gray-blue-dark d-flex align-items-center" *ngIf="baggageItem.forwarding.arrival.tag">
                          <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.tag">Tag:</span> {{baggageItem.forwarding.arrival.tag}}
                        </span>
                      </button>
                    </h2>
                  </div>
                  <div id="collapseArrival" class="collapse" aria-labelledby="headingArrival" data-parent="#accordionFlight">
                    <div class="card-body">
                      <div class="input-group flex-nowrap mb-3">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.baggageTag">Baggage tag</span>
                        </div>
                        <input type="text"
                              class="w-100 form-control custom-sm"
                              name="baggageTag"
                              [(ngModel)]="baggageItem.forwarding.arrival.tag" />
                      </div>
                      <table class="w-100 mb-3">
                        <thead>
                          <tr>
                            <th i18n="laf|Statement tabs@@laf.statement.tabs.baggage.flight">Flight</th>
                            <th i18n="laf|Statement tabs@@laf.statement.tabs.baggage.date">Date</th>
                            <th i18n="laf|Statement tabs@@laf.statement.tabs.baggage.time">Time</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let route of baggageItem.forwarding.arrival.flights; let i = index">
                            <td>
                              <input type="text" class="form-control custom-sm" name="flightItemFlight_{{i}}" [(ngModel)]="route.flight" />
                            </td>
                            <td>
                              <input type="date" class="form-control custom-sm"
                                [ngModel]="route.dt | date: 'yyyy-MM-dd'"
                                (blur)="route.dt = parseDate($event.target.value, flightItemTime.value)"
                                name="flightItemDate_{{i}}" #flightItemDate
                                #flightItemDate{{i}}="ngModel">
                            </td>
                            <td>
                              <input type="time" class="form-control custom-sm"
                                [ngModel]="route.dt | date: 'HH:mm'"
                                (blur)="route.dt = parseDate(flightItemDate.value, $event.target.value)"
                                name="flightItemTime{{i}}" #flightItemTime
                                #flightItemTime{{i}}="ngModel">
                            </td>
                            <td>
                              <span class="material-icons text-red" (click)="removeItem(baggageItem.forwarding.arrival.flights, i)">close</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <button class="btn btn-sm btn-blue float-right mb-3"
                              (click)="addItem(baggageItem.forwarding.arrival.flights, classes.flight)"
                              i18n="laf|Main - button@@laf.main.button.add">
                        Add
                      </button>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header p-0" id="headingStorage">
                    <h2 class="mb-0">
                      <button class="btn btn-block text-left collapsed d-flex justify-content-between align-items-center"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseStorage"
                              aria-expanded="false"
                              aria-controls="collapseStorage">
                        <span>
                          <span class="text-light-blue" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.storage">Storage</span>&nbsp;
                          <span class="text-dark-gray">{{getById(adminData.storages, baggageItem.storage.id)?.name}}</span>
                        </span>
                        <span class="text-gray-blue-dark d-flex align-items-center"
                              *ngIf="baggageItem.storage.dtStart || baggageItem.storage.dtFinish">
                          <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.from" *ngIf="baggageItem.storage.dtStart"> From</span>&nbsp;
                          {{baggageItem.storage.dtStart | date: 'dd.MM.yyyy'}}&nbsp;
                          <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.to" *ngIf="baggageItem.storage.dtFinish">to</span>&nbsp;
                          {{baggageItem.storage.dtFinish | date: 'dd.MM.yyyy'}}
                        </span>
                      </button>
                    </h2>
                  </div>
                  <div id="collapseStorage" class="collapse" aria-labelledby="headingStorage" data-parent="#accordionFlight">
                    <div class="card-body">
                      <div class="input-group flex-nowrap mb-3">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.storageLocation">Storage location</span>
                        </div>
                        <ng-select class="w-100 custom-sm p-0"
                                  [(ngModel)]="baggageItem.storage.id"
                                  name="baggageStorage"
                                  bindLabel="name"
                                  bindValue="id"
                                  [loading]="selectLoadAnimation.storages"
                                  [items]="adminData.storages">
                        </ng-select>
                      </div>
                      <div class="row mb-3">
                        <div class="col-6 pr-0">
                          <div class="input-group flex-nowrap">
                            <div class="input-group-prepend custom-sm">
                              <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.dateFrom">Date from</span>
                            </div>
                            <input type="date" class="form-control custom-sm"
                              [ngModel]="baggageItem.storage.dtStart | date: 'yyyy-MM-dd'"
                              (blur)="baggageItem.storage.dtStart = parseDate($event.target.value)"
                              name="storageDtStart">
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="input-group flex-nowrap">
                            <div class="input-group-prepend custom-sm">
                              <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.dateTo">Date to</span>
                            </div>
                            <input type="date" class="form-control custom-sm"
                              [ngModel]="baggageItem.storage.dtFinish | date: 'yyyy-MM-dd'"
                              (blur)="baggageItem.storage.dtFinish = parseDate($event.target.value)"
                              name="storageDtFinish">
                          </div>
                        </div>
                      </div>
                      <div class="input-group">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.note">Note</span>
                        </div>
                        <textarea class="form-control form-control-sm"
                                  [(ngModel)]="baggageItem.storage.note"
                                  rows="2">{{baggageItem.storage.note}}</textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header p-0" id="headingDelivery">
                    <h2 class="mb-0">
                      <button class="btn btn-block text-left collapsed d-flex justify-content-between align-items-center"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseDelivery"
                              aria-expanded="false"
                              aria-controls="collapseDelivery">
                        <span>
                          <span class="text-light-blue" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.delivery">Delivery</span>&nbsp;
                          <span class="text-dark-gray">{{baggageItem.delivery.address}}</span>
                        </span>
                        <span class="text-gray-blue-dark d-flex align-items-center" *ngIf="baggageItem.delivery.actualDt">
                          <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.actualDate">Actual date</span>&nbsp;
                          {{baggageItem.delivery.actualDt | date: 'dd.MM.yyyy'}}</span>
                      </button>
                    </h2>
                  </div>
                  <div id="collapseDelivery" class="collapse" aria-labelledby="headingDelivery" data-parent="#accordionFlight">
                    <div class="card-body">
                      <div class="input-group flex-nowrap mb-3">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.deliveryAddress">Delivery address</span>
                        </div>
                        <ng-select class="w-100 custom-sm p-0"
                                  name="baggageStorage"
                                  bindLabel="name"
                                  bindValue="id"
                                  [loading]="selectLoadAnimation.storages"
                                  [(ngModel)]="baggageItem.delivery.address"
                                  [items]="['Registration address', 'Temporary address']">
                        </ng-select>
                      </div>
                      <div class="input-group flex-nowrap mb-3">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0"
                            i18n="laf|Statement tabs@@laf.statement.tabs.baggage.scheduledDeliveryDate">Scheduled delivery date</span>
                        </div>
                        <input type="date" class="form-control custom-sm"
                          [ngModel]="baggageItem.delivery.dt | date: 'yyyy-MM-dd'"
                          (blur)="baggageItem.delivery.dt = parseDate($event.target.value)"
                          name="deliveryDt">
                      </div>
                      <div class="input-group flex-nowrap mb-3">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0"
                            i18n="laf|Statement tabs@@laf.statement.tabs.baggage.actualDeliveryDate">Actual delivery date</span>
                        </div>
                        <input type="date" class="form-control custom-sm"
                          [ngModel]="baggageItem.delivery.actualDt | date: 'yyyy-MM-dd'"
                          (blur)="baggageItem.delivery.actualDt = parseDate($event.target.value)"
                          name="deliveryActualDt">
                      </div>
                      <div class="input-group">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.forwarder">Forwarder</span>
                        </div>
                        <textarea class="form-control form-control-sm"
                                  [(ngModel)]="baggageItem.delivery.note"
                                  rows="2">{{ baggageItem.delivery.note }}</textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header p-0" id="headingWorldTracer">
                    <h2 class="mb-0">
                      <button class="btn btn-block text-left collapsed d-flex justify-content-between align-items-center"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseWorldTracer"
                              aria-expanded="false"
                              aria-controls="collapseWorldTracer">
                        <span>
                          <span class="text-light-blue" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.worldTracer">WorldTracer</span>&nbsp;
                          <span class="text-dark-gray">{{baggageItem.worldTracer.statement}}</span>
                        </span>
                      </button>
                    </h2>
                  </div>
                  <div id="collapseWorldTracer" class="collapse" aria-labelledby="headingWorldTracer" data-parent="#accordionFlight">
                    <div class="card-body">
                      <div class="input-group flex-nowrap mb-3">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.status">Status</span>
                        </div>
                        <ng-select class="w-100 custom-sm p-0"
                                  [(ngModel)]="baggageItem.worldTracer.statusId"
                                  name="baggageStorage"
                                  bindLabel="name"
                                  bindValue="id"
                                  [loading]="selectLoadAnimation.worldTracer"
                                  [items]="adminData.worldTracer">
                        </ng-select>
                      </div>
                      <div class="input-group flex-nowrap">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.number">Number</span>
                        </div>
                        <input type="text"
                              class="w-100 form-control custom-sm"
                              name="baggageTag"
                              [(ngModel)]="baggageItem.worldTracer.statement" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header p-0" id="headingDamage">
                    <h2 class="mb-0">
                      <button class="btn btn-block text-left collapsed d-flex justify-content-between align-items-center"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseDamage"
                              aria-expanded="false"
                              aria-controls="collapseDamage">
                        <span>
                          <span class="text-light-blue" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.damage">Damage</span>&nbsp;
                          <span class="text-dark-gray"
                                *ngIf="baggageItem.damage.side1 || baggageItem.damage.side2
                                || baggageItem.damage.end1 || baggageItem.damage.end2
                                || baggageItem.damage.top || baggageItem.damage.bottom
                                || baggageItem.damage.levelId || baggageItem.damage.description
                                || baggageItem.damage.claim">Recorded</span>
                        </span>
                        <span class="text-gray-blue-dark d-flex align-items-center" *ngIf="baggageItem.damage.claim">
                          <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.amountClaims">Amount of claims</span>&nbsp;
                          {{baggageItem.damage.claim}}</span>
                      </button>
                    </h2>
                  </div>
                  <div id="collapseDamage" class="collapse" aria-labelledby="headingDamage" data-parent="#accordionFlight">
                    <div class="card-body">
                      <div class="row">
                        <div class="col pr-0 text-center">
                          <div><img src="../../assets/img/lost-found/Side1.png" alt=""></div>
                          <label>
                            <input type="checkbox"
                                  [checked]="baggageItem.damage.side1"
                                  [(ngModel)]="baggageItem.damage.side1">
                              <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.side">Side</span> 1
                          </label>
                        </div>
                        <div class="col pr-0 text-center">
                          <div><img src="../../assets/img/lost-found/Side2.png" alt=""></div>
                          <label>
                            <input type="checkbox"
                                  [checked]="baggageItem.damage.side2"
                                  [(ngModel)]="baggageItem.damage.side2">
                              <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.side">Side</span> 2
                          </label>
                        </div>
                        <div class="col pr-0 text-center">
                          <div><img src="../../assets/img/lost-found/End1.png" alt=""></div>
                          <label>
                            <input type="checkbox"
                                  [checked]="baggageItem.damage.end1"
                                  [(ngModel)]="baggageItem.damage.end1">
                              <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.end">End</span> 1
                          </label>
                        </div>
                        <div class="col pr-0 text-center">
                          <div><img src="../../assets/img/lost-found/End2.png" alt=""></div>
                          <label>
                            <input type="checkbox"
                                  [checked]="baggageItem.damage.end2"
                                  [(ngModel)]="baggageItem.damage.end2">
                              <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.end">End</span> 2
                          </label>
                        </div>
                        <div class="col text-center">
                          <div><img src="../../assets/img/lost-found/Top.png" alt=""></div>
                          <label>
                            <input type="checkbox"
                                  [checked]="baggageItem.damage.top"
                                  [(ngModel)]="baggageItem.damage.top">
                              <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.top">Top</span>
                          </label>
                          <div><img src="../../assets/img/lost-found/Bottom.png" alt=""></div>
                          <label>
                            <input type="checkbox"
                                  [checked]="baggageItem.damage.bottom"
                                  [(ngModel)]="baggageItem.damage.bottom">
                              <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.bottom">Bottom</span>
                          </label>
                        </div>
                      </div>
                      <div class="mb-3">
                        <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.damageLevel">Damage level:</span>
                        <label *ngFor="let level of references.baggage_damage_levels"
                          (click)="baggageItem.damage.levelId = level.id;">
                          <input type="radio" class="ml-3"
                                [checked]="baggageItem.damage.levelId === level.id"
                                name="levelId" [value]="level.id"> {{level.nameLocal}}
                        </label>
                      </div>
                      <div class="input-group mb-3">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.note">Note</span>
                        </div>
                        <textarea class="form-control form-control-sm"
                                  [(ngModel)]="baggageItem.damage.description"
                                  name="baggageItemDamageNote"
                                  rows="2">{{baggageItem.damage.description}}</textarea>
                      </div>
                      <div class="input-group flex-nowrap">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.amountClaims">Amount of claims </span>
                        </div>
                        <input type="number" class="w-100 form-control custom-sm" [(ngModel)]="baggageItem.damage.claim"/>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header p-0" id="headingShortage">
                    <h2 class="mb-0">
                      <button class="btn btn-block text-left collapsed d-flex justify-content-between align-items-center"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseShortage"
                              aria-expanded="false"
                              aria-controls="collapseShortage">
                        <span>
                          <span class="text-light-blue" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.shortage">Shortage</span>&nbsp;
                          <span class="text-dark-gray"></span>
                        </span>
                      </button>
                    </h2>
                  </div>
                  <div id="collapseShortage" class="collapse" aria-labelledby="headingShortage" data-parent="#accordionFlight">
                    <div class="card-body">
                      <div class="input-group mb-3">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.note">Note</span>
                        </div>
                        <textarea class="form-control form-control-sm"
                                  [(ngModel)]="baggageItem.pilferage.description"
                                  name="pilferageNote"
                                  rows="2">{{baggageItem.pilferage.description}}</textarea>
                      </div>
                      <div class="input-group flex-nowrap">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.amountClaims">Amount of claims </span>
                        </div>
                        <input type="number" class="w-100 form-control custom-sm" [(ngModel)]="baggageItem.pilferage.claim"/>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header p-0" id="headingPhoto">
                    <h2 class="mb-0">
                      <button class="btn btn-block text-left collapsed d-flex justify-content-between align-items-center"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapsePhoto"
                              aria-expanded="false"
                              aria-controls="collapsePhoto">
                        <span>
                          <span class="text-light-blue" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.photo">Photo</span>&nbsp;
                          <span class="text-dark-gray"></span>
                        </span>
                      </button>
                    </h2>
                  </div>
                  <div id="collapsePhoto" class="collapse" aria-labelledby="headingPhoto" data-parent="#accordionFlight">
                    <div class="card-body">
                      <p class="m-0" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.actionNote">Click on photo to view or edit</p>
                      <div class="row">
                        <div class="col-4">
                          <div class="photo-item">
                            <span class="material-icons">add</span>
                          </div>
                        </div>
                        <div class="col-4">
                          <div class="photo-item">
                            <span class="material-icons">add</span>
                          </div>
                        </div>
                        <div class="col-4">
                          <div class="photo-item">
                            <span class="material-icons">add</span>
                          </div>
                        </div>
                        <div class="col-4">
                          <div class="photo-item">
                            <span class="material-icons">add</span>
                          </div>
                        </div>
                        <div class="col-4">
                          <div class="photo-item">
                            <span class="material-icons">add</span>
                          </div>
                        </div>
                        <div class="col-4">
                          <div class="photo-item">
                            <span class="material-icons">add</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-3">
              <div class="card mb-3">
                <div class="card-body">
                  <p class="font-weight-bold text-blue h5" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.searche">Searche</p>
                  <table class="table-striped table-border w-100 mb-3">
                    <thead class="text-dark-gray">
                      <tr>
                        <th class="text-center" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.raiting">Raiting</th>
                        <th i18n="laf|Statement tabs@@laf.statement.tabs.baggage.claim">Claim</th>
                        <th class="text-center" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.request">Request</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="text-center">9,9</td>
                        <td>IKT-S7-01262274</td>
                        <td class="text-center"><input type="checkbox" checked /></td>
                      </tr>
                      <tr>
                        <td class="text-center">7,9</td>
                        <td>SVX-S7-012722189</td>
                        <td class="text-center"><input type="checkbox" /></td>
                      </tr>
                      <tr>
                        <td class="text-center">3,2</td>
                        <td>KUF-U6-94</td>
                        <td class="text-center"><input type="checkbox" /></td>
                      </tr>
                    </tbody>
                  </table>
                  <button class="btn btn-sm btn-blue float-right mb-3" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.openComparison">
                    Open comparison
                  </button>
                  <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.faultStation">
                        Fault station
                      </span>
                    </div>
                    <ng-select class="w-100 custom-sm"
                              [items]="references.airports"
                              bindLabel="iata"
                              bindValue="id"
                              [(ngModel)]="baggageItem.airportId"
                              name="baggageFaultStation"
                              [virtualScroll]="true"
                              [loading]="selectLoadAnimation.airports"
                              (scroll)="onScrollNgSelect($event, 'airports')"
                              (scrollToEnd)="onScrollToEndNgSelect('airports')"
                              [searchFn]="customSelectSearch">
                      <ng-template ng-option-tmp let-item="item">
                        <div class="font-small">
                          <span class="font-weight-bold">{{item.iata}}</span>&nbsp;{{item.name}}
                        </div>
                      </ng-template>
                    </ng-select>
                  </div>
                  <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0">
                        <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.code">Code</span> 1
                      </span>
                    </div>
                    <ng-select [items]="references.irregularity_codes"
                               class="w-100 custom-sm"
                               bindLabel="code"
                               bindValue="id"
                               name="irregularityCode1Id"
                               [loading]="selectLoadAnimation.irregularity_codes"
                               (scroll)="onScrollNgSelect($event, 'irregularity_codes')"
                               (scrollToEnd)="onScrollToEndNgSelect('irregularity_codes')"
                               [(ngModel)]="baggageItem.irregularityCode1Id">
                    </ng-select>
                  </div>
                  <div class="input-group flex-nowrap">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0">
                        <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.code">Code</span> 2
                      </span>
                    </div>
                    <ng-select [items]="references.irregularity_codes"
                               class="w-100 custom-sm"
                               bindLabel="code"
                               bindValue="id"
                               name="irregularityCode2Id"
                               [loading]="selectLoadAnimation.irregularity_codes"
                               (scroll)="onScrollNgSelect($event, 'irregularity_codes')"
                               (scrollToEnd)="onScrollToEndNgSelect('irregularity_codes')"
                               [(ngModel)]="baggageItem.irregularityCode2Id">
                    </ng-select>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-body">
                  <p class="font-weight-bold text-blue h5" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.recentEvents">Recent events</p>
                  <div class="font-small text-dark-gray">
                    <span class="font-weight-bold">27.04.2022 13:30 </span>Получен ответ по заявлению IKT-S7-01262274
                  </div>
                  <div class="font-small text-dark-gray">
                    <span class="font-weight-bold">27.04.2022 12:25 </span>Найдено 3 совпадения в базе багажа
                  </div>
                  <div class="font-small text-dark-gray">
                    <span class="font-weight-bold">27.04.2022 12:23 </span>Начат розыск
                  </div>
                  <div class="font-small text-dark-gray">
                    <span class="font-weight-bold">27.04.2022 12:23 </span>Заявление сохранено
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="S7787565687" role="tabpanel" aria-labelledby="S7787565687-tab">...</div>
      </div>
    </div>
    <div class="tab-pane fade pt-3" id="chat" role="tabpanel" aria-labelledby="chat-tab">...</div>
  </div> -->
  <!-- Исходник -->



</ng-container>


<div class="modal fade bd-example-modal-lg statement-card" data-backdrop="static" tabindex="-1" role="dialog"
  aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content bg-light-gray">
        <form #filterForm="ngForm">
        <div class="loading" *ngIf="viewParametrs.loading">
          <div class="cssload-spin-box"></div>
        </div>
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalScrollableTitle" *ngIf="!statement.id">
            <span i18n="laf|Statement tabs@@laf.modal.newStatement.newClaim">New claim</span>
          </h5>
          <button #closeButton type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row mb-3">
            <div class="col-6">
              <div class="input-group flex-nowrap">
                <div class="input-group-prepend custom-sm">
                  <span class="input-group-text font-small py-0">
                    <span i18n="laf|Statement tabs@@laf.modal.newStatement.airport">
                      Airport
                    </span>&nbsp;
                    <span class="required-asterisk">*</span>
                  </span>
                </div>
                <ng-select #newClaimAirport
                           class="w-100 custom-sm bg-white"
                           [items]="references.airports"
                           bindLabel="iata"
                           bindValue="id"
                           [(ngModel)]="statement.airportId"
                           #filterParamsAirport="ngModel"
                           name="statementAirport"
                           [selectOnTab]="true"
                           [virtualScroll]="true"
                           [loading]="selectLoadAnimation.airports"
                           (scroll)="onScrollNgSelect($event, 'airports')"
                           (scrollToEnd)="onScrollToEndNgSelect('airports')"
                           [searchFn]="customSelectSearch"
                           (close)="newClaimAirline.focus()">
                  <ng-template ng-option-tmp let-item="item">
                    <div class="font-small">
                      <span class="font-weight-bold">{{item.iata}}</span>&nbsp;{{item.name}}
                    </div>
                  </ng-template>
                </ng-select>
              </div>
            </div>
            <div class="col-6">
              <div class="input-group flex-nowrap">
                <div class="input-group-prepend custom-sm">
                  <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.modal.newStatement.airline">
                    Airline
                  </span>
                </div>
                <ng-select #newClaimAirline
                           class="w-100 custom-sm bg-white"
                           [items]="references.airlines"
                           bindLabel="iata"
                           bindValue="id"
                           [(ngModel)]="statement.airlineId"
                           #filterParamsAirport="ngModel"
                           name="statementAirline"
                           [selectOnTab]="true"
                           [virtualScroll]="true"
                           [loading]="selectLoadAnimation.airlines"
                           (scroll)="onScrollNgSelect($event, 'airlines')"
                           (scrollToEnd)="onScrollToEndNgSelect('airlines')"
                           [searchFn]="customSelectSearch"
                           (close)="newClaimReason.focus()">
                  <ng-template ng-option-tmp let-item="item">
                    <div class="font-small">
                      <span class="font-weight-bold">{{item.iata}}</span>&nbsp;{{item.name}}
                    </div>
                  </ng-template>
                </ng-select>
              </div>
            </div>
          </div>
          <div class="input-group flex-nowrap mb-3">
            <div class="input-group-prepend custom-sm">
              <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.modal.newStatement.reason">Reason</span>
            </div>
            <ng-select #newClaimReason
                       class="w-100 custom-sm bg-white"
                       bindValue="id"
                       bindLabel="nameLocal"
                       name="statementReason"
                       [selectOnTab]="true"
                       [(ngModel)]="statement.typeId"
                       [loading]="selectLoadAnimation.statement_types"
                       [items]="references.statement_types"
                       (close)="newClaimPnr.focus()">
            </ng-select>
          </div>
          <!-- <div class="row mb-3">
            <div class="col-6">
              <div class="input-group flex-nowrap">
                <div class="input-group-prepend custom-sm">
                  <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.modal.newStatement.ticketNumber">Ticket number</span>
                </div>
                <input type="text"
                      class="w-100 form-control custom-sm"
                      name="passengerTicket"
                      [(ngModel)]="statement.passenger.ticket" />
              </div>
            </div>
          </div> -->
          <p class="font-weight-bold text-blue mt-3" i18n="laf|Statement tabs@@laf.modal.newStatement.details">Details</p>
          <div class="row">
            <div class="col-6">
              <div class="input-group flex-nowrap">
                <div class="input-group-prepend custom-sm">
                  <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.modal.newStatement.pnr">PNR</span>
                </div>
                <input #newClaimPnr
                       type="text"
                       class="w-100 form-control custom-sm"
                       name="passengerPnr"
                       [(ngModel)]="statement.passenger.pnr"
                       appUpperCase/>
              </div>
            </div>
            <div class="col-6 mb-3 d-flex" *ngFor="let baggageItem of statement.baggage; let i_baggage = index">
              <div class="input-group flex-nowrap">
                <div class="input-group-prepend custom-sm">
                  <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.modal.newStatement.baggageTag">Baggage tag</span>
                </div>
                <input type="text"
                      class="w-100 form-control custom-sm"
                      name="baggageItem_{{i_baggage}}_tag"
                      [(ngModel)]="baggageItem.tag"
                      appUpperCase/>
              </div>
              <button class="btn btn-ico btn-light-gray btn-xs ml-2"
                    *ngIf="statement.baggage.length-1 === i_baggage"
                    style="height: 29px;"
                    (click)="addItem(statement.baggage, classes.baggage);">
                <span class="material-icons">add</span>
              </button>
            </div>
            <div class="col">
            </div>
          </div>
          <!-- <p class="font-weight-bold text-blue mt-3" i18n="laf|Statement tabs@@laf.modal.newStatement.flight">Flight</p>
          <div class="input-group flex-nowrap">
            <div class="input-group-prepend custom-sm">
              <span class="input-group-text font-small py-0" i18n="laf|Statement tabs@@laf.modal.newStatement.flight">Flight</span>
            </div>
            <ng-select class="w-100 custom-sm bg-white"
                        multiple="true"
                        name="statementFlight"
                        [items]="['S7 7845 VVO - SVO 22.04.2022 13:50', 'S7 7845 VVO - SVO 22.04.2022 16:50']"></ng-select>
          </div> -->
        </div>
        <div class="modal-footer">
          <button type="button" *ngIf="!statement.id"
                  class="btn btn-sm btn-blue"
                  (click)="addStatement()"
                  i18n="laf|Baggage modal@@laf.modal.newStatement.button.append">
            Append
          </button>
          <button type="button" *ngIf="statement.id"
                  class="btn btn-sm btn-blue"
                  (click)="saveStatement()"
                  i18n="laf|Baggage modal@@laf.modal.newStatement.button.save">
            Save
          </button>
          <button type="button"
                  class="btn btn-sm btn-gray-blue-dark"
                  data-dismiss="modal"
                  i18n="laf|Baggage modal@@laf.modal.newStatement.button.close">
            Close
          </button>
        </div>
      </form>
      </div>
  </div>
</div>

<div *ngIf="error.errorMessage" class="ms-modal-message">
  <div class="modal-content w-25">
    <div class="modal-header" [ngClass]="{'bg-info': error.errorType == 'info',
                                  'bg-warning': error.errorType == 'warning',
                                  'bg-danger': error.errorType == 'error'}">
      <h5 *ngIf="error.errorType == 'info'" class="modal-title"
        i18n="laf|Modal message error - header information@@laf.main.modalMessage.errMes.header.inf">
        Information
      </h5>
      <h5 *ngIf="error.errorType == 'warning'" class="modal-title"
        i18n="laf|Modal message error - header watning@@laf.main.modalMessage.errMes.header.warn">
        Warning
      </h5>
      <h5 *ngIf="error.errorType == 'error'" class="modal-title"
        i18n="laf|Modal message error - header error@@laf.main.modalMessage.errMes.header.err">
        Error
      </h5>
      <button type="button" class="close" (click)="clearErrorMess()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      {{error.errorMessage}}
    </div>
    <div class="modal-footer">
      <!-- <button type="button" *ngIf="userAction==='setAnswer'"
              class="btn btn-sm btn-blue"
              (click)="userAnswer = true"
              i18n="cmn|Modal message error - button yes@@cmd.main.modalMessage.errMes.button.yes">
        Yes
      </button>
      <button type="button" *ngIf="userAction==='setAnswer'"
              class="btn btn-sm btn-gray-blue-dark"
              (click)="userAnswer = false"
              i18n="cmn|Modal message error - button no@@cmd.main.modalMessage.errMes.button.no">
        No
      </button> -->
      <button type="button"
              class="btn btn-sm btn-gray-blue-dark"
              (click)="clearErrorMess()"
              i18n="laf|Modal message error - button close@@laf.modalCreateMessage.buttons.close">
        Close
      </button>
    </div>
  </div>
</div>
