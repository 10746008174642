import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {
  AdministrationFilter,
  AdministrationGroup,
  AdministrationUser
} from './com-module';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { GlobalSettings } from '../settings/global-settings';
import { AhmData, GroupUsers, User, Workspace } from '../weight-balance-data/weight-balance';

@Injectable({
  providedIn: 'root'
})
export class BalanceRestApiService {

  constructor(private http: HttpClient, private globalSettings: GlobalSettings) {
    globalSettings.loadDefaultConfig();
  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  // Filters
//   getFilters(): Observable<AdministrationFilter[]> {
//     return this.http.get<AdministrationFilter[]>(this.globalSettings.apiAdministrationURL + '/admin/filters?current=true')
//     .pipe(
//       retry(1),
//       catchError(this.handleError)
//     );
//   }

//   getFilter(id: string): Observable<AdministrationFilter> {
//     return this.http.get<AdministrationFilter>(this.globalSettings.apiAdministrationURL +
//                                            '/admin/filters/' +
//                                            id)
//     .pipe(
//       retry(1),
//       catchError(this.handleError)
//     );
//   }

//   addFilter(foder: any): Observable<AdministrationFilter> {
//     return this.http.post<AdministrationFilter>(this.globalSettings.apiCommunicationURL +
//                                                 '/admin/filters',
//                                                 JSON.stringify(foder),
//                                                 this.httpOptions)
//     .pipe(
//       retry(1),
//       catchError(this.handleError)
//     );
//   }

//   updateFilter(id: number, filter: any): Observable<AdministrationFilter> {
//     return this.http.put<AdministrationFilter>(this.globalSettings.apiCommunicationURL +
//                                               '/admin/filters/' + id,
//                                               JSON.stringify(filter),
//                                               this.httpOptions)
//     .pipe(
//       retry(1),
//       catchError(this.handleError)
//     );
//   }

  // Groups
  getGroups(): Observable<GroupUsers[]> {
    return this.http.get<GroupUsers[]>(this.globalSettings.apiWeightBalanceURL + '/admin/groups')
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getGroup(id: number): Observable<GroupUsers> {
    return this.http.get<GroupUsers>(this.globalSettings.apiWeightBalanceURL +
                                              '/admin/groups/' + id)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  addGroup(group: any): Observable<GroupUsers> {
    return this.http.post<GroupUsers>(this.globalSettings.apiWeightBalanceURL +
                                              '/admin/groups', JSON.stringify(group), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  updateGroup(id: string, group: any): Observable<GroupUsers> {
    return this.http.put<GroupUsers>(this.globalSettings.apiWeightBalanceURL +
                                              '/admin/groups/' + id,
                                              JSON.stringify(group),
                                              this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // Users
  getUsers(): Observable<User[]> {
    return this.http.get<User[]>(this.globalSettings.apiWeightBalanceURL + '/admin/users?current=true')
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getUser(id: number): Observable<User> {
    return this.http.get<User>(this.globalSettings.apiWeightBalanceURL +
                                           '/admin/users/' + id)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  addUser(user: any): Observable<User> {
    return this.http.post<User>(this.globalSettings.apiWeightBalanceURL +
                                            '/admin/users', JSON.stringify(user), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  updateUser(id: string, user: any): Observable<User> {
    return this.http.put<User>(this.globalSettings.apiWeightBalanceURL +
                                       '/admin/users/' + id,
                                       JSON.stringify(user),
                                       this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  downloadFile(link: string) {
    return this.http.get(this.globalSettings.apiWeightBalanceURL + '/admin/' + link, {responseType: 'blob'})
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getWorkspaces(showRemoved?): Promise<Workspace[]> {
    let params = new HttpParams();
    if (showRemoved) {
      params = params.append('showRemoved', showRemoved);
    }

    return this.http.get<Workspace[]>(this.globalSettings.apiWeightBalanceURL + '/admin/workspaces', { params })
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  getWorkspace(id: number): Observable<Workspace> {
    return this.http.get<Workspace>(this.globalSettings.apiWeightBalanceURL + '/admin/workspaces/' + id)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  addWorkspace(workspace: any): Observable<Workspace> {
    return this.http.post<Workspace>(this.globalSettings.apiWeightBalanceURL +
                                                '/admin/workspaces',
                                                JSON.stringify(workspace),
                                                this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  updateWorkspace(workspace): Observable<AdministrationFilter> {
    return this.http.put<AdministrationFilter>(this.globalSettings.apiWeightBalanceURL +
                                              '/admin/workspaces/' + workspace.id,
                                              JSON.stringify(workspace),
                                              this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // mamleev

  // Ahms
  getAhms(filterParams): Promise<AhmData[]> {
    let params = new HttpParams();
    let url = '/ahms';
    if (filterParams) {
      for (const key in filterParams) {
        if (filterParams[key]) {
          params = params.append(key, filterParams[key]);
        }
      }
    }

    return this.http.get<AhmData[]>(this.globalSettings.apiWeightBalanceURL + url, { params })
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  getAhm(id): Promise<AhmData> {
    return this.http.get<AhmData>(this.globalSettings.apiWeightBalanceURL +
                                              '/ahms/' + id)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  addAhm(ahm: any): Observable<AhmData> {
    return this.http.post<AhmData>(this.globalSettings.apiWeightBalanceURL +
                                                '/ahms',
                                                JSON.stringify(ahm),
                                                this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  updateAhm(ahm: any): Observable<AhmData> {
    return this.http.put<AhmData>(this.globalSettings.apiWeightBalanceURL +
                                                '/ahms/' + ahm.id,
                                                JSON.stringify(ahm),
                                                this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  copyAhm(ahm: any): Observable<AhmData> {
    return this.http.post<AhmData>(this.globalSettings.apiWeightBalanceURL +
                                                '/ahms/' + ahm.id+ '/copy',
                                                JSON.stringify(ahm),
                                                this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getAhmsData(ahmId: number, removed): Promise<AhmData[]> {
    let params = new HttpParams();
    if (removed) {
      params = params.append('removed', 'true');
    }
    return this.http.get<AhmData[]>(this.globalSettings.apiWeightBalanceURL +
                                              '/ahm_data_by_ahm_id/' + ahmId, { params })
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  getAhmData(id): Promise<AhmData> {
    return this.http.get<AhmData>(this.globalSettings.apiWeightBalanceURL +
                                              '/ahm_data/' + id)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  addAhmData(ahm: any, compartments): Observable<AhmData> {
    ahm.holdsAndCompartments = compartments;
    return this.http.post<AhmData>(this.globalSettings.apiWeightBalanceURL +
                                                '/ahm_data',
                                                JSON.stringify(ahm),
                                                this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  updateAhmData(id: number, ahm: any, compartments): Observable<AhmData> {
    ahm.holdsAndCompartments = compartments;
    console.log(ahm);

    return this.http.put<AhmData>(this.globalSettings.apiWeightBalanceURL +
                                                '/ahm_data/' + id,
                                                JSON.stringify(ahm),
                                                this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  exportAhmData(id: number) {
    return this.http.get(this.globalSettings.apiWeightBalanceURL +
                                                '/ahm_data/' + id + '?export=xls',
                                                {responseType: 'blob'})
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  deleteAhmData(id: number): Observable<AhmData> {
    return this.http.delete<AhmData>(this.globalSettings.apiWeightBalanceURL +
                                                '/ahm_data/' + id,
                                                this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // reference
  getReference(name): Promise<any[]> {
    return this.http.get<any[]>(this.globalSettings.apiWeightBalanceURL +
                                              '/master_data/' + name)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  // Error handling
  handleError(error) {
    console.log(error);
    let errorMessage = '';
    let errorDetail: any = null;
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorDetail = error.error;
      errorDetail.status = error.status;
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    if (errorDetail) {
      return throwError(errorDetail);
    } else {
      return throwError(errorMessage);
    }
  }
}

