export class CompartmentsMap {
  constructor() {
    this.width = null;
    this.height = null;
    this.proportions = null;
    this.trunkMap = [];
    this.darggableCargo = null;
    this.targetBay = null;
    this.filterType = '';
  }
  width: number;
  height: number;
  proportions: number;
  loading: boolean;
  // груз, который тащим мышью
  darggableCargo;
  // багажник, на который притащили
  targetBay: any;
  filterType: string;
  // map or table
  locationEvent: string;
  trunkMap: Array<{
    width: number;
    height: number;
    doors: Array<{
      width: number;
      right: number;
    }>;
    bays: Array<{
      name: string;
      uldTypes: Array<any>;
      category: string;
      maxWeight: number;
      maxVolume: number;
      styles: {
        width: number;
        height: number;
        right: number;
      };
    }>;
  }>;
}

export function generateMap({bayMap, holdsAndCompartments, uldTypes, uldBaseSizes}) {
  const compartmentsMap = new CompartmentsMap();

  const sizes = getMapSizeAndProportions(bayMap, holdsAndCompartments);
  compartmentsMap.width = sizes.length;
  compartmentsMap.proportions =  sizes.proportions;

  compartmentsMap.trunkMap = generateBayMap(holdsAndCompartments, compartmentsMap, uldTypes, uldBaseSizes);

  return compartmentsMap;
}

export function getMapSizeAndProportions(bayMap, holdsAndCompartments) {
  const parent = bayMap.parentElement.offsetWidth - 30;
  let length = 0;
  if (holdsAndCompartments.acSize && holdsAndCompartments.acSize.length > 0) {
    holdsAndCompartments.acSize.forEach(size => {
      length += size.finish - size.start;
    });
    const proportions =  +(parent / length).toFixed(2);
    return { length, proportions };
  } else {
    return { length: null, proportions: null };
  }
}

function generateBayMap(holdsAndCompartments, compartmentsMap, uldTypes, uldBaseSizes) {
  const trunkMap = [];

  if (holdsAndCompartments.acSize && holdsAndCompartments.acSize.length > 0) {
    holdsAndCompartments.acSize.forEach(compartment => {
      const data = {
        width: compartment.finish - compartment.start,
        height: 0,
        doors: [],
        bays: [],
      };
      // добавляем двери
      holdsAndCompartments.doors.forEach(door => {
        if (door.centroid >= compartment.start && door.centroid < compartment.finish) {
          data.doors.push({
            width: door.to - door.from,
            right: door.from  - compartment.start
          });
        }
      });
      // добавляем контейнеры
      holdsAndCompartments.aft.forEach(bay => {
        const bayItem = generateContainerForMap(bay, compartment, compartmentsMap, uldTypes, uldBaseSizes);
        if (bayItem) {
          data.bays.push(bayItem);
        }
        if (bay.bays && bay.bays.length > 0) {
          bay.bays.forEach(bay => {
            const bayItem = generateContainerForMap(bay, compartment, compartmentsMap, uldTypes, uldBaseSizes);
            if (bayItem) {
              data.bays.push(bayItem);
            }
          });
        }
      });
      holdsAndCompartments.fwd.forEach(bay => {
        const bayItem = generateContainerForMap(bay, compartment, compartmentsMap, uldTypes, uldBaseSizes);
        if (bayItem) {
          data.bays.push(bayItem);
        }
        if (bay.bays && bay.bays.length > 0) {
          bay.bays.forEach(bay => {
            const bayItem = generateContainerForMap(bay, compartment, compartmentsMap, uldTypes, uldBaseSizes);
            if (bayItem) {
              data.bays.push(bayItem);
            }
          });
        }
      });
      data.bays.forEach(bay => {
        console.log(bay);
        const interception = holdsAndCompartments.interceptions.find(el => el.cmp1 === bay.name);
        const pos = bay.name.slice(-1).toUpperCase();
        if (interception && pos !== 'R' && pos !== 'L') {
          bay.styles.bottom = 0.3;
          bay.styles.height = bay.styles.height - 0.6;
          bay.styles.index = 10;
        }
        if (bay.type === 'bulk') {
          bay.styles.height = compartmentsMap.height;
        }
        console.log(bay);
      });
      trunkMap.push(data);
    });
  }

  return trunkMap;
}

function generateContainerForMap(bay, compartment, compartmentsMap, uldTypes, uldBaseSizes) {
  if (bay.centroid >= compartment.start && bay.centroid < compartment.finish) {
    if (bay.type === 'bulk') {
      return {
        name: bay.name,
        uldTypes: [bay.type.toUpperCase()],
        category: bay.type,
        maxWeight: bay.maxWeight,
        maxVolume: bay.volume,
        group: bay.group,
        styles: {
          width: bay.to - bay.from,
          height: 3.18,
          right: bay.from - compartment.start,
        }
      };
    }
    if (bay.uldTypeIds && bay.uldTypeIds.length > 0) {
      const uld = uldTypes.find(el => el.id === bay.uldTypeIds[0]);
      const baseSize = uldBaseSizes.find(el => el.id === uld.baseSizeId);
      const bayItem: any = {
        name: bay.name,
        uldTypes: bay.uldTypes,
        category: bay.type.toLowerCase() === 'bulk' ? bay.type : uld.category,
        maxWeight: bay.maxWeight,
        maxVolume: bay.volume,
        group: bay.group,
        styles: {
          width: (bay.orientation === 'LR' ? baseSize.width / 100 : baseSize.length / 100),
          height: (bay.orientation === 'LR' ? baseSize.length / 100 : baseSize.width / 100),
          right: (bay.centroid - (bay.orientation === 'LR' ? baseSize.width / 200 : baseSize.length / 200) - compartment.start),
        }
      };
      if (bay.name.slice(-1).toUpperCase() === 'L') {
        bayItem.styles.top = 0;
        if (baseSize.length / 100 > compartmentsMap.height) {
          compartmentsMap.height = baseSize.length / 100 * 2;
        }
      } else if (bay.name.slice(-1).toUpperCase() === 'R') {
        bayItem.styles.bottom = 0;
        if (baseSize.length / 100 > compartmentsMap.height) {
          compartmentsMap.height = baseSize.length / 100 * 2;
        }
      } else if (baseSize.length / 100 > compartmentsMap.height) {
        compartmentsMap.height = baseSize.length / 100;
      }
      return bayItem;
    }
  } else {
    return false;
  }
}
