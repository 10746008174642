import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BagData, CommercialAct, Statement, StatementData } from '../lost-found-data/lost-found';
import { throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { GlobalSettings } from '../settings/global-settings';

@Injectable({
  providedIn: 'root'
})

export class LostFoundModuleRestApiService {

  constructor(private http: HttpClient,
              private globalSettings: GlobalSettings) {
    globalSettings.loadDefaultConfig();
  }

  // Marks
  getMarks(xRequestId?): Promise<any> {
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<any>(this.globalSettings.apiLostFoundURL + '/marks', httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  setDefaultHttpHeader(requestId?): Object {
    // Формирование заголовков для отслеживания запросов
    // X-Correlation-ID идентификатор пользовательской сессии
    // X-Request-ID идентификатор события / запроса
    let httpOptions = {};
    httpOptions['headers'] = { 'Content-Type' : 'application/json',
                               'X-Correlation-ID' : this.globalSettings.userSessionUUID,
                               'X-Request-ID' : (requestId === undefined) ? this.globalSettings.randomUuid : requestId };
    return httpOptions;
  }

  getStatements(getParams, filterParams, times, xRequestId): Promise<Statement[]> {
    let url = `/statements/${getParams.perPage}/${getParams.pageActive}`;
    const params = this.createParams(filterParams, times);
    if (params.length > 0) {
      url += '?' + params.join('&');
    }

    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<Statement[]>(this.globalSettings.apiLostFoundURL + url, httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  getCountStatements(filterParams, times, xRequestId?): Promise<any> {
    let url = `/statements/counters`;
    const params = this.createParams(filterParams, times);
    if (params.length > 0) {
      url += '?' + params.join('&');
    }
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<number>(this.globalSettings.apiLostFoundURL + url, httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  createParams(filterParams, times) {
    const newParams = {...filterParams};
    const params = [];
    if (times.length > 0) {
      const dayMilliseconds = 24 * 60 * 60 * 1000 * Math.max(...times);
      const currentDate = new Date();
      currentDate.setTime(currentDate.getTime() - dayMilliseconds);
      newParams.finish = currentDate;
    }
    if (newParams) {
      for (const key in newParams) {
        if (Object.prototype.hasOwnProperty.call(newParams, key)) {
          if ((key === 'start' || key === 'finish') && newParams[key]) {
            params.push(key + '=' + newParams[key].toISOString());
          } else if (newParams[key] && newParams[key] != null && newParams[key] != '') {
            if (newParams[key + 'Flag'] && newParams[key + 'Flag'] === '!') {
              params.push(key + '=!' + newParams[key]);
            } else {
              params.push(key + '=' + newParams[key]);
            }
          }
        }
      }
    }
    return params;
  }

  getStatement(id: string, xRequestId): Promise<StatementData> {
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<StatementData>(this.globalSettings.apiLostFoundURL +
                                        `/statements/${id}`,
                                        httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  addStatement(statement: StatementData, xRequestId?): Promise<any> {
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    httpOptions['observe'] = 'response';
    return this.http.post(this.globalSettings.apiLostFoundURL +
                          `/statements`,
                          statement,
                          httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  updateStatement(statement, xRequestId?): Promise<StatementData> {
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.put<StatementData>(this.globalSettings.apiLostFoundURL +
                         `/statements/${statement.id}`,
                         JSON.stringify(statement),
                         httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  getFromBagData(id: string, xRequestId): Promise<BagData[]> {
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<BagData[]>(this.globalSettings.apiLostFoundURL +
                                        `/baggage/${id}/bagdata`,
                                        httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  makeBaggageSelected(id: string, bagdataId: string, xRequestId): Promise<BagData[]> {
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.patch<BagData[]>(this.globalSettings.apiLostFoundURL +
                                        `/baggage/${id}/select/${bagdataId}`,
                                        httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  makeBaggageUnselected(id: string, bagdataId: string, xRequestId): Promise<BagData[]> {
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.patch<BagData[]>(this.globalSettings.apiLostFoundURL +
                                        `/baggage/${id}/unselect/${bagdataId}`,
                                        httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  detDocument(id: string, type: string, xRequestId) {
    // console.log(this.globalSettings.apiSlotCoordinationURL + url + '?' + params.join('&'));
    return this.http.get(this.globalSettings.apiLostFoundURL + `/documents/${id}?document=${type}`, { responseType: 'blob' })
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getCommercialAct(id: number, xRequestId): Promise<CommercialAct> {
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<CommercialAct>(this.globalSettings.apiLostFoundURL +
                                        `/commercial-act/${id}`,
                                        httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  updateCommercialAct(commercialAct, xRequestId?): Promise<CommercialAct> {
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.put<CommercialAct>(this.globalSettings.apiLostFoundURL +
                         `/commercial-act/${commercialAct.id}`,
                         JSON.stringify(commercialAct),
                         httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  // Marks

  // setMark(id: number, folderId: number, mark: string, xRequestId?) {
  //   const httpOptions = this.setDefaultHttpHeader(xRequestId);
  //   return this.http.put(this.globalSettings.apiLostFoundURL +
  //                        `/messages/${id}/folders/${folderId}/mark/${mark}`,
  //                        httpOptions)
  //     .pipe(
  //       retry(1),
  //       catchError(this.handleError)
  //     );
  // }

  // unsetMark(id: number, folderId: number, xRequestId?) {
  //   const httpOptions = this.setDefaultHttpHeader(xRequestId);
  //   return this.http.put(this.globalSettings.apiLostFoundURL +
  //                        `/messages/${id}/folders/${folderId}/unmark`,
  //                        httpOptions)
  //     .pipe(
  //       retry(1),
  //       catchError(this.handleError)
  //     );
  // }

  // reference
  getReference(name, xRequestId?): Promise<any[]> {
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<any[]>(this.globalSettings.apiLostFoundURL +
                                '/master_data/' + name, httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  getAdminData(name, xRequestId?): Promise<any[]> {
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<any[]>(this.globalSettings.apiLostFoundURL +
                                '/admin/' + name, httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  setMark(id: string, mark: string, xRequestId?) {
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.patch(this.globalSettings.apiLostFoundURL +
                         `/statements/${id}/mark/${mark}`,
                         httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  unsetMark(id: string, xRequestId?) {
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.patch(this.globalSettings.apiLostFoundURL +
                         `/statements/${id}/unmark`,
                         httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  // Error handling
  handleError(error) {
    let errorMessage = '';
    let errorDetail: any = null;
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorDetail = error.error;
      errorDetail.status = error.status;
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }

    if (errorDetail) {
      return throwError(errorDetail);
    } else {
      return throwError(errorMessage);
    }
  }
}
